export default function Owlbear(): JSX.Element {
  return (
    <>
      <path
        d="M963.53,2048c-2.88-11.55-4.63-23.29-7.84-34.62c-7.83-27.58-24.55-48.82-48.43-64.45c-11.14-7.29-22.12-14.99-33.87-21.17
		 c-33.98-17.86-61.69-43.31-87.93-70.68c-12.63-13.17-24.29-27.07-32.5-43.57c-5.25-10.55-9.87-21.41-10.6-33.19
		 c-0.25-4.1,2.21-9.11,4.94-12.5c6.24-7.75,14.99-11.58,24.95-11.73c21.03-0.32,41.5,2.67,60.39,12.48
		 c13.27,6.89,26.21,14.4,39.24,21.74c8.08,4.55,16.05,9.29,24.64,14.26c0.86,0.23,1.14,0.15,1.42,0.07
		 c-0.24-0.27-0.48-0.55-0.72-0.82c-4.72-4.61-9.02-9.76-14.22-13.73c-35.45-27.02-69.58-55.43-97.65-90.38
		 c-16.41-20.44-30.88-42.15-41.57-66.16c-2.75-6.17-4.72-12.79-6.19-19.4c-1.78-7.98,1.54-12.51,9.61-11.63
		 c9.29,1.01,19.07,2.54,27.4,6.46c24.5,11.53,48.47,24.21,72.51,36.71c17.78,9.25,35.34,18.94,53.51,28.76
		 c0.83-0.07,1.15-0.47,1.47-0.86c-3.48-3-6.66-6.49-10.5-8.93c-20.61-13.13-41.39-26-62.1-38.99c-1.25-0.79-2.57-1.65-3.48-2.78
		 c-23.3-29.03-44.97-59.09-58.46-94.19c-6.12-15.93-10.94-32.23-10.17-49.52c0.24-5.4,1.34-10.97,3.21-16.03
		 c3.91-10.58,13.43-14.95,25.72-12.74c10.49,1.89,20.24,5.82,28.88,11.87c21.81,15.29,43.35,30.97,64.99,46.52
		 c4.17,2.99,8.29,6.05,12.43,9.08c0.39-0.28,0.79-0.56,1.18-0.84c-3.06-4.56-5.39-9.89-9.29-13.56
		 c-20.5-19.34-41.8-37.75-65.82-52.71c-12.81-7.98-26.43-13.55-41.92-12.07c-15.9,1.52-26.63,13.44-28.9,30.8
		 c-2.92,22.25,2.79,42.97,10.73,63.27c10.84,27.7,26.6,52.47,45.88,75.05c1.12,1.31,2.02,2.82,2.42,4.84
		 c-8-4.08-15.95-8.25-24.01-12.23c-10.05-4.96-20.43-8.82-31.75-9.86c-19.67-1.81-29.59,9.19-25.63,28.66
		 c3.14,15.38,10.37,29.06,17.86,42.62c1.2,2.17,2.46,4.3,3.64,6.36c-10.28-1.47-20.39-2.94-30.52-4.35
		 c-21.43-2.98-42.98-5.3-64.26-9.09c-13.98-2.5-27.59-7.1-41.08-10.69c1.09-10.42,2.84-19.66,2.88-28.91
		 c0.17-36.48,0.33-72.98-0.51-109.45c-0.77-33.31-2.61-66.6-4.43-99.88c-0.91-16.57-2.83-33.09-5.25-49.86
		 c-0.27,1.02-0.67,2.03-0.78,3.06c-7.24,69.1-4.17,138.45-5.24,207.71c-0.31,19.95-2.12,39.91-3.79,59.81
		 c-1.24,14.82-7.63,27.54-18.5,37.75c-15.3,14.38-30.52,28.86-46.23,42.77c-21.54,19.08-46.21,34.06-69.04,51.48
		 c-13.75,10.5-27.89,20.49-41.66,30.97c-5.9,4.49-11.19,9.79-16.98,14.45c-7.26,5.84-8.42,14.07-8.32,22.28
		 c0.24,19.74,0.86,39.48,1.95,59.19c0.68,12.3,2.52,24.52,3.68,36.8c0.1,1.03-0.65,2.79-1.48,3.16
		 c-23.09,10.27-33.98,30.37-42.55,52.4c-0.23,0.6-0.6,1.15-1.19,2.26c-1.23-2.51-2.26-4.61-3.29-6.71
		 c-6.49-13.23-15.13-24.69-27.46-33.06c-18.95-12.87-38.71-11.51-55.76,3.94c-12.53,11.36-20.26,25.81-26.27,41.33
		 c-0.71,1.84-1.46,3.68-2.26,5.69c-2.92-7.86-5.13-15.73-8.7-22.94c-3.96-8-8.4-16-13.96-22.92
		 c-13.37-16.64-35.43-27.74-63.44-12.75c1.82-4.38,3.15-8.06,4.84-11.56c13.94-28.89,23.86-59.32,34.58-89.45
		 c8.83-24.81,17.35-49.83,29.97-73c12-22.04,25.39-43.32,37.84-65.12c14.67-25.67,30.58-50.86,35.54-80.8
		 c0.48-2.88,0.56-6-0.03-8.84c-3.9-18.73-11.71-36.01-19.76-53.24c-10.22-21.85-20.58-43.65-29.78-65.93
		 c-5.72-13.83-8.48-28.58-7.88-43.87c0.56-14.41,3.66-28.32,8.54-41.62c13.09-35.69,26.72-71.18,40.64-106.55
		 c9.89-25.12,20.08-50.16,31.1-74.81c12.23-27.36,26.55-53.55,49.88-73.48c19.8-16.91,41.73-30.45,66.2-39.32
		 c30.4-11.01,61.12-21.12,91.66-31.73c45.55-15.81,91.11-31.6,136.59-47.62c38.33-13.5,76.77-26.73,114.73-41.2
		 c26.27-10.02,51.97-21.58,77.62-33.12c26.88-12.1,53.71-24.4,80-37.71c24-12.15,47.45-25.42,70.72-38.94
		 c7.72-4.49,14.31-11.05,21.09-17.03c8.42-7.42,17.02-14.38,28.2-17.2c2.36-0.6,4.74-1.19,7.15-1.52c3.58-0.5,6.46,0.33,9.3,3.2
		 c12.06,12.19,25.8,22.1,41.97,28.19c3.14,1.18,6.76,1.5,10.16,1.47c7.12-0.05,14.23-0.59,21.71-1.63c-4.2-2.01-8.3-4.24-12.6-5.99
		 c-25.06-10.15-46.16-25.67-63.47-46.38c-4.51-5.4-4.12-7.52,2.22-10.98c7.87-4.3,16.66-5.53,25.24-4.7
		 c11.68,1.13,23.37,3.13,34.77,5.9c21.06,5.13,40.36,14.67,58.96,25.61c1.95,1.15,3.59,2.92,5.16,4.6
		 c16.46,17.58,34.15,33.72,54.79,46.32c3.57,2.18,7.78,3.29,11.69,4.9c0.26-0.4,0.52-0.81,0.78-1.21
		 c-7.81-8.75-15.46-17.65-23.45-26.23c-15.87-17.07-32.05-33.86-47.82-51.02c-4.14-4.5-7.3-9.91-10.8-14.98
		 c-4.06-5.89-2.93-10.49,3.39-14.16c7.54-4.37,15.44-3.21,23.33-1.33c22.17,5.28,41.41,16.62,59,30.45
		 c16.08,12.63,31.36,26.36,46.35,40.3c9.45,8.78,17.78,18.78,26.49,28.34c1.28,1.41,2.04,3.36,2.81,5.16
		 c3.45,8.11,6.44,16.44,10.38,24.29c1.74,3.46,5.05,6.37,8.19,8.83c4.3,3.38,9.12,6.09,14.13,9.38c0.66,0.31,0.92,0.32,1.18,0.33
		 c-0.17-0.33-0.33-0.67-0.5-1c-10.79-16.22-16.39-34.7-23.06-52.72c-3.05-8.24-2.14-16.22,0.44-24.41
		 c3.18-10.08,15.43-14.78,24.85-9.53c8.21,4.58,16.51,9.5,23.47,15.72c22.53,20.14,39.56,44.62,53.64,71.23
		 c0.99,1.87,2.13,3.67,3.22,5.48c5.28,8.72,7.32,18.07,8.02,28.35c1.54,22.74,11.31,42.09,27.65,58.05c1.78,1.74,3.54,3.52,5.72,5.7
		 c0.76,0.57,1.1,0.72,1.45,0.87c-0.22-0.48-0.44-0.97-0.66-1.45c-3.49-9.48-6.63-19.11-10.56-28.4
		 c-4.95-11.69-10.07-23.46-10.64-36.16c-0.3-6.63,2.02-13.66,4.47-20c0.95-2.47,5.33-4.51,8.45-5.09
		 c11.98-2.23,22.02,2.01,31.17,9.84c14.69,12.58,25.48,28.39,37.01,43.57c19.23,25.32,37.22,51.63,57.26,76.28
		 c15.85,19.51,33.26,37.93,51.35,55.41c13.18,12.73,28.12,23.82,43.19,34.33c9.96,6.94,21.55,11.53,32.39,17.21
		 c2.57,1.34,5.07,2.83,7.45,5.17c-6.11-0.53-12.21-1.3-18.33-1.56c-45.9-1.96-87.34-18.7-127.01-39.89
		 c-34.89-18.64-69.14-38.59-102.9-59.22c-15.25-9.32-28.63-21.71-42.86-32.7c-2.37-1.83-4.54-4.09-7.18-5.36
		 c-1.82-0.88-4.9-1.27-6.29-0.29c-1.32,0.93-2.11,4.24-1.53,5.97c3.06,9.13,6.38,18.2,10.06,27.11
		 c24.07,58.15,63.29,104.43,112.41,142.9c1.8,1.41,3.57,2.87,5.42,4.37c-0.65,0.52-0.99,0.91-1.43,1.13
		 c-11.2,5.67-19.84,13.71-27.17,24.19c-18.2,26.01-31.11,54.21-38.26,85.02c-9.01,38.8-9.22,77.57,2.13,116.02
		 c12.66,42.9,38.7,75.5,77.11,98.03c36.76,21.56,76.59,33.78,119.15,36.84c46.58,3.36,85.75-12.75,118.56-45.4
		 c25.04-24.91,41.48-55.21,54.5-87.56c8.69-21.58,15.35-43.81,12.64-67.4c-1.65-14.34-4.9-28.51-7.89-42.66
		 c-3.34-15.76-7.6-31.32-14.8-45.82c-2.77-5.58-6.61-10.66-10.16-15.83c-2.72-3.96-3.12-7.46,0.55-11.13
		 c1.48-1.48,2.35-3.6,3.45-5.46c3.27-5.52,6.45-5.76,10.21-0.79c9.91,13.11,17.18,27.68,22.91,42.96
		 c6.54,17.43,10.63,35.45,12.35,54.08c2.14,23.23,0.16,46.06-4.77,68.73c-5.51,25.33-13.87,49.79-23.42,73.82
		 c-5.39,13.55-13.54,25.58-22.58,37c-16.44,20.76-38.37,34-61.59,45.69c-16.52,8.32-33.43,15.32-51.69,18.59
		 c-8.22,1.47-16.51,2.91-24.83,3.38c-14.09,0.79-28.25,1.57-42.32,0.92c-15.42-0.71-30.82-2.71-46.14-4.77
		 c-19.47-2.62-38.85-5.89-58.27-8.86c-21.17-3.24-42.3-6.77-63.52-9.6c-26.37-3.52-52.07-0.83-75.62,12.34
		 c-20.18,11.28-40.28,22.83-59.52,35.61c-46.27,30.73-94.74,56.92-147.82,73.8c-15.15,4.82-30.93,7.64-46.24,11.99
		 c-2.81,0.8-5.99,4.19-6.89,7.05c-2.57,8.2-4.91,16.64-5.83,25.15c-3.43,31.55-4.39,63.3,0,94.74c3.21,22.94,3.78,46.47,12.69,68.45
		 c3.53,8.7,7.77,16.69,15.41,22.76c-0.88,2.49-1.73,4.91-2.68,7.63c-15.19-13.16-31.08-11.38-47.16-2.85
		 c-15.76,8.36-27.34,21.02-36.33,36.23c-0.59,1-1.11,2.04-1.69,3.04c-0.07,0.12-0.27,0.15-0.72,0.39c-2.41-2.26-4.84-4.7-7.44-6.95
		 c-21.77-18.86-46.57-18.37-66.19,2.62c-20.15,21.56-31.37,47.91-37.18,77.04C984.71,2048,974.35,2048,963.53,2048z M903.54,1675.83
		 c0.09-0.22,0.17-0.43,0.26-0.65C903.57,1675.25,903.33,1675.31,903.54,1675.83z"
      />
      <path
        d="M1476.57,2048c-2.97-1.72-5.31-3.73-7.99-5.11c-15.95-8.25-33.42-11.2-50.86-13.85c-22.06-3.36-44.25-5.84-66.33-9.14
		 c-6.02-0.9-11.83-3.28-17.69-5.12c-2.09-0.65-3.03-1.56-1.95-4.39c5.21-13.66,15.89-21.1,28.68-26.45
		 c9.32-3.9,19.16-4.64,29.05-5.33c13.43-0.93,26.85-1.96,40.65-3.58c-8.72-3.03-17.23-7.05-26.2-8.85
		 c-14.16-2.85-28.68-3.86-42.95-6.28c-25.05-4.26-49.42-10.95-71.84-23.31c-13.66-7.52-23.62-18.32-27.49-33.9
		 c-2.84-11.45-1.06-21.9,6.43-31.28c7.17-8.98,14.15-18.1,20.47-26.21c-15.01,0.25-31.01,1.35-46.92,0.59
		 c-20.38-0.98-40.01-5.92-57.78-16.51c-1.9-1.13-3.97-3.9-4-5.93c-0.31-24.46-0.55-48.94-0.12-73.4c0.18-9.89,2.05-19.76,3.15-29.55
		 c31.89,2.36,60.47-7.69,88.64-18.9c40.83-16.26,78.94-37.66,115.35-62.25c15.57-10.52,31.9-19.92,47.86-29.89
		 c22.31-13.93,46.58-16.93,72.11-13.02c4.43,0.68,8.86,1.3,12.9,1.89c0.47,6.84,1.56,13.41,1.22,19.91
		 c-0.64,12.65-1.22,25.42-3.38,37.86c-3.85,22.19-14.42,42.01-23.97,62.14c-4.05,8.54-7.94,17.05-8.65,26.81
		 c-0.75,10.27,2.91,19.17,7.67,27.55c16.44,28.98,36.04,55.82,56.64,81.95c13.78,17.47,29.48,33.1,46.35,47.57
		 c11.1,9.52,21.61,19.85,33.38,28.47c23.56,17.27,49.42,21.28,77.08,10.44c4.95-1.94,10.1-3.41,15.21-4.95
		 c12.78-3.85,24.87-1.83,34.7,6.91c7.23,6.43,13.71,14.09,19.08,22.17c11.9,17.9,18.57,38.29,25.7,58.39
		 c2.78,7.84,5.11,15.85,7.5,24.17C1697.76,2048,1587.4,2048,1476.57,2048z"
      />
      <path
        d="M551.5,2048c-4.13-12.74-7.72-25.51-11.48-38.22c-8.15-27.58-18.77-54.2-31.64-79.9c-12.53-25.02-25.58-49.8-37.79-74.98
		 c-6.21-12.8-12.55-25.75-14.66-40.08c-0.3-2.03,0.4-4.94,1.76-6.35c15.52-16.02,30.94-32.15,47.01-47.6
		 c26.89-25.86,54.34-51.13,81.32-76.9c8.82-8.43,16.21-18.12,21.15-30.11c4.43,1.52,8.8,2.91,13.08,4.51
		 c29.37,10.99,60.07,15.79,90.98,19.6c10.89,1.34,21.97,3.57,32.73,2.67c9.06-0.76,13.73,2.17,17.5,9.4
		 c1.52,2.91,3.82,5.4,6.25,8.76c-10.98,12.67-21.39,25.86-33.03,37.85c-20.12,20.73-43.22,37.95-67.02,54.23
		 c-5.13,3.51-10.29,6.97-15.29,10.64c-6.26,4.6-10.15,10.93-9.11,18.67c1.77,13.26,3.59,26.64,7.02,39.53
		 c7.52,28.25,17.57,55.59,33.88,80.19c1.89,2.85,4.06,5.51,6.24,8.43c-19.7,10.07-33.83,25.87-46.36,43.29
		 c-12.46,17.33-23.23,35.63-30.82,56.06C592.68,2048,572.33,2048,551.5,2048z"
      />
      <path
        d="M112.47,2048c1.6-11.53,2.35-23.51,6.03-34.5c6.24-18.65,15.33-36.19,28.47-51.12
		 c4.34-4.93,9.5-9.45,15.04-12.94c11.03-6.94,22.27-5.69,32.39,2.56c8.07,6.58,13.53,15.11,17.62,24.51
		 c9.86,22.59,15.47,46.37,19.08,71.07C191.66,2048,152.3,2048,112.47,2048z"
      />
      <path
        d="M1220.55,2048c-0.91-25.22-2.96-50.24-10.37-74.52c-1.89-6.19-4.66-12.1-6.66-18.26
		 c-0.64-1.97-0.84-4.66,0-6.43c1.95-4.09,3.77-9.28,7.24-11.39c8.27-5.02,17.7-7.5,27.65-6.06c3.6,0.52,7.17,1.35,10.73,2.12
		 c13.5,2.93,21.71,13.12,28.19,23.62c13.37,21.69,21.66,45.73,27.76,70.45c1.6,6.48,2.71,13.08,4.02,20.05
		 C1279.73,2048,1250.37,2048,1220.55,2048z"
      />
      <path
        d="M365.49,2048c-1.56-13.38-2.67-26.76-3.72-40.15c-0.1-1.3-0.04-2.72,0.37-3.94
		 c5.4-15.94,11.15-31.83,22.1-44.89c4.49-5.35,10.51-9.52,16.21-13.72c3.24-2.38,6.64-2.8,10.61,0.44
		 c5.91,4.83,12.9,8.3,19.14,12.75c11.54,8.24,19.64,19.51,24.64,32.48c5.65,14.66,9.96,29.84,14.64,44.86
		 c1.16,3.71,1.53,7.67,1.9,11.84C436.01,2048,400.98,2048,365.49,2048z"
      />
      <path
        d="M249.48,2048c-1.54-8.09-2.84-16.15-3.59-24.27c-0.38-4.03-0.54-8.38,0.54-12.21
		 c4.72-16.57,11.14-32.5,22.31-45.89c4.1-4.91,9.12-9.32,14.45-12.85c8.3-5.5,17.26-5.36,26.01-0.34
		 c10.12,5.8,17.7,14.09,23.17,24.26c8.06,15.01,13.1,30.87,13.62,48.09c0.22,7.44,1.42,14.85,2.11,22.73
		 C315.33,2048,282.64,2048,249.48,2048z"
      />
      <path
        d="M620.51,2048c-0.31-0.56-0.32-1.26,0.02-1.65c13.17-15.21,26.07-30.67,39.7-45.46
		 c7.4-8.03,15.9-15.1,24.26-22.18c3.76-3.18,8.25-5.64,12.69-7.83c11.83-5.84,22.66-4.39,31.08,5.73
		 c6.18,7.43,12.11,15.85,15.27,24.85c5.19,14.8,8.04,30.43,11.69,46.12C710.36,2048,665.67,2048,620.51,2048z"
      />
      <path
        d="M1135.54,2048c-1.04-4.69-1.22-9.47-2.27-14.06c-3.99-17.46-10.15-34.09-20.1-49.44
		 c10.08-12.34,20.52-24.14,34.8-31.71c3.65-1.93,7.52-3.61,11.48-4.75c9.95-2.86,16.71-0.87,21.75,8.13
		 c4.58,8.18,8.89,17.02,10.78,26.09c4.48,21.46,7.46,43.23,11.06,65.31C1180.72,2048,1158.37,2048,1135.54,2048z"
      />
      <path
        d="M1004.53,2048c-0.54-0.42-0.81-0.91-0.67-1.24c7.85-18.16,15.91-36.27,27.68-52.28
		 c4.57-6.22,10.13-11.99,16.19-16.75c10.23-8.02,21.05-7.85,31.94-0.66c9.22,6.09,16.07,14.42,21.42,23.96
		 c8.12,14.47,13.2,30.11,17.03,46.57C1080.38,2048,1042.69,2048,1004.53,2048z"
      />
      <path
        d="M774.52,2048c0.08-26.56-6.01-51.45-19.27-74.44c9.29-10.83,20.99-10.94,32.15,0.44
		 c6.3,6.42,11.58,13.86,17.13,20.99c5.34,6.85,11.42,13.39,15.38,20.99c5.2,9.99,8.65,20.89,12.5,31.71
		 C813.03,2048,794.01,2048,774.52,2048z"
      />
      <path
        d="M1776.75,1324.27c-27.8-3.79-52.19-14.56-74.03-31.28c-24.47-18.73-48.79-37.65-73.38-56.22
		 c-7.69-5.81-15.91-10.93-24.1-16.04c-3.49-2.18-7.38-4.45-11.32-5.02c-7.28-1.06-12.23-5.47-17.55-9.68
		 c-49.75-39.41-89.48-86.83-115.85-144.92c-1.34-2.96-2.56-5.97-3.16-9.37c6.61,5.48,13.1,11.11,19.84,16.43
		 c31.35,24.74,66.08,44.25,100.74,63.72c23.16,13.01,47.07,24.78,71.21,35.88c27.17,12.49,56.27,18.13,86.02,20.19
		 c17.24,1.2,34.51,2.5,51.78,2.81c22.84,0.41,40.55-10.43,54.96-27.4c1.81-2.13,3.28-4.56,5.14-6.64
		 c18.43-20.58,23.14-44.68,18.23-71.58c6.85,5.05,12.36,10.62,15.52,18.48c6.25,15.5,11.43,31.21,12.31,48.07
		 c1.25,23.97-5.19,46.33-15.65,67.38c-19.36,38.95-47.58,70.86-80.98,98.43C1790.73,1322.24,1785.04,1326.04,1776.75,1324.27z"
      />
      <path
        d="M1789.94,1512.83c-5.53,7.84-10.89,15.35-16.25,22.87c-0.44-0.11-0.87-0.22-1.31-0.33
		 c-8.92-39.43-23.08-76.36-50.6-106.66c9.47-12.88,18.76-25.24,27.73-37.82c5.1-7.16,9.74-14.67,14.48-22.09
		 c2.57-4.03,6.03-5.18,9.9-1.62c17.23,15.82,36.41,29.87,47.45,51.46c2.56,5.01,3.33,9.79,2.17,15.32
		 C1817.58,1462.38,1805.08,1487.96,1789.94,1512.83z"
      />
      <path
        d="M1616.12,1289.15c8.52-13.49,21.3-15.08,34.9-12.71c26.29,4.57,46.89,26.46,52.23,54.54
		 c1.64,8.63,1.87,17.17-0.88,25.64c-5.41,16.67-20.14,25.12-37.17,20.98c-15.76-3.83-28.09-12.98-36.83-26.4
		 c-8.63-13.24-13.77-28.11-15.3-43.8C1612.5,1301.57,1614.91,1295.46,1616.12,1289.15z"
      />
      <path
        d="M1818.32,1325.23c5.9-5.35,11.2-10.96,17.4-15.29c6.52-4.55,15.68-2.08,19.85,5.35
		 c2.68,4.78,4.67,10.49,5.08,15.92c1.19,15.76-1.98,30.9-10.1,44.59c-2.56,4.32-6.16,8.41-10.18,11.4
		 c-7.28,5.41-16.02,3.72-21.77-3.29c-2.51-3.07-5.09-6.1-7.39-9.33c-4.02-5.64-4.35-12.13-3.62-18.69
		 C1808.8,1345.01,1812.19,1334.82,1818.32,1325.23z"
      />
    </>
  );
}
