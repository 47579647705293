export default function Goblin(): JSX.Element {
  return (
    <>
      <path
        d="M1114.8,2050.56c4.71-29.19,8.23-58.77,9.62-88.43c1.36-28.96,6.26-57.49,8.07-86.35c0.54-8.58,8.37-9.13,13.92-10.81
			c35.55-10.76,72.57-12.86,109.1-17.86c3.31-0.45,6.85-1.02,10.28,2.59c-3.05,12.03-6.08,24.62-9.45,37.13
			c-4.52,16.75-9.47,33.39-13.94,50.15c-4.06,15.2-1.79,17.22,13.51,19.86c28.01,4.83,55.63,12.36,83.77,15.82
			c28.45,3.5,39.12,22.21,47.87,45.43c3.85,10.24,8.96,20.06,12.41,31.57C1305.55,2050.56,1210.77,2050.56,1114.8,2050.56z"
      />
      <path
        d="M602.8,2050.56c3.84-35.65,23.4-57.72,58.03-69.14c31.45-10.37,63.2-18.85,95.87-23.01c22.07-2.81,44.72-6.1,66.07,5.48
			c8.97,4.87,15.12,10.18,17.45,21.16c4.45,20.93,6.87,41.86,6.85,64.35C766.24,2050.56,685.12,2050.56,602.8,2050.56z"
      />
      <path
        d="M1204.24,933.49c36.73,63.36,72.6,126.14,113.98,186.58c23.5-15.08,43.78-32.19,64.51-49.9c7.9,2.4,11.47,5.31,6.69,15.24
			c-12.07,25.06-22.52,50.89-34,76.24c-3.34,7.38-1.92,13.51,5.08,15.77c14.11,4.55,28.76,7.42,43.14,11.18
			c18.42,4.81,23-10.92,29.7-21.32c8.57-13.3,16.79-27.15,20.4-43.46c10.22,3,6.84,8.76,4.64,14.17
			c-18.13,44.44-48.19,80.39-81.11,114.36c-15.51,16.01-30.93,32.11-46.18,48.37c-18.64,19.87-28.43,21.19-49.31,3.1
			c-23.72-20.55-46.55-42.13-69.41-63.65c-11.37-10.7-21.82-22.39-32.93-33.38c-9.32-9.22-12.77-21.83-19.38-32.32
			c-14.52-23.05-5.17-47.88-9.51-71.43c-0.91-4.96-0.29-10.27-9.03-13.64c1.37,28.49-15.09,55.9,4.58,82.8
			c11.6,15.86,3.61,32.57,0.32,48.81c-1.25,6.18-3.85,12.14-5.89,18.12c-4.32,12.66-15.69,8.35-26.04,8.37
			c-2.59,11.71-7.47,23.61-7.31,35.44c0.23,17.46-10.85,24.94-23.11,30.88c-47.58,23.03-98.11,34.76-150.9,37.13
			c-46.14,2.07-91.83-1.41-137.05-10.57c-11.6-2.35-22.89-6.22-34.33-9.37c-8.53-2.35-12.44-8.39-13.28-16.76
			c-2.38-23.65-7.38-46.83-13.1-69.83c-8.56-34.46-17.79-68.76-26.43-103.2c-2.54-10.13-6.61-11.61-16.28-7.02
			c-39.5,18.72-76.15,42.22-112.86,65.63c-6.22,3.97-10.95,5.62-17.9-0.18c-11.82-9.85-26.42-15.04-41.24-19.02
			c-8.89-2.39-17.45-2.05-25.76,5.46c20.88,8.12,41.74,13.86,59.39,26.79c9.69,7.1,18.59,13.32,19.08,27.6
			c0.37,10.78,5.06,21.39,7.67,32.11c1.4,5.75,3.4,8.86,10.58,6.87c14.48-4.01,26.78-0.65,37.98,10.6
			c13.97,14.03,29.04,27.2,46.62,36.81c9.31,5.09,10.15,12.49,4.96,20.01c-6.68,9.67-11.04,21.46-22.21,27.5
			c-6.37,3.45-6.02,8.18-1.69,12.41c13.67,13.37,10.59,31.72,14.74,47.89c1.22,4.76-3.6,7.22-6.26,10.44
			c-25.09,30.48-52.05,59.07-84.48,81.94c-5.49,3.87-11.79,6.82-18.06,9.32c-9.14,3.64-17.58,2.27-24.16-5.78
			c-22.67-27.72-41.53-57.68-52.22-92.11c-4.02-12.93-6.95-26.27-9.35-39.6c-2.65-14.7-14.25-22.91-22.82-33.07
			c-27.2-32.24-46.97-68.49-63.96-106.97c-11.51-26.08-15.68-52.44-16.54-80.32c-0.34-11.09,4.07-19.5,10.77-25.94
			c23.94-23.02,47.29-46.76,73.3-67.53c15.7-12.54,29.25-27.21,42.59-42.06c5.02-5.59,8.83-6.94,16.11-3.04
			c24.3,13.02,50.97,18.17,78.15,20.59c24.12,2.15,48.05,5.15,72.59,3.28c67.69-5.16,120.58-36.61,162.96-87.93
			c23.59-28.57,27.69-63.78,32.31-98.63c2.39-18-12.68-22.99-24.15-30.03c-4.66-2.86-8.98-5-8.53-11.2c1.3-18-6.72-34.05-11.46-50.6
			c-3.12-10.91-6.63-21.81-10.43-35.21c20.95,22.79,41.94,42.24,66.26,57.66c27.14,17.22,55.88,30.72,86.58,40.91
			c29.23,9.7,59.07,15.22,89.26,19.83c2.68,0.41,5.17,0.91,6.04,3.78c3.33,10.98,11.86,9.94,21.12,11.48
			c21.18,3.5,42.71,0.06,63.52,4.08C1168.42,893.23,1190.74,906.82,1204.24,933.49z M831.75,1035.87
			c17.88,13.44,35.56,27.15,53.68,40.25c30.74,22.23,61.57,44.35,92.71,65.99c29.43,20.45,59.25,40.35,89.05,60.27
			c14.07,9.41,28.13,18.95,42.91,27.15c6.91,3.83,16.76,7.31,21.77-2.87c4.44-9.03,6.35-19.28,9.62-28.91
			c4.22-12.44,2.38-21.77-9.16-30.56c-31.2-23.76-63.37-46.13-95.42-68.66c-52.42-36.84-103.5-75.6-157.66-109.97
			c-23.13-14.67-22.89-15.04-42.93,3.87c-2.74,2.58-5.99,4.34-8.66,7.26C810.17,1018.77,810.02,1018.63,831.75,1035.87z"
      />
      <path
        d="M981.93,835.63c-27.93-9.02-54.83-18.07-79.3-32.55c-47.83-28.32-89.57-62.42-109.87-116.98
			c-4.83-12.99-12.64-24.86-17.54-38.01c-1.03-2.76-3.13-6.28-10.67-5.68c2.94,35.56,19.74,67.34,27.12,102.83
			c-13.22,3.27-26.33,0.96-39.59-1.01c-16.53-2.45-19.84-14.28-22.7-28.01c-1.77-8.5-5.33-17-10.9-24.74
			c-7.57,0.43-11.3,7.18-16.55,10.8c-6.45,4.46-10.93,4.19-17.25,0.47c-32.4-19.07-60.75-43.23-87.43-69.39
			c-51.37-50.35-97.59-105.04-137.07-165.25c-7.58-11.55-16.12-22.81-18.58-38.61c11.48-2.62,22.87-2.53,33.72,0.21
			c50.06,12.66,100.09,25.42,149.13,41.88c40.07,13.45,80.55,25.75,121.2,37.33c10.91,3.11,22.02,6.53,33.31,8.12
			c14.55,2.05,26.86-6.71,40.14-10.59c28.8-8.43,57.8-15.77,87.15-22.12c21.94-4.75,43.72-9.92,65.98-13.25
			c30.26-4.52,59.97-1.53,90.22,1.12c45.9,4.02,73.26,35.66,104.14,62.66c18.02,15.76,31.69,35.32,42.13,56.68
			c9.13,18.68,16.76,38.47,13.86,59.85c-2.11,15.57-12.49,27.76-21.77,39.71c-9.56,12.31-20.18,23.84-31.57,34.68
			c-8.89,8.46-7.36,21.15-10.37,32.01c-7.58,27.41-15.36,55-38.4,74.18c-13.76,11.45-30.78,16.97-48.36,16.75
			C1041.94,848.34,1012.15,843.62,981.93,835.63z M984.38,770.68c28.64,18.79,59.19,30.7,94.32,26.46
			c40.73-4.92,54.73-29.87,64.32-65.9c-59.43,22.68-121.77,15.27-185.1,17.79C964.75,759.37,974.02,764.24,984.38,770.68z
			 M960.03,615.6c-24.52-13.15-50.03-24.5-73-40.47c-6.02-4.19-8.93,1.09-10.48,5.2c-10.95,29.02-0.92,57.9,32.24,68.41
			c27.73,8.8,54.82,0.22,82.16-1.08c5.67-0.27,12.5-2.31,16.71-9.81C993.21,628.15,977.16,622.98,960.03,615.6z M1158.15,596.11
			c-12.57,14.07-28.47,24.39-44.35,37.68c19.76,6.91,38.25,6.89,55.71,2.66c28.85-6.99,28.03-27.82,16.54-48.18
			c-4.65-8.23-12.16-8.39-19.23-2.06C1164.01,588.73,1161.64,591.74,1158.15,596.11z M1060.73,701.02
			c28.47,7.62,42.43-7.63,44.66-33.59c-0.79,0-1.97-0.34-2.31,0.05c-15.58,18.14-34.17,21.24-55.76,11.93
			c-4.22-1.82-9.8-3.97-13.76,2.02C1036.61,695.09,1050.25,694.12,1060.73,701.02z"
      />
      <path
        d="M966.13,1497.12c31.03-9.34,61.74-16.52,90.88-27.77c24.63-9.51,47.03-24.42,68.52-40.14c3.43-2.51,7.16-4.61,10.5-7.23
			c4.74-3.72,8.31-2.86,11.74,1.87c22.06,30.43,44.43,60.63,65,92.12c17.26,26.42,35.24,52.36,52.15,79
			c10.16,15.99,19.3,32.68,28.03,49.5c3.79,7.31,1.78,15.98,0.9,23.93c-0.5,4.47-5.12,4.75-9.18,4.98
			c-42.59,2.4-84.44,8.76-124.52,24.06c-13.63,5.2-19.76-7.12-28.4-12.4c-19.91-12.17-36.59-28.63-53.48-44.65
			c-3.67-3.48-7.35-6.84-12.93-7.66c1.19,30.82,2.45,61.31,9.21,91.22c5.39,23.88,2.84,29.52-20.28,38.62
			c-38.83,15.28-75.35,34.32-107.07,61.95c-10.47,9.12-19.5,6.41-22.59-7.07c-7.28-31.75-13.51-63.74-20.21-95.63
			c-1.71-8.12-2.82-16.48-9.01-25.63c-7.49,16.51-14.19,31.27-20.86,46.05c-0.7,1.54-1.11,3.21-1.79,4.77
			c-8.15,18.76-10.26,18.47-29.11,10.65c-31.22-12.95-62.17-26.45-92.74-40.82c-20.24-9.51-40.25-19.51-60.29-29.43
			c-4.47-2.22-3.51-5.78-2.16-9.85c12.01-36.19,21.67-73.07,30.39-110.13c8.61-36.62,15.26-73.7,23.05-111.93
			c12.79,6.18,25.23,11.16,36.63,17.89c35.51,20.98,74.96,28.94,114.88,34.27c12.63,1.69,26.23-2,39.1-4.59
			C943.33,1500.89,954.13,1498.69,966.13,1497.12z M1002.94,1497.46c-29.95,11.66-61.25,16.98-93.05,20.23
			c-26.16,2.68-26.13,2.99-18.3,27.32c0.26,0.81,0.58,1.6,0.76,2.42c6.79,31.89,14.12,63.59,19.6,95.83
			c6.61,38.88,8.86,78.52,19.22,116.76c3.71,13.69,7.61,27.32,11.62,41.69c10.42-3.02,16.55-9.95,23.8-14.69
			c27.52-18,51.98-40.74,83.09-53.12c11-4.38,12.37-12.23,9.67-22.74c-13.11-51.01-10.4-103.13-9.62-154.99
			c0.37-24.45-0.78-48.93,2.98-73.49C1034.42,1483.92,1021.02,1494.78,1002.94,1497.46z"
      />
      <path
        d="M1656.19,742.54c-18.76,33.16-37.45,65.28-55.15,97.94c-14.67,27.06-28.17,54.74-42.8,83.33
			c-14.12-8.29-28.54-16.76-42.97-25.22c-13.48-7.9-26.63-16.45-40.56-23.45c-8.78-4.41-11.34-7.16-6.85-17.92
			c11.53-27.66,20.85-56.28,30.36-84.74c6.96-20.82,11.9-42.35,19.39-62.96c9.72-26.72,14.93-54.79,25.52-81.25
			c2.42-6.04,5.96-10.47,10.56-13.58c22.35-15.09,45.07-29.63,67.74-44.24c12.47-8.04,25.17-15.72,37.63-23.78
			c8.38-5.42,11.01-2.38,11.9,6.55c4.06,40.74,3.87,81.69,6.44,122.45C1679.06,701.58,1672.83,722.6,1656.19,742.54z"
      />
      <path
        d="M877.01,1796.68c-8.53,27.15-16.76,53.35-25.32,79.44c-5.36,16.36-7.71,17.4-23.86,12.3
			c-46.41-14.66-92.99-28.79-139.13-44.24c-21.19-7.1-41.37-17.16-62.27-25.19c-8.66-3.32-9.48-7.8-6.19-15.58
			c9.55-22.63,19.87-44.88,31.09-66.72c4.82-9.38,10.73-18.26,14.82-27.93c4.12-9.75,10.03-9.97,18.27-6.29
			c18.51,8.28,36.61,17.48,54.83,26.29c40.07,19.4,80.48,37.48,123.57,49.02C872.19,1780.28,878,1785.21,877.01,1796.68z"
      />
      <path
        d="M1155.26,1713.44c46.65-12.76,92.94-21.24,140.5-20.17c18.02,0.4,23.56,6.69,22.32,25c-1.47,21.6-2.86,43.23-8.85,64.32
			c-3.11,10.96-4.17,22.5-6.39,33.73c-1.51,7.65-5.68,10.79-14.22,10.97c-46.04,0.99-91.58,6.51-136.2,18.23
			c-8.94,2.35-17.39,6.54-26.17,9.59c-8.55,2.96-14.34,0.57-15.29-9.2c-2.79-28.79-7.52-57.4-8.29-86.38
			c-0.26-9.88,3.26-17.55,11.94-23.73C1127.08,1726.92,1139.44,1718.21,1155.26,1713.44z"
      />
      <path
        d="M545.71,976c16.95-10.59,31.32-24.81,48.15-32.2c27.7-12.16,50.03-32.52,77.26-44.84c5.92-2.68,10.28-1.55,15.43,1.42
			c29.1,16.79,53.86,39.05,78.24,61.77c9.14,8.52,19.29,16.28,25.24,29.1c-29.51,26.1-62.91,43.9-102.7,50.09
			c-31.62,4.91-62.67,2.45-94.1-1.12c-17-1.93-33.14-7.06-49.91-9.52c-10.27-1.5-18.16-6.84-21.93-17.18
			c-1.73-4.76-3.89-9.36-6.12-14.69C522.48,988.32,535.06,984.07,545.71,976z"
      />
      <path
        d="M1393.2,983.46c7.74-17.58,15.65-33.8,29.54-46.43c3.17-2.88,5.92-5.1,9.86-4.04c40.72,11,81.69,21.72,106.21,60.88
			c5.38,8.6,9.68,17.98,11.49,27.94c2.1,11.61-0.56,22.59-7.97,32.2c-13.96,18.11-25.64,37.95-41.12,54.89
			c-11.4,12.48-16.26,12.56-28,0.06c-12.43-13.23-25.62-25.47-40.45-35.97c-9.75-6.9-20.17-12.54-31.19-17.02
			c-14.75-6-21.2-16.73-18.2-32.28C1385.94,1010.47,1386.45,996.7,1393.2,983.46z"
      />
      <path
        d="M744.85,1346.12c29.88,13.41,61.7,14.74,92.23,21.52c12.09,2.69,24.46,3.56,36.82,2.69c8.16-0.57,10.78,2.77,12.09,10.67
			c4.85,29.3,10.71,58.43,15.99,87.65c2.97,16.42-0.84,20.68-17.27,17.53c-24.16-4.64-47.72-11.87-70.59-20.81
			c-25.6-10.01-50.55-21.65-75.98-32.1c-4.42-1.82-7.6-3.2-7.7-8.45c-0.5-25.26,2.52-50.06,9.53-74.35
			C740.59,1348.4,741.6,1346.52,744.85,1346.12z"
      />
      <path
        d="M1245.24,490.43c-7.47,26.17-17.4,50.23-29.51,76.29c-25.27-38.27-56.26-67.5-94.28-90.19
			c6.82-14.86,20.69-21.03,31.5-29.81c25.83-20.98,52.42-41.07,79.44-60.49c6.28-4.51,12.56-14.56,21.46-10.27
			c8.92,4.3,5.66,15.15,5.71,23.26C1259.75,430.01,1253.46,459.89,1245.24,490.43z"
      />
      <path
        d="M802.01,1931.39c-3.69,3.99-7.71,3.09-11.45,3.12c-33.89,0.25-66.3,8.88-98.69,17.51c-19.61,5.22-39.31,10.06-57.35,20.13
			c-4.42,2.47-7.62,6.87-16.01,8.48c10.69-44.98,18.57-89.3,26.48-135c55.76,23.92,109.85,43.75,165.62,58.96
			C808.86,1914.87,802.79,1921.79,802.01,1931.39z"
      />
      <path
        d="M1380.46,905.68c-0.87-2.48-2.31-4.3-1.94-5.59c4.31-15.02,8.78-30,13.44-44.92c2.36-7.56,7.26-8.61,13.87-4.8
			c27.07,15.59,54.19,31.11,81.29,46.65c24.99,14.33,50.1,28.46,74.94,43.03c21.06,12.35,41.82,25.22,63.56,38.37
			c-10.24,16.79-19.78,32.58-29.48,48.27c-3.36,5.43-7.41,3.77-12.01,1.15c-11.54-6.57-16.52-17.4-20.45-29.23
			c-8.95-26.94-28-45.79-52.09-58.54c-27.21-14.4-56.66-22.74-88.01-22.08c-2.98,0.06-6.27-0.49-8.89,0.56
			C1399.73,924.54,1389.41,918.04,1380.46,905.68z"
      />
      <path
        d="M1060.07,1344.4c19.8-6.21,35.42-17.79,51.23-29.05c3.8-2.7,4.86-10.67,11.46-7.65c6.74,3.09,14.97,5.53,18.22,12.84
			c10.41,23.42,9.9,48.32,5.26,72.56c-2.3,11.99-17.65,13.43-26.88,19.7c-15.66,10.63-33.08,18.65-49.69,27.89
			c-3.46,1.92-6.85,3.17-11.76,1.88c-6.13-29.26-5.93-60.59-24.55-87.65C1042.28,1351.28,1051.57,1349.97,1060.07,1344.4z"
      />
      <path
        d="M635.34,876.27c24.55-8.59,46.33-20.94,68.84-31.72c4.71-2.26,8.62-5.1,13.15,0.97c26.08,34.95,59.61,62.95,88.96,94.86
			c5.6,6.09,10.02,13.26,14.32,19.03c-1.31,6.74-7.34,7.35-9.58,11.6c-3.45,6.53-7.79,3.93-11.25,0.3
			c-26.19-27.48-55.33-51.49-86.28-73.32c-28.02-19.76-39.86-21.66-68.36-1.01c-9.87,7.15-19.82,14.19-29.72,21.26
			C610.08,904.24,620.1,882.02,635.34,876.27z"
      />
      <path
        d="M843.54,886.91c-5.26,16.2-3.05,33.36-14.11,48.69c-24.62-27.22-52.14-50.6-78.86-75.76
			c13.78-10.61,29.42-16.37,44.08-17.91c17.09-1.79,34.04,7.23,46.95,19.15C847.86,866.86,843.2,877.42,843.54,886.91z"
      />
      <path
        d="M1440.72,1101.76c-7.03,23.99-13.92,47.06-21.29,71.7c-16.64-3.18-32.54-6.23-49.65-9.5
			c7.74-16.75,14.63-32.06,21.87-47.2c4.9-10.24,10.66-20.07,15.34-30.4c3.68-8.13,8.78-10.68,15.69-4.82
			C1429.39,1087.24,1443.6,1086.21,1440.72,1101.76z"
      />
    </>
  );
}
