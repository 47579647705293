export default function Skeleton(): JSX.Element {
  return (
    <>
      <path
        d="M986.8,2050.56c-2.55-17.36-3.82-34.51-9.93-51.32c-5.57-15.31-16.79-25.26-27.52-35.98
			c-14.62-14.61-14.81-22.62-1.66-38.89c9.18-11.36,19.5-21.26,32.11-28.51c18.33-10.56,32.58-5.38,43.86,12.45
			c10.69,16.91,12.69,37.73,26.43,53.23c12.88,14.52,28.67,23.54,46.68,29.32c21.83,7.02,43.3,14.69,61.28,29.61
			c9.15,7.59,13.23,18.54,19.13,29.33C1114.4,2050.56,1051.2,2050.56,986.8,2050.56z"
      />
      <path
        d="M679.6,2050.56c12.34-22.91,25.58-46.01,39.54-68.67c9.83-15.95,41-21.76,61.81-13.19c15.32,6.31,27.16,17.11,38.73,28.16
			c16.26,15.53,31.55,32.03,42.69,52.9C802.08,2050.56,741.44,2050.56,679.6,2050.56z"
      />
      <path
        d="M1155.6,464.91c-22.66,0.81-43.45-3.9-64.28-8.3c-12.16-2.57-17.92-9.14-15.79-20.41c5.01-26.55-11.31-33.53-31.48-35.66
			c-11.81-1.25-23.86-0.27-35.8-0.27c-25.57-0.01-42.92-20.14-37.92-45.23c2.05-10.3,4.08-20.82,9.26-30.39
			c7.1-13.11,7.56-27.29,2.48-40.88c-14.63-39.16-1.91-71.17,26.47-97.8c39.57-37.13,86.97-45.56,139.28-35.03
			c47.43,9.55,79.43,38.07,101.42,79.33c11.05,20.74,13.81,43.68,12.29,66.8c-1.04,15.81-2.05,31.37,1.7,46.94
			c4.71,19.57-1.92,36.05-15.28,49.92c-6.41,6.66-13.54,12.65-21.66,17.62c-16.28,9.97-15.77,28.21-19.22,44.03
			c-3.37,15.48-5.11,17.26-21.36,14.3C1176.08,468.12,1166.4,466.71,1155.6,464.91z M1120.33,273.95
			c-9.57-11.32-22.34-12.58-34.88-8.38c-10.63,3.55-19.27,11.88-21.72,23.26c-2.09,9.73,8.61,11.82,14.17,15.97
			c15.66,11.69,30.35,25.07,50.06,33.88C1129.63,315.99,1128.45,295.49,1120.33,273.95z M1236.86,308.07
			c-0.74-2.44-1.12-5.07-2.29-7.28c-6.96-13.21-14.46-13.68-22.19-1.28c-4.7,7.55-10.36,14.92-13.05,23.19
			c-3.23,9.94-11.85,18.54-9.6,31.77c13.85-5.43,26.49-9.9,37.09-18.96C1234.84,328.65,1239.96,321.03,1236.86,308.07z
			 M1176.94,397.35c-1.41-14.49-10.77-24.92-17.56-37.89c-10.91,9.18-16.98,19.97-23.86,29.93c-4.03,5.83,0.64,7.98,6.06,9
			c6.85,1.29,11.83-4.14,18.39-4.81c2.92,3.15,5.59,6.46,8.69,9.29C1173.92,407.67,1175.96,404.43,1176.94,397.35z"
      />
      <path
        d="M605.62,313.51c-7.04,42.55-15.15,83.86-20.23,125.54c-6.67,54.76-16.77,109.11-19.23,164.41
			c-1.07,24.14-5.04,48.15-7.78,72.2c-0.67,5.88-3.24,9.78-9.51,12.06c-17.38,6.32-35.82,7.41-53.56,11.85
			c-5.11,1.28-8.23-0.95-9.01-6.2c-0.25-1.68-0.36-3.39-0.41-5.09c-0.99-34.9-1.24-69.85-3.12-104.71
			c-2.4-44.58-6.12-89.09-9.26-133.63c-0.27-3.82-0.7-7.66-0.66-11.48c0.56-48.58-2.36-97.01-7.8-145.22
			c-2.56-22.67,4.28-41.34,16.65-59.59c25.9-38.2,50.99-76.94,77.56-117.2c9.11,11.66,9.55,24.83,13.98,36.15
			c9.08,23.22,16.4,47.14,24.48,70.75C607.68,252.41,617.3,281.46,605.62,313.51z"
      />
      <path
        d="M1396.01,814.08c0.52,58.83-2.06,116.17-6.8,173.59c-3.44,41.57-8.51,82.83-13.52,124.17
			c-2.53,20.89-30.54,43.52-47.1,47.24c-10.7,2.41-17.84-4.89-16.84-15.63c4.46-47.83,10.42-95.42,11.72-143.64
			c1.41-52.45,3.37-104.93,1.3-157.28c-2.48-62.62,0.42-125.4-5.06-187.86c-3.35-38.2-3.23-76.55-6.9-114.7
			c-0.37-3.8-0.71-7.78-0.03-11.48c1.5-8.1,6.58-11.62,13.67-6.38c19.65,14.54,37.18,31.62,50.49,52.05
			c8.13,12.48,3.88,28.37,5.67,42.62C1390.77,681.83,1395.09,747.22,1396.01,814.08z"
      />
      <path
        d="M990.09,1028.4c15.29-17.26,33.74-20.67,53.95-14.72c18.39,5.42,24.88,35.62,13.44,56.28
			c-7.5,13.55-18.82,24.01-27.66,36.45c-10.35,14.55-14.68,30.3-11.23,48.04c1.3,6.69,2.45,13.44,3.03,20.22
			c1.08,12.63-2.35,24.18-13.34,31.08c-11.35,7.13-30.34,4.72-39.84-3.36c-6.83-5.82-13.03-12.17-17.09-20.34
			c-15.88-31.93-35.34-24.41-56.41-8.22c-16.13,12.39-29.83,27.86-48.25,37.38c-14.87,7.69-36.9,5.93-47.54-5.39
			c-11.86-12.63-9.04-29.2-4.35-42.32c8.09-22.61,4.19-39.15-14.03-54.13c-14.75-12.13-25.53-26.24-24.31-47.76
			c1.23-21.68,12.49-37.15,29.27-46.31c15.31-8.35,31.37,1.5,45.26,9.81c18.22,10.9,29.48,29.21,43.71,44.23
			c18.45,19.48,57.76,20.32,77.64,1.58C966.06,1057.98,975.22,1041.35,990.09,1028.4z M827.58,1092.04
			c1.78-11.91,1.92-23.37-4.65-34.4c-4.02-6.76-9.42-7.83-16.1-7.11c-7.3,0.79-9.81,6.57-11.12,12.33
			C792.31,1077.87,810.91,1096.3,827.58,1092.04z M999.79,1074.89c-1.13,4.3-4.98,8.6-0.56,13c2.83,2.83,6.44,2.56,9.37,0.41
			c9.6-7.05,19.15-14.04,23.03-26.37c1.89-6,2.08-11.15-3.14-14.83c-5.58-3.93-10.48-0.55-14.97,2.98
			C1006.02,1055.98,1004.36,1065.16,999.79,1074.89z"
      />
      <path
        d="M1000.62,596.22c14.44,11.59,27.89,23.31,46.64,25.86c3.96,0.54,7.23,1.46,10.76-1.04
			c15.73-11.17,34.62-14.14,52.59-18.56c15.87-3.9,30.21-11.17,45.44-16.33c9.62-3.26,20.39-7.08,27.88-0.48
			c8.59,7.56,6.23,20.13,3.13,30.23c-5.48,17.85-17.77,29.04-36.5,33.32c-15.39,3.51-30.83,5.76-46.53,7.56
			c-19.81,2.28-36.45,8.9-44.46,31.57c-9.59,27.15-18.6,54.47-27.97,81.68c-5.37,15.6-12.88,30.46-19.47,45.63
			c-5.06,11.66-10.34,23.23-15.22,34.96c-2.06,4.96-4.29,9.19-10.41,9.49c-2.84-3.84-1.23-7.52,0.18-11
			c14.9-36.84,15.82-76.23,21.15-114.82c1.99-14.38,2.53-28.8,2.94-43.29c0.68-23.94-10.24-41.5-29.16-54.48
			c-9.11-6.26-17.62-13.49-26.95-19.15c-12.15-7.37-24.29-14.87-39.89-15.27c-35.66-0.91-56.61-28.29-57.92-55
			c-0.95-19.34,16.65-32.32,35.71-26.68c15.2,4.49,28.72,12.37,41.05,22.41c9.81,7.99,20,15.5,30.95,23.95
			c5.35-23.22,21.81-39.11,30.35-59.37c11.8-28,18.23-57.42,25.09-86.76c1.43-6.11,2.68-10.97,9.96-10.29
			c7.81,0.73,7.6,6.9,7.46,12.5c-0.81,31.54-6.04,62.57-11.16,93.6c-0.71,4.28-2.16,8.77,0.39,12.43
			c10.5,15.09,6.2,31.86,5.78,48.11c-0.4,15.66-7.96,20.82-23.23,16.61c-3.21-0.89-6.39-2.49-9.64-0.24
			c-0.22,0.15-0.18,0.82-0.09,1.21C999.57,594.98,999.88,595.32,1000.62,596.22z"
      />
      <path
        d="M718.54,1509.89c14.71-32.23,25.95-64.77,34.69-97.95c11.12-42.21,14.9-85.36,9.67-128.99
			c-2.99-24.99-4.42-50.04-3.21-75.19c0.25-5.26-0.7-12.03,7.33-12.66c7.33-0.58,8.88,4.63,9.94,10.65
			c0.88,4.99,2.76,9.84,4.55,14.62c6.27,16.74,12.61,20.02,30.36,16.06c4.15-0.93,8.33-2.25,12.53-2.36
			c10.95-0.3,17.92,8.9,14.54,19.78c-6.28,20.23-13.14,40.28-19.81,60.38c-19.87,59.93-25.4,120.97-15.52,183.51
			c3.04,19.26,3.94,38.9,4.99,58.4c0.33,6.14-1,13.54-8.31,15.74c-6.95,2.09-9.02-5.02-12.55-8.88
			c-5.49-6.01-7.17-14.18-11.82-20.93c-6.69-9.71-13.02-9.35-21.32-3.77c-4.59,3.09-9.37,5.93-13.68,9.37
			c-7.6,6.08-15.95,6.85-23.58,1.71c-8.26-5.56-7.61-14.83-5.52-23.3C713.12,1520.81,716.1,1515.93,718.54,1509.89z"
      />
      <path
        d="M556.47,842.94c-0.26,25.11-7.05,48.09-10.65,71.51c-3.96,25.76-22.04,39.26-46.88,43.37
			c-11.18,1.85-22.46,2.44-34.17,1.49c-22.02-1.8-35.91-20.46-37.34-38.92c-1.93-24.85-2.1-49.67,7.12-74.25
			c7.54-20.07,21.42-32.47,38.72-41.61c15.17-8.02,31.62-13.83,47.85-19.64c25.48-9.12,37.57-0.31,37.95,26.4
			C559.21,821.57,558.15,831.67,556.47,842.94z"
      />
      <path
        d="M1130.68,1491.67c-4.86,6.23-9.73,10.72-19.02,10.77c-1.3-15.93-2.61-31.76-3.88-47.59
			c-3.15-39.4-13.21-76.41-33.93-110.71c-14.01-23.18-29.73-44.35-50.59-61.68c-5.89-4.89-11.03-10.56-15.1-17.21
			c-9.13-14.93-8.45-23.32,5.27-34.16c20.9-16.53,29.43-39.83,36.64-64.02c10.19-2.61,13.82,5.05,18.04,11.07
			c14.32,20.44,17.4,44.82,23,68.22c5.32,22.24,7.76,45.31,14.7,66.98c10.39,32.48,26.55,62.34,49.7,87.99
			c9.36,10.37,18.48,21.02,27.03,32.06c6.82,8.81,4.87,17.29-5.31,20.04C1156.02,1459.14,1142.66,1473.64,1130.68,1491.67z"
      />
      <path
        d="M822.17,663.45c-18.04,17.65-32.23,37.16-45.21,57.63c-17.26,27.22-30.67,56.53-45.92,84.84
			c-9.93,18.43-23.01,34.43-38.35,48.52c-9.98,9.17-31.66,9.93-41.83,2.09c-11.73-9.04-15.91-27.77-10.15-41.69
			c5.69-13.76,17.96-21.23,27.99-30.76c36.33-34.51,69.28-71.76,97.24-113.52c22.93-34.26,40.97-71.26,61.89-106.64
			c3.7-6.25,7.98-10.51,16.32-13.54c2.86,26.49,18.05,45.28,38.47,62.28C861.44,629.14,839.72,643.22,822.17,663.45z"
      />
      <path
        d="M1404.53,1007.86c-0.48-40.4,5.62-79.24,5.78-118.48c0.24-56.23-1.25-112.27-4.73-168.43
			c-2.52-40.73-9.25-80.85-12.3-121.42c13.43,7.31,35.48,50.67,54.02,110.07c12.47,39.98,15.94,81.19,15.99,123.22
			c0.06,42.56-6.02,83.65-16.96,124.49c-10.77,40.22-27.26,77.97-46.78,114.9C1396.66,1050.65,1403.35,1030.06,1404.53,1007.86z"
      />
      <path
        d="M495.97,788.09c-9.94,5.03-13.31,2.57-12.3-7.71c1.38-14.14-1.36-16.28-14.73-11.64c-25.14,8.71-51.32,13.94-77.84,25.32
			c0-21.23-0.04-40.28,0.02-59.32c0.02-5.83,5.28-5.95,8.91-7.61c19.54-8.91,41-10.6,61.5-15.86c24.21-6.21,48.95-10.35,73.43-15.55
			c31.09-6.6,62.14-13.36,93.22-20.05c7.16-1.54,10.29,1.37,10.14,8.7c-0.23,11.07-0.44,22.17,0.02,33.22
			c0.4,9.52-4.05,13.74-12.96,15.56c-30.74,6.25-61.4,12.9-93.64,19.72c3.76,2.27,11.45-0.92,9.13,6.17
			c-2.32,7.11-0.05,16.86-10.86,19.54C518.9,781.35,507.91,784.6,495.97,788.09z"
      />
      <path
        d="M1072.86,1669.21c-14.34,28.98-25.46,58.06-33.51,88.54c-9.17,34.73-18.51,69.65-35.86,101.51
			c-3.19,5.86-5.91,14.97-18.33,11.75c4.38-12.92,9.29-25.25,12.76-37.98c9.58-35.18,17-70.83,22.83-106.85
			c2.98-18.38,7.23-36.6,11.84-54.65c7.27-28.49,17.99-55.92,34.78-80.02c13.24-18.99,28.76-36.83,53.15-42.72
			c1.65-0.4,3.37-0.7,5.05-0.68c7.5,0.11,16.72-2.25,20.56,6.22c3.3,7.29-2.92,13.55-7.13,19.11c-12.02,15.87-25.2,30.9-36.54,47.22
			C1091.84,1635.94,1082.98,1652.43,1072.86,1669.21z"
      />
      <path
        d="M1071.09,471.7c28.97,19.34,60.88,27.07,93.78,31.89c5.37,0.79,10.61,2.37,15.52,3.49c2.16,7.61-0.98,12.64-4.78,17.31
			c-9.06,11.13-18.85,21.72-27.25,33.33c-6.27,8.66-14.26,10.92-23.3,8.3c-29.33-8.5-57.82-19.19-84.8-33.83
			c-9.51-5.16-10.97-12.64-9.04-21.31c5.31-23.75,7.48-47.78,8.83-72.01c0.37-6.64-2.32-13.6,1.17-20.03
			c17.66,1.68,25,9.12,26.64,25.79C1068.73,453.42,1066.45,462.53,1071.09,471.7z"
      />
      <path
        d="M1027.52,1878.21c-4.38,1.72-7.68,4.11-14.88,2.13c49.28-103.46,88.88-210.89,151.82-307.79
			c4.85,14.82,5.21,29.47,1.36,44.12c-11.26,42.82-28.83,83.04-49.3,122.26c-17.65,33.81-34.85,67.81-55.58,99.86
			C1051.7,1853.06,1041.33,1866.52,1027.52,1878.21z"
      />
      <path
        d="M678.82,1836.97c-7.12-28.32-5.77-56.11-2.02-83.35c3.7-26.88,14.57-52.18,25.13-77.15c3.72-8.79,9.28-17.25,19.79-21.91
			c8.99,17.95,7.49,36.7,4.82,55c-6.03,41.31-10.9,82.59-9.29,124.47c0.98,25.41,10.55,48.79,15.72,73.2
			c1.84,8.72,3.79,17.47,4.73,26.31c0.59,5.59-0.78,11.4-7.92,12.61c-6.06,1.02-9.15-2.31-11.53-7.66
			C703.62,1905.61,688.65,1872.87,678.82,1836.97z"
      />
      <path
        d="M955.13,801.63c5.69-4.35,10.47-10.65,17.3-5.05c5.95,4.88,3.81,11.5,1.32,17.68c-12.26,30.38-37.4,45.62-66.84,53.84
			c-21.78,6.08-44.48,6.71-66.88,0.63c-25.69-6.97-47.21-30.02-38.69-57.43c4.86-15.62,14.86-18.76,29.24-11.1
			C871.33,821.9,912.7,826.56,955.13,801.63z"
      />
      <path
        d="M959.41,730.73c9.63-1.39,20.44-6.68,25.57,2.63c5.81,10.54-5.85,17.11-11.92,22.89c-18.24,17.37-40.52,27.13-65.79,30.08
			c-26.1,3.05-51.62,2.64-75.69-9.67c-26.18-13.39-31.54-47.38-11.07-68.59c5.96-6.17,11.21-8.18,18.16-1.59
			C873.42,739.48,914.24,743.28,959.41,730.73z"
      />
      <path
        d="M779.8,1895.67c-1.97,12.45-3.97,23.78-3.31,35.38c0.42,7.4-1.33,14.05-10.14,15.05c-10.24,1.17-10.88-7.78-11.24-13.75
			c-0.93-15.28-0.46-30.67-0.11-46.01c0.91-40.34,7.07-80.58,2.1-121.04c-1-8.14,1.03-16.07,2.18-24.1
			c2.89-20.18,5.1-40.45,7.94-60.63c1.44-10.2,5.12-11.47,13.25-4.83c8.78,7.18,11.97,17.47,13.77,28
			c8.63,50.33,3.91,100.05-7.73,149.35C783.29,1866.79,784.76,1881.16,779.8,1895.67z"
      />
      <path
        d="M877.43,1024.01c9.01-20.69,8.06-42.63,14.92-62.86c5.48-16.14,18.86-28.56,35.5-30.01c10.75-0.94,16.98,4.18,13.62,13.9
			c-10.03,29.04-7.29,57.86-1.47,86.94c0.41,2.06,0.37,4.26,0.27,6.38c-1.42,29.75-17.17,34.49-42.39,27.8
			c-1.98-0.53-3.72-2-5.56-3.06C870.1,1050.3,869.8,1049.55,877.43,1024.01z"
      />
      <path
        d="M840.25,676.76c-1.52-19.42,16.93-32.66,33.46-26.2c17.52,6.85,36.25,8.38,54.92,8.99c13.6,0.44,27.24-0.11,40.85,0.09
			c6.97,0.1,14.15,1.21,16.46,9.17c2.12,7.3-1.27,12.4-7.28,17.34c-34.71,28.56-89.29,35.85-125.34,10.05
			C846.52,691.32,844.27,684.25,840.25,676.76z"
      />
      <path
        d="M934.06,874.75c10.02-3.71,18.48-9.04,28.69-8.96c10.76,0.08,15.57,6.77,12.01,16.87c-8.81,25.07-45.93,50.57-72.86,50.18
			c-11.51-0.17-23.04,0.23-34.54-0.22c-18.55-0.72-37.66-15.38-42.11-31.77c-2.57-9.45,0.9-14.7,9.12-12.3
			C869.42,898.77,901.57,889.49,934.06,874.75z"
      />
      <path
        d="M1154.25,769.66c-17.13,47.25-73.6,45.64-102.1,20.21c-7.43-6.63-4.3-14.35-2.34-21.29c2.75-9.73,7.2-18.98,11.5-29.92
			c9.41,4.81,18.72,8.48,28.74,9.68c17.68,2.12,34.82,1.61,50.04-9.37c4.8-3.46,9.6-8.13,15.98-3.57c5.82,4.15,4.99,10.7,4,16.45
			C1159.09,757.62,1156.55,763.13,1154.25,769.66z"
      />
      <path
        d="M1177.56,762.24c-7.24,30.84-1.42,59.97,6.16,88.76c3.25,12.35,6.01,24.74,8.06,37.29c3.27,20-9.64,34.33-18.7,49.78
			c-2.94,5-7.21,2.34-10.2-1.68c-4.85-6.51-4.59-14.46-6.27-21.82c-4.15-18.19-7.2-36.65-11.99-54.66
			c-4.88-18.36-5.97-35.8,3.49-53.17c7.28-13.37,13.29-27.43,20.49-40.85C1169.93,763.42,1171.41,756.74,1177.56,762.24z"
      />
      <path
        d="M780.85,1650.11c-17.58,3.67-45.12-12.65-51.36-30.43c-10.2-29.08-5.45-37.69,12.64-57.46
			c9.8-10.71,24.15-10.97,31.91,0.8c13.2,20.03,26.65,40.48,20.8,67.13C792.89,1639.05,787.93,1644.34,780.85,1650.11z"
      />
      <path
        d="M1270.55,822.17c18.29-9.77,22.67-9.43,28.53,1.6c9.02,16.99,6.4,39.54-6.54,44.46c-38.82,14.76-72.26,37.07-100.38,67.54
			c-1.8,1.95-4.09,4.64-9.02,1.87c14.41-33.68,28.01-67.72,57.92-91.8C1250.63,838.14,1260.21,830.44,1270.55,822.17z"
      />
      <path
        d="M1168.73,1552.9c-16.89-9.75-30.56-20.26-28.02-42.11c1.45-12.49,17.89-36.28,29.79-40.55c7.36-2.65,13.43,0.36,18.8,4.9
			c18.74,15.85,19.52,37.64,18.45,59.5c-0.31,6.35,0.33,13.55-5.09,19.27C1193.78,1563.27,1187.83,1563.44,1168.73,1552.9z"
      />
      <path
        d="M1069.48,694.29c1.19-10.17,6.33-12.59,15.14-11.7c25.85,2.61,50.76,0.14,73.65-14.09c8.14-5.06,17.49,2.33,16.48,13.5
			c-1.3,14.47-7.72,32.51-26.76,39.86c-25.57,9.87-49.86,9.06-74.19-2.19C1061.41,713.94,1067.65,704.02,1069.48,694.29z"
      />
      <path
        d="M1019.17,846.44c0.72-13.31,10.79-21.61,14.2-33.05c1.66-5.56,7.16-4.03,10.66-1.34c14.22,10.91,30.62,15.62,47.98,18.3
			c9.06,1.4,20.25,1.77,21.92,13.52c1.73,12.15-8.07,19.31-16.64,24.95C1071.91,885.49,1035.25,874.97,1019.17,846.44z"
      />
      <path
        d="M1295.69,932.36c-32.97,19.02-67.52,21.77-103.15,18.11c8.62-12.97,22.97-12.88,34.48-18.45
			c18.49-8.93,34.82-20.44,50.48-33.31c3.27-2.69,6.42-5.58,9.93-7.91c8.78-5.81,15.2-3.03,19.35,6.06
			C1312.07,908.47,1308.31,921.06,1295.69,932.36z"
      />
      <path
        d="M1003.56,928.78c-9.57-17.36-13.84-34.42-6.91-52.99c9.74-2.14,14.51,6.43,21.67,9.39c12.05,4.99,24.16,10.01,35.09,17.55
			c7.16,4.94,10.39,11.31,9.48,19.4c-0.94,8.3-4.65,14.69-13.23,18.6C1031.13,949.18,1016.94,942.74,1003.56,928.78z"
      />
      <path
        d="M481.41,969.87c16.6-3.13,31.53-9.41,47.36-10.7c4.01,7.97,0.01,14.55-1.22,20.76c-2.2,11.11-6.63,21.79-10.27,32.6
			c-2.4,7.13-7.4,11.45-15.01,11.69c-6.62,0.2-9.1-5.01-11.8-10.01c-5.03-9.32-10.64-18.33-15.61-27.68
			C471.1,979.45,471.84,973.37,481.41,969.87z"
      />
      <path
        d="M635.77,866.51c9.89,11.52,4.62,21.01-3.1,29.57c-14.21,15.75-32.48,23.88-53.32,26.3c-5.53,0.64-11.57,0.3-13.7-6.5
			c-2-6.37-0.16-11.2,5.9-15.04c17.45-11.06,37.47-18.03,52.38-33.27C626.05,865.39,630.96,864.51,635.77,866.51z"
      />
      <path
        d="M561.35,848.79c5.74-12.54,12.06-23.3,22.12-31.81c7.72-6.53,16.52-9.24,24.56-4.33c6.96,4.25,12.58,12.49,11.7,21.37
			c-0.96,9.69-8.51,13.42-18.01,14.65c-8.92,1.15-14.27,8.84-20.58,14.35c-6.05,5.29-12.68,8.35-19.6,3.92
			C554.28,862.3,559.15,855.55,561.35,848.79z"
      />
    </>
  );
}
