export const EXAMPLE_ARMY_NAMES = [
  'Himbo Heroes',
  'MILFstrosities',
  'Monster Mash-ters',
  'Abomi-Nation',
  'Bad Ghoul Coven',
  'The Hoard House',
  'Bulette Time',
  'It was an Undead Smash',
  'Dragon Drama',
  'Mine Flayers',
  'Definitely not Mimics'
];
