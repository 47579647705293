export default function DisplacerBeast(): JSX.Element {
  return (
    <>
      <path
        d="M1113.37,1905.86c-0.01-0.7-0.02-1.43-0.02-2.17c-0.37-1.81-0.72-3.63-1.06-5.44c-0.91-4.8-1.77-9.61-2.69-14.41
		c-0.15-0.54-0.29-1.08-0.43-1.62c0.86,5.52,1.75,11.03,2.72,16.53C1112.31,1901.13,1112.82,1903.5,1113.37,1905.86z"
      />
      <path
        d="M1530.28,1982.1c-1.72-2.15-3.44-4.3-5.17-6.45c-3.68,1.25-4.98,5.7-5.59,9.54c-2.79,17.57-2.21,35.64,1.4,53.05
		c1.33,6.41,3.22,13.03,7.79,17.72c4.56,4.69,12.54,6.65,17.75,2.68c3.79-2.89,5.13-7.96,5.57-12.7
		c1.78-19.13-7.04-37.48-15.62-54.67C1534.31,1987.07,1531.99,1982.63,1530.28,1982.1z"
      />
      <path
        d="M1483.4,1992.47c-4.57-0.34-8.98,2.3-11.8,5.92c-2.82,3.61-4.28,8.09-5.37,12.54c-4.05,16.54-3.43,34.75,4.74,49.69
		l38.92,4.95c-0.42-19.57-4.28-39.07-11.35-57.33C1495.75,2001.05,1491.09,1993.04,1483.4,1992.47z"
      />
      <path
        d="M1919.93,1463.99c-12.69-21.98-25.25-43.12-29.95-69.03c-4.03-22.26-10.1-44.77-21.28-65.08
		c-14.13-25.68-38.74-34.65-65.24-41.57c-21.61-5.64-43.12-12.11-62.66-23.75c-27.64-16.46-47.29-41.41-69.42-63.78
		c-5.62-5.68-10.2-13.73-21.61-13.31c-9.74,44.2,9.16,86.02,9.44,129.31c-6.97-12.16-14.14-24.03-16.37-37.79
		c-3.28-20.25-6.76-22.79-26.71-16.94c-12.26,3.59-24.38,8.07-37.24,8.55c-36.24,1.35-71.72,6.69-106.76,16.39
		c-11.74,3.25-23.38,7.51-35.56,9.22c-10.28,1.44-20.8,2.26-17.36,20.93c-13.42-15.39-20.63-30.24-31.86-42.26
		c-30.44-32.57-66.15-54.02-110.74-61.35c-61.24-10.06-122.93-11.93-184.64-11.13c-31.21,0.41-62.08-0.8-93.14-3.28
		c-53.58-4.29-103.96-20.18-152.81-40.96c-40.52-17.23-78.9-38.84-112.92-67.27c-39.16-32.72-73.17-69.47-88.19-119.83
		c-12.56-42.11-13.84-84.26,1.51-126.27c10.12-27.68,41.43-45.43,72.14-28.31c23.52,13.11,45.73,28.81,72.83,35.54
		c33.25,8.26,64.73,6.99,95.26-9.02c38.23-20.04,66.2-50.51,85.61-88.31c6.73-13.12,17.95-26.77,10.95-43.87
		c-2.36-1.13-4.27-0.33-6.33,0.39c-36.78,12.84-73.65,25-112.31,31.58c-47.77,8.13-95.63,10.64-143.79,6.3
		c-31.78-2.86-63-2.58-91.38,14.25c-45.06,26.71-69.49,66.07-73.29,118.86c-4.79,66.45,16.09,125.53,50.16,180.76
		c26.37,42.74,60.18,79.1,100.52,109.24c22.48,16.8,46.3,30.82,72.39,41.69c21.68,9.03,44.83,12.73,66.37,20.97
		c32.18,12.31,65.01,21.96,98.22,30.55c16.42,4.24,33.16,7.44,49.76,10.68c35.39,6.91,70.55,14.63,105.27,24.18
		c24.86,6.84,49.75,13.98,73.84,23.08c14.62,5.53,30.49,9.74,43.7,21.84c-11.83,10.16-25.75,14.55-38.61,20.14
		c-18.88,8.2-37.53,17.94-59.67,15.26c-40.93-4.96-81.69-11.08-121.44-21.93c-41.34-11.29-82.71-21.91-125.08-28.7
		c-40.73-6.53-80.45-4.18-119.34,9.87c-31.82,11.5-59,31.09-85,51.76c-48.93,38.91-94.74,81.53-141.97,122.48
		c-46.23,40.09-93.72,78.55-150.16,104.67c-50.97,23.59-102.37,35.03-157.71,18.72c-32.3-9.52-53.37-32.07-63.72-62
		c-19.01-54.97-3.73-107.73,20.23-157.36c16.52-34.21,40.35-63.88,70.34-88.58c40.23-33.15,85.97-50.64,137.51-52.72
		c53.22-2.14,104.74,8.14,155.27,24.26c10.81,3.45,22.05,5.59,34.28,8.63c-19.63-22.38-43.01-36.91-68.5-47.4
		c-50.52-20.79-102.85-31.41-157.95-24.9c-30.73,3.63-60.14,11.67-86.96,26.62c-35.29,19.68-65.29,46.27-93.22,75.53
		c-40.05,41.97-72.27,88.32-86.23,145.44c-10.94,44.75-15.12,89.63,1.55,134.23c13.75,36.79,39.46,62.56,74.08,79.6
		c36.54,17.98,75.84,19.43,115.77,19.1c34.77-0.29,67.47-9.25,99.06-21.28c26.3-10.02,51.71-22.93,76.55-36.64
		c43.71-24.13,85.84-50.77,127.77-77.82c11.71-7.56,23.43-15.95,37.9-20.68c0.54,3.18,1.5,5.05,0.99,6.34
		c-9.32,23.42-10.73,48.78-17.99,72.81c-4.73,15.66-15.07,23.99-27.63,30.93c-8.54,4.72-16.78,9.75-24.8,15.24
		c-22.25,15.2-34.87,38.19-47.19,60.78c-25.97,47.61-60.98,87.55-100.48,124c-4.21,3.88-8.53,8.04-8.66,13.71
		c-0.61,27.07-1.28,54.14-3.67,81.92c15.86-1.46,26.58,4.86,33.97,16.81c30.05-25.08,55.25-17.57,60.84,24.68
		c5.29-8.57,8.89-17.5,15.15-23.76c7.45-7.45,7.53-15.2,6.89-24.4c-1-14.43-1.28-28.94-1.34-43.41c-0.04-8.98,3.53-15.73,12.06-20.7
		c16.43-9.58,33.07-19.23,47.95-30.98c46.63-36.81,97.05-67.8,147.57-98.7c32.84-20.09,65.43-40.63,94.14-66.5
		c13.56-12.22,29.41-23,37.77-39.53c18.02-35.63,31.49-72.99,38.31-112.64c1.55-9,2.02-19.48,13.23-28.75
		c6.57,30.35-2.64,56.22-8.94,82.51c-6.36,26.57-15.81,51.89-28.13,76.53c7.23-4.08,13.46-8.96,19.95-13.46
		c10.89-7.55,21.94-15.56,36.32-11.29c31.68,9.4,63.39,18.73,94.75,29.1c7.13,2.36,11.21,1.5,15.18-3.92
		c12.55-17.14,23.08-35.43,31.55-54.94c4.99-11.5,10.47-22.78,16.2-35.17c3.64,5.03,3.37,8.65,2.68,11.84
		c-7,32.74-21.39,62.18-40.14,89.72c-14.13,20.75-33.33,37.49-47.03,58.33c-9.9,15.06-25.02,27.92-22.72,49.55
		c1.97,18.47,10.5,34.83,13.71,52.85c5.11,28.72,14.32,56.54,15.44,86.22c0.69,18.18-2.39,36.39,0.45,56.9
		c25.37-26.9,44.2-11.4,62.07,9.53c8.96-15.72,14-33.06,32.82-35.25c18.66-2.18,27.21,13.02,36.26,25.52
		c6.08-3.82,3.17-10.63,7.64-14.09c7.57-5.86,8.12-9.37,5.87-14.31c-0.49-0.94-1-1.87-1.55-2.77c-2.57-4.15-5.83-7.82-8.22-12.08
		c-2.12-3.78-3.53-7.95-4.79-12.07c-1.34-4.4-2.55-8.81-3.54-13.3c-2.11-9.57-4.89-18.98-6.46-28.67
		c-1.57-9.71-3.05-19.43-4.53-29.16c-0.75-4.93-1.39-9.89-2-14.84c-0.3-2.45-0.54-4.9-0.64-7.35c-0.67-2.64-1.22-5.16-1.6-7.48
		c-1.43-8.69,0.53-15.28,6.37-20.76c10.47-9.81,21.75-18.76,32.11-28.67c20.63-19.73,44.54-35.28,66.61-53.15
		c19.65-15.91,32.79-35.78,33.99-61.77c1.51-32.55-4.23-64.35-11.42-95.9c-3.99-17.48-8.53-34.82-12.93-52.67
		c7.59-0.11,9.81,3.8,11.9,8.23c22.58,47.82,32.65,98.16,29.52,150.92c-1.02,17.25-7.79,32.33-19.34,45.89
		c-7.29,8.56-17.89,14.22-23.37,25.91c14.63,8.98,30.11,14.38,45.93,18.91c4.47,1.28,7.37-2.38,9.55-5.18
		c7.49-9.65,14.9-19.42,21.52-29.68c8.11-12.56,15.38-25.66,23.01-38.53c1.16,0.45,2.33,0.9,3.49,1.35
		c-0.78,10.58-1.12,21.1-6.05,31.25c-9.66,19.91-21.95,38.05-35.35,55.59c-7.09,9.28-15.87,18.57-10.98,32.03
		c3.32,9.16,6.8,18.31,10.88,27.14c13.66,29.53,31.67,56.74,45.18,86.44c17.15,37.71,37.21,74.15,45.98,115.46
		c2.88,0,4.8,0,6.44-1.1c-1.82-16.99,3.67-31.69,9.78-45.48c11.49-25.92,31.61-33.94,52.81-22.76c5.02,2.65,9.17,6.92,14.44,11
		c11.4-23.71,29.73-26.34,50.88-17.14c6.99-11.24-2.32-13.26-7.39-15.89c-9.87-5.11-18.38-11.73-26.78-18.87
		c-23-19.53-36.53-45.62-48.8-72.06c-12.29-26.49-10.79-50.23,10.78-66.98c8.73-6.78,17.64-13.34,26.76-19.57
		c36.21-24.72,71.12-50.61,85.19-95.07c4.93-15.57,14.28-30.19,9.93-47.84c-4.68-18.98-10.47-37.74-19.65-54.84
		c-22.22-41.37-41.67-83.99-59.32-127.42c-2.94-7.23-7.56-14.92-6.05-25.02c2.39,1.8,4.19,2.48,4.86,3.76
		c12.79,24.23,26.04,48.23,37.94,72.89c11.13,23.04,27.74,42.59,39.47,65.22c9.94,19.17,16.65,39.38,22.26,63.4
		c17.36-37.94,40.66-67.64,68.67-93.67c5.26-4.89,2.76-9.19,1.43-13.86c-6.8-23.94-4.55-47.43,1.85-72.23
		c3.81,3.13,3.66,5.71,3.51,8.12c-0.8,13.27,0.03,26.5,2.54,39.48c3.75,19.36,4.32,40.41,24.41,52.25
		c7.65,4.51,14.89,9.81,22.84,13.69c33.35,16.28,57.03,41.25,71.13,75.71c5.49,13.43,13.84,25.46,30.78,24.38
		c24.28-1.55,46.86-8.08,64.14-26.78c13.15-14.23,12.88-16.06-2.99-26.82c-17.62-11.94-32.02-27.81-39.54-47.02
		c-8.06-20.59-17.82-38.29-36.71-50.26c-6.37-4.03-12.67-8.21-18.7-12.72c-13.85-10.37-29.21-19.55-37.79-35.15
		c-11.38-20.7-16.74-43.45-17.66-67.01c-0.24-6.12-2.99-13.14,3.08-20.28c9.38,18.38,11.16,37.56,16.21,55.7
		c3.04,10.9,7.33,20.86,15.66,28.52c19.72,18.13,42.48,32.11,65.11,46.1c27.56,17.04,55.01,33.93,71.66,63.26
		c6.14,10.82,12.54,21.49,19.33,33.11c13.93-9.81,29.02-18.19,35.37-33.25C1950.22,1538.03,1940.6,1499.8,1919.93,1463.99z
		 M758.66,799.77c0.78-1.37,1.94-2.5,3.28-3.32c1.44-2.88,3.81-5.39,6.81-7.27c5.92-3.72,11.11-2.11,17.44-2.77
		c11.33-1.19,22.85-3.28,33.82-6.37c11.79-3.31,23.61-6.33,35.37-9.66c5.97-1.69,11.68-3.15,17.4-5.37
		c5.22-2.02,10.36-2.94,15.94-3.34c1.59-0.52,3.18-1.05,4.77-1.57c0.63-0.3,1.26-0.61,1.89-0.9c4.92-2.29,12.06-4.47,18.9-4.71
		c2.22-0.36,4.46-0.63,6.7-0.72c3.09-0.12,6.36-0.02,9.14,1.46c2.01,1.07,3.62,2.83,4.68,4.82c2.37,4.45,2.52,9.79,1.8,14.67
		c-0.35,2.37-0.98,4.68-1.93,6.88c-1.23,2.85-3.4,5.05-5.5,7.31c-5.66,6.08-12.17,11.35-19.09,15.94c-3.43,2.28-7.01,4.34-10.63,6.3
		c-1.81,0.97-3.63,1.92-5.46,2.86c-1.89,0.97-3.79,2.09-5.77,2.87c-7.95,3.14-16.22,5.71-24.42,8.09
		c-4.21,1.22-8.42,2.38-12.63,3.59c-4.14,1.19-8.35,2.1-12.6,2.76c-8.21,1.28-16.52,1.67-24.82,1.68
		c-7.48,0.01-14.96-0.98-22.06-3.37c-1.64-0.33-3.27-0.71-4.9-1.18c-6.06-1.72-13.04-3.7-17.76-8.07c-1.49-1.38-2.7-2.88-3.73-4.42
		c-1.42-0.94-2.78-1.98-4.11-3.11c-2.36-2.01-4.44-5.1-4.31-8.31C756.99,802.76,757.82,801.26,758.66,799.77z M1837.26,1417.29
		c-17.62-13.55-21.03-25.36-11.48-35.55c1.06-1.13,2.78-1.64,5.94-3.43c4.88,14.95,15.07,26.8,17.37,42.24
		C1843.34,1423.72,1840.94,1418.49,1837.26,1417.29z"
      />
      <path
        d="M1639.6,2050.56c-1.23-11.57-10.84-17.56-16.78-25.33c-28.9-37.76-66.49-66.83-100.68-99.34
		c-15.51-14.74-35.31-25.07-46.28-44.58c-2.98-5.3-5.46-10.65-5.49-16.76c-0.08-20.47,0.09-40.95-0.08-61.43
		c-0.05-5.72,2.1-9.39,6.95-12.54c38.6-25.09,63.35-61.96,85.58-101c12.97-22.78,22.82-47.02,32.81-71.12
		c5.19,1.34,5.54,5.1,6.34,8.19c10.16,39.37,16.74,78.39,6.87,119.58c-4.88,20.37-11.2,40.75-12.81,62.25
		c-1.69,22.55,6.81,40.19,20.94,55.1c31.52,33.28,61.36,67.88,89.56,104c18.23,23.34,38.23,27.77,65.38,17.01
		c7.06-2.8,14.36-5.17,21.73-7c16.42-4.07,31.17,4.92,39.07,26.97c5.08,14.17,11.1,28.61,10.68,45.14
		C1775.73,2050.56,1708.27,2050.56,1639.6,2050.56z"
      />
      <path
        d="M676.8,2050.56c0.56-7.63,0.33-15.28-1.34-22.82c-9.55-43.09-20.03-85.83-42.31-124.67c-6.22-10.84-4.29-23.47,4.31-30.74
		c30.99-26.22,51.98-61.04,79.88-89.82c26.22-27.04,57.55-46.51,90.48-64.19c21.17-11.37,43.56-21.17,61.79-37.33
		c20.1-17.81,41.42-11.55,63.03-4.61c4.9,1.57,5.6,5.31,6.13,9.95c6.35,55.38-18.66,96.17-59.02,130.67
		c-28.33,24.21-58.59,45.83-88.44,67.95c-24.83,18.4-28.11,42.12-20.88,69.18c3.03,11.33,4.01,23.37,11.05,33.59
		c4.99,7.24,1.92,14.25-2.68,22.37c-17.63-21.33-39.22-29.22-62.84-16.6c-20.7,11.06-32.81,30.14-32.75,56.01
		C681.6,2050.56,679.68,2050.56,676.8,2050.56z"
      />
      <path
        d="M1214.64,2050.56c-0.19-12.39-6.53-23.03-10.56-33.85c-19.07-51.2-40.5-101.38-68.81-148.36
		c-5.36-8.9-4.24-18.99-0.55-28.35c4.95-12.56,10.58-24.85,15.89-37.26c2.31-5.39,7.22-8.28,11.44-11.74
		c5.47-4.48,11.97-7.89,16.77-12.95c8.95-9.43,18.44-9.06,28.58-3.27c12.55,7.17,25.61,13.05,39.78,16.99
		c-4.21,28.19,5.06,54.54,8.02,81.59c2.83,25.87,8.4,51.23,15,76.01c4.82,18.11-0.8,31.5-7.18,46.54c-7.16,16.88-7,34.72-3.81,53.61
		C1244.96,2050.56,1230.4,2050.56,1214.64,2050.56z"
      />
      <path
        d="M1265.84,2050.56c5.45-22.11,8.25-45.54,22.4-65.05c8.36-11.52,15.97-12.99,23.47-1c12.49,19.98,23.37,40.99,29.54,65.07
		C1316.64,2050.56,1291.84,2050.56,1265.84,2050.56z"
      />
      <path
        d="M547.95,2048.9c0.15-0.67,0.33-1.34,0.52-2.01c0.29-3.86,0.32-7.75,0.34-11.65c0.01-2.21,0.08-4.44,0.12-6.67
		c-1.07-4.23-1.92-8.48-2.41-12.85c-1.37-12.32-15.22-26.66-26.07-27.73c-11.26-1.11-17.2,6.76-21.99,14.66
		c-0.02,0.05-0.05,0.09-0.07,0.14c-2.21,4.42-3.95,9.17-5.66,13.8c-1.71,4.64-3.24,9.35-4.57,14.11c-1.33,4.75-2.66,9.52-3.56,14.37
		c-0.14,0.77-0.29,1.55-0.43,2.33c-0.08,0.92-0.15,1.83-0.22,2.75c-0.01,0.14-0.01,0.28-0.02,0.42c21.83-0.01,42.96-0.09,63.83-0.69
		C547.82,2049.55,547.88,2049.22,547.95,2048.9z"
      />
      <path
        d="M1127.6,2050.56c3.1-20.09,0.91-41.65,14-59.8c3.94-5.46,7.13-9.01,14.01-8.43c6.66,0.57,8.57,5.11,11.16,10.47
		c8.73,18.05,9.08,39.04,20.61,56.96C1168.16,2050.56,1148.48,2050.56,1127.6,2050.56z"
      />
      <path
        d="M413.36,2050.56c3.11-18.76,2.76-38.91,14.58-55.43c4.58-6.39,9.34-14.25,18.89-12.25c9.52,1.99,11.08,11.25,12.72,18.81
		c3.37,15.58,0.15,31.18-1.56,47.79C443.68,2050.56,429.12,2050.56,413.36,2050.56z"
      />
      <path
        d="M354.48,2050.56c4.02-13.89,2.28-29.19,8.86-43.18c3.74-7.94,8.62-14.57,14.01-20.7c7.49-8.51,18.99-5.13,22.1,5.87
		c3.78,13.39,1.96,26.99,0.64,40.54c-0.49,5.06-0.72,10.14-0.91,16.34C384.8,2050.56,370.24,2050.56,354.48,2050.56z"
      />
      <path
        d="M794.8,2050.56c-0.06-12.55-0.57-25.21,6.83-36.68c5.15-7.99,8.71-16.86,20.28-16.36c12.49,0.54,19.45,8.43,24.54,18.71
		c5.12,10.32,7.63,21.47,10.67,33.47C837.07,2050.56,816.53,2050.56,794.8,2050.56z"
      />
      <path
        d="M1063.6,2050.56c-5.76-21.3,0.17-40.29,12.81-57.12c6.44-8.57,12.59-7.34,18.33,2.8c9.25,16.3,13.85,34.32,18.58,53.34
		C1097.33,2050.56,1081.07,2050.56,1063.6,2050.56z"
      />
      <path
        d="M984.24,2050.56c0.44-14.9,5.69-28.45,13.48-41.22c8.95-14.69,20.37-14.99,30.46-0.31c8.24,11.99,11.12,26.19,16.07,40.58
		C1024.8,2050.56,1005.12,2050.56,984.24,2050.56z"
      />
      <path
        d="M1386.16,2050.56c1.34-14.16,5.31-27.69,13.11-40.11c9.82-15.64,21.96-16.84,30.78-0.93
		c6.62,11.94,13.69,24.37,13.55,40.01C1425.01,2050.56,1406.19,2050.56,1386.16,2050.56z"
      />
      <path
        d="M700.08,2050.56c1.24-14.5,9.63-25.14,20.53-34.39c9.4-7.97,21.45-8.06,30.87,0.07c10.07,8.68,13.92,20.8,18.86,33.4
		C747.47,2050.56,724.37,2050.56,700.08,2050.56z"
      />
      <path
        d="M1526.93,2050.56c-2.12-11.45-5.25-22.39-8.68-33.31c-4.48-14.27-0.45-27.14,7.11-41.07c27.75,17.95,27.5,45.22,28.46,73.2
		C1545.32,2050.56,1536.71,2050.56,1526.93,2050.56z"
      />
      <path
        d="M1468.07,2050.56c1.03-5.79-2.24-10.85-3.78-15.93c-4.26-14.06-0.99-26.71,6.16-38.76c4.01-6.76,10.96-6.82,14.57-1.56
		c5.51,8.04,12.4,15.7,12.13,27.1c-0.22,9.15,1.19,18.57,5.07,28.27C1491.57,2050.56,1480.41,2050.56,1468.07,2050.56z"
      />
      <path
        d="M1458.54,1253.96c-32.73-47.77-78.09-81.39-125.87-112.36c-38.21-24.77-77.98-47.24-112.44-77.53
		c-28.6-25.14-50.95-53.29-64.16-90.43c-19.77-55.57,5.63-123.09,55.95-153.75c31.41-19.14,64.63-34.99,95.32-55.47
		c52.67-35.15,76.3-84.18,74.14-146.73c-1.22-35.21-8.24-69.11-23.66-100.83c-15.99-32.9-40.86-58.65-75.58-69.36
		c-49.9-15.4-99.22-5.1-146.86,13.47c-10.11,3.94-19.78,9.14-27.25,17.73c2.64,5.21,6.61,8.4,10.72,11.16
		c12.04,8.11,22.73,17.56,32.24,28.6c18.95,22,31.47,47.86,45.4,72.94c8.85,15.93,19.15,30.06,41.17,27.54
		c8.35-0.96,16.81,2.26,24.82,5.32c16.9,6.46,25.62,23.13,21.41,40.89c-4.61,19.43-14.13,35.74-29.14,49.22
		c-24.89,22.35-52.83,40.73-79.38,60.84c-42.4,32.12-72.45,72.58-85.52,123.53c-12.11,47.21-2.67,94,16.24,137.97
		c19.35,44.98,44.99,86.7,73.34,126.74c10.13,14.31,21.75,23.77,40.28,23.46c34.86-0.6,67.95,8.33,101.31,20.57
		c33.43,15.96,61.96,36.47,82.85,66.46c2.65,3.8,6.23,5.55,10.94,5.24c17.68-1.15,35.09-3.3,50.98-12.46
		C1463.16,1262.06,1461.17,1257.79,1458.54,1253.96z M1272.98,605.22c-7.11-0.33-12.64-1.74-18.36-2.7
		c-5.42-0.91-11.1-2.08-17.08-3.24c-7.33-1.42-11.69-5.23-16.89-10.2c-1.65-1.58-3.32-3.12-4.97-4.68
		c-0.52-0.32-1.04-0.65-1.55-1.01c-5.71-4.13-10.21-9.62-13.6-15.8c-1.33-2.42-2.42-4.97-3.51-7.51c-0.47-1.09-0.93-2.18-1.39-3.27
		c0.18,0.41-1.23-2.72-1.53-3.45c-0.16-0.32-0.32-0.63-0.33-0.64c-3.91-7.41-7.76-14.57-10.79-22.41c-1.17-3.03-2.62-6.85-3.69-9.19
		c-0.67-1.47,0.02-0.56,0.3-0.11c-0.95-1.64-2.13-2.8-2.94-4.58c-0.36-0.77-0.61-1.69-0.93-2.52c-0.03-0.03-0.06-0.06-0.1-0.11
		c-0.09-0.08-0.28-0.27-0.64-0.65c-0.6-0.62-1.21-1.24-1.78-1.89c-1.43-1.62-2.54-3.62-4.02-5.17c0.01,0.11-1.89-1.42-2.17-1.7
		c-1.5-1.49-2.76-3.26-3.88-5.04c-1.55-2.46-2.1-5.31-1.85-8.1c-0.37-3.04-0.04-10.05,2.84-11.36c19.88-9.02,27.84-11,43.52-10.27
		c16.63,0.77,34.66,2.36,50.08,8.96c6.25,2.67,12.26,5.87,18.4,8.76c6.83,3.21,12.66,7.49,17.3,12.9
		c9.58,11.15,17.72,24.18,23.22,37.85c5.77,14.35,9.74,33.1,1.83,47.33c-1.06,1.91-2.42,3.82-3.95,5.38
		c-1.8,3.53-4.41,6.74-7.82,9.44c0.06-0.03-0.01,0.02-0.27,0.22c0.09-0.07,0.18-0.15,0.27-0.22c-0.27,0.16-3.34,2.38-3.94,2.7
		c-2.48,1.33,2.78,2.7,0.18,3.12c-2.7,0.43-12.21-0.58-14.92-0.67C1283.02,605.19,1277.99,605.46,1272.98,605.22z"
      />
      <path
        d="M1801.99,1251.44c3.38,6.42,6.26,12.03,10.22,19.72c-29.23-5.23-55.7-11.67-78.44-29.03c-4-3.05-5.43-5.5-3.78-10.5
		c5.18-15.65,9.68-31.52,14.85-47.18c1.3-3.94,3.77-7.62,6.25-11.01c7.31-9.99,14.72-9.02,19.52,2.5
		C1780.97,1200.81,1791.21,1225.73,1801.99,1251.44z"
      />
      <path
        d="M933.09,752.24c2.81,5.87,2.88,12.76,1.46,19.12c-3.39,15.19-14.79,27.68-28.21,35.57s-28.81,11.72-44.08,14.74
		c-15.9,3.15-32.06,5.53-48.26,4.98c-16.2-0.54-32.52-4.13-46.54-12.28c-4.65-2.7-9.34-6.33-10.62-11.56
		c-1.29-5.23,2.92-11.8,8.21-10.83c-6.88-3.47-15.97,1.1-18.81,8.26c-2.85,7.16-0.38,15.62,4.55,21.54
		c4.93,5.92,11.97,9.68,19.1,12.6c36.49,14.96,78.81,10.82,115.19-4.39c6.13-2.56,12.15-5.43,17.72-9.04
		c11.88-7.71,21.33-18.54,30.63-29.22c1.86-2.14,3.73-4.29,5.13-6.75c1.42-2.49,2.32-5.23,3.22-7.94
		C944.14,769.9,946.5,762.76,933.09,752.24z"
      />
      <path
        d="M760.42,802.21c3.37-6.89,11.43-9.89,18.79-12.04c25.18-7.33,51.04-12.03,76.84-16.71c16.72-3.03,33.44-6.07,50.17-9.1
		c10.11-1.83,20.37-3.68,30.6-2.69c3.63,0.35,7.93,0.82,10.33-1.93c2.27-2.59,1.36-6.88-1.07-9.32s-5.92-3.44-9.28-4.2
		c-23.8-5.36-48.62-2.64-72.77,0.81c-36.84,5.27-74.56,12.63-105.64,33.11c-3.56,2.35-7.11,4.95-9.4,8.55
		c-3.6,5.64-3.52,13.21-0.47,19.16c3.04,5.95,8.76,10.31,15.09,12.46C764.78,820.71,766.41,820.86,760.42,802.21z"
      />
      <path d="M761.03,802.96c-3.14,0.15-6.13,2.15-7.46,4.99C753.76,808.26,753.95,808.57,761.03,802.96z" />
      <path d="M904.04,904.8c1.99,0.1,3.99-0.31,5.78-1.18" />
      <path
        d="M1162.66,475.1c-2.93,9.87,2.15,20.21,7.55,28.97c11.95,19.42,26.27,37.37,42.54,53.34
		c15.61,15.32,34.02,29.27,55.61,32.75c10.16,1.64,20.61,0.88,30.75,2.64c10.14,1.76,20.61,6.82,24.83,16.21
		c0.37,0.82,0.65,1.88,0.03,2.53c-0.37,0.39-0.94,0.49-1.46,0.56c-21.44,3.12-43.26,3.64-64.83,1.55
		c-6.53-0.63-13.07-1.51-19.32-3.49c-21.59-6.82-37.1-25.8-48.1-45.59c-11-19.79-18.89-41.39-32.18-59.72
		c-2.08-2.87-4.32-5.71-5.56-9.03c-1.79-4.8-1.32-10.14-0.44-15.19c0.62-3.55,1.58-7.31,4.31-9.66
		C1159.1,468.64,1164.52,468.84,1162.66,475.1z"
      />
      <path
        d="M1318.26,529.69c6.68,10.77,6.57,24.31,5.69,36.95c-0.36,5.13-0.85,10.41-3.27,14.94c-3.27,6.15-9.71,10.11-16.43,11.94
		c-6.72,1.84-13.78,1.82-20.74,1.79c-1.73-0.01-3.59,0.03-4.98,1.05c-2.9,2.15-1.73,6.79,0.33,9.75
		c7.33,10.54,22.23,14.4,34.4,10.31s21.33-15.06,25-27.36C1341.94,576.77,1340.57,563.35,1318.26,529.69z"
      />
    </>
  );
}
