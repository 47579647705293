export default function Kobold(): JSX.Element {
  return (
    <>
      <path
        d="M1391.28,2050.56c-29.68-41.54-47.97-88.95-75.72-130.69c-12.07-18.16-6.99-39.62-18.22-57.24
		c-4.14-6.5-9.62-12.9-11.8-21.63c23.62-21.97,43.37-47.42,59.28-76.16c11.42-20.63,15.53-42.62,18.09-65.45
		c2.2-19.59,1.77-39.14,0.97-58.73c-0.38-9.35,2-13.55,13.33-14.88c24.76-2.92,49.31-8.46,72.58-18.02c3.94-1.62,8.3-4.14,9.54-9.1
		c-2.36-2.3-5.04-2.19-7.4-1.29c-50.14,19.15-102.49,19.23-154.87,17c-25.1-1.07-47.91-10.67-67.77-26.38
		c-5.29-4.19-7.33-10.17-7.48-16.94c-0.79-37.06,1.64-73.65,8.53-110.32c7.3-38.87,21.88-75.53,30.56-113.87
		c1.22-5.4,3.96-7.91,10.84-7.62c26.79,1.15,50.2,12.02,72.55,24.9c46.66,26.9,92.56,55.25,141.42,78
		c35.69,16.62,73.89,26.7,111.47,38.23c15.14,4.64,24,15.6,27.46,30.19c14.48,61.1-8.23,107.31-57.07,143.94
		c-38.77,29.07-77.22,58.73-113.95,90.3c-19.01,16.34-37.76,33.85-51.68,55.5c-13.19,20.51-10.28,45.88,6.8,63.61
		c25.17,26.13,49.48,53.05,77.21,76.65c15.16,12.9,31.51,24.3,49.31,32.27c11.6,5.19,26.37,3.6,39.09,0.61
		c21.78-5.12,43.28-11.58,62.08-24.86c6.64-4.69,12.65-7.7,17.82,4.89c9.84,23.95,7.82,47.24,2.24,71.07
		c-1.92,8.21-4.72,16.22-7.5,25.18C1563.25,2050.56,1477.87,2050.56,1391.28,2050.56z"
      />
      <path
        d="M682.16,2050.56c3.82-37.91,15.71-73.78,31.07-108.62c5.22-11.83,19.41-23.19,28.3-22.66
		c12.48,0.75,28.96,15.88,34.05,28.11c11.9,28.61,20.78,57.99,26.04,88.48c0.72,4.17,1.15,8.38,1.96,13.63
		C763.68,2050.56,723.52,2050.56,682.16,2050.56z"
      />
      <path
        d="M438.96,2050.56c5.56-38.82,13.68-77.22,35.18-111.16c4.49-7.1,9.42-14.15,15.3-20.1c14.39-14.57,30.1-10.62,36.69,8.5
		c13.53,39.29,15.32,79.68,11.49,121.68C505.12,2050.56,472.64,2050.56,438.96,2050.56z"
      />
      <path
        d="M556.72,2050.56c7.7-11.18,11.23-25.02,16.74-37.75c10.97-25.32,24.2-49.1,41.92-70.23c5.91-7.04,12.72-16.23,22.87-13.33
		c10.68,3.04,12.98,14.84,16.08,24.35c10.03,30.75,11.57,62.56,11.09,95.77C629.71,2050.56,593.81,2050.56,556.72,2050.56z"
      />
      <path
        d="M1667.76,2050.56c9.8-34.88,15.49-69.8-2.38-105.94c12.72-2.4,21.84,2.16,30.86,7.16c32.27,17.9,53.94,46.3,74.74,75.54
		c5.1,7.17,10,14.47,15.45,22.48C1747.57,2050.56,1708.27,2050.56,1667.76,2050.56z"
      />
      <path
        d="M1669.92,474.16c-3.46-13.26-8.74-26.79-21.32-36.21c-19.18-14.37-36.15-2.71-52.58,3.78
		c-21.86,8.64-43.12,17.68-67.27,14.85c-7.69-0.9-13.98,0.57-19.14,7.05c-6.31,7.91-15.68,11.73-24.78,15.06
		c-5.84,2.14-12.35,2.31-17.55-3.63c-4.88-5.57-3.37-10.6-1.15-16.51c2.55-6.8,6.54-12.48,12.07-17.75
		c-14.11-14.5-27.75-28.64-45.3-37.07c-9.12-4.38-18.27-15.02-29.84-8.55c-22.28,12.45-46.43,17.9-71.14,21.86
		c-8.16,1.31-16.29-0.53-23.87-3.08c-12.42-4.17-13.99-11.64-6.52-22.53c7.12-10.39,18.15-15.68,29.62-22.98
		c-9.82-2.67-17.93-4.62-26.47-4.12c-33.14,1.95-66.24-5.6-99.45-0.23c-8.99,1.45-17.71-1.84-26.18-4.87
		c-32.42-11.62-61.59-29.9-91.97-45.62c-29.06-15.03-57.24-31.73-89.51-46.04c8.1,13.53,17.61,22.45,26.72,31.61
		c34.39,34.59,68.33,69.63,105.16,101.77c3.21,2.8,9.92,4.92,12.58,9.58c2.1,1.95,3.49,5.62,4.49,7.66c1,2.04,1.83,4.07,2.66,6.11
		c4.53,5.09,4.34,11.81,2.63,18.42c-2,7.71-7.2,13.01-16.31,13.94c-6.77,0.69-13.12,1.83-20.11,0.21
		c-12.61-2.91-25.43-4.43-33.28,9.19c-0.01,0.42-0.14,0.84-0.47,1.24c-2.63,3.16-5.19,6.33-7.7,9.59c-0.1,0.13-0.21,0.26-0.32,0.4
		c-1.71,2.83-3.43,5.81-5.61,8.17c-0.24,0.26-0.54,0.42-0.86,0.5c1.15,5.73-6.55,8.51-1.65,15.05c6.44,0,14.04,0.26,21.62-0.06
		c9.53-0.41,17.54,4.02,24.99,8.62c5.26,3.25,9.57,9.53,8.34,16.29c-2.08,11.39-10.63,17.76-20.5,22.68
		c-17.94,8.94-37.67,9.66-56.89,12.75c-3.26,0.52-8-0.92-9.32,2.66c-4.75,12.8-11.38,25.43-7.86,40.86
		c19.93-2.9,38.72-1.67,56.85,6.17c14.53,6.28,23.19,21.51,18.64,34.74c-4.58,13.29-13.24,23.23-25.05,30.89
		c-18.93,12.27-39.39,19.19-62,19.61c-11.58,0.22-18.49,9.36-27.28,14.79c-6.41,3.96-2.62,11-3.26,16.61
		c-2.54,22.18-22.69,33.67-42.57,23.96c-8.35-4.08-16.24-8.83-21.42-16.91c-5.77-8.99-14.82-7.35-23.09-6.59
		c-8.28,0.77-4.73,8.75-6.42,13.41c-1.88,5.15-2.37,10.79-3.61,16.18c-10.1,44.01-58.21,64.61-94.56,38.96
		c-9.01-6.36-13.77-16.47-23.42-22.58c-13.35,21.28-24.25,43.15-38.21,63.17c-18.63,26.71-38.4,52.32-62.13,74.91
		c-20.97,19.96-43.72,37.59-68.15,52.67c-23.28,14.37-46.16,30.73-72.77,38.01c-27.81,7.62-54.35,17.12-74.37,38.17
		c-12.97,13.64-37.35,21.87-25.01,50.2c11.33,26.02,35.97,48.39,65.9,49.94c38.04,1.97,74.24-7.74,110.72-16
		c13.91-3.15,28.58-4.62,41.67-0.34c9.49,3.1,8.81,16.45,12.33,25.4c1.09,2.77,2.09,5.68,3.74,8.11
		c9.89,14.57,17.88,29.35,4.67,48.1c26.96-3.23,51.44-8.61,74.3,3.11c5.05-4.68,4.94-9.71,5.37-14.91
		c1.45-17.69-5.69-33.37-11.44-49.22c-4.72-13.03-2.83-25.81-2.43-38.92c12.29,34.02,32.71,65.56,24.5,106.65
		c20.85-12.24,38.61-20.14,57.93-23.26c16.53-2.67,19.17-4.79,15.93-21.51c-1.82-9.41-2.57-18.99-7.34-28.08
		c-8.73-16.65-22.54-28.57-35.15-41.63c-5.63-5.84-8-11.9-7.07-20.76c13.55,7.93,20.92,22.13,36.6,27.63
		c2.25-23.6,7.78-45.1,19.45-64.15c-7.05-8.99-16.8-8.51-25.81-10.12c-30.97-5.53-62.13-13.62-93.57-8.28
		c-37.62,6.39-74.69,4.23-111.83-1.3c-8.39-1.25-18.6-0.43-25.47-9.7c9.59-5.37,19.28-5.89,28.15-3.59
		c19.73,5.12,39.66,4.21,59.64,4.75c23.1,0.62,45.95-2.12,68.87-3.85c12.71-0.96,25.9,4.84,37.9-5.7
		c10.69-9.38,22.93-17.17,35.13-24.61c18.05-11,33.17-26.1,51.95-36.07c10.72-5.69,21.08-4.43,34.37-1.88
		c-14.16,10.8-30.98,8.98-42.41,19.9c6.53,29.49,17.03,57.96,27.71,86.38c9.51,25.32,21.31,49.67,31.16,74.87
		c8.86,22.65,15.8,45.44,18.03,70.8c36.4-5.46,69.91,6.31,103.76,13.41c28.08,5.89,56.29,9.03,84.63,11.21
		c40.63,3.13,80.5-3.79,120.41-11.13c12.68-2.33,15.84-8.29,18.11-18.25c6.53-28.6,21.76-52.56,40.48-76.67
		c8.1,9.59,14.17,18.9,20.57,28.14c29.79,42.96,44.81,90.64,48.69,142.5c0.92,12.38-0.31,25.5,6.74,37.12
		c11.32,18.66,29.45,22.82,49.09,23.96c32.42,1.89,62.05-8.25,90.1-22.93c16.09-8.42,33.46-15.57,45.54-30.81
		c-2.08-2.51-4.39-4.55-5.77-7.09c-3.68-6.75-14.56-12.69-9.2-20.44c5.11-7.37,15.62-2.9,23.77-0.96
		c22.34,5.32,44.77,9.92,71.61,10.8c-19.57-3.71-33.93-10.83-46.94-19.75c-6.63-4.55-14.71-10.46-14.1-20.96
		c-0.02,0-0.14-0.04-0.64-0.26c-3.74-3.32-7.05-6.18-5.23-11.71c11.93-2.5,24.22-5.11,36.39-3.43c17.5,2.41,26.39-6.7,33.79-21.68
		c-19.72-3.18-38.37-6.96-52.32-21.78c-2.92-3.11-6.64-1.96-10.53,0.03c-18.21,9.32-37.02,15.98-58.03,16.7
		c-23.24,0.8-47.1,1.6-64.08,21.79c-1.46,1.74-3.66,2.04-5.91-1.13c7.57-7.63,13.17-16.35,5.36-27.26
		c-10.21-14.27-16.13-30.73-22.54-46.71c-22.21-55.29-35.97-112.33-31.81-172.6c0.27-3.95,1.77-8.65-2.69-11.63
		c-24.01,4.68-39.52-3.32-51.12-24.84c-12.9-23.92-17.4-49.47-16.43-76.19c0.37-10.06-3.58-15.75-12.19-17.77
		c-3.79,1.87-3.69,4.79-4.98,6.87c-5.36,8.63-12.7,15.71-23.15,15.98c-10.66,0.28-16.69-7.88-20.6-16.69
		c-5.09-11.48-5.3-23.85-4.51-36.2c0.37-5.81,3.57-12.5-1.24-17.1c-11.14-10.67-18.19-24.07-25.67-36.83
		c-8.98,1.6-6.07,11.8-13.75,16.49c0.49-38.45,23.99-62.66,49.28-89.85c-27.05,3.87-50.21,0.97-70.3-10.89
		c-19.74-11.65-37.57-26.67-43.59-51.66c4.1,4,8.2,8,12.3,12c19.63,19.16,40.15,36.85,69.36,38.74c16.56,1.07,33.82-1,49.58,3.01
		c37.45,9.53,75.28,13.27,113.65,14.13c29.63,0.67,52.88,10.55,66.83,39.58c9.31,19.37,33.43,25.59,51.84,16.64
		c16.94-8.23,32.06-19.47,44.38-33.55c9.42-10.77,20.2-17.32,33.84-21.04c11.9-3.24,21.83-10.26,23.17-24
		c0.74-7.58-0.93-16.25-11.25-13.79c-21.56,5.14-43.45,6.96-65.33,9.7c-25.18,3.16-47.65-4.24-70.28-12.99
		c-20.08-7.76-36.03-22.03-53.59-33.72c-25.59-17.04-52.08-27.93-83.42-18.04c-10.51,3.32-20.68,1.7-31.11-2.23
		c-20.99-7.91-37.46-22.61-55.52-34.93c-9.5-6.48-20.14-11.82-26.19-22.8c4.08-3.09,7.1-2.98,10.29-0.9
		c16.59,10.84,33.23,21.61,49.81,32.48c16.77,10.99,33.69,17.8,54.99,12.71c19.42-4.64,38.4-10.39,57.51-15.92
		c14.24-4.11,28.92-7.94,43.65-2.88c11.57,3.97,22.49,9.83,34.06,13.8c21.33,7.32,42.41,13.15,65.78,5.77
		c21.14-6.68,40.47-17.55,61.49-23.97c17.22-5.26,34.91-7.72,51.41,3.53c3.89,2.65,7.45,8.35,13.27,4.28
		c4.99-3.49,5.01-9.07,5.52-14.82C1680.67,519.73,1675.78,496.66,1669.92,474.16z M1061.89,806.28
		c26.61,5.57,50.98,12.11,74.65,21.29c-29.66-0.66-58.57-4.31-85.51-20.35C1055.56,803.42,1058.72,805.94,1061.89,806.28z
		 M1106.63,1047.42c-26.51-2.37-53.35-4.27-77.97-15.23c-22.65-10.08-44.77-21.5-65.33-35.58c-9-6.21-16.21-11.94-23.74-17.92
		c3.72-4.76,6.71-3.53,10.1-1c23.08,17.23,48.79,30.07,75.01,41.29c25.68,10.99,53.37,15.81,81.18,19.07
		c14.54,1.7,28-4.3,42.54-4.68C1135.69,1041.68,1122.53,1048.84,1106.63,1047.42z M1250.81,1061.42
		c41.02,16.75,70.12-5.34,89.28-38.04c3.99-6.81,9.59-13.16,10.89-21.47c3.61-7.77,6.67-13.68,10.96-21.98
		c0.68,15.21-3.74,26.59-9.6,37.47c-11.85,21.99-22.99,44.43-47.92,55.46c-20.45,9.05-47.2,4.87-60.27-10.65
		C1246.01,1059.02,1248.69,1060.55,1250.81,1061.42z M1222.31,832.03c5.82-4.82,12.82-7.68,22.85-6.67
		c-6.2,6.12-12.45,7.97-18.67,9.88C1220.41,836.95,1218.27,835.39,1222.31,832.03z M1194.74,931.44
		c2.65,15.93,3.51,32.16-0.85,48.06c-1.45,5.28-1.83,5.18-6.93,3.07c-0.16-25.55-0.16-49.18-0.16-76.15
		C1195.93,915.45,1193.53,924.19,1194.74,931.44z M1194.07,1087.28c15.6-0.07,24.53,6.3,29.43,20.13
		c-11.85-2.58-22.38-8.47-34.2-8.12c-19.86,3.08-37.16,7.71-54.23,13.93C1150.61,1094.76,1170.92,1087.38,1194.07,1087.28z
		 M1339.31,485.92c-1.66,0.07-3.34-0.54-5.02-0.75c-21.93-2.65-24.59-7.24-16.31-28.21c5.32-8.85,9.99-10.63,18.65-7.21
		c17.52,6.91,35.77,11.97,53.72,17.76c2.4,0.77,5,1.17,6.06,4.77C1379.9,487.19,1359.23,485.11,1339.31,485.92z M1628.78,500.94
		c-9.97-5.14-18.37-8.97-26.35-13.53c-8.15-4.66-11.45-12.36-6.1-20.28c5.17-7.66,13.4-5.54,20.41-1.64
		c11.2,6.23,15.43,17.61,19.32,28.72C1637.83,499.29,1636.02,503.89,1628.78,500.94z"
      />
      <path
        d="M1062.44,482.43c3.57-4.17,7.55-8.03,10.65-12.54c2.8-4.07,5.75-7.61,9.36-11c0.16-0.15,0.33-0.26,0.51-0.34
		c2.13-2.75,4.05-5.8,4.24-10.34c-27.48,7.45-53.55,15.9-76.14,33.21c17.7-3.01,34.67-0.01,50.92,2.15
		C1061.99,483.19,1062.13,482.79,1062.44,482.43z"
      />
      <path
        d="M866.82,1684.78c1.56,3.12,3.28,4.13,4.95,1.49c1.93-3.04,1.31-6.54,0.95-9.95c-8.64-81.48-10.63-163.22-8.94-245.05
		c1.01-48.69,27.61-81.6,67.88-105.17c13.95-8.16,29.56-12.64,44.64-18.23c10.3-3.82,18.44-2.79,23.32,9.34
		c12.45,30.91,26.92,61.05,38.29,92.33c10.95,30.14,21.25,60.86,25.45,92.81c5.42,41.25,14.56,82.16,13.48,124.12
		c-0.09,3.34,0.04,6.51-1.68,9.84c-25.02,48.4-49.54,97.13-80.84,141.88c-9.96,14.23-22.31,27.15-37.12,36.62
		c-16.34,10.45-34.31,13.9-53.69,8.62c-28.59-7.79-57.05-16.48-86.09-22.03c-29.89-5.71-59.76-13.44-90.82-10.65
		c-10.05,0.9-18.99,4.66-28.06,8.26c-6.55,2.59-10.1,7.53-9.42,14.94c2.58,28.33,4.4,56.77,11.93,84.4
		c0.11,0.41,0.12,0.93,0.38,1.21c14.45,15.39,3.35,29.06-2.74,43.2c-6.52,15.11-14.19,29.84-16.27,46.55
		c-0.23,1.88-1.79,3.59-4.16,5.47c0.14-18.76-6.9-35.54-11.77-52.88c-5.71-20.35-21.87-31.11-41.25-24.74
		c-10.46,3.44-17.68,12.6-24.5,21.15c-18.36,23.01-30.11,49.66-41.49,76.49c-1.47,3.46-3.29,6.76-6.26,12.81
		c3.32-28-1.66-52.73-4.84-77.48c-1.77-13.78-8.06-26.67-14.85-38.92c-1.84-3.32-5.13-5.75-2.21-10.1
		c16.09-23.97,22.37-51.74,30.18-78.88c6.58-22.86,8.32-46.69,13.19-69.86c4.99-23.74,22.1-54.65,54.18-52.14
		c39.95,3.12,78.27-7.06,116.36-18.38c22.78-6.77,44.21-16.88,66.72-24.08c17.66-5.65,33.89-9.93,51.55,20.67
		C864.32,1680.29,865.45,1682.1,866.82,1684.78z"
      />
      <path
        d="M851.93,1500.22c-0.33,53.18,5.05,104.86,6.81,158.88c-25.27-22.15-50.79-10.56-75.31-2.27
		c-41.44,14.02-83.28,25.85-126.75,31.3c-11.42,1.43-19.98-6.74-28.69-11.61c-34.18-19.13-64.95-43.19-90.36-73.19
		c-31.81-37.55-70.67-64.45-118.08-77.02c-25.4-6.74-51.75-7.51-78.51-3.56c-48.69,7.2-87.67,31.88-123.84,62.99
		c-31.43,27.03-56.7,59.01-77.26,94.86c-0.33,0.57-1.38,0.72-4.05,2.03c-1.23-89.95,40.01-160.16,94.23-223.66
		c5.65-1.72,7.52,2.03,8.77,3.69c27.77,37.01,79.68,13.93,96.81-15.86c11.92-20.73,23.12-41.72,20.98-66.9
		c-0.33-3.91,0.07-7.39,4.65-9.38c28.81-12.53,58.75-19,90.26-17.28c0.68,0.04,1.32,0.99,3.31,2.58
		c-8.06,16.21-13.03,33.99-16.11,52.22c-5.78,34.23,18.5,74.9,59.78,73.08c38.08-1.68,67.75-17.85,94.24-42.89
		c3.89-3.68,8.8-6.01,11.3-11.61c2.32-5.19,8.68-4.55,12.43-2.12c18.74,12.13,37.74,23.76,59.41,33.28
		c-13.72,19.36-21.12,39.8-25.33,61.67c-3.67,19.06,2.87,48.14,24.6,57.67c11.96,5.25,24.65,5.15,36.11,1.45
		c34.26-11.07,56.99-35.87,72.77-67.18c9.88-19.6,19.34-39.61,20.94-62.13c0.09-1.21,0.59-2.61,1.38-3.51
		c9.82-11.21,20.99-21.19,29.58-35.42C842.44,1441.46,859.41,1468.05,851.93,1500.22z"
      />
      <path
        d="M1086.18,1554c-3.27-28.61-7.49-55.83-13.53-82.81c-9.1-40.66-25.37-78.77-40.91-117.1c-6.97-17.2-16.06-33.56-24.39-50.19
		c-2.27-4.54-4.02-9.1-4.86-15.29c85.4,22.59,170.83,35.78,259.21,15.47c-1.67,19.28-5.08,36.73-11.32,53.2
		c-8.27,21.84-16.3,43.68-22.44,66.23c-14.33,52.71-24.86,105.87-18,160.87c1.3,10.42-3.12,19.41-5.66,28.46
		c-6.86,24.35-12.8,49.41-29.4,70.53c-1.82,0.97-3.11-1.17-4.88-2.15c-12.6-6.99-22.47-1.69-28.78,13.96
		c-7.74,19.21-16.41,38.05-24.71,57.04c-3,6.85-7.41,12.24-15.93,14.14c-5.23-4.53-4.15-11.14-4.11-17.29
		c0.3-46.48-1.25-92.9-5.09-139.22C1089.85,1591.61,1085.95,1573.6,1086.18,1554z"
      />
      <path
        d="M1865.2,813.03c32.19-16.32,62.48-34.37,96.51-49.6c-1.59,23.26-2.02,43.57-4.56,63.61c-3.64,28.72-1.8,57.67-6.17,86.48
		c-2.98,19.65-14.76,32.03-25.79,45.54c-43.56,53.34-86.09,107.44-125.73,163.77c-0.94,1.34-2.17,2.48-4.32,4.91
		c-19.87-12.01-39.61-23.99-59.39-35.89c-8.66-5.21-17.37-10.35-26.13-15.39c-6.41-3.69-8.59-7.14-4.45-15.15
		c23.14-44.86,44.71-90.55,68.35-135.14c12.87-24.29,25.08-48.91,38.1-73.11c5.77-10.72,13.98-18.58,25.29-23.79
		C1846.46,824.85,1855.23,818.79,1865.2,813.03z"
      />
      <path
        d="M1176.23,1231.02c21.6-3.86,42.12-7.15,62.5-11.17c11.06-2.18,18.07-0.62,19.28,12.54c1.51,16.49,3.9,32.91,6.43,49.28
		c0.9,5.85-2.51,7.05-6.45,9.16c-11.9,6.37-25.11,3.83-37.6,5.48c-33.9,4.48-67.89,1.64-101.98-0.28
		c-38.95-2.19-76.24-11.89-113.83-20.16c-25.11-5.52-49.39-14.88-73.92-22.89c-8.24-2.69-18.03-1.54-24.52-9.31
		c0.32-5.32,4.28-9.24,5.55-14.01c6.17-23.2,11.69-27.35,41.83-25.42c21.23,1.36,41.83,7.13,62.27,12.27
		C1068.24,1229.73,1121.2,1234.86,1176.23,1231.02z"
      />
      <path
        d="M1629.23,1052.74c-6.16,9.35-12.31,18.74-18.53,28.08c-2.01,3.02-3.95,6.6-0.35,9.02c11.81,7.94,23.16,19.6,36.17,22.08
		c24.64,4.69,39,24.66,60.01,33.97c0.16,0.07,0.27,0.23,0.37,0.43c6.43,3.89,11.79,10.19,16.96,15.53
		c6.63,4.24,9.65,11.64,15.95,14.63c20.22,9.61,39.1,21.57,58.12,33.18c8.12,4.96,12.42,2.91,16.45-4.08
		c6.67-11.56,13.24-23.18,20.87-36.58C1767.44,1128.32,1700.08,1087.43,1629.23,1052.74z"
      />
      <path
        d="M538.64,1457.9c-11.69,4.33-22.88,7.07-34.24,7.89c-30.27,2.18-50.09-17.83-50.3-50.27c-0.12-18.89,5.27-36.78,10.9-54.55
		c1.96-6.2,6.93-6.4,12.52-5.34c26.9,5.08,51.77,15.51,75.39,28.98c14.8,8.43,29.14,17.67,44.07,26.78
		C581.42,1433.03,561.68,1446.74,538.64,1457.9z"
      />
      <path
        d="M734.34,1470.27c19.32-1.76,36.58-6.79,54.53-15.48c-6.42,29.78-17.03,55.59-36.03,77.4
		c-13.73,15.77-29.57,28.37-50.97,32.58c-19.04,3.75-34.02-8.34-35.78-27.65c-2.48-27.2,7.14-50.66,20.24-74.65
		C701.63,1465.55,716.81,1472.01,734.34,1470.27z"
      />
      <path
        d="M1664.21,1274.66c13.87-8.96,28.67-3.67,45.08-4.61c-9.01-9.34-21.02-3.33-28.33-11.54c4.33-12.14,15.79-23.93,3.91-39.45
		c22.72-10.74,45-18.91,69.54-18.74c6.46,0.05,12.2,3.26,16.92,8.05c12.44,12.6,9.72,27.78-7.33,42.68
		c-12.86,11.24-29.76,15.34-46.25,19.57c9.57,18.05,8.21,24.4-13.75,38.08c-17.63,10.98-37.44,16.87-60.82,12.57
		C1652.56,1306.03,1656.88,1290.24,1664.21,1274.66z"
      />
      <path
        d="M917.03,1321.24c-37.54,22.92-55.49,58-65.5,99.99c-16.1-41.78,4.46-110.87,48.21-166.49c18.85,6.9,37.73,14,56.75,20.66
		c6.74,2.36,13.92,3.45,20.79,5.49c5.93,1.76,10.92,4.72,10.77,12.2C962.87,1298.23,939.44,1307.19,917.03,1321.24z"
      />
      <path
        d="M812.63,792.8c-5.8-0.19-8.18-4.35-11.76-6.5c-10.35-6.21-24.61-14.38-22.8-25.07c2.4-14.08,16.24-24.24,30.12-30.73
		c19.72-9.22,40.49-8.61,61.44-5.59c4.18,0.6,7.32,1.63,7.65,6.68C879.54,766.74,852.19,804.72,812.63,792.8z"
      />
      <path
        d="M1345.22,356.54c-20.24-1.37-39.73-3.09-59.26-3.82c-9.96-0.37-13.91-3.39-16.58-14.12
		c-9.55-38.31-13.9-77.74-25.39-115.59c-0.96-3.15-1.1-6.55-1.84-11.21c7.94,3.16,8.68,10.13,11.9,14.95
		c17.48,26.17,34.1,52.93,52.29,78.6c10.73,15.14,23.92,28.51,35.68,42.95C1343.82,350.51,1350.21,351.3,1345.22,356.54z"
      />
      <path
        d="M719.46,1241.79c-27.42,28.4-61.04,43.87-96.39,55.92c-4.41-5.98-1.45-9.24,0.97-12.78
		c12.58-18.39,26.34-36.03,35.08-56.81c4.94-11.73,16.58-10.84,26.16-12.38c16.24-2.6,32.9-4.75,47.63,7.97
		C730.16,1231.07,724.85,1235.91,719.46,1241.79z"
      />
      <path
        d="M298.44,1401.8c14.78-7.16,26.21-19.46,44.2-21.61c-6,29.28-12.88,56.37-36.82,74.49c-13.7,10.37-28.96,15.57-46.15,6.37
		c-14.96-8-15.7-16.38-2.76-27.33C270.03,1422.61,283.31,1411.78,298.44,1401.8z"
      />
      <path
        d="M870.08,1123.7c-1.58,26.95-2.94,52.72-16.27,76.58c-4.31-19.47-4.96-39.74-13.48-58.06c-1.24-2.67-1.47-6.14-3.34-8.12
		c-7.94-8.43-9.79-17.49-7.05-28.93c4.52-18.86,10.42-37,19.59-56.63C870.15,1071.25,868.55,1097.3,870.08,1123.7z"
      />
      <path
        d="M1653.98,1185.74c-19.06,0.82-34.95-4.74-48.91-17.11c-15.82-14.02-4.36-40.17,14.03-43.13
		c15.82-2.54,32.93-3.99,48.8,3.74c7.7,3.75,9.9,7.24,5.29,15.65C1666.1,1157.78,1657.78,1170.28,1653.98,1185.74z"
      />
      <path
        d="M1079.41,622.47c13.7,15.6,13.26,20.11-0.83,34.22c-16.06,16.1-36.93,20.66-59.88,27.24c4.81-22.16,3.08-42.46,3.67-62.7
		c0.23-7.9,6.06-8.17,11.73-8.47C1049.72,611.95,1064.08,617.38,1079.41,622.47z"
      />
      <path
        d="M1390.66,885.26c8.9,14.03,11.25,28.98,12.52,44.18c0.41,4.91-2.61,7.54-7.1,9.26c-8.67,3.33-16.6,2.02-23.86-3.29
		c-8.13-5.95-13.22-14.4-16.35-23.66c-6.95-20.58-15.96-40.89-12.49-66.22C1363.43,855.32,1379.64,866.27,1390.66,885.26z"
      />
      <path
        d="M761.77,1244.54c-0.66-22.38,1.33-24.9,23.53-30.75c7.37-1.94,13.66-7.14,21.64-7.22c4.62-0.04,9.23-0.01,16.56-0.01
		c-12.35,39.62-35.71,69.86-63.05,100.19C755.2,1284.78,762.36,1265.25,761.77,1244.54z"
      />
      <path
        d="M1579.18,1231.23c2.56-4.18,6.6-4.68,11.05-4.82c19.54-0.6,39.06-1.75,58.6-1.95c9.41-0.09,18.25-2.96,28.42-4.77
		c3.13,15.11-3.14,26.84-11.81,36.33c-7.92,8.68-18.6,7.54-30.55,5.34c-22.09-4.07-38.12-16.55-54.9-30.39
		C1579.02,1231.09,1579.14,1231.18,1579.18,1231.23z"
      />
      <path
        d="M1580.81,1294.04c-9.28-7.15-18.91-12.68-24.74-23.93c21.98-0.13,42.15,5.08,62.68,6.48c6.35,0.43,12.69,1.03,19.04,1.56
		c12.11,1.02,15.05,5.71,10.4,16.61c-1.83,4.3-3.75,8.56-5.6,12.85c-4.3,10.01-13.67,14.16-23.63,10.35
		C1604.96,1312.62,1593.43,1303.32,1580.81,1294.04z"
      />
      <path
        d="M1102.38,517.74c10.83,9.41,1.86,15.32-3.82,18.12c-16.07,7.92-33.58,11.76-51.54,12.99c-5.89,0.41-8.13-2.89-6.13-7.98
		c3.71-9.46,7.97-18.71,12.31-27.9c1.52-3.22,4.73-5.53,8.33-4.91C1075.09,510.41,1089.87,507.39,1102.38,517.74z"
      />
      <path
        d="M1279.26,1860.34c9.91,11.9,7.13,26.73,11.82,39.33c0.45,1.21-0.26,3.95-1.21,4.42c-14.14,7.04-21.02,22.31-35.65,32.91
		c-4.89-32,5.05-57.72,17.79-82.46C1277.27,1853.29,1277.07,1857.64,1279.26,1860.34z"
      />
      <path
        d="M1350.39,400.15c-13.61,2.17-26.3,6.95-39.29,0.33c19.54-29.23,50.08-38.61,79.9-13.6
		C1377.73,392.67,1365.53,398.84,1350.39,400.15z"
      />
      <path
        d="M1304.86,1316.64c20.63,14.82,39.86,29.94,57.7,47.03c-29.24-15.22-57.28-33.71-92.89-30.57
		c-1.11-10.68,2.52-19.07,4.52-27.83C1286.22,1305.08,1295,1310.98,1304.86,1316.64z"
      />
      <path
        d="M1719.11,1162.86c-4.08-3.79-8.5-7.34-12.96-10.58c-3.22-2.33-6.84-3.86-10.28-5.72c-0.3-0.12-0.61-0.22-0.93-0.3
		c-4.5-1.13-9.55-2.97-13.06,1.42c-7.19,8.99-12.39,19.25-16.36,30c-1.75,4.75,0.27,9.55,6.07,7.68c17.99-5.82,36.23-9.71,54.83-8.6
		C1727,1169.83,1721.96,1167.14,1719.11,1162.86z"
      />
      <path
        d="M528.33,1723.19c2.43,1.24,4.93,1.48,7.45,1.44c1.4-0.51,2.8-0.97,4.23-1.23c3.98-0.73,7.99-0.88,11.99-1.4
		c4.34-0.57,8.43-1.99,12.73-2.71c3.07-0.51,6.16-0.57,9.25-0.48c0.89-0.27,1.79-0.44,2.74-0.42c3.88-3.09,7.37-7.19,10.48-12.37
		c3.28-6.03,10.8-5.78,14.01-11.01C579.35,1684.66,540.61,1699.84,528.33,1723.19z"
      />
      <path
        d="M1715.63,1183.46c5.17-0.2,9.33-0.2,13.5-0.2c0.42,1.47,0.83,2.95,1.25,4.42c-22.95,17.33-48.54,27.39-81.96,24.62
		c9.99-7.6,12.37-18.14,27.28-18.71C1688.37,1193.1,1701.42,1186.45,1715.63,1183.46z"
      />
      <path
        d="M928.08,730.01c13.39-6.01,27.48-9.92,42.81-10.65c2.76,8.75,0.73,15.97-5.03,21.45c-5.81,5.54-13.15,4.59-20.28,1.93
		C938.73,740.18,932.37,737.04,928.08,730.01z"
      />
      <path
        d="M1297.02,815.07c7.62,4.91,12.88,9.6,7.36,18.76c-5.56,9.25-13.96,9.71-19.77,0.76c-6.33-9.76-7.84-20.33-5.67-32.04
		C1287.02,803.51,1290.16,811.3,1297.02,815.07z"
      />
      <path
        d="M1003.8,563.5c8.56-8.44,16.68-16.04,25.2-24.02c6.54,21.55-22.18,42.96-42.44,41.64
		C991.22,574.02,997.58,569.45,1003.8,563.5z"
      />
      <path
        d="M1140.79,428.54c0.25-1.49,0.43-3,0.59-4.5c-0.44-1.17-0.86-2.35-1.34-3.51c-13.02,4.58-22.36,12.88-31.93,22.43
		c9.79,7.65,19.17,3.75,28.04,0.96C1144.18,441.38,1139.9,433.9,1140.79,428.54z"
      />
      <path
        d="M1495.8,458.01c-4.8,4.24-10.04,5.31-15.31,7.33c-2.22-8,2.51-12.09,6.34-16.38c3.05-3.41,6.35-0.92,9.24,0.55
		C1500.55,451.81,1499.03,454.71,1495.8,458.01z"
      />
      <path d="M1310.64,549.57c-34.99-0.08-97.28-33.95-97.28-33.95h23.16" />
      <path d="M1215.26,515.77c3.57,4.5,7.89,8.4,12.73,11.51" />
      <path d="M1206.35,511.49c18.44,17.36,42.53,28.61,67.68,31.62c6.07,0.73,12.35,0.97,18.17-0.9" />
      <path d="M1224.24,521.99c16.18,10.82,32.7,21.32,50.53,29.11s37.15,12.83,56.61,12.27" />
    </>
  );
}
