export default function MindFlayer(): JSX.Element {
  return (
    <>
      <path
        d="M272.56,2050.56c-5.27-12.26-9.83-24.29-11.5-37.32c-6.73-52.58,10.18-97.29,44.32-136.5c4.28-4.92,9.93-7.55,17.45-11.02
			c-4.02,10.8-5.71,20.27-9.84,29.59c-7.92,17.91-6.78,37.34-0.92,55.74c8.83,27.71,26.42,48.34,52.79,61.6
			c21.06,10.59,43.55,10.85,64.54,4.78c51.68-14.95,91.37-48.15,124.82-89.37c35-43.12,62.49-90.51,84.75-141.48
			c12.5-28.62,24.11-57.6,33.3-87.17c11.72-37.72,21.84-76.03,29.92-114.89c4.35-20.92,9.46-41.87,14.44-62.74
			c5.95-24.92,10.56-50.17,15.64-75.29c1.26-6.22,4.56-9.7,10.3-13.13c34.62-20.69,67.62-43.68,97.52-71
			c4.5-4.11,8.03-10.76,16.75-11.29c1.61,18.98-0.86,38.07,2.94,56.69c7.82,38.36,9.27,77.52,13.92,116.26
			c5.2,43.24,14.66,85.69,20.05,128.85c2.66,21.27,8.68,42.16,13.74,63.08c7.92,32.74,15.87,65.49,24.67,98
			c6.05,22.36,13.55,44.32,20.41,66.45c5.85,18.88,12.55,37.38,22.95,54.37c3.32,5.42,6.34,10.95,13.09,16.05
			c64.57-177.26,131.67-352.89,161.41-542.4c12.55,14.9,23.61,28.54,35.26,41.65c6.16,6.93,11.16,14.79,18.98,20.46
			c12.23,8.86,8.53,23.62,11.12,35.85c7.07,33.52,9.48,67.78,15.1,101.59c6.1,36.69,13.59,73.04,20.25,109.58
			c7.35,40.36,17.47,79.98,28.05,119.42c10.44,38.93,23.06,77.72,46.33,111.41c13.02,18.85,30.78,32.49,50.94,44.5
			c19.71,11.74,39.64,20.85,61.9,22.94c47.77,4.5,90.43-7.71,123.51-44.43c15.24-16.92,27.49-35.62,30.12-59.42
			c1.81-16.38-1.46-31.71-7.2-47.76c32.15,19.18,63.51,122.68,18.64,185.61C1159.63,2050.56,716.69,2050.56,272.56,2050.56z"
      />
      <path
        d="M646.05,387.38c-14.7-5.21-27.31-1.25-40.58,3.09c-12.67,4.14-21.33,14.76-33.92,19.06
			c-5.06-44.66,31.11-83.21,78.29-84.41c44.03-1.12,80.33,14.81,110.68,47.02c22.08-12.39,46.47-19.69,67.52-33.88
			c4.12-2.77,6.24-5.98,6.62-11.32c1.48-20.68,4.62-40.95,12.52-60.5c7.81-19.34,20.15-34.74,35-48.94
			c17.23-16.48,36.87-29.12,57.97-39.76c15.6-7.86,31.49-14.21,49.61-13.72c39.39,1.08,77.55,7.2,112.93,25.6
			c18.75,9.75,30.9,25.68,38.99,44.59c14.4,33.62,21.48,68.67,18.3,105.43c-0.14,1.58,0.55,3.23,0.79,4.51
			c27.41,5.66,53.79,13.08,81.62,21.01c-5.73,10.37-15.73,16.19-23.55,23.49c-21.58,20.14-41.38,41.62-57.1,66.61
			c-2.58,4.1-7.32,8.77-3.38,14.73c3.51,5.3,9.66,6.27,14.66,5.53c10.28-1.52,20.84-1.97,30.3-8.06
			c10.92-7.03,22.69-12.8,34.28-18.73c17.64-9.02,44.14,0.07,57.4,18.38c13.26,18.3,17.55,38.69,17.28,60.73
			c-0.18,14.93,2.42,29.2,9.91,42.68c13.57,24.42,35.15,28.24,55.4,8.19c11.99-11.88,18.77-26.77,20.39-43.9
			c2.74-29.02,23.21-58.1,54.97-65.35c5-1.14,9.21,0.17,14.51,2.51c-31.43,19.85-34.42,49.89-30.19,82.31
			c4.4,33.72-9.55,59.15-33.94,81.17c-31.6,28.53-66.94,35.17-105.48,18.74c-13.18-5.62-21.99-17.46-29.05-31.8
			c-10.69-21.72-6.56-45.91-16.38-67.52c-8.32-18.3-21.59-23.11-36.22-9.56c-8.13,7.53-15.65,15.74-24.08,22.91
			c-6.06,5.16-4.71,9.12,0.1,13.61c13.62,12.72,26.98,25.73,40.99,38c5.44,4.76,9.07,10.04,10.49,16.83
			c5.34,25.52,10.19,51.15,15.84,76.59c2.83,12.74,8.11,24.8,11.76,37.39c7.57,26.14,17.18,51.69,26.13,77.42
			c1.65,4.75,4.13,9.2,7.4,16.38c37.66-50.39,75.63-97.96,115.68-145.65c9.08,37.16,21.96,70.58,55.78,90.62
			c-17.59,39.7-40.38,76.4-62.37,113.36c-17.36,29.18-34.01,59.55-62.73,80.41c-23.47,17.04-48.54,30.05-77.44,34.23
			c-12.96,1.88-23.57-8.33-31.84-25.85c-9.66-20.47-20.52-40.37-30.94-60.47c-6.55-12.63-13.26-25.18-20.7-39.31
			c-4.97,10.61-2.23,20.48-3.72,29.73c-3.16,19.63-7.67,39.04-9.35,58.97c-1.27,15.15,5.4,26.4,14.76,36.5
			c20.73,22.37,43.53,42.13,72.36,53.73c2.67,1.08,4.93,3.16,9.28,6.03c-13.65,14.59-29.39,19.07-48.03,19.14
			c-18.1,0.07-35.38-1.98-54.07-12.61c13.33,123.94,27.6,244.53,37.34,366.44c-80.19-85.84-136.06-185.82-181.41-293.95
			c-96.93,87.75-179.69,186.41-287.8,260.21c1.74-15.18,5.45-28.42,9.17-40.69c9.21-30.36,15.13-61.5,24.35-91.85
			c11.38-37.46,21.65-75.38,29.87-113.65c8-37.24,12-75.25,13.85-113.41c0.39-7.95-1.78-11.97-9.72-14.86
			c-38.3-13.96-62.83-42.67-74.86-80.01c-9.24-28.69-9.18-58.68,15.76-82.3c1.2-1.14,2.63-2.1,4.1-2.85
			c0.62-0.32,1.62,0.12,2.44,0.22c5.92,53.22,20.18,74.7,49.87,75.11c4.67-18.56-19.68-143.18-34.69-174.44
			c-9.94,14.04-19.08,27.32-28.6,40.32c-23.72,32.39-47.3,64.88-71.54,96.88c-15.59,20.59-20.9,44.43-21.74,69.02
			c-1.34,39.15-3.54,78.27-3.5,117.51c0.02,21.54-3.72,43.14-0.52,66.06c-42.26-3.1-73.74,19.08-105.39,45.25
			c-0.97-24.16-5.01-46.81-8.08-69.59c-6.69-49.48-12.79-99.04-19.76-148.49c-3.23-22.9-4.72-46.43-6.39-69.73
			c-2.15-30.06,10.83-54.06,27.59-76.74c21.77-29.46,41.43-60.23,60.69-91.36c18.77-30.35,35.19-61.97,51.72-93.47
			c14.39-27.42,29.36-54.73,39.85-84.11c2.22-6.21,5.52-13.46,10.58-16.94c9.55-6.55,7.19-15.19,6.57-23.34
			c-2.07-27.15-2.57-54.71-7.97-81.24C687.5,428.78,679.41,399.56,646.05,387.38z M762.73,401.64c-4.75-6.04-5.91-13.84-10.78-20.07
			c-14.65-18.72-33.62-29.79-55.75-38.06c-26.8-10.02-52.63-10.77-77.65,2.33c-13.55,7.09-26.98,16.35-29.92,35.93
			c8.28-7.13,17.04-6.95,25.1-9.14c43.51-11.83,73.92,16.69,88.08,53.42c11.76,30.51,9.44,61.95,11.75,93.1
			c2.08,27.96,7.85,54.07,26.88,75.64c15.64,17.73,34.48,29.96,58.01,35.45c19.43,4.53,38.52,3.93,57.46,0.03
			c0.77,1.71,1.29,2.42,1.39,3.18c0.16,1.26,0.37,2.66-0.02,3.81c-9.64,27.91,2.2,52.18,13.34,76.33
			c11.38,24.68,25.71,47.98,35.19,73.54c12.42,33.53,18.12,66.51,6.82,102.88c-12.92,41.59-55.01,82.69-104.84,77.86
			c-22.17-2.15-38.01-12.24-50.05-30.31c-6.79-10.19-9.76-22.18-15.1-34.74c-4.02,6.68-1.8,11.52-2.81,16.03
			c-7.79,34.73,4.55,63.62,27.99,87.89c17.28,17.89,37.33,33.23,64.54,30.18c15.43-1.73,30.87-4.29,45.56-10.41
			c14.5-6.04,27.61-14.14,40.32-23.14c21.17-15,37.07-35.16,48.79-57.45c12.53-23.83,19.69-50.14,20.88-77.6
			c1.11-25.58,1.54-51.33-5.8-75.96c-8.4-28.16-19.63-55.47-28.64-83.47c-11.67-36.28,0.05-71.34,38.28-91.63
			c4.25,11.92,8.32,23.89,14.86,34.59c17.36,28.4,42.62,48.57,69.79,66.87c14.93,10.05,28.01,22.06,37.16,38.1
			c13.64,23.92,4.47,47.54-1.92,70.24c-5.58,19.8-12.46,39.16-16.95,59.42c-9.42,42.5-1.91,81.59,17.31,120.03
			c18.14,36.27,45.56,59.81,84.75,69.4c17.27,4.23,34.87,1.88,52.69,0.89c-18.55-12.67-37.39-23.91-54.43-37.95
			c-31.94-26.32-54.33-73.03-38.27-119.81c10.29-29.96,24.79-57.92,35.46-87.52c5.54-15.36,12.34-30.34,13.8-47.08
			c3-34.27-13.47-61.26-33.35-85.98c-19.4-24.12-41.65-45.95-62.34-69.05c-16.47-18.39-23.79-43.21-41.94-61.17
			c8.49,3.65,17.37,5.38,25.88,8.23c34.85,11.65,65.86,4.9,93.75-18.55c11.44-9.62,22.2-20.07,35.57-27.29
			c13.1-7.08,29.22-2.74,35.83,10.26c9.46,18.62,14.76,38.37,18.24,58.96c3.78,22.35,16.14,38.73,37.64,48.2
			c31.03,13.67,57.47,3.5,81.15-15.91c19.26-15.79,31.94-36.82,29.28-62.73c-2.53-24.66-4.77-48.76,6.14-72.27
			c-9.66,7.35-13.82,17.45-16.27,28.52c-5.08,23.04-10.12,45.68-27.97,63.58c-17.73,17.77-46.46,20.16-64.71,2.68
			c-17.83-17.07-24.01-39.21-24.82-63.11c-0.79-23.24-2.25-45.62-24.4-60.71c-12.07-8.22-35.05-11.06-46.42-1.73
			c-12.34,10.13-26.06,16.67-41.23,20.69c-25.42,6.73-53.19-2.65-56.65-35.22c-2.11-19.86-0.17-39.88,6.39-58.98
			c6.94-20.24,10.85-40.9,11.29-62.29c0.63-30.66-6.9-59.79-17.76-87.94c-7.87-20.4-20.23-37.39-41.94-46.7
			c-19.71-8.45-40.39-11.47-60.87-16.38c-41.59-9.97-79.13-2.08-113.02,22.19c-42.07,30.12-67.62,69.32-67.04,123.8
			c0.36,33.45,2.45,66.11,14.38,97.47c9.88,25.95,22.33,50.89,30.7,77.46c5.28,16.77,0.51,38.83-10.6,46.67
			c-20.94,14.79-43.54,16.45-65.33,3.76c-27.08-15.78-37.58-42.84-39.34-72.26C774.73,452.91,768.65,428.42,762.73,401.64z
			 M938.65,1008.66c-4.41,3.61-8.83,7.22-13.24,10.83c60.65-12.52,120.01-5.47,179.32,8.28c-0.55-11.25-5.87-16.58-15.1-19.28
			c-39.58-11.61-80-15-120.94-12.02C958.37,997.21,947.3,998.04,938.65,1008.66z M825.33,519.78c4.96-0.49,10.16,1.3,16.56-2.44
			c-12.77-35.61-33.01-66.36-59.06-94.18c-0.56,25.06,3.25,48.43,8.42,71.6C796.91,520.15,797.07,520.12,825.33,519.78z"
      />
      <path
        d="M1061.77,1265.09c11.67,19.56,20.73,39.41,31.91,58.21c11.12,18.69,23.24,36.65,35.26,54.67
			c5.58,8.37,7.23,15.88,5.22,25.87c-9.12,45.27-18.5,90.43-29.66,135.29c-10.58,42.56-23,84.46-35.44,126.46
			c-11.7,39.51-26.67,77.79-39.55,116.83c-13.16,39.9-28.92,78.94-43.44,118.4c-0.92,2.49-0.94,5.31-1.69,9.88
			c-9.54-13.71-11.26-28.36-15.81-41.65c-10.1-29.51-19.49-59.41-26.59-89.75c-6.93-29.61-14.86-58.98-20.84-88.86
			c-4.65-23.2-10.51-46.29-14.08-69.64c-4.92-32.19-11.64-64.07-15.07-96.55c-6.33-59.85-19.35-118.98-18.88-179.56
			c0.07-8.58,2.74-14.64,9.14-21.02c25.67-25.6,51.06-51.49,75.75-78.09c22.32-24.05,46.12-46.75,68.42-72.16
			c6.35,10.99,5.85,22.67,9.73,33.16C1043.45,1226.31,1053.55,1244.75,1061.77,1265.09z"
      />
      <path
        d="M1460.01,756.91c-15.1-19.26-23.27-40.85-31-62.77c-3.04-8.63,2.96-14.22,5.82-19.89c15.88-31.57,37.07-59.31,64.44-82.01
			c0.47-0.39,1.55-0.05,3.56-0.05c-4.07,9.6-5.35,19.52-6.94,29.4c-2.45,15.14-1.52,29.94,7.08,42.85c5.26,7.9-0.94,11.4-5.37,18.09
			c15.23-3.95,24.37-13.79,34.46-21.76c7.58-5.98,14.9-12,24.18-15.13c18.81-6.35,35.14,1.89,40.62,20.75
			c1.41,4.83,2.21,9.84,3.43,15.38c-12.47,7.56-21.07,19.4-27.62,31.94c-7.21,13.81,3.25,26.71,7.13,40.86
			c-27.67,3.39-53.24,11.92-78.98,20.11C1483.52,780.17,1470.58,771.44,1460.01,756.91z"
      />
      <path
        d="M582.91,1276.62c4.37-16.06,15.09-27.15,23.68-40.45c10.87,6.92,22.17,6.87,34.01,3.18c-9.49-3.77-18.54-8.41-27.69-12.18
			c-7.18-2.96-15.75,3.36-20.3,9.7c-14.53,20.22-29.04,40.74-27.66,67.69c-13.2-3.94-27.02-21.36-27.28-33.13
			c-0.34-15.27-1.41-30.66,10.17-43.06c6.78-7.27,14.64-13.25,22.96-18.62c19.56-12.62,39.94-22.85,65.93-21.71
			c-0.2,16.56,23.93,26.92,9.32,47.74c8.47-1.48,12.68-7.93,18.65-11.49c8.12,24.84-2.02,53.96-27.12,64.47
			c-19.81,8.3-40.03,9.13-61.12,8.84C576.28,1289.57,580.83,1283.96,582.91,1276.62z"
      />
      <path
        d="M1612.82,681.43c-3.16-16.24-5.96-32.32-18.47-42.87c-19.03-16.05-39.66-10.3-59.44,3.3c-3.4,2.33-6.7,5.07-9.3,8.23
			c-2.94,3.58-6.25,6.55-10.73,5.09c-4.95-1.61-6.36-6.7-6.81-11.32c-2.09-21.49,1.71-42.03,9.51-62.16
			c3.18-8.21,10.56-9.91,16.51-12.85c36.69-18.14,67.65-12.36,81.59,36.48c7.52,26.34,7.7,51.94,0.06,77.91
			c-0.22,0.73-1,1.3-1.52,1.94C1613.77,684.22,1613.33,683.26,1612.82,681.43z"
      />
      <path
        d="M1591.35,703.54c3.58-4.21,6.55-7.7,10.2-11.98c6.49,9.52,1.18,20.23,4.98,31.53c15.5-8.87,16.78-25.55,23.59-39.82
			c16.3,13.19,19.37,30.4,17.49,48.59c-4.05,39.26-21.79,72.83-45.43,103.63c-2.72,3.54-5.19,8.02-11.93,7.82
			c0.55-14.44,4.71-28.3,5.14-42.62c0.76-25.76-0.94-50.91-12.14-74.58c-2.06-4.36-0.07-7.53,2.01-10.59
			C1587.66,711.99,1590.15,708.63,1591.35,703.54z"
      />
      <path
        d="M697.77,1245.59c7-12.45,13.48-24.13,20.92-37.53c1.51,35.44,1.44,68.39-14.12,100.13c-6.89,14.07-11.5,28.62-24.64,38.64
			c-6.25,4.77-13.29,7.6-20.34,9.42c-11.33,2.93-21.96-6.99-22.02-19.08c-0.1-17.61,3.69-32.02,19.93-43.75
			C674.03,1281.47,687.61,1265.12,697.77,1245.59z"
      />
      <path
        d="M680.51,1361.72c15.66-6.01,21.46-21.1,32.68-30.96c-4.52,45.64-38.36,92.86-82.42,100.5c-1.24,0.22-2.39,0.98-3.63,1.2
			c-24.66,4.32-32.75-5.09-27.3-29.75c2.51-11.36,9.63-19.48,15.21-28.79c2.6-4.34,6.19-4.76,10.86-4.38
			c11.34,0.92,22.54,2.82,34.18,0.55C667.41,1368.67,674.03,1367.07,680.51,1361.72z"
      />
      <path
        d="M1635.92,618.59c-0.42-4.34-0.82-7.58-1.24-10.95c58.97,7.9,85.83,80.16,74.21,120.22c-11.6-14.5-22.3-28.7-37.27-38.63
			c-7.99-5.3-15.61-11.04-25.02-13.83c-7.84-2.33-12.44-5.99-11.09-16.23C1637.21,646.21,1635.93,632.87,1635.92,618.59z"
      />
      <path
        d="M573.7,1312.07c19.18,3.64,37.13,1.91,56.34-4.02c-1.78,16.4-13.39,30.43-4.92,46.93c-9.75-3.68-20.26-2.26-27.29-15.21
			c1.04,12.91,7.17,17.69,15.87,23c-22.18,13.84-31.91,33.73-32.47,58.41c-25.31-11.4-28.27-24.05-22.24-43.66
			c6.84-22.22,3.42-45.55,7.63-68.11C568.79,1310.15,570.78,1310.84,573.7,1312.07z"
      />
      <path
        d="M945.85,370.72c-11.43-6.21-21.62-12.58-23.93-25.63c-2.78-15.75,7.08-23.05,24.73-16.6
			c24.7,9.02,48.81,19.68,73.12,29.75c1.44,0.6,2.47,2.16,4.45,3.96C999.38,378.56,973.81,380.8,945.85,370.72z"
      />
      <path
        d="M1111.9,380.88c-19.39,1.54-34.99-5.16-53.07-16.48c26.79-10.71,50.21-20.08,74.1-29.63
			C1134.54,368.32,1134.09,373.15,1111.9,380.88z"
      />
    </>
  );
}
