export default function Phoenix(): JSX.Element {
  return (
    <>
      <path
        d="M744.59,1855.81c-15.45,29.35-29.35,58.49-51.38,82.68c-5.58,6.13-12.43,9.57-19.91,10.19
			c-7.32,0.61-13.45-3.28-17.94-10.02c-4.51-6.78-0.6-11.64,2.64-16.17c27.44-38.25,44.08-81.96,63.73-124.2
			c16.98-36.5,32.98-73.45,51.13-109.45c19.15-37.97,43.83-72.44,65.43-108.84c0.91-1.53,1.63-2.9,0.25-5.18
			c-4.74-0.44-7.28,3.43-9.46,6.57c-28.76,41.48-58.05,82.71-80.29,128.21c-19.2,39.29-36.41,79.57-56.12,118.59
			c-23.97,47.47-50.09,93.7-89.67,130.68c-22.02,20.57-48.71,31.76-76.53,41.61c-30.72,10.88-61.76,19.16-94.65,18.41
			c-62.35-1.43-118.52-20.26-167.42-59.54c-24.04-19.31-45.96-40.45-60.47-68.22c-2.02-3.87-3.17-7.77-3.44-12.14
			c-1.11-18.06,5.95-23.27,23.16-17.42c33.93,11.52,64.4,30.56,97.25,44.41c28.07,11.84,55.15,26.31,87.58,22.97
			c18.45-1.9,36.27-6.31,53.55-11.69c48.95-15.23,88.49-44.8,121.42-85.24c-8.19-3.14-12.51,2.64-17.11,5.86
			c-21.54,15.13-39.93,34.52-63.21,47.32c-38.71,21.27-79.68,34.97-124.34,30.8c-24.59-2.3-45.16-15.13-60.77-34.38
			c-35.04-43.19-53.3-93.14-58.45-148.24c-0.63-6.73-2.77-13.31-3.84-20.01c-1.08-6.76-3.59-13.78,2.3-21.7
			c12.37,24.02,25.89,45.82,40.69,66.71c22.93,32.37,48.29,62.55,84.83,80.82c22.7,11.35,46.64,10.08,69.91,4.23
			c44.75-11.26,81.28-36.94,114.85-67.97c43.98-40.65,79.64-87.86,112.04-137.72c13.34-20.53,24.92-42.21,37.26-63.4
			c0.82-1.41,1.64-3.03-0.38-5.21c-8.46,1.81-11.95,9.75-16.78,15.59c-27.21,32.89-55.1,65.14-91.18,88.73
			c-34.6,22.63-72.15,36.86-114.36,32.96c-38.62-3.57-68.41-21.86-86.97-56.56c-5.42-10.13-2.54-13.84,9.17-13.39
			c12.15,0.47,22.55,7.05,34.17,9.8c29.8,7.05,56.74,0.44,81.91-14.62c38.87-23.25,69.26-56.04,98.07-90.37
			c32.79-39.06,64.9-78.72,98.48-117.08c56.19-64.19,123.55-114.32,198.16-155.34c39.03-21.46,78.64-41.8,117.57-63.34
			c22.49-12.44,40.27-32.28,60.43-48.43c28.67-22.95,50.42-51.24,70.38-81.64c8.09-12.32,17.89-23.47,24.47-39.9
			c-16.62,7.3-29.01,17.4-41.73,26.46c-34.22,24.4-72.94,33.28-113.89,35.5c-1.7,0.09-3.43,0.11-5.12-0.07
			c-8.48-0.91-17.97-0.9-22.12-9.97c-4.58-9.99,2.77-17.44,8.61-23.75c14.99-16.19,31.81-30.75,46.21-47.42
			c20.75-24.03,44.77-45.1,63.6-70.8c0.91-1.24,1.22-2.92,2.29-5.59c-10.38,0.16-17.08,6.95-24.35,10.57
			c-27.77,13.83-55.39,27.94-84.08,39.76c-62.1,25.58-125.44,41.16-192.74,22.18c-7.22-2.04-13.01-6.36-18.46-11.08
			c-4.85-4.21-3.17-8.83,0.04-13.64c8.62-12.92,21.96-20.2,34.51-27.87c34.79-21.27,73.07-35.82,108.94-55.08
			c33.1-17.78,66.11-35.71,94.68-60.48c-2.54-4.77-5.15-2.62-7.81-1.29c-36.41,18.18-71.88,38.16-109.63,53.74
			c-20.49,8.46-41.56,15.07-62.36,22.46c-8.54,3.03-15.91,7.95-24.3,11.01c-35.48,12.95-72.23,15.48-109.52,13.97
			c-2.55-0.1-5.15-0.25-7.62-0.82c-8.48-1.97-17.17-4.5-19.88-13.93c-2.61-9.05,3.31-15.69,9.49-20.93
			c22.72-19.27,48.14-34.7,73.72-49.74c19.47-11.45,40.36-20.52,58.93-33.49c-0.43-1.24-0.85-2.47-1.28-3.71
			c-10.31,3.11-21.19,5.01-30.8,9.58c-25.89,12.3-53.5,17.74-81.46,19.94c-43.37,3.41-86.67,2.04-130.13-3.05
			c-34.75-4.07-67.24-13.19-98.97-27.15c-21.13-9.29-36.92-23.88-48.32-43.51c-5.34-9.21-2.49-15.35,7.72-18.33
			c21.5-6.28,43.37-7.81,65.75-7.02c43.44,1.53,86.76,7.05,130.34,4.19c28.93-1.9,57.34-7.2,85.74-12.78
			c29.27-5.75,57.62-14.73,86.16-23.06c4.05-1.18,8.11-2.25,10.86-5.79c-0.8-4.04-3.8-3.1-6.08-2.69
			c-19.24,3.51-38.57,6.59-57.64,10.89c-78.14,17.6-156.37,22.77-235.79,7.49c-20.49-3.94-40.93-8.35-60.65-14.23
			c-28.35-8.45-48.51-29.81-64.3-54c-10.55-16.16-17.93-34.39-27.84-53.9c14.43,0.15,26.44,3.92,38.2,7.32
			c83.84,24.24,168.44,41.75,256.57,33.9c15.68-1.4,31.5-1.49,47.05-4.32c4-0.73,8.66,0.1,11.96-4.3
			c-2.77-5.41-7.92-3.75-12.11-3.81c-21.75-0.3-43.54-1.08-65.26-0.34c-55.82,1.89-110.6-6.19-164.48-19.05
			c-34.67-8.28-67.03-24.15-99.09-39.94c-26.52-13.06-50.39-29.8-71.31-50.56c-10.88-10.79-7.9-20.9,6.8-25.58
			c10.44-3.33,21.09-2.29,31.73-1.18c27.51,2.86,54.29,11.7,82.11,11.31c48.29-0.69,95.7,11.22,144.02,9.27
			c3.76-0.15,7.67,0.58,11.77-2.16c-4.33-7.4-12.49-7.78-18.6-8.6c-25.51-3.41-49.45-12.24-73.54-20.25
			c-35.41-11.77-70.94-23.49-105.39-37.73c-41.55-17.17-76.85-44.16-110.29-74.29c-27.88-25.13-34.36-54.61-26.07-88.93
			c3.31-13.69,5.79-28.02,14.43-42.03c12.18,14.55,22.01,28.98,34.44,40.63c33.6,31.49,65.03,66.1,108.74,84.62
			c29.68,12.58,56.04,31.69,86.1,43.47c19.34,7.58,38.65,15.24,58.03,22.74c1.96,0.76,4.21,0.64,6.07-2.31
			c-6.15-9.58-17.6-10.79-26.77-14.73c-39.92-17.15-77.95-37.64-113.13-63.03c-21.6-15.59-45.73-27.64-64.98-46.54
			c-26.87-26.37-44.95-56.49-41.76-95.88c0.38-4.64,0.89-9.36,2.18-13.8c3.67-12.64,8.14-25.06,8.52-39.37
			c7.64,1.48,11.04,7.12,15.34,11.34c51.68,50.68,99.62,105.35,156,151c29.34,23.76,63,41.41,97.38,57.28
			c19.29,8.9,39.02,16.68,58.99,23.85c6.68,2.4,13.11,6.43,21.41,3.75c-1.9-6.2-7.73-7.87-12.21-10.19
			c-28.65-14.79-57.49-29.21-86.33-43.63c-42.93-21.46-80.49-50.08-115-83.33c-51.15-49.29-63.9-111.94-64.2-179.11
			c-0.05-10.71,4.43-20.79,9.4-30.32c5.62-10.79,13.12-11.02,20.27-1.18c4.08,5.61,3.83,12.09,4.92,18.28
			c7.56,42.84,29.17,78.24,58.17,109.59c55.55,60.05,120.4,107.9,191.74,147.64c60.99,33.97,124.2,63.17,189.04,88.6
			c48.21,18.91,97.71,34.91,148.1,46.88c25.41,6.04,51.48,10.19,77.94,12.68c42.17,3.98,81.75,17.67,106.52,56.45
			c14.78,23.14,19.36,49.55,23.28,76.15c7.26,49.36,20.09,95.93,64.46,126.48c21.73,14.96,45.6,26.11,68.45,39.07
			c29.56,16.75,56.98,35.48,70.43,68.96c2.83,7.05,6.69,14.43,14.52,20.75c4.48-16.32-2.33-30.21-8.73-40.9
			c-23.97-40.08-17.36-82.47-10.84-123.98c6.14-39.08,19.85-76.73,42.2-109.95c23.75-35.3,55.28-59.7,99.84-62.45
			c9.05-0.56,17.22,3.81,27.04,5.36c-1.3-8.95-8.43-11.79-13.96-15.12c-23.63-14.22-25.39-17.46-21.61-45.52
			c0.34-2.53,0.47-5.1,0.72-7.64c0.56-5.74-1.4-12.91,5.64-15.68c6.5-2.56,10.01,3.77,13.74,7.31c6.68,6.35,13.67,12.4,17.79,21.25
			c6.93,14.9,20.19,23.71,33.63,32.09c3.12,1.95,6.23,2.74,10-0.38c-2.36-8.45-9.56-13.18-15.5-18.87
			c-21.18-20.29-24.55-36.32-12.43-57.59c6.13-10.77,17.02-15.26,27.14-20.26c9.83-4.86,20.12-8.86,30.87-11.92
			c11.64-3.31,18.19-14.27,27.73-24.55c-6.06,19.98-19.18,33.54-26.47,50.09c-7.95,18.07-11.11,36.58-1.72,59.23
			c9.47-22.2,18.09-40.95,38.47-52.31c-6.94,24.05-10.7,46.83-4.96,70.32c3.9,15.96,11.89,30.13,20.91,43.23
			c18.3,26.59,31.05,55.25,38.55,86.5c1.12,4.69,0.08,7.92-2.57,11.73c-20.34,29.32-39.5,59.32-51.37,93.32
			c-2.4,6.87-6.34,14-14.61,9.14c-21.11-12.4-44.75-13.69-67.83-15.78c-22.7-2.05-36.75-13.66-46.72-32.53
			c-1.56-2.96-3.07-5.95-4.59-8.89c-6.39,0.05-8.08,4.31-9.76,8.79c-9.45,25.28-11.71,51.38-7.54,77.83
			c2.38,15.13,11.2,27.35,22.12,37.76c18.09,17.24,38.05,32.51,52.99,52.96c20.56,28.14,30.6,58.4,21.18,93.3
			c-19.77,73.21-61.6,128.49-130.34,162.58c-42.74,21.2-81.18,49.28-120.29,76.35c-40.96,28.35-86.53,47.99-132.34,66.33
			c-7.96-4.38-5.56-14.74-13.57-19.66c-3.07,7.18-2.13,14.39-2.04,21.23c0.48,34.09-5.16,67.25-18.71,98.31
			c-9.64,22.1-22.26,43.36-39.87,60.2c-17.85,17.07-23.56,37.39-23.66,60.97c-0.12,30.22-0.9,60.39,11.9,92.45
			c-24.16-9.01-40.9-1.6-55.14,15.15c-4.57-10.24-7.79-18.57-6.32-29.83c2.13-16.38-3.67-32.79-7.18-48.97
			c-4.58-21.13-13.24-41.14-17.28-62.5c-3.8-20.08-7.88-39.22,1.52-59.63c11.04-24,14.51-50.3,16.5-78.08
			c-8.77,2.86-12.58,9.2-16.47,14.54c-35.35,48.56-65.94,100.02-90.92,154.64c-16.82,36.77-29.46,75.27-37.51,114.78
			c-11.23,55.07-14.28,110.91-0.55,166.03c12.8,51.42,37.52,94.03,86.25,122.03c35.69,20.5,72.31,39.49,107.21,61.39
			c1.8,1.13,3.86,2.04,5.31,3.52c3.3,3.36,10.54,4.24,8.43,11.19c-1.88,6.18-6.71,9.37-13.08,10.85
			c-26.15,6.1-52.24,6.31-78.67,1.43c-64.22-11.86-112.22-47.78-150.76-98.9c-16.49-21.87-31.29-44.8-40.84-70.2
			c-7.55-20.08-13.42-40.96-15.07-63.02c-2.82-37.62-1.22-74.93,2.89-112.26c1-9.09,4.87-17.89,3.29-28.91
			c-10.17,8.76-11.04,20.53-14.56,30.39c-9.4,26.37-18.48,53.02-25.05,80.19c-10.71,44.35-16.03,89.25-12.77,135.34
			c2.68,37.84,18.37,69.18,39.73,98.81c4.91,6.82,10.27,13.83,4.58,21.9c-5.5,7.78-14.25,7.84-23.22,6.43
			c-43.24-6.77-70.3-34.54-92.15-69.68c-13.83-22.24-17.26-47.82-23.65-72.29c-3.43-13.13,4.24-26.57,7.93-39.44
			c12.67-44.23,33.23-85.34,52.97-126.68c10.04-21.02,22.02-41.11,33.03-61.67c1.5-2.8,4.47-5.46,1.72-9.75
			c-5.95,0.46-7.73,5.48-10.28,9.64c-21.71,35.48-43.22,71.12-60.72,108.9c-13.17,28.44-25.43,57.29-31.96,88.23
			C760.43,1827.37,750.94,1840.43,744.59,1855.81z M1735.27,619.47c-6.27-5.81-10.38-14.37-19.48-16.77
			c-16.49,25.64-7.91,52.47,17.71,58.67c6.95,1.68,10,0.12,10.97-7.52C1746.02,641.65,1741.46,631.43,1735.27,619.47z"
      />
      <path
        d="M1367.82,226.01c21.16-26.15,41.77-51.58,62.37-77.01c6.5-8.03,10.24-6.72,13.65,2.94c6.93,19.62,6.41,38.9-3.18,57.02
			c-16.31,30.84-31.07,62.37-40.59,95.89c-7.16,25.22-14.84,50.3-13.89,77.48c1.36,38.74-0.55,77.6,5.12,116.15
			c0.42,2.83-0.43,6.1,2.66,8.46c4.53-3.99,3.12-9.44,3.23-14.19c0.68-28.57,2.07-57.18,1.24-85.72
			c-0.94-32.37,7.01-62.28,19.86-91.44c11.29-25.63,19.07-53.03,33.91-76.83c26.04-41.76,43.89-88.24,74.07-127.52
			c8.39-10.93,18.88-19.64,31.28-25.74c6.96-3.43,14.39-6.6,21.44-0.21c6.74,6.1,7.78,13.65,5.28,22.24
			c-6.45,22.17-16.89,42.53-27.77,62.73c-19.51,36.21-37.31,73.1-51.24,112c-8.89,24.81-17.18,49.77-24.49,75
			c-16.6,57.26-24.77,115.83-18.84,175.4c4.53,45.59,1.13,89.66-12.54,133.57c-11.66,37.45-19.23,75.94-11.81,115.65
			c0.37,2,0.05,4.13,0.05,7.79c-14.57-9.11-27.91-17.7-41.5-25.89c-20.34-12.26-42.24-21.86-61.34-36.26
			c-23.49-17.71-39.48-39.52-47.06-68.95c-7.35-28.5-9.99-57.85-17.97-86.01c-11.87-41.9-36.6-73.32-78.13-90.12
			c-21.54-8.72-40.01-22.29-55.41-39.74c-8.02-9.09,0.66-27.69,14.64-31.94c25.13-7.65,40.75-3.53,57.66,19.56
			c19.77,27,41.49,52.88,55.17,83.95c6.25,14.19,11.27,29.03,22.82,44.7c0.35-13.81-2.45-23.61-4.74-33.49
			c-7.28-31.39-25.24-57.13-43.26-82.94c-9.96-14.27-20.08-28.43-28.51-43.78c-14.47-26.35-17.21-54.03-10.82-82.84
			c3.98-17.97,17.16-30.56,27.52-46.83c19.26,77.9,56.55,145.67,91.23,217.12c5.22-11.26,0.93-20.25,0.52-28.98
			c-2.39-51.49-6.03-103.08,7.11-153.73C1333.01,274.62,1348.76,249.82,1367.82,226.01z"
      />
      <path
        d="M1389.69,1496.59c-24.19,0.12-45.32,8.39-66.54,15.72c-13.71,4.73-17.56,2.92-16.4-11.36c0.58-7.15,2.53-14.21,4.12-21.26
			c5.04-22.4,4.58-44.97,0.29-67.24c-3.4-17.63-4.03-35.47-6.47-53.15c-2.25-16.28,0-32.2,2.84-48.2
			c3.17-17.85,18.08-24.3,31.23-30.97c34.83-17.66,67.68-38.4,100.06-60.04c2.69-1.8,5.03-4.9,11.03-3.09
			c6.37,27.25,16.14,54.14,5.2,84.54c-13.12,36.47-34.37,67.99-54.04,100.62c-5.89,9.77-11.3,19.83-17.02,29.7
			c-4.39,7.59-0.73,11.08,6.4,12.68c26.36,5.91,52.51,2.57,78.42-2.7c10.76-2.19,21.24-6.01,31.58-9.82
			c8.77-3.24,16.88-1.14,22.54,4.6c21.54,21.88,34.82,49.49,52.69,75.23c-16.46,6.77-29.19,15.82-42.81,24.57
			c-12.99-25.52-33.97-32.16-60.12-26.85c-2.48,0.5-5.36,1.51-7.29-0.64c-17.8-19.96-41.01-13.91-63.09-13.24
			C1398.51,1495.8,1394.7,1496.27,1389.69,1496.59z"
      />
      <path
        d="M407.43,1805.21c-30.66-0.63-46.67-21.31-60.79-42.46c-19.68-29.5-20.47-63.42-16.79-97.38
			c1.44-13.25,4.83-26.29,8.4-39.32c3.64,6.45,1.78,13.19,1.68,19.67c-0.82,50.89,25.69,81.95,71.82,99.4
			c33.19,12.56,66.9,19.39,102.4,16.61c3.23-0.25,6.95-0.86,10.68,4.88C490.84,1794.13,451.24,1803.91,407.43,1805.21z"
      />
      <path
        d="M1833.67,842.45c-1.04,2.49-1.64,4.1-2.5,5.57c-2,3.43-1.66,9.32-7.47,8.96c-4.85-0.3-4.71-5.4-6.31-8.69
			c-13.42-27.56-32.33-50.79-56.25-69.79c-5.8-4.61-8.65-9.41-6.26-16.28c11.18-32.12,25-62.97,44.56-92.21
			c7.81,6.89,8.21,16.49,12.64,23.91c11.31,18.93,20.16,39.05,27.8,59.86c8.84,24.1,3.05,47.03-2.07,70.32
			C1836.55,829.91,1835.27,835.71,1833.67,842.45z"
      />
      <path
        d="M218.6,1617.58c0.03-6.74-0.03-12.28,0.09-17.82c0.35-15.16,7.23-25.08,18.52-26.86c17.14-2.7,27.92,2.98,34.31,17.08
			c4.66,10.28,7.72,21.07,7.81,32.06c0.21,25.34,7.71,48.46,18.57,70.87c1.05,2.16,1.07,4.81,1.86,8.65
			c-14.47-6.09-24.07-16.41-33.8-26.05c-7.93-7.86-13.01-6.68-19.72,1.03c-17.89,20.55-18.5,44.74-15.93,69.82
			c0.58,5.72,3.18,11.43,0.14,18.48c-6.55-8.41-11.4-16.43-11.77-26.62c-0.71-19.17-1.98-38.51,2.42-57.28
			C226.06,1659.78,218.97,1639.5,218.6,1617.58z"
      />
      <path
        d="M1272,1748.15c-5.42,1.32-5.67,1.43-7.8-3.66c-8.53-20.44-14.96-41.71-19.56-63.21c-5.11-23.89-15.69-46.83-12.62-72.44
			c1.74-14.45,25.25-38.06,39.68-36.69c10.71,1.02,11.63,10.22,12.53,18.08c2.76,24.24,1.57,48.57-0.52,72.78
			c-2.05,23.72-7.75,47.04-7.55,71.06C1276.2,1738.52,1273.64,1742.98,1272,1748.15z"
      />
      <path
        d="M1193.7,1598.19c17.36,1.06,21.56,5.64,19.14,21.45c-7.21,47.01-8.26,94.81-18.48,141.42c-1.07,4.87-1.77,9.93-6.15,15.28
			c-12.33-41.23-26.82-80.86-25.31-123.76c0.59-16.74,4.09-32.94,16-45.99C1182.7,1602.42,1186.63,1598.61,1193.7,1598.19z"
      />
      <path
        d="M367.84,71.03c3.97-5.09,6.83-10.1,10.96-13.59c12.07-10.22,25.75-5.03,30,10.1c3.62,12.9,4.42,25.55,3.59,38.97
			c-1.43,23.1,4.74,45.52,9.93,67.87c1.6,6.89,5.23,13.33,4.64,21.78c-25.36-20.64-48.59-41.87-65.08-69.74
			c-4.26-7.2-5.88-14-4.44-21.76C359.56,93.37,362.39,82.27,367.84,71.03z"
      />
      <path
        d="M608.36,871.75c37.33,6.19,73.97,4.26,111.21,4.89c-0.79,2.49-0.81,4.24-1.53,4.61c-19.29,9.81-38.57,19.66-58.05,29.08
			c-6.17,2.98-13.04,3.66-19.97,2.94c-24.87-2.6-50.21-1.22-74.33-10.14c-6.59-2.43-12.4-5.31-17.48-9.98
			c-4.17-3.84-8.57-7.85-6.55-14.13c2.19-6.81,7.76-9.73,14.72-10.3C573.46,867.34,590.36,869.13,608.36,871.75z"
      />
      <path
        d="M1294.45,317.53c3.19,29.96,2.77,58.79,1.75,87.91c-12.36-29.71-28.01-57.76-38.06-88.4
			c-9.62-29.33-13.45-58.77-7.51-89.16c1.19-6.1,1.62-12.68,6.64-18.89C1281.51,241.17,1292.25,277.23,1294.45,317.53z"
      />
      <path
        d="M1385.08,1629.21c-0.05-5.29,3.97-7.47,7.36-9.55c31.45-19.3,54.13-45.84,66.92-80.54c2.88-7.8,6.55-14.19,16.01-13.43
			c12.37,0.99,26.23-2.83,35.55,9.19c3.85,4.96,3.9,11.07,1.83,17.08c-6.27,18.24-15.85,34.1-34.04,42.48
			c-15.79,7.27-30.08,17.31-46.37,23.71C1417.21,1624.1,1401.53,1627.25,1385.08,1629.21z"
      />
      <path
        d="M190.27,1814.2c7.68,7.98,14.77,15.29,22.37,23.13c-9.13-1.1-18.97-5.85-25.19,5.73c-4.82,8.98-7.63,17.9-5.11,28.1
			c3.57,14.46,7.68,28.61,15.31,41.67c2.11,3.62,4.83,8.03,3.97,13.05c-27.2-5.38-56.83-38.34-57.6-64.71
			c-0.45-15.31,0.16-30.66,0.42-45.99c0.11-6.62,2.62-12.34,6.84-17.48c4.18-5.1,8.91-6.48,14.5-2.78
			C174.2,1800.51,183.37,1805.19,190.27,1814.2z"
      />
      <path
        d="M1102.52,1675.96c-10.7,9.16-18.79,20.22-30.83,26.34c-4.24-4.82-2.78-8.76-1.62-12.91
			c8.57-30.74,22.51-59.26,36.27-87.84c0.89-1.84,2.53-3.39,4.03-4.86c6.21-6.08,11.2-16.4,20.95-14.36
			c11.42,2.39,13.1,14.82,16.17,24.15c4.24,12.88,2.29,23.85-9.92,33.53C1124.78,1650.12,1114.44,1663.33,1102.52,1675.96z"
      />
      <path
        d="M1558.86,1656.14c-4.33,9.65-8.29,18.38-12.26,27.12c-2.34-24.85-1.23-49.4-4.33-73.79c-1.72-13.49-1.76-27.22-3.93-40.62
			c-1.44-8.87,0.95-14.59,8.1-19.46c10.18-6.93,20.03-14.23,32.1-17.82c7.64-2.27,10.41,0.66,10.18,7.94
			c-0.03,0.85,0.07,1.74-0.13,2.55c-8.57,33.23-11.85,67.79-25.2,99.82C1561.61,1646.16,1560.6,1650.76,1558.86,1656.14z"
      />
      <path
        d="M442.79,718.61c-13.33,5.08-26.07,9.52-36.59,18.8c-5.36,4.73-12.23,7.82-20.03,6.33c-17.09-3.25-34.3-6.07-49.42-15.66
			c-5.64-3.57-11.25-7.61-9.32-14.9c1.82-6.85,8.65-5.95,14.06-5.54c10.98,0.83,22.17-1.71,30.78-6.6
			c20.61-11.69,38.31-5.28,56.47,4.22c5.61,2.93,11.35,5.73,16.59,9.25C449.66,717.42,446.29,718.11,442.79,718.61z"
      />
      <path
        d="M925.31,1906.01c-2.75-8.04-5.1-15.19-7.51-22.52c14.15-1.18,91.34,72.57,100.06,95.22c-11.76,9.1-23.42,4.37-33.84-1.78
			c-23.17-13.68-40.38-33.12-51.53-57.79C930.58,1914.9,927.99,1910.96,925.31,1906.01z"
      />
      <path
        d="M316.34,202.51c-0.22,3-0.43,5.07-0.8,8.74c-14.6-10.74-24.54-24.09-30.26-39.18c-6.57-17.35-11.18-35.96-4.64-54.82
			c4.68-13.51,15.84-16.83,25.21-6.14c15.66,17.87,29.03,34.76,15.62,60.57C316.7,180.83,315.74,191.21,316.34,202.51z"
      />
      <path
        d="M1158.75,1876.43c14.43-7.42,21.16-20.45,28.81-32.48c2.7-4.25,4.86-7.79,10.47-7.6c6.22,0.21,8.96,4.59,10.94,9.33
			c7.17,17.2,4.36,34.18-5.94,48.47c-9.32,12.93-24.72,14.59-39.48,11.13c-13.38-3.13-27.07-6.82-37.62-20.5
			C1137.07,1880.53,1149.41,1885.06,1158.75,1876.43z"
      />
      <path
        d="M373.68,1563.96c2.03-2.82,3.53-4.88,5.11-6.89c4.16-5.3,9-9.61,16.3-7.13c7.12,2.42,10.45,8.07,11.84,15.35
			c2.79,14.67-0.61,28.08-8.51,40.25c-12.77,19.68-24.49,39.39-12.77,64.66c-11.23-2.33-20.02-13.73-21.03-25.15
			c-0.72-8.17,0.07-16.16,3.57-23.75c4.85-10.52,5.7-21.22,1.6-32.29C366.61,1580.43,367.88,1572.24,373.68,1563.96z"
      />
      <path
        d="M369.3,452.83c19.37,6.88,35.45,18.78,53.47,25.42c16.64,6.13,31.22,14.31,45.14,25.84
			c-24.75-7.96-50.73-5.56-75.21-13.32c-21.38-6.78-38.87-18.57-53.27-35.43c-3.24-3.8-4.91-7.85-1.64-13.82
			C347.97,445.14,358.19,448.78,369.3,452.83z"
      />
      <path
        d="M724.45,1958.33c8.63-22.5,15.46-32.98,25.43-38.25c10.35,19.68,16.15,40.53,17.81,62.57c0.49,6.55-3.26,10.55-9.85,10.77
			C736.11,1994.17,718.13,1985.2,724.45,1958.33z"
      />
      <path
        d="M1423.75,89.45c-14.83,35.79-35.22,67.05-60.34,95.98c-12.54-31.24,17.19-81.14,60.67-103.35
			C1427.02,84.25,1425.02,86.52,1423.75,89.45z"
      />
      <path
        d="M1323.85,133.34c1.87-5.78,4.36-9.84,9.51-12.05c1.05,41.24,3.16,82.03-20.68,120.7
			C1302.28,203.25,1302.52,167.74,1323.85,133.34z"
      />
      <path
        d="M303.84,526.17c22.47,3.68,33.25,22.75,49.28,34.09c9.22,6.52,18.52,13.18,27.65,20.25c-28.19-9.6-60.21-7.79-83.93-29.9
			c-4.68-4.36-9.96-8.08-12.98-13.85c-2.16-4.12-4.59-8.72-0.72-13.05c3.7-4.15,8.34-3.43,12.9-1.23
			C298.32,523.6,300.62,524.7,303.84,526.17z"
      />
      <path
        d="M1147.14,317.81c9.01,2.05,10.54,9.42,11.14,15.81c1.15,12.21,1.89,24.51,0.73,36.89c-1.15,12.2-8.94,18.77-18.27,24.29
			c-5.39,3.19-7.02-0.3-8.71-5.12c-3.63-10.3-0.79-20.43,0.83-30.24C1135.22,345.09,1138.33,330.61,1147.14,317.81z"
      />
      <path
        d="M1517.52,1565.13c7.76,14.1,7.27,28.67-0.64,42.51c-8.5,14.88-21.12,26.45-33.91,38.06
			C1495.24,1619.31,1507.85,1593.12,1517.52,1565.13z"
      />
      <path
        d="M432.72,1562.26c8.26,16.2,4.03,29.84-6.9,44.16c-7.12-13.77-6.51-27.77-6.27-41.15
			C419.7,1557.21,425.86,1556.13,432.72,1562.26z"
      />
      <path
        d="M1702.92,388.86c8.67-12.22,11.6-26.5,18.86-39.55c6.81,18.11,0.5,37.95-22.46,46.56
			C1698.8,392.91,1701.49,391.6,1702.92,388.86z"
      />
      <path
        d="M1576.83,471.42c-8.1-14.14-3.17-30.71,10.58-37.89c2.75-1.43,5.62-3.05,7.91,0.21c2.09,2.97,0.58,5.9-1.73,7.98
			C1584.7,449.71,1580.64,460.24,1576.83,471.42z"
      />
    </>
  );
}
