import Header from "../../components/Header";


export default function ArenaPage(): JSX.Element {
	return (
		<div>
			<Header />
			<div>about page</div>
		</div>
	);
}