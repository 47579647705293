export default function Gnoll(): JSX.Element {
  return (
    <>
      <path
        d="M1114.8,2050.56c-19.31-22.72-22.04-50.49-25.6-77.62c-3.64-27.73-4.82-55.82-11.88-83.01
		c-8.36-32.22-23.08-61.72-40.73-89.76c-10.81-17.16-19.81-35.04-27.27-53.97c-9.92-25.15-4.97-48.84,8.52-69.47
		c19.87-30.38,33.48-63.57,49.5-95.72c9.6-19.27,21.25-37.52,31.79-56.33c2.65-4.73,4.6-9.85,7.19-15.49
		c12.85,3.87,23.67,13.22,38.84,16.29c-3.36-12.57-13.9-13.36-20.7-17.35c-28.19-16.56-54.39-35.82-80.38-55.48
		c-5.08-3.85-6.93-8.12-6.43-14.14c3.82-45.83,1.49-92.07,9.11-137.68c1.11-6.67,0.88-13.6,0.85-20.41
		c-0.18-48.17,1.25-96.23,7.14-144.12c1.17-9.51,4.88-13.11,14.01-11.03c29.68,6.76,59.71,12.66,87.36,25.8
		c7.62,3.62,13.34,11.59,19.43,18.03c37.5,39.71,69.55,83.65,97.67,130.28c24.45,40.53,46.39,82.47,61.75,127.53
		c6.69,19.61,5.98,39.09,1.89,59.1c-7.92,38.78-30.05,69.27-55.42,98.04c-29.35,33.27-64.12,60.6-98,88.85
		c-16.87,14.07-23.87,30.61-25.23,53c-2.11,34.85,11.04,64.69,23.34,95.28c8.55,21.26,18.77,41.68,31.12,61.06
		c7.98,12.52,17.85,19.53,33.5,18.45c32.03-2.22,59.97,9.2,85.21,27.98c16.52,12.29,21.69,31.73,26.09,49.75
		c3.84,15.75,7.34,32.34,6.42,49.18c-0.4,7.3-0.68,14.43-4.12,22.07c-17.53,0.88-34.65,0.88-52.68-0.22
		c-0.25-16.4,1.11-31.69-0.71-47.01c-5.92-50.01-36.17-93.59-95.9-85.53c-5.13,0.69-10.68,1.14-14.55,6.05
		c9.74,4.96,9.86,4.24,18.44,5.66c27.4,4.53,51.5,19.41,62.81,48.21c9.53,24.26,11.22,48.51,7.94,73.72
		C1228.75,2050.56,1172.37,2050.56,1114.8,2050.56z M1153.84,1381.46c-3.38-22.05-7.92-28.37-22.14-29.99
		c-1.58,10.39-1.54,20.81-1.36,31.31c0.14,8.56,1.86,15.74,11.64,16.41C1153,1399.94,1153.02,1391.11,1153.84,1381.46z
		 M1141.37,1251.35c5.25,10.41,13.63,13.11,18.57,5.98c5.31-7.67,3.14-21.23-4.33-27.5c-3.34-2.8-6.84-5.36-11.39-3.33
		c-4.55,2.02-3.03,6.46-3.21,10.03C1140.79,1240.74,1141.01,1244.96,1141.37,1251.35z M1230.71,1371.74
		c-0.35,9.92,4.02,15.17,15.01,15.52c0.49-8.28-1.38-14.98-6.24-20.31C1235.5,1362.59,1231.43,1361.82,1230.71,1371.74z"
      />
      <path
        d="M533.68,2050.56c-8.06-24.22-5.41-49.02-5.34-73.6c0.05-16.36,7.81-31.25,12.49-46.46c7.68-24.99,11.64-50.54,14.92-76.07
		c2.56-19.85,8.04-39.23,9.22-59.14c1.57-26.37,4.81-52.6,9.72-78.37c5.67-29.73,11.97-59.77,33.02-84.01
		c19.51-22.47,33.24-48.91,48.53-74.25c16.04-26.59,30.95-53.93,55.92-74.01c9.54-7.67,16.72-8.04,25.29,0.39
		c6.6,6.48,13.66,12.55,20.94,18.26c4.56,3.57,9.59,7.61,16.81,3.29c-6.72-16.84-20.75-27.15-34.23-37.39
		c-26.99-20.48-50.1-44.49-69.84-71.91c-2.25-3.13-5.15-5.86-3.83-10.21c9.45-31.13,16.64-62.92,31.81-92.21
		c13.88-26.8,30.12-51.88,49.04-75.25c18.44-22.78,39.19-43.24,65.14-57.4c11.02-6.02,23.56-0.66,35.31-1.98
		c16.28-1.83,18.55,13.03,24.89,21.77c25.27,34.86,42.97,73.89,55.2,114.86c8.67,29.04,16.82,58.41,20.82,88.82
		c2.99,22.74,5.87,45.47,8.66,68.23c3.76,30.62-3.61,56.18-25.31,77.81c-27.68,27.58-56.39,54.03-85.54,80.05
		c-34.65,30.93-69.79,61.26-106.11,90.21c-6.9,5.5-14.08,10.63-21.07,16.01c-11.33,8.71-18.4,19.83-19.34,34.4
		c-2.34,36.14-5.12,72.26-0.29,108.44c1.86,13.94,13.57,27.98,26.69,31.88c25.3,7.52,51.26,32.69,56.09,64.52
		c4.15,27.37,6.37,53.92-0.79,80.8c-0.97,3.66-2.21,7.25-3.78,11.7c-17.58,0.83-34.7,0.83-52.94-0.23
		c8.01-38.22,7.07-74.86-5.51-111.27c-6.08-17.59-17.38-30.39-31.68-41.3c7.46,25.35,17.41,49.8,20.96,75.53
		c3.59,26.02,3.37,52.57-5.52,78.1c-20.53,0-41.07,0-62.22-0.79c1.07-6.88,3.12-12.89,4.38-19.07c6.64-32.57,1.07-64.23-9.46-94.91
		c-3.76-10.96-8.87-23.33-22.72-27.52c-1.29,4.08,0.8,6.57,1.46,9.36c5.97,25.03,14.24,49.58,13.54,75.9
		c-0.5,19.01,0.21,38.06-1.79,57.02C596.43,2050.56,565.65,2050.56,533.68,2050.56z M867.32,1427.3c0.21,6.43,1.14,12.74,3.52,18.75
		c2.25,5.66,6.08,9.42,12.59,8.66c5.89-0.68,9.88-3.36,11.52-9.9c3.06-12.2,0.51-23.8-4.06-34.6c-2.68-6.35-2.75-15.34-12.3-18.88
		C873.83,1402.6,868,1412.9,867.32,1427.3z M774.83,1346.44c12.88-17.39,6.23-34.11-0.94-51.02c-16.25,4.96-28.35,33.51-20.54,46.95
		C758.21,1350.73,764.68,1353.42,774.83,1346.44z M861.17,1277.07c8.7-6.8,3.76-10.74-3.07-15.22
		C852.35,1268.06,849.05,1273.17,861.17,1277.07z"
      />
      <path
        d="M1482.81,205.62c25.29,18.9,40.96,44.09,44.07,74.62c2.7,26.57,11.72,44.82,35.95,59.38c29.13,17.5,45.56,48,60.86,78
		c5.94,11.64,11.71,23.54,19.14,34.22c14.61,21,6.84,40.45-2.46,59.98c-3.44,7.22-8.17,13.83-11.95,20.9
		c-4.24,7.93-5.64,15.98-4.97,25.49c2.3,32.62,7.18,66.63-10.13,96.28c-11.14,19.06-20.04,40.29-36.22,55.97
		c-17.29,16.74-39.33,20.7-62.61,18.24c-16.56-1.76-32.24-7.27-47.67-13.13c-15.73-5.98-23.17-20.45-31.02-33.82
		c-8.61-14.69-19.64-24.22-37.12-27.7c-24.84-4.95-48.96-11.91-68.52-30.36c-16.11-15.19-21.32-34.05-23.63-54.49
		c-2.29-20.27-3.75-40.64-5.6-60.96c-0.23-2.56-0.42-5.23-4.72-5.83c-6,27.02-8.28,54.15-3.04,81.55
		c1.45,7.58,0.53,12.61-5.33,19.05c-17.85,19.63-28.27,43.62-37.24,68.57c-12.22,34.03-20.22,70.31-46.44,97.76
		c-22.12,23.17-40.8,49.42-64.94,72.11c-20.38-13.71-41.27-27.53-61.92-41.68c-33.14-22.72-65.57-46.51-96.31-72.31
		c-22.99-19.3-45.67-39.19-65.69-61.86c-5.55-6.29-2.16-9.54,1.56-13.86c8.51-9.9,17.43-19.31,25.37-29.84
		c19.57-25.99,19.67-55.22,13.77-83.95c-10.11-49.24-39.44-87.28-76.59-120.24c-13.44-11.92-28.7-20.74-42.84-31.35
		c-9.05-6.79-21.23-5.76-29.96-12.59c-1.07-5.37,6.28-6.13,5.03-12.97c-8.25-6.81-24.62-6.67-27.74-23.33
		c29.27,4.39,48.22-22.37,76.27-22.95c-17.7-13.21-41.44-17.11-51.27-41.93c17.68,0.44,33.51-3.06,49.37-7.41
		c9.27-2.54,16.78-7.92,25.55-11.1c21.29-7.72,43.12-12.23,67.9-13.45c-12.81-21.75-19.44-43.36-15.16-70.36
		c32.61,19.24,64.23,35.74,101.89,32.78c-1.63-13.06-14.59-13.14-22-20.33c17.12-11.12,33.22-0.58,49.21,0.94
		c4.55-5.69,0.75-14.45,7.89-18.34c33.36,19.12,46.47,22.67,60.76,16.47c-4.43-15.39-35.17-27.88-68.28-27.74
		c9.56-15.81,53.34-29.02,87.07-25.31c31.32,3.45,60.93,13.49,91.75,28.44c-3.38-20.19-17.47-34.81-14.06-56.6
		c43.54,37.69,99.84,43.68,147.47,71.05c4.89-12.32,6.98-25.14,18.27-34.24c2.85,7.91,7.2,14.75,7.5,21.77
		c0.96,22.1,15.39,35.23,30.64,47.07c20.05,15.57,38.28,32.12,47.99,56.28c0.35,0.88,1.92,1.27,5.06,3.21
		c0.26-17.28,2.73-32.92-3.49-48.33C1482.78,213.69,1478.76,210.38,1482.81,205.62z M1253.52,455.67
		c10.22,7.03,21.37,12.01,33.47,14.75c17.94,4.07,37.14-4.31,43.78-19.86c-14.14,5.14-27.72,9.35-42.35,6.12
		c-18.47-4.08-31.25-15.04-41.47-30.64c-13.1-19.99-20.46-41.47-22.73-65.19c-1.09-11.37,3.98-15.09,13.91-16.43
		c7.72-1.05,15.82-1.56,22.91-6.88c-5.01-10.31-13.75-15.83-22.34-21.13c-6.42-3.96-7.88-8.11-4.22-14.67
		c6.35-11.4,10.64-23.76,18.04-34.78c18.94-28.2,44.52-42.17,71.56-35.24c33.27,8.52,58.33,28.25,73.44,59.68
		c6.8,14.14,14.54,27.78,25.49,39.08c11.34,11.7,26.08,16.36,42.21,17.82c-22.7-14.98-38.97-35.77-54.82-56.93
		c-13.05-17.41-22.79-37.71-40.36-51.2c-18.27-14.02-38.06-25.72-62.87-21.71c-45.95,7.43-71.33,37.1-84.54,79.23
		c-6.67,21.27-5.94,21.75,14.65,31.14c1.2,0.55,2.35,0.96,1.67,3.9c-5.9,0-12.13-0.18-18.34,0.06c-4.1,0.16-7.95,1.65-7.84,6.58
		c0.54,23.57-3.43,47.1,6.93,70.36C1227.56,427.37,1239.81,440.64,1253.52,455.67z M1473.58,473.7
		c-7.17-8.28-14.6-16.37-21.41-24.94c-3.34-4.2-6.9-4.33-11.24-2.93c-13.7,4.39-24.61,23.1-21.35,36.55
		c3.12,12.86,20.96,22.64,35.8,19.64C1468.43,499.37,1478.3,485.74,1473.58,473.7z M1587.38,518.76
		c22.97,7.12,29.06-2.08,35.67-18.94c3.83-9.77-2.09-17.79-9.12-23.31c-6.73-5.28-9.98,3.74-14.66,6.39c-4.36,2.47-8,6.26-11.85,9.6
		C1575.88,502.48,1575.74,505.08,1587.38,518.76z M1369.17,344.25c2.09,6.53,3.66,13.33,8.91,19.14
		c10.86-30.82-36.94-105.42-63.21-101.66C1341.78,283.18,1359.04,310.34,1369.17,344.25z M1142.85,314.91
		c-2.16,6.68-8.45,11.56-8.97,19.73c9.76,3.01,18.13,1.61,24.85-5.53c5.5-5.84,9.99-12.64,15.47-18.5
		c6.67-7.13,3.56-10.02-4.17-11.76C1157,295.93,1149.24,302.39,1142.85,314.91z M1040.37,538.53c-2.19,4.36-1.83,9.05-1.79,13.69
		c0.03,3.47,0.15,6.93,4.14,8.39c3.71,1.35,6.15-0.43,8.84-3.02c10.28-9.91,15.23-34.64,8.96-48.51
		C1046.94,513.31,1046.31,526.61,1040.37,538.53z M980.84,394.73c-9.92,7.73-14.17,19.29-20.96,29.7
		c19.98,0.05,38.59-13.84,43.04-31.97C995.94,390.46,989.13,389.33,980.84,394.73z M1585.46,666.56
		c11.27-4.12,18.31-17.57,16.02-31.37c-9.38,2.48-15.66,9.9-23.54,14.54c-4.13,2.44-8.7,6.36-6.67,12.5
		C1573.3,668.38,1579.02,665.88,1585.46,666.56z M1465.72,650.67c4.33-0.76,7.38-3.06,6.28-7.72c-2.29-9.65-3.23-19.81-9.15-28.4
		C1446.17,629.11,1446.56,642.79,1465.72,650.67z M1018.92,321.69c4.06-3.66,8.23-7.22,12.15-11.02c2.56-2.49,4.37-5.66,1.31-8.82
		c-2.88-2.97-6.86-3.94-10.37-1.48c-4.8,3.37-9.29,7.24-13.6,11.23c-3.95,3.65-9.46,7.71-4.78,13.71
		C1008.95,332.14,1013.03,325.27,1018.92,321.69z M1529.73,601.98c5.56-1.33,3.71-4.46,1.85-7.59c-3.95-6.64-9.26-11.87-15.95-15.78
		c-2.93-1.71-5.86-4.48-9.03-1.21c-3.67,3.79-0.74,7.79,1.83,10.01C1514.48,592.65,1521.29,597.01,1529.73,601.98z M1183.09,438.85
		c8.69-5.12,5.91-12.01,3.49-20.36c-11.24,5.94-15.47,15.41-20.79,25.09C1174.68,445.08,1174.68,445.08,1183.09,438.85z
		 M1554.92,606.24c5.43,2.82,17.4,0.24,22.54-5.2c3.67-3.89,4.03-8.05-0.07-11.94C1567.19,591.35,1567.19,591.35,1554.92,606.24z"
      />
      <path
        d="M1086.69,957.53c-42.34-28.58-86.53-54.3-128.76-82.95c-48.97-33.21-96.27-68.89-138.65-110.49
		c-5.19-5.1-9.95-10.36-15.49-11.82c-0.97,0.59-1.95,1.15-2.86,1.7c-5.4,3.3-11.07,6.04-16.57,9.13
		c-9.88,5.56-19.51,11.39-30.37,14.88c-1.22,0.39-2.13-0.18-2.58-1.05c-23.21,3.05-43.75-4.16-63.4-14.08
		c-49.61-25.04-90.94-60.27-125.5-103.84c-5.08-6.4-7.72-15.13-16.07-19.95c-9.26,14.13-19.67,26.8-32.29,37.27
		c-31.48,26.09-62.69,52.55-85.75,86.73c-25.21,37.37-54.77,70.06-90.76,97.66c-27.73,21.27-42.34,49.61-35.34,86.85
		c4.69,24.97,8.62,50.29,8.3,75.96c-0.5,40.05,2.34,80.25-2.88,120.08c-5.82,44.41-5.77,89.88-24.08,132.19
		c-5.53,12.78-12.12,25.03-17.37,37.9c-8.04,19.71-5.83,39.54-1.93,59.93c7.07,37.02,5.1,74.65,5.4,112.11
		c0.17,20.9,4.74,40.37,22.58,54.17c29.65,22.94,63.96,26.83,91.88,2.98c6.07-5.19,7.33-10.19,1.51-16.1
		c-14.68-14.89-23.23-33.41-28.69-52.98c-4.63-16.58-1.5-33.79-1.06-52.32c7.1,4.38,7.21,9.91,7.36,13.8
		c0.63,16.51,7.03,31.43,11.4,46.9c5.8,20.54,24.86,40.39,41.91,39.49c24.03-1.27,48.05-5.63,68.89-19.3
		c29.28-19.21,42.85-45.05,32.13-81.25c-8.62-13.6-20.82-17.4-35.25-18.22c-19.48-1.11-25-7-27.74-26.37
		c-1.07-7.56-2.08-15.13-3.22-22.68c-2.14-14.17,7.12-23.19,21.19-19.56c8.99,2.32,17.43,6.68,26.3,9.55
		c14.74,4.78,27.44,3.06,39.35-8.87c14.2-14.23,16.16-34.1,26.15-50.1c1.13-1.8,0.45-5.1-0.11-7.53
		c-3.92-17.06-12.91-29.98-29.86-36.53c-14.66-5.67-29.67-7.46-45.17-4.97c-12.29,1.98-24.69,2.1-37.01,1.52
		c-13.12-0.62-22.65-5.69-27.84-19.74c-10.58-28.65-3.04-55.84,5.91-82.2c11.42-33.63,31.36-63.59,46.01-95.93
		c13.01-28.72,23.72-57.91,24.63-89.88c0.12-4.2,0.28-8.06,3.03-12c21.89-31.43,50.86-54.22,85-70.9
		c41.06-20.07,83.18-37.69,125.72-54.34c7.21-2.82,14.45-5.57,22.96-8.85c-7.09,13.54-17.36,21.31-30.66,26.72
		c-23.39,9.51-46.47,19.78-69.6,29.9c-3.97,1.74-6.05,4.72-7.07,9.62c-5.04,24.15-3.24,48.31-1.56,72.55
		c0.52,7.53,4.78,11.81,11.33,13.76c22.67,6.76,44.8,15.1,67.66,21.41c39.68,10.96,79.7,19.31,120.34,26.22
		c52.98,9,105.95,9.37,159.22,9.45c24.48,0.04,48.04-5.14,72.01-8.42c22.88-3.13,32.1-13.3,31.98-34.85c0-0.41,0.2-0.83,0.3-1.24
		C1092.81,963.65,1091.77,960.95,1086.69,957.53z M493.88,826.58c-5.5,13.4-18.09,14.73-30.67,16.83
		c-3.09-10.07,5.54-13.21,9.72-18.67c5.4-4.68,9.89-7.73,14.84-11.09C493.91,815.98,496.18,820.98,493.88,826.58z M522.18,721.93
		c-1.74,8.02-1.91,16.75-9.54,22.6c-3.71,2.85-6.73,5.08-11.01,1.63c-4.69-14.85,0.08-27.06,10.99-31.44
		C519.27,712.05,523.97,713.66,522.18,721.93z"
      />
      <path
        d="M1299.69,935.99c5.44,22.38,1.84,43.33-3.16,64.13c-5.68,23.69-2.48,32.27,19.86,38.7c34.51,9.93,62.73,30.06,90.79,51.08
		c17.7,13.26,36.41,24.89,59.57,25.86c9.42,0.39,16.75-1.14,24.46-9.27c13.83-14.58,24.07-31.44,35.53-47.47
		c27.09-37.9,60.93-39.67,99.5-13.05c-29.64,8.36-60.3,12.78-75.77,43.06c-15.7,30.72-13.14,66.49,9.2,83.59
		c-18.07,11.54-29.41,27.52-32.83,48.63c-3.54,21.85,6.33,38.18,24.09,53.9c-18.76,3.07-35.06,4.03-50.9-1.07
		c-13.66-4.4-26.92-10.21-40.02-16.15c-23.51-10.68-48.5-16.18-73.83-16.63c-39.66-0.71-79.17-0.67-117.93-10.75
		c-22.93-5.96-39.65-20.28-53.32-38.73c-19.02-25.68-35.45-53.3-43.84-83.96c-11.48-41.93-31.61-78.99-54.83-115.02
		c-4.02-6.24-7.02-13.43-10.53-20.16c-3.93-7.53-3.81-14.84,1.27-23.55c16.35-28,31.93-56.52,41.04-88.05
		c3.85-13.3,15.71-21.41,24.03-31.69c18.74-23.16,39.45-44.73,58.14-67.92c20.96-26,31.39-56.77,35.86-92.49
		c10.72,13.04,15.53,26.55,20.33,39.4c11.52,30.8,13.15,62.93,8.06,95.34c-1.12,7.1-3.6,13.97-5.16,21
		c-4.87,21.89-5.56,43.94,0.99,65.47C1294.75,904.94,1296.22,920.13,1299.69,935.99z M1246.13,872.75c-1.53-0.44-4.1-1.65-4.44-1.2
		c-5.29,7.11-7.96,14.86-2.92,23.16c1.99,3.28,5.8,6.73,9.36,3.13c5.94-6.01,6.24-13.51,2.05-20.94
		C1249.58,875.83,1248.45,875.06,1246.13,872.75z"
      />
      <path
        d="M937.16,1277.82c-14.82-40.61-36.35-77.11-52.91-118.3c52.49-6.49,104.12-14.28,154.05-36.12
		c0,22.65,0.55,43.26-0.15,63.83c-0.69,20.42-3.14,40.77-3.94,61.19c-2.19,56.14-9.78,111.87-12.66,167.94
		c-1.42,27.65-1.12,55.41-0.76,83.11c0.39,30.38-4.1,60.28-8.38,90.13c-1.58,11.01-8.02,21.05-11.05,32.21
		c-9.73,35.88-17.38,72.3-27.7,107.97c-4.28,14.8-8.42,30.19-17.02,43.67c-4.43,6.95-8.22,8.71-15.02,2.87
		c-10.84-9.3-16.45-7.69-22.59,4.4c-8.37,16.49-10.81,34.71-16.25,52.05c-3.05,9.71-6.47,19.31-9.92,29.51
		c-8.78-27.59-5.7-55.65-8-83.13c-4.2-50.02-9.67-100.03-8.67-150.38c0.12-6.19,2.11-10.38,6.25-15.46
		c22.18-27.19,48.56-50.55,70.48-77.87c18.8-23.44,23.7-50.43,19.9-80.13c-4.42-34.51-8.04-69.15-14.94-103.25
		C952.86,1327.22,945.62,1302.85,937.16,1277.82z"
      />
      <path
        d="M779.27,446.99c18.73,14.52,39.42,25.41,53.7,43.64c12.1,15.45,26.39,28.96,36.01,46.72c9.66,17.84,3.44,31.37-7.24,44.14
		c-23.56,28.14-51.7,51.4-81.36,72.84c-9.85,7.12-19.67,14.28-29.65,21.21c-18.85,13.1-35.46,4.51-51.16-4.03
		c-50.1-27.28-91.08-66.79-134.67-102.78c-3.76-3.1-6.21-6-4.83-11.84c8.16-34.41,19.19-67.46,42.13-95.29
		c23.29-28.24,54.26-44.76,88.05-56.36c10.41-3.57,18.76,5.8,28.09,8.98C740.07,421.63,758.78,435.09,779.27,446.99z"
      />
      <path
        d="M692.58,1116.71c-26.23-6.97-51.48-13.53-78.96-20.68c21.24-41.41,21.67-83.77,16.33-127.96
		c149.56,60.66,300.4,82.17,458.19,51.78c6.25,12.86,5.68,29.16,12.08,43.57c2.17,4.89-4.65,8.91-8.62,11.23
		c-27.41,16.04-56.13,29.24-86.64,38.4c-12.99,3.9-25.55,8.75-39.01,11.6c-42.62,9.04-85.44,15.13-129.06,13.8
		c-34.9-1.06-69.39-6.01-103.6-12.78C720.02,1123.05,706.28,1122.42,692.58,1116.71z"
      />
      <path
        d="M690.65,1134.73c32.38,11.25,65.37,14.74,100.6,20.64c-31.75,22.07-55.91,48-75.2,78.31
		c-18.19,28.59-35.94,57.54-48.44,89.15c-9.63,24.34-16.82,49.46-20.97,75.52c-2.85,17.91-7.42,35.64-12.37,53.12
		c-9.31,32.88-20.39,65.27-33.98,96.59c-12.46,28.71-26.38,56.85-41.04,84.52c-9.84,18.57-18.47,38.05-32.5,54.19
		c-1.3,1.5-2.39,2.2-6.23,1.31c2.47-11.48,6.65-22.73,9.81-33.93c13.8-49.05,21.72-99.07,28.91-149.42
		c6.26-43.82,10.74-87.88,13.59-131.83c4.13-63.66,14.94-126.96,11.76-191.11c-0.92-18.58,12.97-57.12,24.91-73.12
		C635.88,1117.6,662.12,1128.11,690.65,1134.73z"
      />
      <path
        d="M1614.45,1381.33c-9.65,34.88-19.16,68.69-25.74,103.26c-2.85,14.98-8.08,29.34-11.08,44.42
		c-6.93,34.9-17.5,69.08-24.35,103.99c-9.85,50.25-20.74,100.3-29.04,150.9c-4.3,26.25-10.26,52.43-14.85,78.72
		c-6.49,37.2-15.07,73.91-22.75,110.83c-0.87,4.16-1.71,8.2-6.49,7.78c-12.53-1.1-25.36,1.1-37.39-5.67
		c-7.38-4.15-9.48-8.97-7.62-16.48c11.9-48.24,16.07-97.84,26.16-146.49c9.54-45.98,18.23-92.28,26.07-138.64
		c8.06-47.61,18.05-94.78,28.01-142.01c8.64-40.96,17.66-81.79,27.41-122.51c10.15-42.34,20.94-84.6,28.01-128.9
		c23.61,1.9,46.87-5.79,72.57-0.77c-6.17,12.38-7.94,24.86-11.67,36.83C1625.16,1337.61,1618.52,1358.58,1614.45,1381.33z"
      />
      <path
        d="M650.57,662.91c17.62,12.67,35.43,23.51,54.67,31.06c29.55,11.59,53.55-2.08,75.91-19.88
		c18.06-14.38,36.23-28.66,53.85-43.57c17.89-15.15,35.38-30.96,47.49-51.37c10.27-17.29,7.34-34.92-0.99-52.25
		c-17.77-36.97-46.29-64.31-79.01-87.67c-17.21-12.29-33.81-25.48-52.57-35.65c-5.43-2.94-11.76-4.16-19.42-9.06
		c35.5-5,67.24-11.91,97.06,12.62c24.6,20.23,50.87,38.19,70.8,63.64c19.03,24.3,32.31,51.44,39.66,81.12
		c8.49,34.3-7.6,60.37-30.48,84.1c-27.01,28.01-57.42,52.01-87.66,76.2c-16.16,12.92-33.32,24.82-49.09,38.03
		c-18.27,15.29-36.24,13.33-54.38,4.86c-63.47-29.64-115.59-73.35-156.01-130.66c-8.51-12.07-8.54-20.86-1.75-36.03
		C589.17,613.33,618.13,639.57,650.57,662.91z"
      />
      <path
        d="M887.53,680.26c6.04-5.24,10.06-3.54,14.78,1.23c48.79,49.34,103.14,92.02,159.47,132.31
		c17.82,12.74,35.2,26.09,53.03,38.82c15.93,11.37,13.22,14.89,5.87,31.31c-6.19,13.85-13.82,27.1-19.67,41.2
		c-4.4,10.61-8.62,11.4-21.11,3.43c-23.23-14.81-46.31-29.85-69.31-45.02c-26.82-17.69-53.39-35.76-80.24-53.41
		c-36.01-23.66-70.34-49.55-103.39-77.17c-3.51-2.94-7.22-5.64-12.1-9.44C839.03,722.54,862.98,701.74,887.53,680.26z"
      />
      <path
        d="M1752.86,1097.33c-3.06,23.69-15.91,40.8-31.48,56.02c-4.41,4.31-10.99,3.49-16.95,0.94c-17.78-7.58-35.51-5.68-53.7-0.66
		c-18.73,5.17-37.36,10.77-56.92,13.01c-28.9,3.31-47.61-22.66-40.88-49.86c5.92-23.93,19.56-41.6,44.87-50.33
		c22.5-7.76,45.67-3.33,68.08-8.17c14.87-3.21,29.86-5.87,43.67-12.59c5.74-2.79,10.23-0.27,15.58,1.71
		C1748.32,1055.99,1756.72,1072.48,1752.86,1097.33z"
      />
      <path
        d="M1704.44,1023.59c-0.88,10.72-5.69,15.5-15.59,17.68c-16.62,3.65-33.27,5.76-52.28,7.52c4-15,6.2-28.69,9.21-42.01
		c10.54-46.66,22.82-92.93,33.72-139.51c5.69-24.33,10.68-48.89,14.4-73.58c1.68-11.16,7.37-17.03,16.11-22.32
		c10.28-6.22,21.39-6.55,32.18-4.3c13.09,2.74,19.45,16.31,15.77,29.37c-13.68,48.5-23.14,97.98-33.99,147.13
		c-3.38,15.32-7.11,30.6-11.7,45.59C1708.87,1000.17,1707.23,1011.44,1704.44,1023.59z"
      />
      <path
        d="M1696.84,762.25c-14.5,1.1-24.61,11.74-40.66,13.63c46.65-83.47,92.43-165.38,139.14-248.95
		c5.13,89.59,3.49,177.84,1.82,266.93C1769.62,763.22,1739.53,744.18,1696.84,762.25z"
      />
      <path
        d="M1644.66,1168.29c15.42-0.47,29.14-5.9,44.02-3.38c23.1,3.9,39.61,25.08,35.26,48.27c-4.49,23.94-18.86,41.07-38.88,53.95
		c-3.85,2.48-8.37,2.79-12.22,0.77c-21.6-11.33-43.83-7.11-66.1-3.21c-16.12,2.82-31.74,3.67-47.17-4.51
		c-9.01-4.78-14.14-11-16.61-20.57c-7.83-30.26,15.51-67.5,49.43-63.88C1609.96,1177.6,1627.17,1175.37,1644.66,1168.29z"
      />
      <path
        d="M1670.08,316.05c-13.33,5.12-24.6,11.74-36.69,20.88c8.07,3.22,13.78,0.85,19.28-0.14c12.13-2.19,20.95,2.74,23.85,13.83
		c5.45,20.79,12.1,41.8,2.82,63.59c-2.19,5.14-4.31,9.93-7.82,14.34c-15.13,19.04-25.6,18.07-34.14-3.95
		c-9.86-25.42-22.86-49.1-41.38-68.99c-9.21-9.89-8.05-17.83-2.81-28.34c9.93-19.9,23.85-36.17,43.2-49.19
		c-17.26-2.66-35.12,14.15-60.89,55.75c-9.49-7.52-21.94-10.18-31.89-20.68c29.97-22.55,55.11-51.01,92.44-62.08
		c15.24-4.52,26.69-0.36,36.36,12.64c7.47,10.03,9.16,21.72,11.07,33.33C1685.07,306.62,1678.72,311.85,1670.08,316.05z"
      />
      <path
        d="M1114.21,1092.55c8.83,12.36,17.18,23.97,28.14,39.22c-27.81-7.05-52.15-13.38-79.22-17.53
		c15.68-9.67,30.92-15.94,43.47-26.56C1110.42,1084.45,1112.5,1088.35,1114.21,1092.55z"
      />
      <path
        d="M1163.32,325.13c-0.3-0.14-0.61-0.27-0.92-0.39c-1.47,1.55-3.2,2.96-4.84,3.57c-2.87,1.7-6.31,2.44-10.28,2.42
		c-0.65,0-1.15-0.22-1.53-0.55c-2.39,0.29-4.81,0.27-6.72-0.34c-1.09-0.35-1.67-1.25-1.69-2.21c-0.23,0.11-0.47,0.22-0.7,0.34
		c-8.25,4.2-15.73,10-24.46,13.07C1176.04,341.51,1171.72,329.02,1163.32,325.13z"
      />
      <path
        d="M1024.71,559.85c6.3,3.56,13.73,7.21,20.45,4.54c4.98-1.98,8.04-6.91,10.75-11.52c8.74-14.89,17.49-29.77,26.23-44.66
		c4.5,4.45,8.06,9.85,10.37,15.74"
      />
      <path
        d="M1052.97,551.72c3.46,3.77,6.93,7.55,10.39,11.32c0.15-9.62-0.46-19.26-1.83-28.78c-16.46,2.75-30.45,16.63-33.33,33.07
		C1027.44,571.62,1027.88,576.95,1052.97,551.72z"
      />
      <path
        d="M1057.67,534.26c2.15,4.4,5.54,8.18,9.69,10.79c-1.52,0.57-1.25-2.3-0.11-3.45c6.37-6.37,10.74-14.72,12.35-23.58
		c0.71-3.9-0.35-9.38-4.31-9.47c-2.83-0.07-4.67,2.86-5.91,5.4C1060.34,532.37,1051.31,550.79,1057.67,534.26z"
      />
    </>
  );
}
