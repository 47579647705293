export default function GelatinousCube(): JSX.Element {
  return (
    <>
      <path
        d="M178.48,2048c-0.85-0.83-1.06-1.82-1.63-2.47c-14.22-16.24-19.86-35.55-20.07-56.7
		c-0.27-26.93,6.54-52.46,16.07-77.35c10.48-27.38,21.89-54.41,31.84-81.97c15.27-42.3,22.21-86.31,25.16-131.09
		c4.44-67.35,10.01-134.62,13.83-202c3.86-68.05,5.83-136.2,3.44-204.39c-1.58-45.07-6.91-89.49-25.1-131.31
		c-14.44-33.21-12.41-66.6-2.58-100.23c14.03-48,16.7-96.9,13.08-146.51c-2.05-28.09-2.42-56.21,2.21-84.25
		c5.34-32.29,23.81-53.82,52.71-67.39c1.24-0.58,3.43-0.69,4.46,0.02c25.13,17.34,53.64,25.46,83.17,30.93
		c39.09,7.24,78.61,7.74,118.1,6.35c51.12-1.79,102.2-4.79,153.3-7.25c63.89-3.07,127.63-2.52,190.9,8.44
		c2.75,0.48,5.49,1.03,7.79,1.47c0.64,18.16,0.71,35.81,1.98,53.38c2.78,38.28,8.95,76,21.28,112.5
		c9.16,27.13,17.74,54.44,22.05,82.83c2.44,16.1,4.69,32.28,5.91,48.51c1.7,22.41,2.42,44.9,3.4,67.36
		c0.23,5.3-0.08,10.65-0.47,15.95c-0.09,1.28-1.24,3.16-2.36,3.59c-19.97,7.66-40.23,14.51-61.91,14.47
		c-7.2-0.01-14.74-1.04-21.51-3.37c-14.54-5-19.45-18.89-11.13-31.8c4.25-6.59,10.14-12.31,16.03-17.59
		c7.55-6.77,16.01-12.51,23.78-19.07c7.42-6.26,13.13-13.89,14.29-23.87c0.28-2.41-0.62-6.25-2.32-7.35
		c-4.47-2.89-9.6-6.22-14.63-6.5c-10.37-0.58-21.12-0.19-30.73,4.61c-31.43,15.66-56.43,38.2-72.54,69.79
		c-5.63,11.03-8.86,22.95-6.67,35.31c4.31,24.4,22.2,44.7,50.88,48.23c43.6,5.35,81.76-8.67,117.9-31.02
		c0.83-0.52,1.63-1.09,3.74-2.5c0,8.39,0.22,15.77-0.04,23.15c-0.71,19.95-1.68,39.89-2.37,59.85c-0.11,3.3-1.07,5.25-4.37,6.16
		c-12.5,3.43-24.93,7.11-37.46,10.42c-2.67,0.71-5.63,0.28-8.46,0.39c-15.46,0.59-25.59-17.86-18.38-30.12
		c2.7-4.58,5.18-9.35,8.39-13.54c2.26-2.95,1.95-4.82-0.38-7.23c-4.19-4.34-9.27-6.63-15.22-6.29c-36.25,2.1-51.19,32.95-47.89,57
		c1.69,12.31,7.87,22.25,17.25,30.11c19.42,16.25,42.17,19.89,66.33,15.9c9.95-1.65,19.57-5.25,29.33-8.01
		c1.57-0.44,3.06-1.14,5.46-2.05c-1.65,10.89-3.34,21.01-4.65,31.19c-0.58,4.5-2.34,7.1-6.69,9.19
		c-13.36,6.42-15.77,20.87-5.39,31.91c3.06,3.26,3.44,5.98,2.62,10.13c-7.68,39.2-15.68,78.35-22.41,117.72
		c-5.12,29.97-5.64,60.32-2.94,90.66c0.49,5.47,0.69,10.99,1.59,16.39c0.58,3.5-1.07,4.67-3.69,5.99
		c-27.39,13.75-54.77,27.52-82.08,41.41c-35.33,17.97-70.61,36.05-105.91,54.09c-0.72,0.37-1.36,0.9-2.06,1.37
		c7.06,15.75,14.05,31.35,21.04,46.93c59.6-30.82,122.08-54.38,183.65-80.64c16.21,65.61,27.07,131.51,26.72,198.51
		c-10.83,6.14-21.82,11.65-32.05,18.3c-42.58,27.65-89.29,44.76-138.69,55.06c-41.94,8.75-84.48,12.65-127.18,15.03
		c-35.26,1.97-70.56,3.2-105.8,5.51c-34.87,2.29-69.75,4.81-104.48,8.56c-31.3,3.38-62.43,8.42-92.66,18.92
		C254.99,2048,216.97,2048,178.48,2048z M553.69,1134.7c-1.55-14.56-0.18-28.91,2.83-43.2c3.81-18.07,8.02-36.1,10.77-54.34
		c2.18-14.49,0.31-28.97-6.18-42.45c-5.93-12.32-10.77-14.57-24.26-12.66c-5.65,0.8-9.46,3.29-11.98,8.4
		c-2.73,5.52-5.66,10.96-8.92,16.18c-4.29,6.86-10.61,9.85-18.76,8.53c-1.81-0.29-3.64-0.46-5.44-0.8
		c-5.62-1.06-10.25,0.67-13.75,5.1c-6.42,8.11-10.77,16.99-8.25,27.68c1.67,7.1,3.18,14.4,6.08,21.03
		c5.67,12.95,12,25.64,18.52,38.2c33.91,65.3,51.28,134.5,49.33,208.27c-0.36,13.65-1.39,27.29-1.85,40.94
		c-0.47,13.83-0.91,27.71,3.18,41.18c5.77,18.99,22.75,25.51,39.84,15.36c9.47-5.63,16.07-13.92,21.01-23.57
		c8.96-17.55,9.74-36.26,4.39-54.55c-6.54-22.33-14.72-44.21-22.93-66.01C571.16,1225.09,558.86,1181.28,553.69,1134.7z
		 M596.36,1767.24c18.58,38.49,35.73,77.56,48.38,118.97c8.44-4.56,16.61-8.95,24.76-13.37c12.09-6.56,12.25-6.63,7.41-19.64
		c-14.44-38.83-28.99-77.63-43.57-116.41c-1.1-2.93-2.68-5.72-4.33-8.39c-2.03-3.3-4.52-4.09-8.31-1.92
		c-9.39,5.37-18.92,10.51-28.62,15.3c-3.84,1.9-4.89,4.39-3.48,7.93C590.88,1755.41,593.54,1760.96,596.36,1767.24z M268.6,901.77
		c-1.78-22.96-3.55-45.91-5.33-68.9c-0.24,0.21-0.63,0.39-0.65,0.6c-1.25,15.57-3.17,31.12-3.55,46.71
		c-0.57,23.32,0.04,46.66-0.02,69.99c-0.08,32.56-4.63,64.42-14.94,95.41c-3.62,10.89-6.84,21.94-9.6,33.07
		c-3.23,13.04-4.81,26.28-0.52,39.47c7.27,22.3,31.55,30.03,50.44,16.1c0.13-0.1,0.27-0.19,0.41-0.29
		c15.17-11.47,23.41-42.26,16.07-60.11c-2.42,3.55-4.4,7.51-7.35,10.51c-5.04,5.1-8.05,11.06-10.83,17.59
		c-2.34,5.49-6.12,10.84-10.53,14.87c-8.32,7.61-18.36,4.28-20.91-6.66c-1.19-5.1-1.75-10.69-1-15.82
		c1.52-10.51,4.06-20.88,6.26-31.3c4.47-21.16,10.39-42.13,13.18-63.51C273.93,967.33,271.02,934.95,268.6,901.77z M540.4,1871.81
		c20.49-7.76,40.99-15.53,61.57-23.33c-5.07-14.18-10.42-29.12-15.84-44.28c-0.98,0.43-2.19,0.92-3.37,1.48
		c-31,14.62-61.71,29.91-94.23,41.05c-1.68,0.57-3.47,2.64-4.02,4.38c-3.4,10.79,4.12,28.61,14.35,33.57
		c2.34,1.14,5.92,1.26,8.38,0.35C518.17,1881.02,528.89,1876.46,540.4,1871.81z M217.78,1920.43c5.96-16.24,12.12-32.4,17.84-48.72
		c13.5-38.54,21.08-77.98,17.31-119.04c-0.22-2.39-0.82-4.75-1.24-7.13c-1.02,6.6-0.64,13.23-2,19.49
		c-5.53,25.49-10.15,51.31-17.84,76.16c-8.15,26.33-19.48,51.68-29.29,77.5c-7.13,18.77-12.62,37.96-12.16,58.27
		c0.38,17.04,3.91,33.13,16.71,45.68c15.53,15.21,38.34,9.08,46.73-12.31c-6.71,0.33-13.52,0.31-20.23,1.08
		c-8.58,0.99-14.35-2.82-18.84-9.56c-5.56-8.36-7.24-17.8-7.63-27.56C206.4,1955.77,211.47,1938.37,217.78,1920.43z M447.85,891.05
		c-11.13-5.13-15.27-15.12-17.91-26.24c-2.25,12.6,0.15,24.04,7.45,34.25c3.56,4.98,8.68,7.72,14.87,8.08
		c13.99,0.83,27.94-13.82,26.75-28.02C464.43,892.84,459.8,894.71,447.85,891.05z M335.45,1885.6c-8.68-1.79-15.08-7.28-21.56-14.33
		c1.99,9.58,7.53,19.46,13.23,24.27c4.23,3.57,8.92,5.33,14.25,2.73c5.59-2.72,8.55-7.29,9-12.52
		C345.43,1885.75,340.85,1885.75,335.45,1885.6z"
      />
      <path
        d="M1834.36,683.86c1.85,1.85,3.7,3.71,5.66,6.17c0.64,1.05,1.17,1.5,1.7,1.95c1.79,2.39,3.58,4.77,5.4,7.79
		c0.49,1.16,0.95,1.69,1.42,2.22c10.33,17.41,14.05,36.37,11.22,56.18c-4.86,34.1-1.93,67.42,7.68,100.33
		c6.07,20.77,12.66,41.4,17.89,62.38c5.05,20.3,6.15,40.87-2.12,60.88c-3.68,8.9-8.98,16.65-16.17,23.16
		c-1.53,1.39-2.81,3.74-3.04,5.78c-5.78,51.47-11.9,102.9-16.92,154.45c-6.72,68.92-7.34,138.02-4.69,207.21
		c0.96,25.1-0.81,50.3-1.01,75.45c-0.73,93.19,5.03,185.9,23.3,277.45c6.32,31.67,8.99,63.65,11.86,95.64
		c3.96,44.31,14.13,87.01,30.01,128.5c7.07,18.46,11.04,37.55,10.6,57.61c-0.46,20.82-11.3,30.36-28.19,34.78
		c-9.92,2.6-20.34,3.3-30.54,4.87c-0.98,0.15-1.95,0.43-3.11,0.99c-94.55,0.34-188.91,0.34-283.74,0.34
		c-2.35-7.37-4.28-14.72-6.1-22.1c-6.66-26.97-9.44-54.43-10.48-82.26c0.8-0.28,1.4-0.69,2-0.68c24.3,0.37,48.67-0.3,72.87,1.45
		c32.76,2.37,64.61,10.3,95.94,20.07c34.15,10.65,66.93,24.73,99.15,40.17c1.87,0.9,3.93,1.42,5.9,2.12
		c0.28-0.52,0.56-1.03,0.84-1.55c-2.35-1.84-4.47-4.15-7.09-5.46c-47.42-23.81-96.51-43.17-148.54-54.33
		c-36.46-7.82-73.37-10.9-110.65-9.28c-3.31,0.14-6.62,0.02-11.01,0.02c0-10.07-0.1-20.15,0.02-30.22
		c0.54-45.88,4.71-91.48,10.4-136.97c1.04-8.34,3.06-16.57,3.9-24.93c1.75-17.43,3.05-34.91,4.46-52.38
		c2.6-32.29,2.67-64.59-1.13-96.77c-4.78-40.5-9.82-80.98-15.36-121.38c-4.78-34.81-9.98-69.57-15.8-104.22
		c-5.49-32.67-11.96-65.18-18.11-97.74c-9.84-52.1-25.57-102.71-38.9-153.93c-4.73-18.18-10.23-36.34-12.76-54.87
		c-2.2-16.18-1.61-32.91-0.65-49.3c1.33-22.83,6.26-45.16,13.25-66.96c5.67-17.66,15.78-32.98,25.85-48.29
		c0.71-1.08,1.9-1.84,2.89-2.72c11.31-10.05,23.5-19.29,33.76-30.31c25.23-27.11,55.9-46.13,88.68-62.22
		c32.84-16.12,68.12-24.67,103.47-33.13c16.61-3.98,33.18-8.82,48.98-15.21c18.08-7.31,32.46-20.13,43.4-36.48
		c7.25-10.83,8.01-21.98,1.54-33.76C1833.22,683.95,1833.79,683.9,1834.36,683.86z M1756.25,1831.37c-1.04-0.68-2.08-1.35-3.12-2.03
		c-0.15,0.13-0.29,0.25-0.44,0.38c0.81,1.07,1.62,2.15,2.43,3.61c0,0,0.33,0.2,0.58,0.83c0.81,0.83,1.62,1.66,2.43,2.48
		c10.09,10.12,21.83,8.87,28.63-3.77c3.09-5.74,5.02-12.35,6.23-18.8c3.28-17.42,0.04-34.39-4.45-51.26
		c-4.78-17.97-9.09-36.07-13.53-54.13c-1.39-5.64-2.51-11.34-3.75-17.02c-2.56,9.42-6.48,18.51-4.4,28.4
		c2.74,13,5.34,26.05,8.44,38.96c4.59,19.12,7.61,38.21,2.32,57.7c-0.37,1.38-0.06,2.94-0.4,4.79c0.01,0.81,0.02,1.62-0.37,3.04
		c-1.24,2.31-2.49,4.61-4.27,6.99c-0.51,0.48-1.01,0.97-2.27,1.49c-0.4,0.29-0.8,0.59-1.78,0.7c-0.75,0.26-1.49,0.52-3.01,0.52
		c-0.53,0.08-1.05,0.16-2.05-0.14c-0.79-0.05-1.58-0.1-3.01-0.68c-1.29-0.47-2.58-0.94-3.96-1.42
		C1756.51,1832,1756.53,1831.92,1756.25,1831.37z M1614.28,1381.71c19.26-11.51,34.56-27.17,46.58-45.89
		c33.28-51.83,44.27-108.6,35.72-169.21c-4.28-30.33-14.68-58.44-34.97-82.07c-27.15-31.61-65.08-39.8-101.93-22.09
		c-24.66,11.86-42.43,31.13-57.36,53.52c-3.21,4.82-4.22,9.24-2.78,14.87c9.91,38.7,19.17,77.56,29.45,116.15
		c12.62,47.36,23.75,94.98,29.09,143.79c0.39,3.53,2.27,3.81,4.97,3.99C1581.27,1395.93,1597.98,1391.09,1614.28,1381.71z
		 M1539.72,977.01c-17.18,8.09-32.4,19.03-46.45,31.74c-3.63,3.28-5.64,6.8-5.35,11.67c1.28,21.6,2.41,43.2,3.85,64.79
		c0.38,5.67,1.63,11.28,2.68,18.23c2.49-3.39,3.92-5.83,5.81-7.83c10.15-10.75,19.45-22.58,30.82-31.86
		c48.95-39.95,108.95-32.65,147.67,17.25c14.95,19.26,24.52,41.16,30.27,64.7c11.6,47.54,8.88,94.58-6.21,140.92
		c-11.38,34.93-29.04,66.19-56.7,90.97c-20.63,18.49-44.14,30.91-72.59,31.74c-4.28,0.12-8.57,0.02-13.49,0.02
		c4.05,23.93,8.06,47.61,12.11,71.52c1.8-0.17,3.27-0.26,4.73-0.45c19.29-2.51,37.15-9.12,53.82-19.01
		c34.91-20.7,61.05-50.02,80.48-85.08c46.14-83.28,58.44-171.63,36.39-264.31c-9.11-38.3-25.87-73.1-52.91-102.31
		C1652.43,964.1,1594.32,951.73,1539.72,977.01z M1843.9,1758.42c-1.72,34.93-3.47,69.85-5.13,104.78
		c-0.98,20.46,0.7,40.64,7.46,60.11c5.13,14.78,11.44,29.19,15.85,44.17c2.92,9.92,4.49,20.54,4.71,30.88
		c0.21,9.77-3.74,18.59-13.84,22.93c-4.42,1.9-8.78,3.95-13.22,5.84c-4.63,1.97-6.11,6.02-7.77,11.23c6.13,0,11.61-0.08,17.08,0.02
		c18.1,0.33,29.28-10.44,34.17-27.13c3.42-11.7,2.86-23.71-0.69-35.04c-4.81-15.36-11.33-30.17-16.77-45.34
		c-4.53-12.65-9.77-25.23-12.55-38.3c-5.22-24.5-2.19-49.46-1.51-74.24c0.84-30.65,1.87-61.29-2.6-91.78
		c-0.75-5.12-2-10.16-3.02-15.24c-0.72,0.06-1.44,0.11-2.15,0.17C1843.9,1726.81,1843.9,1742.12,1843.9,1758.42z M1855.99,930.56
		c-1.8-12.18-3-24.47-5.51-36.5c-6.48-31.05-11.24-62.25-11.38-94.04c-0.05-11.9-0.01-23.81-0.01-35.72
		c-0.89,0.41-1.06,0.96-1.26,1.51c-10.96,29.89-13.01,60.73-9.07,92.03c2.43,19.31,6.11,38.47,8.62,57.77
		c1.22,9.37,1.24,18.91,1.43,28.38c0.07,3.82-1.62,7.21-5.65,8.6c-4.07,1.4-7.88,0.23-10.47-2.8c-2.41-2.82-4.38-6.28-5.61-9.79
		c-1.58-4.48-2.22-9.29-3.27-13.96c-1.8,11.91-1.57,23.64,5.14,34.04c8.82,13.67,24.85,12.62,32.34-1.8
		C1855.65,949.86,1856.09,940.73,1855.99,930.56z"
      />
      <path
        d="M311.48,2048c-0.06-0.68,0.27-1.87,0.78-1.96c19.44-3.39,38.83-7.12,58.37-9.85c18.59-2.6,37.29-4.43,56-6.06
		c18.25-1.59,36.54-2.64,54.83-3.66c39.23-2.19,78.51-3.71,117.71-6.47c30.01-2.11,60.06-4.64,89.83-8.82
		c66.42-9.33,129.25-28.98,184.62-68.48c6.63-4.73,14.32-7.06,22.2-9.63c12.28-4.01,23.13,0.2,34.38,1.59
		c21.58,2.67,42.96,6.99,64.5,10.03c28.8,4.08,57.66,7.85,86.55,11.26c18.5,2.18,37.09,4.02,55.69,5.02
		c41.23,2.24,82.49,4.47,123.76,5.56c46.51,1.22,92.91-1.62,139.13-7.13c35.01-4.17,70.03-8.22,105.05-12.28
		c4.12-0.48,8.27-0.73,13.17-1.16c-2.79,34.38-1.95,68.31,8.32,101.68C1121.38,2048,716.67,2048,311.48,2048z"
      />
      <path
        d="M1049.4,1639.3c3.14-5.71,6.56-10.91,8.91-16.56c3.2-7.69,0.7-12.25-7.39-13.88
		c-19.42-3.91-38.87-7.62-58.37-11.1c-2.52-0.45-5.65,0.37-8.03,1.54c-38.27,18.79-76.46,37.74-114.68,56.65
		c-1.62,0.8-3.27,1.55-5.82,2.76c-0.57-5.72-1.17-10.88-1.57-16.05c-3.41-43.68,0.89-86.85,9.04-129.71
		c4.97-26.16,10.37-52.24,15.6-78.35c0.16-0.79,0.53-1.53,0.92-2.65c20.18,8.16,38.44,2.59,55.53-8.13
		c17.83-11.19,30.25-26.89,35.95-47.47c3.35-12.12,1.12-23.65-3.94-36.47c-18.82,29.63-45.86,43.71-79.76,46.9
		c0.81-5.92,1.61-11.94,2.46-17.95c0.42-2.96,1.52-5.96,1.3-8.86c-0.7-9.36,4.34-13.9,12.06-18.11
		c26.48-14.44,40.63-37.57,44.94-67.08c0.18-1.25,0.18-2.52,0.2-2.91c-16.47,9.68-33.14,19.48-49.82,29.28
		c-0.47-0.4-0.93-0.79-1.4-1.19c0-3.19-0.1-6.39,0.02-9.58c0.93-24.62,1.92-49.24-0.67-73.84c-0.22-2.07,0.84-4.89,2.3-6.42
		c9.09-9.55,18.36-18.93,24.12-31.04c1.47-3.08,2.58-6.33,4.21-10.39c-11.69,5.73-22.31,10.93-34.11,16.71
		c0.29-5.03,0.72-8.7,0.68-12.37c-0.28-23.78-0.16-47.58-1.2-71.33c-0.71-16.43-2.85-32.8-4.73-49.16
		c-3.19-27.75-10.61-54.52-19.92-80.75c-11.69-32.97-18.99-66.96-22.55-101.61c-2.12-20.64-1.64-41.54-2.28-62.32
		c-0.04-1.26,0.42-2.54,0.78-4.5c10.97,2.45,21.67,4.7,32.3,7.25c45.92,10.99,90.26,26.97,134.68,42.71
		c46.4,16.44,93.75,29.4,142.56,36.17c27.87,3.87,55.95,6.25,83.93,9.32c13.91,1.52,27.8,3.25,41.73,4.58
		c18.8,1.8,36.05,9.31,54.03,14.14c10.29,2.77,20.68,5.22,30.89,8.24c4.03,1.19,8.1,2.98,11.49,5.41
		c25.36,18.13,41.88,43.28,56.16,70.4c12.77,24.25,19.48,50.12,23.45,76.98c3.68,24.95,10.03,49.22,18.99,72.85
		c13.27,35,20.43,71.68,28.35,108.07c7.47,34.32,13.71,68.93,19.62,103.55c6.79,39.72,12.79,79.58,18.81,119.43
		c3.75,24.87,7.06,49.8,10.27,74.75c2.55,19.81,6.14,39.64,6.54,59.52c0.46,23.06-1.25,46.26-3.44,69.26
		c-4.8,50.5-17.08,99.72-26.65,149.41c-6.33,32.85-11.28,65.97-16.55,99.02c-0.64,4-1.92,4.95-5.77,5.36
		c-25.99,2.74-51.93,5.93-77.9,8.75c-24.64,2.67-49.29,5.32-73.98,7.51c-48.54,4.29-97.19,5.07-145.83,2.74
		c-38.92-1.86-77.87-4.08-116.67-7.61c-36.75-3.34-73.35-8.42-109.98-13.08c-21.08-2.68-42.06-6.18-63.12-9
		c-9.55-1.28-19.2-1.83-29.67-2.79c-0.86-33.56,1.26-67.43-4.33-100.86c-5.56-33.28-11.47-66.51-17.33-100.35
		c9.37-3.72,18.98-7.87,28.82-11.37c39.51-14.04,79.12-27.81,118.61-41.93c3.33-1.19,6.69-3.68,8.85-6.49
		C1037.4,1656.98,1043.19,1648.21,1049.4,1639.3z M1191.48,1552.86c-25.43,19.35-39.19,44.63-39.7,76.95
		c-0.32,20.31,3.25,39.8,11.04,58.46c5.19,12.43,6.45,24.96,3.73,38.13c-1.07,5.18-1.49,10.59-1.4,15.88
		c0.13,8.55,3.45,16.4,9.93,21.67c15.38,12.53,33.05,19.23,53.26,16.88c3.2-0.37,5.02,0.28,6.57,2.87
		c3.4,5.72,6.63,11.58,10.42,17.04c3.3,4.74,7.02,9.26,11.12,13.31c1.26,1.25,4.94,1.64,6.53,0.73
		c23.64-13.61,49.2-22.84,74.46-32.73c3.1-1.22,4.25-2.85,4.08-5.81c-0.26-4.32-0.11-8.75-1.07-12.92
		c-2.4-10.47,0.67-19.38,6.91-27.53c3.03-3.96,6.43-7.65,9.54-11.55c4.36-5.46,8.85-10.84,12.83-16.57
		c4.19-6.03,6.75-13.14,5.69-20.32c-1.91-12.93-3.84-26.11-13.27-36.41c-3.38-3.68-6.58-7.54-10.09-11.08
		c-2.66-2.68-3.53-5.57-3.5-9.37c0.17-27.48-8.54-52.08-25.72-73.37c-14.36-17.78-32.92-27.17-56.4-24.86
		c-3.31,0.32-6.66,0.31-9.97,0.64C1233.67,1535.16,1211.56,1539.49,1191.48,1552.86z M1499.37,1377.19
		c-5.76-7.52-12.25-14.6-17.17-22.64c-26.68-43.66-31.41-91.75-26.39-141.23c1.67-16.44,6.81-32.52,10.12-48.81
		c0.5-2.43,0.19-5.3-0.6-7.68c-7.18-21.55-12.74-43.46-15.44-66.04c-0.86-7.2-1.58-14.41-2.46-22.49c-1.12,1.54-1.85,2.34-2.35,3.26
		c-43.43,78.75-57.38,162.25-39.53,250.57c8.14,40.26,23.99,77.23,51.04,108.71c18.29,21.3,40.45,37.05,67.5,45.43
		c2.14,0.66,4.32,1.18,6.58,1.8c0.21-1.13,0.41-1.63,0.36-2.09c-2.66-25.68-5.28-51.36-8.15-77.02c-0.2-1.77-2.01-3.64-3.51-4.94
		C1512.97,1388.47,1506.39,1383.12,1499.37,1377.19z M1386.54,1839.89c-4.56-36.22-13.24-71.53-22.92-106.66
		c-4.01,5.78-6.54,11.19-5.88,18.31c1.2,12.89,1.03,25.91,1.49,38.87c0.33,9.22-4,16.31-11.19,21.16
		c-7.53,5.09-15.55,9.7-23.88,13.3c-9.29,4.01-19.22,6.48-28.77,9.92c-8.32,3-11.59,7.83-10.36,16.51
		c2.1,14.82,6.82,28.87,15.08,41.46c5.53,8.44,13.47,11.11,23.08,8.19c3.8-1.16,7.7-2.49,11.05-4.57
		c13.43-8.32,26.94-16.58,39.88-25.62C1384.29,1863.67,1390.54,1854.15,1386.54,1839.89z M1037.33,1079.08
		c11.78,5.11,10.23,15.48,4.39,22.45c-1.9,2.27-4.14,4.41-6.6,6.04c-5.12,3.4-10.54,6.33-15.72,9.65c-1.53,0.98-2.63,2.64-3.92,3.99
		c1.54,1.01,2.95,2.39,4.64,2.94c2.83,0.92,5.8,1.56,8.76,1.89c24.11,2.67,50.26-16.32,55.46-40.29c0.41-1.88,0.32-4.01-0.1-5.9
		c-5-22.76-20.79-35.52-41.18-43.73c-3.13-1.26-6.88-1.5-10.32-1.42c-9.15,0.2-18.32,0.46-27.42,1.39
		c-10.59,1.08-20.43,4.72-29.12,11.01c-8.11,5.86-14.1,13.26-15.28,23.59c-1.31,11.46,9.29,19.48,20.25,15.57
		c9.4-3.35,18.81-6.69,28.32-9.71C1018.76,1073.6,1027.89,1074.39,1037.33,1079.08z M1075.08,1152.44c-1.86,1.66-3.9,3.16-5.55,5
		c-4.89,5.49-6.72,11.5-3.95,18.86c2.92,7.78,8.33,11.18,16.32,11.48c8.99,0.34,16.34,2.76,21.41,11.6
		c3.3,5.75,4.35,10.39,0.61,15.51c-4.3,5.89-8.84,11.67-13.81,17.01c-4.79,5.14-10.83,8.25-18.25,7.7c-0.94-0.07-1.94,0.6-2.91,0.93
		c-0.11,0.6-0.23,1.2-0.34,1.81c4.39,1.52,8.67,3.74,13.18,4.45c23.91,3.76,50.45-12.14,60.99-36.18
		c7.59-17.32,3.48-36.18-10.69-48.68c-2.5-2.2-5.17-4.2-7.81-6.24C1111.07,1145.49,1090.78,1141.99,1075.08,1152.44z
		 M1465.86,1237.48c-0.35,21.77,1.63,43.29,7.41,64.32c7.5,27.27,19.46,52.06,40.84,71.43c1.4,1.26,2.93,2.38,4.83,3.91
		c0.27-22.36-36.28-180.56-45.04-195.14C1469.18,1200.13,1466.9,1218.21,1465.86,1237.48z M1011.54,1160
		c-11.95-24.31-30.1-42.58-52.08-55.15c-7.12,6.61-13.31,12.36-19.73,18.32c1.23,0.75,2.65,1.56,4.01,2.45
		c4.98,3.27,10.29,6.15,14.87,9.91c33.87,27.78,49.61,65.93,60.28,106.8c1.96,7.51,2.34,15.42,3.45,23.15
		c3.96-21.35,4.83-42.79,3.45-64.31c-0.2-3.12-0.84-6.33-1.92-9.26C1020.03,1181.44,1015.9,1171.07,1011.54,1160z M1069.82,1312.38
		c-5.85,0-11.7,0-18.19,0c7.58,12.13,18.5,17.45,31.73,18.62c13.79,1.22,25.93-2.95,37.69-10.29c8.44-5.26,13.88-11.52,15.37-21.27
		c2.09-13.71-11.99-27.17-25.61-24.45c-5.3,1.06-8.25,4.94-7.52,9.88c1.7,11.43-2.28,18.94-13.25,22.89
		C1083.85,1309.99,1077.17,1310.83,1069.82,1312.38z M1342.52,1074.03c3.65,7.14,8.62,13.04,15.91,16.69
		c8.53,4.27,17.44,3.39,24.2-2.73c8.01-7.24,10.18-16.81,10.09-27.13c0-0.46-0.24-0.91-0.26-0.94c-3.62,4.9-6.41,9.71-10.18,13.56
		c-6.99,7.12-16.57,6.21-22.32-1.98c-2.24-3.19-4.18-7.03-4.82-10.82c-1.82-10.79-3.06-21.69-4.22-32.59
		c-1.69-16.02-2.91-32.09-4.78-48.08c-0.49-4.16-2.67-8.12-4.08-12.17c-0.4,0.11-0.8,0.22-1.21,0.34c0,5.61,0.35,11.25-0.07,16.83
		c-1.24,16.76-2.89,33.48-4.17,50.24C1335.63,1048.33,1336.65,1061.19,1342.52,1074.03z"
      />
      <path
        d="M872.89,575.03c-21.58-0.7-42.73-2.11-63.84-1.7c-10.63,0.21-21.23,3.57-31.78,5.8
		c-6.66,1.41-13.33,2.99-19.76,5.18c-8.51,2.91-16.78,6.5-25.16,9.79c0.2,0.59,0.39,1.18,0.59,1.77c5.57-0.83,11.16-1.58,16.71-2.51
		c22.74-3.81,45.54-6.41,68.62-3.49c0.33,0.04,0.66,0.09,0.99,0.14c28.08,4.47,39.72,21.03,32.74,48.57
		c-4.45,17.53-3.21,34.82-2.19,52.48c0.99,17.24,0.22,34.62-0.39,51.91c-0.47,13.3-1.89,26.56-2.9,39.84
		c-0.07,0.97-0.25,1.93-0.44,3.41c-7.21-1.16-14.21-2.31-21.22-3.4c-49.58-7.66-99.51-9.79-149.55-7.97
		c-52.62,1.92-105.21,4.79-157.81,7.2c-9.16,0.42-18.33,0.36-27.48,0.9c-12.94,0.76-25.87,1.81-38.79,2.84
		c-24.3,1.94-47.84-2.09-70.95-9.16c-14.82-4.54-29.42-9.73-42.48-18.34c-3.62-2.38-9.2-4.54-7.68-9.73
		c0.67-2.28,6.01-3.68,9.47-4.59c36.88-9.67,73.91-18.8,110.7-28.78c60.8-16.5,116.44-44.47,170.34-76.6
		c29.01-17.29,59.05-32.94,89.24-48.11c48.33-24.28,100.38-28.65,153.41-26.05c55.17,2.7,107.53,18.05,159.25,36.03
		c7.53,2.62,14.56,6.73,22.16,9.04c12.85,3.89,25.88,7.28,39,10.15c12.28,2.69,12.31,2.28,13.81,14.65
		c2.51,20.77-2.29,40.7-6.47,61.09c-13.76-4.01-27.23-7.85-40.59,1.01c6.8,0.82,13.71,1.15,20.37,2.63
		c5.44,1.21,10.65,3.62,15.77,5.92c0.81,0.36,1.17,3.09,0.81,4.49c-2.52,10-6.09,19.81-7.73,29.94c-1.55,9.57-2.11,19.54-1.32,29.18
		c0.82,10.01,6.92,15.68,14.63,16.61c8.27,1,18.63-4.7,22.42-12.15c7.72-15.18,11.56-31.51,14.6-48.1c0.21-1.15,0.46-2.29,0.7-3.43
		c1.54-7.07,1.53-6.74,8.63-6.56c11.29,0.28,22.59,0.08,34.28,0.08c-0.18,13.68-2.42,27.56-6.02,41.23
		c-1.65,6.26-3.77,12.4-5.67,18.59c-0.54,1.75-1.46,3.51-1.45,5.26c0.02,2.49-0.01,6.41,1.34,7.11c1.97,1.02,5.71,0.59,7.7-0.73
		c12.86-8.51,20.96-21.03,26.16-35.08c4.37-11.8,7.25-24.19,10.28-36.44c0.87-3.51,1.93-4.84,5.62-5.21
		c8.45-0.86,16.83-2.39,26.25-3.8c-8.16-7.99-18.59-7.87-28.31-9.09c0.76-20.91,1.52-41.64,2.29-62.75c0.64-0.04,1.91-0.2,3.18-0.19
		c62.66,0.75,125.33,0.83,187.96,2.52c36.95,1,73.88,3.86,110.71,7.1c45,3.96,89.93,8.5,135.2,7.07c5.66-0.18,11.32-0.56,16.98-0.75
		c36.91-1.23,73.02,4.49,108.66,13.36c7.77,1.94,15.47,5.52,22.25,9.84c18.88,12.05,21.35,36.6,5.73,52.6
		c-14.03,14.37-31.64,22.69-50.58,28.04c-27.55,7.79-55.39,14.58-82.97,22.26c-41.19,11.47-80.38,27.72-115.4,52.53
		c-11.03,7.81-20.21,18.25-30.1,27.64c-6.64,6.31-12.87,13.05-19.58,19.28c-16.18,15.03-35.49,23.16-57.32,25.85
		c-25.63,3.15-51.33,3.75-76.7-0.88c-13.48-2.46-26.51-7.66-39.58-12.08c-31.89-10.77-63.7-21.76-96.95-27.75
		c-28.02-5.05-56.29-6.32-84.67-5.07c-32.17,1.42-63.96,0.14-94.78-10.64c-10.03-3.51-20.17-7.2-30.56-9.12
		c-34.74-6.41-67.3-19.78-100.79-30.19c-21.28-6.61-43.16-11.25-64.77-16.77c-1.44-0.37-2.9-0.67-4.97-1.15
		c0.55-6.31,1.01-12.56,1.64-18.78c3.67-36.49,4.96-73.07,5.51-109.74c0.2-13.23,5.01-26.21,15.23-36.24
		c23.19-22.76,50.94-31.85,83.03-28.5c2.47,0.26,4.93,0.55,7.49,0.36C935.52,585.11,904.74,578.66,872.89,575.03z M969.51,595.51
		c-0.61-0.08-1.23-0.17-1.84-0.25c-0.01,0.11-0.02,0.22-0.03,0.34C968.1,595.64,968.56,595.69,969.51,595.51z M731.12,596.39
		c0.23,0.08,0.46,0.15,0.69,0.23C731.73,596.4,731.65,596.17,731.12,596.39z"
      />
      <path
        d="M1118.41,511.62c-4.71-1.39-8.98-2.8-13.32-3.95c-11.02-2.93-17.56-10.56-21.22-20.78
		c-4.43-12.36-8.44-24.88-12.44-37.39c-2.09-6.55-1.69-13.18,0.65-19.63c2.88-7.93,7.96-13.08,16.79-14.41
		c31.75-4.79,61.52,2.14,90.41,14.69c1.52,0.66,2.9,1.64,4.3,2.53c11.74,7.49,15.13,15.76,9.83,28.77
		c-2.81,6.91-7.78,13.04-12.32,19.12c-5.37,7.19-11.36,13.9-16.98,20.91c-1.13,1.41-2.33,3.13-2.54,4.82
		c-0.88,7.09-6.15,8.43-11.54,8.18C1139.62,514,1129.22,512.68,1118.41,511.62z"
      />
      <path
        d="M1136,573.9c-1.21,10.17-1.6,20.05-3.81,29.51c-4.65,19.94-5.92,40.13-6.23,60.44
		c-0.17,11.32,0.04,22.64,0.05,33.96c0,1.29-0.2,2.58-0.34,4.38c-9.35-1.4-18.37-2.75-27.72-4.15c2.23-11.22,4.83-21.88,6.36-32.68
		c1.31-9.19,1.38-18.55,2.05-27.83c0.16-2.31,0.34-4.64,0.75-6.91c3.14-17.46-1.34-34.09-5.67-50.67
		c-3.75-14.36-7.16-28.73-7.2-43.68c-0.02-6.25,1.69-11.8,7.7-14.49c6.19-2.77,12.16-0.61,16.46,3.95
		c8.59,9.08,14.6,19.79,16.59,32.31C1135.79,563.08,1135.69,568.28,1136,573.9z"
      />
      <path
        d="M1161.23,560.88c-0.85-8.16-1.65-15.88-2.47-23.8c5.94-0.24,10.74,1.02,13.81,5.02
		c4.31,5.61,8.86,11.39,11.58,17.82c11.53,27.27,17.39,55.89,17.46,85.5c0.04,17.07-1.04,34.15-1.62,51.45
		c-6.5,0.45-11.88,0.82-17.71,1.22c-0.51-9.13-0.87-18.22-1.54-27.28c-2.02-27.14-8.26-53.56-13.86-80.09
		C1164.82,580.97,1163.14,571.13,1161.23,560.88z"
      />
      <path
        d="M1134.38,678.95c0.4-17.11,0.82-33.72,1.25-50.99c3.02,0.21,5.26,0.32,7.48,0.54
		c4.14,0.4,8.28,0.87,12.42,1.31c11.03,1.18,11.53,1.11,12.87,11.88c2.24,17.98,3.78,36.04,5.59,54.07
		c0.11,1.12,0.01,2.26,0.01,4.31c-13.15,0.86-26.01,1.7-39.62,2.59C1134.4,694.35,1134.4,686.9,1134.38,678.95z"
      />
      <path
        d="M1131.16,376.97c12.11-27.88,21.45-56.61,36.85-82.56c0.56,0.05,1.11,0.1,1.67,0.15
		c1.13,6.63,2.65,13.23,3.32,19.91c1.51,15.03-1.73,29.56-6.14,43.74c-4.3,13.81-9.18,27.47-14.4,40.97
		c-3.23,8.34-4.3,8.42-13.16,6.97c-3.12-0.51-6.26-1.11-9.41-1.19c-4.29-0.11-5.97-2.59-5.11-6.15
		C1126.53,391.58,1128.91,384.49,1131.16,376.97z"
      />
      <path
        d="M1113.39,347.45c-1.63,15.02-3.19,29.59-4.83,44.15c-0.22,1.97-0.89,3.89-1.27,5.84
		c-0.91,4.7-2.93,7.75-8.49,7.85c-3.38,0.06-7.09,0.94-10.02,2.59c-4.18,2.36-4.54,0.16-4.77-2.91c-1.07-14.69,0-29.23,2.97-43.66
		c3.52-17.11,6.52-34.32,10.35-51.35c1.65-7.34,4.83-14.34,7.54-22.14c5.93,3.81,8.4,8.6,9,13.9c0.91,8.07,1.25,16.25,1.18,24.37
		C1115,333.06,1114.02,340.02,1113.39,347.45z"
      />
      <path
        d="M1187.76,419.77c-1.85,2.1-3.38,2.83-5.8,1.18c-3.69-2.53-7.65-4.66-12.13-7.33
		c3.09-9.04,5.88-18.4,9.46-27.44c9.47-23.97,19.25-47.82,29.02-71.68c0.53-1.3,2.02-2.2,3.07-3.29c0.87,1.44,2.23,2.78,2.54,4.32
		c3.75,18.86,0.61,37.07-5.58,54.93c-4.14,11.94-8.13,23.94-12.7,35.71C1193.8,410.89,1190.6,415.08,1187.76,419.77z"
      />
      <path
        d="M1038.67,424.39c-4.17-14.95-9.27-29.36-11.8-44.2c-1.63-9.57,0.3-19.8,1.12-29.69
		c0.21-2.59,2.2-5.04,3.8-8.49c2.03,2.2,3.69,3.55,4.75,5.28c1.39,2.25,2.38,4.76,3.4,7.21c7.24,17.33,14.37,34.71,21.74,51.98
		c1.49,3.49,1.43,5.91-0.53,9.52c-3.21,5.91-4.91,12.64-7.13,19.07c-0.74,2.14-1.12,4.41-1.77,7.03
		C1042.44,439.91,1041.86,431.37,1038.67,424.39z"
      />
      <path
        d="M1232.12,367.81c1.71-1.54,3.23-2.75,4.76-3.96c0.7,1.79,2.22,3.7,1.96,5.34c-1.19,7.48-2.21,15.1-4.52,22.27
		c-6.01,18.67-15.28,35.62-29.68,51.61c-0.49-3.45-1.33-5.7-1.03-7.8c2.73-18.76,11.35-35.31,19.87-51.86
		C1226.15,378.24,1229.11,373.23,1232.12,367.81z"
      />
      <path
        d="M1834.26,683.53c-0.47,0.38-1.04,0.42-2.04,0.51c-1.9-1.84-3.36-3.71-4.97-5.92c-0.14-0.33-0.2-0.41-0.2-0.41
		C1829.42,679.54,1831.79,681.37,1834.26,683.53z"
      />
      <path d="M1841.69,691.7c-0.5-0.18-1.03-0.63-1.58-1.36C1840.61,690.52,1841.14,690.98,1841.69,691.7z" />
      <path d="M1848.54,701.7c-0.47-0.26-0.93-0.79-1.38-1.61C1847.63,700.35,1848.09,700.89,1848.54,701.7z" />
      <path
        d="M1827.16,677.8c0.05,0.01-0.17,0.03-0.17,0.03s0.2,0.07,0.13-0.02
		C1827.05,677.71,1827.11,677.79,1827.16,677.8z"
      />
      <path
        d="M1227.8,1667.35c6,10.72,9.23,21.73,8.14,33.78c-1.75,19.37-16.76,27.34-33.88,17.99
		c-16.81-9.18-23.84-27.38-17.64-46.19c1.29-3.92,3.05-7.77,5.16-11.31c6.01-10.12,16.83-12.84,26.55-6.16
		C1220.54,1658.47,1223.82,1663.12,1227.8,1667.35z"
      />
      <path
        d="M1282.77,1646.69c1.94-3.53,3.43-6.92,5.52-9.89c6.31-8.97,17.2-9.84,24.02-1.23
		c3.68,4.66,6.53,10.57,7.9,16.35c4.36,18.42,0.42,34.55-15.06,46.9c-10.7,8.54-23.29,5.4-28.21-7.45
		c-4.08-10.67-2.17-21.33,0.8-31.83C1278.96,1655.26,1280.96,1651.19,1282.77,1646.69z"
      />
      <path
        d="M1259.42,1718.33c2.5-7.39,5.19-9.06,11.82-6.09c9.97,4.48,18.74,10.76,25.04,19.97
		c0.37,0.55,0.69,1.14,1.01,1.72c3.42,6.4,3.13,12.67-0.74,16.17c-3.83,3.46-9.4,3.08-15.61-1.14c-1.76-1.2-3.42-2.54-5.57-4.14
		c-0.79,4.06-1.32,7.75-2.25,11.34c-1.14,4.42-3.39,8.2-8.46,8.66c-5.27,0.48-8.33-2.87-10.24-7.24
		c-2.67-6.09-3.71-12.33-1.42-18.91C1255.29,1732.08,1257.2,1725.36,1259.42,1718.33z"
      />
    </>
  );
}
