export default function RedDragon(): JSX.Element {
  return (
    <>
      <path
        d="M994.53,2048c-11.7-16.62-21.94-34.05-34.45-49.64c-12.13-15.11-26.6-28.35-40.05-42.4
		c-1.03-1.08-2.16-2.08-3.32-3.04c-8.6-7.15-10.43-15.49-6.49-26.2c9.7-26.42,19.38-52.88,27.72-79.75
		c5.73-18.45,9.38-37.56,13.57-56.45c0.96-4.3,1.02-9.08,0.21-13.4c-0.49-2.63-2.96-5.71-5.39-7c-9.51-5.05-19.34-9.51-29.09-14.1
		c-22.2-10.45-42.96-23.18-61.76-38.98c-4.06-3.41-7.67-7.38-11.12-10.74c17.38-22.18,34.48-44,50.89-64.94
		c8.1-0.51,16.16-0.66,24.13-1.59c26.03-3.03,52.07-5.01,78.28-2.98c21.04,1.62,41.33,6.9,61.52,12.62c1.33,0.38,2.8,1.88,3.34,3.22
		c7.95,19.48,15.86,38.98,23.55,58.57c7.29,18.56,4.31,36.87-3.55,54.22c-14.12,31.2-29.23,61.95-43.79,92.96
		c-7.33,15.6-14.35,31.35-21.54,47.01c-3.7,8.07-3.03,15.31,3.26,22.02c25.97,27.71,55.19,50.42,92.58,60.65
		c4.47,1.22,9.12,1.8,13.58,3.07c1.62,0.46,3.77,1.82,4.21,3.23c5.54,17.59,10.81,35.26,16.21,53.28
		C1096.38,2048,1045.69,2048,994.53,2048z"
      />
      <path
        d="M1394.56,2048c-1.85-10.5-3.24-21.01-4.62-31.51c-0.19-1.45-0.73-3.02-0.38-4.34
		c5.51-20.84,12.54-41.04,25.51-58.62c11.1-15.04,25.98-15.19,37.53-0.42c8.82,11.28,13.64,24.57,18.38,37.82
		c6.52,18.23,10.48,37.09,12.99,56.68C1454.41,2048,1424.72,2048,1394.56,2048z"
      />
      <path
        d="M1159.54,2048c-1.57-4.74-2.12-9.7-3.88-14.18c-5.35-13.57-11.17-26.95-16.92-40.67
		c4.67,0,9.69,0.07,14.7-0.01c19.15-0.3,37.4-4.53,55.03-12.01c15.83-6.72,22.87-4.39,32.89,9.89c10.33,14.7,15.25,31.48,18.8,48.79
		c0.5,2.43,0.79,4.91,1.05,7.78C1227.39,2048,1193.7,2048,1159.54,2048z"
      />
      <path
        d="M1784.59,2048c-0.89-7.44-1.4-14.88-1.72-22.33c-0.33-7.47-0.93-14.97-0.59-22.42
		c0.55-12.13,6.85-21.91,15.18-30c7.77-7.55,22.57-6.92,33.54,0.55c13.23,9.01,22.16,21.54,27.13,36.55
		c3.99,12.07,6.78,24.54,10.06,37.24C1840.44,2048,1812.75,2048,1784.59,2048z"
      />
      <path
        d="M1682.58,2048c6.4-23.69,14.15-46.99,29.4-66.93c0.91-1.19,1.97-2.26,3.04-3.31
		c9.8-9.64,18.58-9.82,28.07,0.07c12.69,13.22,19.51,29.65,23.81,47.11c1.78,7.21,2.03,14.79,3.09,22.64
		C1741.1,2048,1712.07,2048,1682.58,2048z"
      />
      <path
        d="M516.5,2048c-1.57-4.93-2.75-9.85-3.75-14.81c-0.45-2.23-1.12-4.64-0.7-6.77c2.62-13.3,7.05-25.95,15.05-37.07
		c11.55-16.08,25.71-17.32,39.78-3.3c14.73,14.67,22.3,33.13,27.11,52.94c0.67,2.74,1.24,5.51,1.95,8.64
		C569.68,2048,543.32,2048,516.5,2048z"
      />
      <path
        d="M330.48,2048c9.9-30.48,30.91-49.67,61.88-57.65c9.57-2.47,18,1.08,24.3,9c7.86,9.88,11.35,20.66,8.18,33.38
		c-1.14,4.59-0.73,9.56-0.92,14.82C393,2048,361.97,2048,330.48,2048z"
      />
      <path
        d="M1304.55,2048c-0.06-2.75-0.29-5.8,0.86-8.2c7.22-15.09,14.42-30.2,22.35-44.92
		c2.51-4.65,6.82-8.62,11.02-12.01c6.58-5.3,12.79-5.31,18.92,0.41c4.78,4.46,9.01,9.8,12.34,15.43
		c8.8,14.86,12.1,31.48,13.98,48.87C1357.74,2048,1331.38,2048,1304.55,2048z"
      />
      <path
        d="M1495.57,2048c-2.91-13.67-4.51-27.57-8.05-40.96c-3.32-12.59-8.67-24.65-13.31-37.43
		c7.78-7.99,23.14-8.18,33.33,2.51c6.09,6.38,11.91,13.75,15.36,21.75c6.85,15.89,12.01,32.51,17.79,48.86
		c0.48,1.37,0.39,2.95,0.49,4.85C1526.08,2048,1511.06,2048,1495.57,2048z"
      />
      <path
        d="M439.49,2048c-0.79-7.78-1.23-15.56-1.35-23.34c-0.05-2.9-0.12-6.23,1.17-8.64
		c3.97-7.4,7.81-15.11,13.15-21.49c7.25-8.67,16.72-8.82,24.04-0.23c6.38,7.49,11.83,16.02,16.32,24.79
		c4.58,8.94,7.48,18.72,11.17,28.52C482.67,2048,461.32,2048,439.49,2048z"
      />
      <path
        d="M1630.58,2048c-0.41-0.15-0.24-0.38-0.3-0.45c-11.04-16.5-5.78-33.43-1.05-50.19c1.41-5,3.84-9.89,6.66-14.27
		c2.81-4.38,6.29-4.46,9.16-0.14c3.55,5.34,7.09,11.04,8.91,17.1c4.65,15.56,8.34,31.41,12.29,47.55
		C1654.42,2048,1642.74,2048,1630.58,2048z"
      />
      <path
        d="M1407.62,1479.47c-4.86,7.81-10.8,15.16-14.35,23.53c-7.33,17.27-13.28,35.12-20,52.66
		c-14.77,38.61-40.04,68.32-75.91,88.96c-4.61,2.66-9.07,5.61-13.79,8.06c-13.35,6.91-17.75,18.16-16.19,32.45
		c0.27,2.47,0.53,4.95,0.89,8.29c-31.92-18.18-63.11-36.42-98.49-46.78c52.85-55.5,98.38-115.66,126.09-187.63
		c-0.52-0.41-1.05-0.82-1.57-1.23c-6.58,7.28-15.01,13.58-19.42,21.99c-23.82,45.5-53.4,87.06-84.76,127.49
		c-8.78,11.31-17.52,22.66-26.17,34.07c-1.58,2.09-2.85,2.82-5.72,2.06c-23.51-6.19-47.62-9.23-71.71-6.81
		c-25.78,2.59-43.22-2.1-74.31-10.37c1.09-1.03,1.97-1.92,2.9-2.73c18.37-16.05,32.58-35.56,46.07-55.64
		c15.81-23.52,29.15-48.41,41.41-73.95c5.76-12,8.5-24.37,7.88-37.97c-1.24,2.25-2.67,4.41-3.69,6.75
		c-21.92,50.39-50.95,96.41-85.53,138.99c-5.67,6.98-11.79,13.59-17.85,20.23c-0.93,1.02-2.84,2.01-4.07,1.78
		c-17.61-3.34-35.23-1.47-52.8-0.12c-13.39,1.03-26.7,3.1-40.71,4.79c1.74-3.65,3.33-7.04,4.96-10.41
		c16.6-34.38,30.99-69.6,39.65-106.9c1.98-8.52,2.6-17.36,4.07-26.56c0.21-0.95,0.18-1.39,0.16-1.83c-0.26,0.4-0.52,0.8-0.79,1.19
		c-0.96,1.63-2.17,3.15-2.83,4.89c-10.17,26.51-19.89,53.2-30.46,79.54c-15.4,38.34-37.1,72.96-64.61,103.75
		c-40.05,44.84-80.3,89.5-120.96,133.79c-10.99,11.98-23.44,22.7-35.77,33.35c-21.56,18.62-47.36,27.38-75.32,29.66
		c-18.58,1.52-37.28,1.6-55.92,2.42c-9.98,0.44-19.97,0.98-29.93,1.69c-2.6,0.19-5.29,0.87-7.69,1.89
		c-4.63,1.97-6.32,5.96-4.57,10.63c7.59,20.31,15.15,40.63,22.98,60.85c2.31,5.95,5.44,11.59,8.39,17.76
		c-19.65-3.69-32.28,5.77-40.96,21.37c-4.77,8.58-7.84,18.11-11.7,27.27c-1.25-2.28-2.54-5.28-4.38-7.88
		c-4.89-6.9-9.28-14.37-15.21-20.27c-12.36-12.33-28.75-11.67-40.59,1.2c-4.67,5.08-8.22,11.2-12.16,16.68
		c-18.64-28.3-23.79-29.84-64.55-18.36c1.47-1.93,2.46-3.26,3.49-4.57c16.9-21.55,32.54-44.1,43.91-68.94
		c9.75-21.29,16.45-43.94,20.93-67.08c1.23-6.38,2.62-12.72,4.09-19.05c4.84-20.76,16.97-35.42,37.54-41.55
		c25.8-7.69,48.24-21.59,70.28-36.26c18.06-12.02,37.39-18.87,59.21-17.71c5.64,0.3,11.26,1.09,17.52,1.72
		c15.39-24.44,18.35-53.35,25.27-80.88c7.99-31.75,14.62-63.85,26.59-94.47c13.25-33.89,32.5-64.37,53.1-94.1
		c0.99-1.44,1.94-2.9,2.1-5.23c-0.95,0.66-2.05,1.18-2.83,2.01c-12.93,13.67-27.18,26.38-38.32,41.39
		c-9.68,13.05-16.64,28.34-23.39,43.28c-13.9,30.75-21.47,63.53-29.13,96.24c-5,21.36-10.57,42.59-15.93,63.87
		c-0.57,2.25-1.32,4.46-2.12,6.64c-3.65,9.95-3.99,10.14-13.63,6.61c-7.87-2.88-15.8-3.02-23.34,0.56
		c-7.35,3.48-14.34,7.7-21.59,11.93c-0.1,0.33-0.07,0.44-0.07,0.44c0.3-1.48,0.32-3.09,0.94-4.41c9.62-20.36,16.07-41.8,21.64-63.54
		c0.22-0.87,0.03-1.84,0.03-2.76c-0.42-0.2-0.84-0.41-1.25-0.61c-4.1,7.07-9.42,13.71-12.03,21.3c-5.6,16.27-9.77,33.03-14.62,49.56
		c-0.47,1.61-1.26,3.63-2.55,4.44c-18.73,11.77-37.57,23.37-56.4,34.98c-1.09,0.67-2.35,1.07-4.1,1.84
		c4.05-13.34,8.34-26.17,7.46-39.74c-0.73-0.22-1.46-0.44-2.19-0.67c-2.07,3.84-4.68,7.49-6.1,11.57
		c-3.42,9.87-6.48,19.89-9.28,29.96c-1,3.62-3.05,5.3-6.35,5.97c-13.54,2.76-24.14,10.84-34.16,19.55
		c-8.13,7.07-10.95,17.26-13.33,27.39c-2.77,11.81-5.43,23.66-8.07,35.5c-0.76,3.41-2.62,5.28-6.07,6.31
		c-16.31,4.87-32.55,9.98-49.45,15.2c4.76-18.13,9.16-34.89,13.56-51.65c-0.5-0.19-1-0.37-1.49-0.56
		c-4.91,4.64-10.45,8.54-12.1,16.02c-2.37,10.71-5.66,21.21-8.08,31.91c-0.85,3.75-2.02,5.38-6.13,6.04
		c-29.07,4.65-58.24,6.15-87.59,3.66c-0.62-0.05-1.23-0.18-2.14-0.32c7.66-8.35,15.11-16.48,22.57-24.61
		c-0.32-0.36-0.65-0.71-0.97-1.07c-6.98,1.81-14.4,2.43-18.98,9.57c-1.86,2.89-5.26,4.82-7.01,7.75c-4.08,6.83-9.33,7.32-16.73,5.98
		c-26.86-4.86-52.33-13.25-75.91-27.09c-1.43-0.84-2.86-1.67-4.25-2.57c-0.21-0.13-0.23-0.56-0.31-0.76
		c7.92-2.59,15.85-5.17,23.77-7.76c-0.05-0.73-0.09-1.47-0.14-2.2c-5.24-0.38-10.5-1.15-15.72-1c-3.94,0.11-8.07,0.81-11.7,2.27
		c-5.76,2.31-9.87,0.51-14.37-3.1c-18.89-15.14-34.9-32.68-46.44-54.09c-0.47-0.87-0.86-1.77-1.53-3.15
		c9.43,0.99,18.3,5.18,27.44,1.55c-7.3-9.74-18.7-9.33-29.03-11.68c-1.54-0.35-3.62-1.35-4.15-2.6
		c-10.58-24.92-13.58-51.01-11.91-77.48c8.4,7.54,16.79,15.08,25.19,22.61c0.47-0.36,0.94-0.72,1.41-1.08
		c-5.17-7.96-10.32-15.93-15.54-23.86c-0.82-1.24-1.8-2.48-2.97-3.36c-6.53-4.94-6.88-11.13-5.47-18.78
		c5.37-29.14,16.46-55.82,32.87-80.68c1.1,8.98,2.17,17.67,3.33,27.16c4.84-8.19,6.71-16.32,5.99-24.63
		c-1.1-12.68,3.99-21.86,12.59-30.82c31.05-32.32,68.11-54.8,110.15-69.48c9.67-3.38,19.55-6.15,29.89-9.03
		c0.68,0.3,0.79,0.43,0.9,0.56c-19.56,9.13-37.6,20.64-53.85,34.84c-41.81,36.56-73.1,80.05-87.03,134.58
		c-9.26,36.22-9.76,72.39,5.43,107.23c17.86,40.98,49.97,64.71,93.56,72.9c37.25,6.99,71.07-3.77,103.22-21.54
		c20.93-11.58,37.95-28.13,54.72-44.81c53.51-53.2,101.67-110.98,147.42-170.93c14.72-19.28,31.73-36.86,48.17-54.77
		c42.4-46.2,86.2-90.98,135.52-129.97c15.77-12.47,31.16-25.4,48.65-35.63c19.68-11.5,33.34-29.07,44.3-48.54
		c6.73-11.95,12.06-24.72,17.47-37.35c2.24-5.24,3.05-11.09,4.43-16.35c-13.85-10.21-28.87-15.73-45.17-18.26
		c-19.45-3.03-38.82-2.35-58.15,0.77c-38.59,6.24-75.15,18.88-110.41,35.51c-0.72,0.34-1.46,0.65-2.51,1.12
		c0-0.75-0.14-1.24,0.02-1.6c42.93-92.28,85.18-184.89,129.03-276.73c53.14-111.3,109.09-221.18,171.81-327.49
		c42.24-71.6,88.46-140.62,134.79-209.58c31.29-46.57,62.26-93.35,93.54-139.94c5.38-8.01,11.64-15.41,17.27-23.26
		c1.87-2.61,3.54-3.06,6.64-2.17c14.39,4.13,28.83,5.72,42.81-1.51c3.94-2.04,7.57-4.65,11.66-7.21
		c4.15,14.03,5.83,28.47,6.14,43.07c0.67,31.84-3.87,63.14-10.33,94.19c-10.71,51.52-26.92,101.54-41.92,151.87
		c-14.82,49.71-30.19,99.3-39.87,150.33c-4.15,21.88-6.81,44.1-9.12,66.27c-2.47,23.67,5.21,44.44,20.09,62.99
		c37.57,46.82,73.28,95.12,114.23,139.21c35.51,38.24,79.07,63.67,126.81,82.91c5.05,2.04,10.58,2.85,15.79,4.53
		c2.31,0.75,4.54,2.02,6.53,3.45c21.27,15.19,35.84,35.88,48.05,58.54c11.78,21.85,17.87,45.36,20.95,69.81
		c0.82,6.55,2.28,13.02,3.96,19.5c0-19.52,0.59-39.07-0.31-58.55c-0.33-7.21-3.61-14.47-6.46-21.34
		c-12.06-29.05-28.89-54.7-54.29-74c-1.46-1.11-2.88-2.37-4.52-3.11c-12.03-5.41-17.83-15.99-22.42-27.36
		c-11.17-27.63-19.12-56.27-24.45-85.53c-9.3-51.04-8.03-101.83,6.52-151.86c16.46-56.58,51.37-98.07,103.72-125
		c6.8-3.5,13.4-7.42,20.3-10.69c7.96-3.77,16.16-7.02,24.24-10.52c8.58-3.71,16.6-1.98,23.96,2.98c22.03,14.85,46.51,18.69,72.43,17
		c14.27-0.94,28.61-2.16,42.88-1.73c23.55,0.71,44.98,9.42,65.77,19.86c20.2,10.13,40.17,20.79,60.79,29.98
		c17.55,7.81,36.15,12.87,55.72,10.67c5.11-0.57,8.43,1.61,12.53,3.49c7.18,3.28,14.82,5.54,22.27,8.21
		c1.39,0.5,2.99,0.68,4.18,1.47c21.34,14.24,40.62,30.94,51.81,54.35c9.65,20.2,11.53,41.83,3.45,63.67
		c-0.69-0.19-1.25-0.17-1.52-0.43c-17.09-17.17-38.66-24.82-61.83-28.92c-19.67-3.49-39.42-4.04-59.15-0.39
		c-3.79,0.7-6.88-0.17-9.42-3.08c-16.86-19.27-39.04-30.52-62.35-39.07c-10.79-3.96-23.08-4.21-34.77-5.21
		c-12.23-1.04-24.55-0.97-37.95-1.42c4.6,2.54,8.42,4.59,12.19,6.72c26.32,14.92,48.68,34.64,68.3,57.44
		c13,15.11,29.02,24.64,47.93,30.46c30.63,9.43,61.04,19.62,92.78,24.84c12.78,2.1,25.6,3.93,39.31,2.51
		c-1.41,1.6-2.55,3.64-4.28,4.74c-20.33,12.87-38.2,28.39-52.52,47.86c-1.18,1.61-3.26,2.83-5.18,3.59
		c-24.67,9.71-48.63,7.65-71.69-5c-3.64-2-6.26-5.79-9.54-8.52c-13.32-11.07-28.54-18.95-44.41-25.53
		c-9.12-3.78-18.4-7.7-27.99-9.76c-18.79-4.04-36.95-1.71-52.76,9.08c-7.74-18.71-8.49-38.98-13.36-58.47
		c-3.77,22.84,0.16,45.79-1.41,69.05c-24.65-9.91-49.23-10.06-74.06-4.61c-0.2-0.38-0.51-0.69-0.45-0.91
		c6.73-28.44,13.42-56.88,20.36-85.27c0.81-3.29,0.35-5.09-3.42-6.44c-13.05,29.33-23.62,59.45-27.99,91.92
		c-18.87-42.98-51.76-60-97.84-54.03c3.59-3.23,7.03-6.3,10.44-9.41c17.74-16.15,35.62-32.16,53.14-48.55
		c7.04-6.59,13.45-13.88,19.74-21.2c1.19-1.38,0.47-4.4-0.3-7.04c-34.22,21.95-63.36,48.81-89.12,79.95
		c1.62-8.02,3.6-15.57,4.59-23.26c1.82-14.17,1.1-28.24-3.45-41.96c-6.28-18.92-18.74-31.87-37.29-39.02
		c-2.17-0.84-4.35-1.64-6.82-2.57c29.23-14.19,58.19-28.15,89.5-36.71c9.46-2.59,18.24-7.82,27.11-12.27
		c1.67-0.84,2.32-3.72,3.45-5.66c-0.23-0.42-0.46-0.85-0.68-1.27c-6.7,1.28-13.45,2.35-20.1,3.86
		c-26.9,6.12-51.96,17.15-76.74,28.93c-10.65,5.06-21.45,9.78-32.16,14.71c-4.12,1.9-7.5,4.6-7.47,9.63c0.03,5,4.04,6.45,7.8,7.94
		c3.24,1.29,6.54,2.45,9.8,3.69c21.58,8.21,32.23,24.77,35.39,46.82c2.96,20.65-0.16,40.42-8.88,59.41
		c-1.08,2.36-1.48,6.49-0.15,8.08c1.35,1.6,5.5,1.98,7.99,1.3c15.1-4.11,30.27-6.81,45.86-3.44c29.57,6.39,47.66,24.97,56.15,53.5
		c2.91,9.76,6,10.85,15.12,6.52c6.15-2.92,12.94-4.49,20.58-7.04c-8.61,27.54-12.82,53.45-1.45,80c-0.14,0.52-0.44,0.82-0.44,0.82
		c-19.59-8.57-37.64-19.78-55.39-31.61c-6.73-4.48-14.33-7.66-21.58-11.33c-0.96-0.48-2.24-0.32-4.43-0.58
		c17.65,15.42,33.64,31.42,51.76,44.47c10.72,7.72,24.83,10.69,37.35,15.97c2.59,1.09,5.48,2.48,7.16,4.58
		c16.92,21.13,38.29,37.4,59.02,54.36c23.81,19.49,48.02,38.51,71.4,58.51c14.94,12.78,27.99,27.52,36.57,45.61
		c0.71,1.49,1.36,3.02,2.01,4.54c0.25,0.59,0.4,1.21,0.8,2.45c-49.06,8.61-96.88,4.88-143.89-11.04c-0.11,0.4-0.21,0.8-0.32,1.2
		c1.13,0.79,2.23,1.63,3.4,2.36c4.24,2.64,8.85,4.83,12.71,7.93c15.77,12.68,34.39,15.78,53.76,16.93
		c28.79,1.71,50.01,0.01,78.25-6.86c0.96,7.57,2.48,15.03,2.77,22.54c1.78,46.74-9.53,90.88-27.82,133.41
		c-6.35,14.76-14.05,28.96-21.56,43.19c-4.41,8.34-9.67,16.23-14.75,24.67c-30.75-22.34-54.17-50.87-74.42-82.35
		c-0.56,0.38-1.11,0.76-1.67,1.14c6.85,16.07,13.64,32.16,20.63,48.17c0.97,2.22,2.76,4.15,4.44,5.97
		c13.09,14.1,27.64,26.49,44.38,36.89c-1.49,2.47-2.83,4.88-4.33,7.18c-11.48,17.51-22.93,35.04-34.58,52.44
		c-2,2.98-4.72,5.56-7.43,7.97c-38.48,34.19-81.08,62.77-124.06,90.83c-18.12,11.83-36.22,23.7-54.46,35.33
		c-3.22,2.05-7.14,3.02-10.73,4.49c-0.36-0.46-0.72-0.93-1.08-1.39c10.52-14.95,20.63-30.21,31.64-44.79
		c22.97-30.45,46.74-60.29,69.53-90.87c15.88-21.31,28.73-44.39,35.83-70.32c6-21.93,4.22-42.89-7.31-62.67
		c-10.06-17.25-20.65-34.19-30.51-51.54c-14.15-24.88-25.36-51.12-34.94-78.07c-0.89-2.51-1.41-5.14-2.52-7.62
		c-0.35,14.92,2.59,29.23,7.17,43.31c8.91,27.38,22.36,52.56,36.65,77.41c6.93,12.06,13.67,24.44,18.59,37.4
		c5.7,15,1.83,30.26-3.17,44.95c-8.09,23.76-21.32,44.73-36.35,64.54c-21.85,28.8-44.47,57-66.26,85.84
		c-24.56,32.49-46.59,66.6-61.3,104.82c-4.88,12.67-7.81,26.07-11.93,39.04c-3.05,9.62-6.71,19.04-9.88,28.63
		c-7.14,21.6-9.79,43.81-7.78,66.42c2.09,23.51,4.82,46.96,7.16,70.44c0.16,1.61-0.15,3.79-1.14,4.92
		c-14.89,16.96-23.5,36.9-28.31,58.7c-0.14,0.63-0.46,1.22-0.93,2.42c-1.82-3.38-3.02-6.62-5.06-9.2
		c-4.62-5.82-8.98-12.15-14.68-16.76c-9.5-7.69-21.54-7.8-30.82,0.2c-6.66,5.74-11.78,13.37-17.13,20.5
		c-2.63,3.51-4.21,7.81-6.26,11.75c-0.47-0.09-0.95-0.18-1.42-0.27c1.6-11.35,2.82-22.76,4.85-34.02
		c6.85-38.06,8.65-76.36,6.03-114.9c-2.94-43.21-11.23-85.54-19.5-127.98c-3.62-18.56-10.95-35.83-14.38-54.27
		c-0.66-3.54-0.02-5.58,3.04-7.33c6.5-3.71,12.89-7.61,19.32-11.45c42.48-25.43,68.68-63.04,82.12-110.1
		c5.2-18.22,10.07-36.54,15.16-54.79c0.83-2.99,1.99-5.89,3.29-9.28c0.31-0.72,0.32-0.99,0.33-1.26
		C1408.39,1479.23,1408.01,1479.35,1407.62,1479.47z M1754.48,752.2c3.68-3.65,4.25-7.05,0.57-9.61
		c-8.7-6.04-17.74-11.62-26.85-17.05c-1.49-0.89-4.25-0.65-6.01,0.08c-8.75,3.63-17.36,7.59-26.01,11.45
		c-1.39,0.62-2.73,1.36-5.12,2.56c2.83,1.12,4.44,1.97,6.15,2.4c11.77,2.94,23.49,6.15,35.37,8.54
		C1739.49,751.95,1746.68,751.98,1754.48,752.2z M1679.96,811.47c-0.22-0.01-0.43-0.02-0.65-0.04
		C1679.42,811.54,1679.52,811.64,1679.96,811.47z"
      />
      <path
        d="M1154.88,307.54c-0.17,0.81-0.11,1.78-0.55,2.41c-24.39,34.95-49.14,69.65-73.18,104.84
		c-42.35,62.01-85.06,123.79-126.17,186.61c-65.34,99.85-121.76,204.81-174.96,311.54c-53.28,106.9-104.33,214.82-149.38,325.49
		c-7.21,17.7-13.86,35.63-20.66,53.5c-1.11,2.91-1.82,5.57-5.92,5.33c-4.01-0.24-6.68-1.95-7.97-5.6c-0.66-1.85-1.07-3.89-1.09-5.85
		c-0.6-50.28-14.18-97.15-37.05-141.6c-15.06-29.27-32.92-56.54-56.85-79.44c-29.7-28.42-64.49-47.05-105.09-54.45
		c-19.44-3.54-38.67-2.34-57.93,1.95c-16.49,3.67-33.2,6.4-49.62,9.51c0.48-0.82,1.32-2.33,2.23-3.79
		c68.35-110.03,149.91-209.62,239.5-302.86c81.83-85.17,170.03-163.02,266.91-230.76c89.25-62.4,183.64-115.42,285.87-153.63
		c23.32-8.72,47.43-15.34,71.56-23.02C1154.9,307.62,1154.88,307.54,1154.88,307.54z"
      />
      <path
        d="M911.36,396.23C817.09,448.67,730.3,511.1,649.28,581.59C531.16,684.37,425.42,798.61,333.72,925.64
		c-45.09,62.46-84.45,128.48-120.95,196.28c-6.18,11.48-14.99,21.52-22.1,32.54c-12.43,19.26-24.71,38.62-36.62,58.21
		c-13.27,21.82-26.05,43.94-39.04,65.92c-1.35,2.29-2.72,4.56-5,6.81c0-1.83-0.13-3.67,0.02-5.49c2.38-28.21,3.67-56.57,7.45-84.6
		c6.26-46.33,12.8-92.7,21.68-138.58c12.2-63.03,26.65-125.62,40.22-188.39c2.39-11.04,4.15-22.38,8.07-32.88
		c4.78-12.81,10.69-25.38,17.64-37.15c13.75-23.3,31.59-43.62,49.6-63.69c34.75-38.72,71.95-75.02,110.74-109.66
		c86.26-77.05,177.99-146.75,278.12-204.96c71.84-41.77,147.25-75.83,225.75-103.09c48.76-16.94,98.41-30.6,149.2-39.83
		c30.95-5.63,62.19-9.07,93.67-9.94c1.79-0.05,4.12,1.03,5.36,2.37c5.1,5.48,9.53,11.6,14.86,16.82c5.2,5.1,11.2,9.38,17,14.15
		c-7.81,1.64-15.84,3.03-23.71,5.03c-51.15,13.03-99.59,33.3-147.02,56.05C956.02,372.41,933.98,384.5,911.36,396.23z"
      />
      <path
        d="M1336.96,211.79c21.11-8.01,42.11-15.3,62.44-24.13c10.5-4.56,19.8-11.88,29.58-17.92
		c13.95,31.51,38.56,51.58,73.07,61.14c-1.73,4.34-2.96,9.19-5.43,13.3c-13.5,22.45-26.89,44.99-41.1,66.99
		c-30.45,47.14-60.14,94.76-86.52,144.28c-17.04,31.99-32.11,65.05-47.87,97.72c-15.22,31.55-30.27,63.17-45.21,94.85
		c-3.38,7.17-5.88,14.75-8.94,22.07c-7.28,17.41-5.1,34.52,1.9,51.31c8.09,19.41,16.48,38.7,24.98,57.94
		c1.35,3.05,1.4,4.92-1.09,7.35c-21.27,20.78-44.33,39.35-68.79,56.22c-1.63,1.12-3.65,1.71-5.19,2.93
		c-1.17,0.93-2.76,2.63-2.56,3.67c0.27,1.38,1.9,2.95,3.32,3.57c3.33,1.45,7.03,2.08,10.34,3.59
		c24.14,10.97,35.72,30.91,39.38,56.19c2.74,18.94-2.21,36.65-9.62,53.84c-2.1,4.87-4.47,9.63-7.12,15.29
		c-9.63-10.99-19.12-21.24-27.97-32.03c-21.97-26.79-43.44-53.95-61.87-83.38c-11.19-17.87-18.89-36.69-16.59-58.37
		c0.84-7.95,1.44-15.93,2.45-23.86c7.34-57.16,23.71-112.15,40.02-167.18c14.93-50.36,29.97-100.7,43.88-151.34
		c9.14-33.28,14.29-67.39,16.01-101.96c1.9-38.03-5.21-73.86-25.27-106.65c-0.51-0.83-0.87-1.76-1.4-2.83
		C1259.25,237.67,1298.1,225.27,1336.96,211.79z"
      />
      <path
        d="M1772.69,1964.79c3.86,0.5,7.32,0.92,10.3,1.29c-4.9,7.81-9.98,15.91-14.52,23.14
		c-3.87-5.65-7.95-12.77-13.15-18.97c-15.38-18.37-35.11-18.39-50.61-0.14c-12.43,14.62-19.09,32.22-24.65,50.28
		c-1.07,3.47-2.05,6.96-3.04,10.36c-3.33-11.84-6.02-23.79-10.14-35.23c-2.66-7.38-6.86-14.39-11.4-20.85
		c-4.13-5.88-10.49-8.53-17.7-6.99c0.85-24.58-10.12-45.33-20.45-66.5c-19.11-39.18-50.15-68.4-80.36-98.38
		c-0.83-0.82-1.63-1.69-2.54-2.41c-17.3-13.78-25.97-31.92-27.36-53.81c-1.35-21.25-2.79-42.49-4.26-63.73
		c-0.2-2.96,0.59-4.69,3.46-6.24c50.98-27.4,99.64-58.41,145.32-94.02c1.01-0.78,2.09-1.46,4.2-2.92c-0.66,2.64-1.01,4.15-1.41,5.65
		c-6.32,24.11-11.74,48.36-14.05,73.27c-5.24,56.5,6.49,108.62,38.84,155.76c15.41,22.46,30.38,45.22,45.49,67.89
		c11.96,17.95,19.88,37.82,27.68,57.79c2.15,5.51,4.86,11.06,8.48,15.68C1763.74,1959.41,1768.4,1961.76,1772.69,1964.79z"
      />
      <path
        d="M1621.8,690.46c-29.07,0.77-54.64-6.68-74.03-28.88c-6.96-7.97-12.62-17.11-18.56-25.93
		c-5.1-7.59-9.58-15.59-14.64-23.21c-10.34-15.59-24.34-26.73-41.75-33.57c-4.48-1.76-8.98-3.49-13.47-5.23
		c-26.97-10.44-41.87-35.14-39.81-64.06c0.95-13.36,3.91-25.53,12.72-35.8c1.81-2.11,4.32-3.62,6.51-5.4c0.45,0.3,0.89,0.6,1.34,0.9
		c-0.52,4.38-0.97,8.77-1.57,13.15c-1.57,11.45,1.21,21.42,9.72,29.57c8.53,8.17,18.36,13.19,30.24,15.04
		c25.75,4.01,50,12.07,70.86,28.53c10.83,8.55,19.89,18.65,27.95,29.76c10.18,14.02,20.34,28.04,33.93,39.14
		c17.1,13.98,37.27,21,58.41,25.27c50.69,10.23,93.53,35.03,131.77,68.97c12.87,11.43,24.6,24.32,40.11,32.55
		c-0.1,0.45-0.2,0.91-0.3,1.36c-5.12-0.64-10.27-1.08-15.34-1.96c-14.93-2.57-28.93-7.87-42.04-15.35
		c-14.9-8.5-29.51-17.48-44.43-25.95c-20.32-11.53-41.42-21.43-65.05-23.39c-8.63-0.72-17.48,1.32-26.24,2.11
		C1639.49,688.84,1630.88,689.64,1621.8,690.46z"
      />
      <path
        d="M630.51,1457.44c-36.71-9.04-73.04-17.92-109.37-26.81c-0.09-0.43-0.19-0.85-0.28-1.28
		c3.67-0.84,7.33-1.71,11-2.53c38.15-8.5,74.02-22.21,105.27-46.31c9.27-7.15,17.58-15.25,23.28-25.72
		c6.2-11.38,5.85-22.53-0.73-33.59c-1.06-1.79-2.07-3.6-2.73-5.73c31.89,24.37,67.05,41.63,106.5,51.97
		c-4.17,3.13-7.98,6.11-11.91,8.92c-36.19,25.88-71.77,52.51-104,83.33c-1.11,1.06-3.55,1.46-5.16,1.14
		C638.48,1460.08,634.7,1458.71,630.51,1457.44z"
      />
      <path
        d="M1140.71,174.29c23.72,22.69,42.44,48.89,60.86,75.45c7.47,10.77,15.43,21.03,19.86,33.51
		c0.33,0.94,0.67,1.87,0.99,2.75c-14.08,15.18-31,13.14-48.05,7.61c-21.37-6.92-37.33-21.5-50.98-38.69
		c-2.29-2.88-2.51-7.42-3.67-11.19c-6.64-21.62-17.77-40.94-30.05-59.77c-13.29-20.38-27.18-40.22-45.09-57.6
		c4-2.59,7.71-1.87,11.28-0.94c24.75,6.5,46.89,18.29,67.13,33.73C1129.06,163.79,1134.64,169.07,1140.71,174.29z"
      />
      <path
        d="M1363.87,849.75c-5.02,26.74-6.77,53.27-5.05,79.99c0.24,3.79-0.55,6.06-3.97,8.02
		c-20.35,11.65-40.62,23.43-60.83,35.32c-8.45,4.97-16.63,10.41-25.05,15.44c-1.3,0.78-3.21,0.54-6.32,0.99
		c1.91-3.55,2.97-5.55,4.06-7.54c11.17-20.47,19.04-41.92,17.16-65.67c-2.18-27.52-13.13-50.31-37.56-65.17
		c-0.4-0.24-0.85-0.41-0.49-0.24c39.96-4.81,80.2-9.65,120.61-14.51C1365.68,840.3,1364.81,844.81,1363.87,849.75z"
      />
      <path
        d="M1291.03,834.86c-15.5,2.29-30.55,4.54-45.47,6.78c10.21-7.19,20.72-14.14,30.73-21.75
		c17.01-12.94,32.81-27.36,45.45-44.63c10.76-14.69,16.07-31.52,14.26-50.11c-0.77-7.97-3.41-15.16-8.04-21.68
		c-1.76-2.47-3.16-5.19-4.73-7.79c0.33-0.42,0.66-0.84,0.99-1.26c31.43,13.26,62.86,26.52,94.8,40
		c-5.44,6.75-10.19,12.29-14.56,18.11c-15.13,20.17-25.9,42.56-33.18,66.64c-0.97,3.21-2.4,4.39-5.71,4.85
		C1340.84,827.48,1316.16,831.19,1291.03,834.86z"
      />
      <path
        d="M584.19,1458.92c17.61,3.73,34.8,7.4,52.34,11.14c-0.84,0.98-1.51,1.85-2.26,2.65
		c-29.82,31.65-59.67,63.25-89.39,94.99c-2.36,2.52-4.17,2.88-7.56,2.18c-20.35-4.23-40.79-8.02-61.2-11.95
		c-1.62-0.31-3.24-0.59-5.66-2.18c3-2.29,5.84-4.82,9.01-6.81c13.32-8.37,27.31-15.82,39.93-25.14
		c7.69-5.68,14.45-13.44,19.64-21.53c10.07-15.69,7.25-35.13-5.72-50.2c-2.26-2.63-4.65-5.16-7.13-7.91
		C545.57,1449.1,564.67,1453.98,584.19,1458.92z"
      />
      <path
        d="M159.5,1641.41c19.17-4.49,38.12-7.52,58.22-7.4c-4.3,5.41-8.37,10.08-11.94,15.11
		c-9.84,13.86-17.45,28.77-17.74,46.19c-0.09,5.47,0.89,11.4,3.08,16.37c4.22,9.59,14.63,13.29,26.87,10.79
		c0.95-0.19,1.89-0.39,2.11-0.43c-12.79,24.17-25.72,48.61-38.85,73.4c-2.97-2.78-7-6.18-10.61-9.98
		c-20.85-21.94-29.77-48.77-31.49-78.38c-1.08-18.6,1.61-36.82,5.91-54.9c1.24-5.19,3.71-8.13,8.8-9.14
		C155.64,1642.69,157.37,1642.07,159.5,1641.41z"
      />
      <path
        d="M1276.95,1005.1c-2.93-2.69-5.6-5.14-8.49-7.8c33.33-10.58,61.65-29.78,91.69-46.91
		c1.14,7.37,1.61,14.53,3.43,21.32c5.36,19.94,11.11,39.78,17,59.57c3.26,10.94,6.96,21.76,10.81,32.52
		c1.2,3.36,3.66,6.24,5.29,9.48c0.54,1.08,0.81,2.94,0.23,3.77c-0.57,0.83-2.61,1.44-3.58,1.06c-6.95-2.71-13.94-5.41-20.66-8.65
		C1337.81,1052.62,1306.08,1031.1,1276.95,1005.1z"
      />
      <path
        d="M1426.1,726.92c-28.98-16.59-59.41-28.91-90.84-38.91c-0.56-0.18-1.12-0.36-1.69-0.54
		c18.8-1.47,34.3-12.3,51.58-17.93c15.84-5.16,27.74-15.81,31.21-33.22c1.01-5.06,0.58-10.41,0.85-16.55
		c19.91,23.96,39.31,47.3,59.03,71.03C1458.06,700.29,1441.8,713.01,1426.1,726.92z"
      />
      <path
        d="M1506.63,150.5c3.93,21.56,3.63,42.64-0.52,65.13c-8.13-3.45-15.77-6.11-22.86-9.83
		c-13.62-7.15-24.8-17.3-33.53-30c-1.06-1.54-1.95-4.15-1.38-5.7c11.61-31.39,12.01-63.44,6.16-95.92
		c-0.06-0.31,0.04-0.64,0.15-1.99C1482.82,91.46,1499.92,117.22,1506.63,150.5z"
      />
      <path
        d="M192.53,1803.32c-1.81-1.17-3.57-1.6-1.92-4.25c12.56-20.21,24.95-40.52,37.4-60.8
		c0.41-0.67,0.89-1.3,2.14-3.1c0,8.22-0.81,15.19,0.24,21.87c0.93,5.9,3.2,11.89,6.19,17.08c4.6,7.97,14.38,10.76,24.23,8.04
		c14.71-4.06,25.85-13.27,35.43-24.69c0.95-1.13,1.87-2.29,3.1-3.81c-1.59,20.54-3.14,40.58-4.77,61.63
		C259.72,1825.3,225.37,1823.12,192.53,1803.32z"
      />
      <path
        d="M822.75,1289.04c-0.04,1.1,0.24,2.35-0.18,3.28c-11.34,25.06-25.45,48.12-47.15,65.83
		c-3.35,2.73-5.86,3.21-10.01,1.57c-32.09-12.67-63.68-26.28-92.32-45.85c-2.02-1.38-3.99-2.82-5.78-3.23
		c31.25,0.74,62.51,2.12,93.76,1.96c17.23-0.09,33.84-5.01,48.94-13.77c4.43-2.57,8.37-6.01,12.65-9.35
		C822.78,1289.17,822.75,1289.04,822.75,1289.04z"
      />
      <path
        d="M1465.55,659.5c-11.48-14.22-22.74-28.15-34.91-43.21c22.75,4.51,43.5,3.34,63.25-9.88
		c3.01,3.73,6.97,8,10.19,12.77c6.32,9.38,12.03,19.18,18.36,28.55c5.02,7.43,10.58,14.5,15.44,21.11
		c-17.52,5.35-35.59,10.87-53.87,16.45C1478.31,677.31,1472.04,668.54,1465.55,659.5z"
      />
      <path
        d="M1854.43,1084.46c2.63,7.02,4.91,13.82,12.97,17.02c-8.15,1.41-14.8,0.27-20.83-4.82
		c-19.02-16.05-36.24-33.6-43.8-58.03c-3.36-10.85-4.5-22.38-6.66-33.59c-0.56-2.91-1.18-5.81-1.97-9.69
		c21.67,15.39,45.15,21.28,71.82,17.1C1850.04,1034.99,1848.69,1059.01,1854.43,1084.46z"
      />
      <path
        d="M176.28,1626.09c-8.5,2.5-16.59,4.93-25.32,7.54c16.95-44.87,45.26-81.17,81.82-111.75
		c-20.38,30.9-31.73,62.93-14.9,98.71C204.17,1622.4,190.43,1624.21,176.28,1626.09z"
      />
      <path
        d="M1704.61,627.55c-0.05,8.23-0.05,16-0.05,24.06c-0.18-0.04-1.21-0.18-2.21-0.46
		c-15.99-4.39-31.99-8.72-47.95-13.24c-4.14-1.17-9.33-1.87-11.81-4.72c-2.19-2.53-1.5-7.74-1.71-11.79
		c-0.65-12.57-1.12-25.15-0.94-38.13c20.29,16.14,42.66,22.07,67.76,17.09C1706.64,609.73,1705.65,618.41,1704.61,627.55z"
      />
      <path
        d="M504.26,1614.23c-8.66,9.82-17.08,19.38-25.49,28.93c4.25-25.1,4.07-49.42-5.94-73.18
		c20.84,2.82,41.34,5.59,62.46,8.45C525.02,1590.29,514.76,1602.13,504.26,1614.23z"
      />
      <path
        d="M311.65,1767.48c0.46-4.07,0.85-7.69,1.26-11.48c23.84,15.16,46.3,6.28,68.95-2.81
		c-22.29,23.48-45.74,45.26-76.54,58.19C307.44,1796.6,309.51,1782.26,311.65,1767.48z"
      />
      <path
        d="M1960.54,825.24c-4.58-6.85-8.58-13.95-13.26-20.56c-10.62-15-24.14-26.94-40.66-36.6
		c13.04-7.24,19.7-18.23,20.94-33.89C1952.11,760.43,1961.11,791.1,1960.54,825.24z"
      />
      <path
        d="M1714.32,645.02c1.68-12.28,3.3-24.14,5.1-37.25c19.98,28.04,42.01,53.4,60.67,82.5
		c-23.36-12.84-45.34-24.91-67.48-37.08C1713.03,651.24,1713.65,648.34,1714.32,645.02z"
      />
      <path
        d="M1590.28,589.25c5.99-0.41,12.06-0.35,17.94-1.35c7.31-1.24,14.48-3.36,20.69-4.85
		c0.79,14.73,1.61,30.15,2.42,45.28c-16.59-8.74-28.79-23.52-40.63-39.07C1590.37,589.21,1590.28,589.25,1590.28,589.25z"
      />
      <path
        d="M273.9,1481.88c0.47-0.38,1.11-0.56,1.75-0.75c-0.2,0.39-0.39,0.78-0.92,1.24
		C274.29,1482.32,274.18,1482.19,273.9,1481.88z"
      />
      <path
        d="M1154.92,307.65c0.28,0,0.54-0.04,0.81-0.07c-0.21,0.16-0.43,0.32-0.74,0.22C1154.88,307.54,1154.9,307.62,1154.92,307.65z
		"
      />
      <path
        d="M1590.33,589.23c0.22-0.24,0.48-0.5,0.74-0.76c-0.09,0.17-0.18,0.35-0.53,0.65
		C1590.28,589.25,1590.37,589.21,1590.33,589.23z"
      />
      <path
        d="M561.3,1728.86c-0.22-0.25-0.46-0.55-0.71-0.84c0.23,0.08,0.46,0.17,0.7,0.58
		C561.3,1728.93,561.27,1728.82,561.3,1728.86z"
      />
      <path d="M1416.46,619.22c0.27,0.01,0.33,0.23,0.38,0.45C1416.65,619.59,1416.45,619.51,1416.46,619.22z" />
      <path />
      <path
        d="M1596.18,1046.89c0.33,0.16,0.52,0.47,0.7,0.77c-0.26,0.04-0.53,0.07-0.97-0.11
		C1595.74,1047.33,1596.04,1047.03,1596.18,1046.89z"
      />
      <path
        d="M822.75,1289.12c0.2,0.21,0.44,0.46,0.67,0.72c-0.22-0.06-0.43-0.12-0.66-0.48
		C822.75,1289.04,822.78,1289.17,822.75,1289.12z"
      />
    </>
  );
}
