export default function BlackDragon(): JSX.Element {
  return (
    <>
      <path
        d="M822.52,2048c-0.92-1.27-1.16-2.68-1.85-3.8c-18.9-30.84-40.5-59.41-68.19-83.09
		c-11.04-9.45-11.17-13.29-6.48-27.24c11.04-32.82,22.13-65.63,32.31-98.72c3.29-10.68,3.84-22.21,5.58-33.36
		c0.94-6.04-2.07-9.66-7.32-12.16c-13.84-6.58-27.79-12.96-41.33-20.13c-17.72-9.38-34.23-20.62-48.33-35.07
		c-0.92-0.94-1.77-1.95-2.82-3.1c7.55-9.07,15.24-17.81,22.37-26.99c7.76-9.99,15.06-20.34,22.43-30.64c1.5-2.1,2.55-2.98,5.56-2.37
		c3.97,0.8,8.29,0.5,12.37-0.03c26.33-3.41,52.81-6.45,79.23-3.31c19.09,2.27,37.84,7.44,56.7,11.5c1.33,0.29,2.88,1.83,3.42,3.16
		c7.39,18.19,14.85,36.36,21.84,54.71c5.61,14.73,4.34,29.68-1.51,43.94c-7.2,17.54-15.1,34.81-23.12,52
		c-12.75,27.32-25.79,54.5-38.82,81.68c-4.33,9.03-2.53,16.36,4.37,23.5c23.51,24.3,49.67,44.39,82.79,53.81
		c4.31,1.23,8.85,1.71,13.13,3.02c1.73,0.53,3.93,2.09,4.43,3.65c5.11,16.03,9.91,32.15,14.79,48.63
		C917.04,2048,870.01,2048,822.52,2048z"
      />
      <path
        d="M1193.54,2048c-1.88-9.69-3.3-19.39-4.69-29.08c-0.21-1.45-0.61-3.03-0.26-4.37
		c4.98-19.29,11.65-37.87,23.61-54.1c9.96-13.53,24.25-14.38,33.84-0.64c6.86,9.82,11.98,21.08,16.47,32.27
		c7.1,17.67,10.71,36.35,13.36,55.58C1248.72,2048,1221.37,2048,1193.54,2048z"
      />
      <path
        d="M975.53,2048c-1.08-3.55-1.12-7.31-2.41-10.6c-5.22-13.3-10.82-26.44-16.49-40.16c4.69,0,9.39,0.07,14.08-0.01
		c18.4-0.35,35.84-4.87,52.74-11.99c11.27-4.74,16.75-3.34,24.62,5.89c12.07,14.17,17.05,31.4,20.75,49.13
		c0.48,2.28,0.78,4.59,1.2,7.31C1038.71,2048,1007.35,2048,975.53,2048z"
      />
      <path
        d="M1553.57,2048c-0.78-6.6-1.08-13.2-1.4-19.8c-0.27-5.65-0.57-11.3-0.84-16.95
		c-0.6-12.55,4.88-22.91,13.25-31.31c6.69-6.71,15.75-7.53,24.74-4.25c10.12,3.7,17.25,11.14,23.27,19.7
		c9.92,14.08,13.92,30.43,17.54,46.89c0.36,1.62,0.65,3.26,0.97,5.3C1605.42,2048,1579.73,2048,1553.57,2048z"
      />
      <path
        d="M1459.56,2048c3.25-11,6.45-22.21,10.84-32.93c3.45-8.43,7.93-16.54,12.82-24.24
		c2.87-4.51,7.08-8.41,11.35-11.74c5.12-4.01,11.08-3.81,16.2,0.26c3.61,2.87,7.18,6.12,9.76,9.89
		c11.94,17.39,19.27,36.45,19.54,58.3C1513.41,2048,1486.72,2048,1459.56,2048z"
      />
      <path
        d="M381.49,2048c-1.14-3.08-1.36-6.35-2.57-9.19c-2.74-6.41-2.61-12.8,0.08-18.9
		c4.19-9.53,7.91-19.53,13.74-28.03c8.76-12.78,21.24-13.46,32.99-3.07c11.68,10.33,18.61,23.78,23.51,38.29
		c2.23,6.6,3.59,13.5,5.57,20.58C430.67,2048,406.31,2048,381.49,2048z"
      />
      <path
        d="M209.48,2048c2.46-6.4,4.74-13.22,8.45-19.13c9.2-14.67,22.37-24.89,38.44-31.28c3.53-1.4,7.27-2.47,11.01-3.1
		c8.17-1.36,15.33,1.05,20.52,7.56c6.65,8.35,11.78,17.17,8.86,28.8c-1.31,5.23-0.92,10.89-1.02,16.75
		C267.32,2048,238.63,2048,209.48,2048z"
      />
      <path
        d="M1109.54,2048c4.67-20.59,13.44-39.53,27.04-55.82c9.13-10.93,18.35-10.32,27.48,0.73
		c10.74,12.99,15.25,28.58,18.04,44.81c0.53,3.11,0.76,6.27,1.06,9.84C1158.72,2048,1134.36,2048,1109.54,2048z"
      />
      <path
        d="M1286.55,2048c-1.17-17.11-5.62-33.45-11.05-49.52c-2.22-6.59-5.12-12.99-8.23-19.22
		c-1.56-3.13-0.63-4.38,1.92-5.86c10.55-6.1,23.93-3.02,32.55,8.58c5.01,6.74,9.76,14.05,12.78,21.82
		c5.52,14.18,9.67,28.89,14.48,43.79C1315.06,2048,1301.04,2048,1286.55,2048z"
      />
      <path
        d="M309.48,2048c-0.55-6.73-0.8-13.47-0.65-20.2c0.08-3.54,0.02-7.47,1.51-10.5c3.11-6.36,6.56-12.77,11.05-18.19
		c7.01-8.48,16.25-8.97,23-0.44c6.93,8.75,12.45,18.73,17.73,28.63c3.33,6.24,5.1,13.32,7.73,20.36
		C350,2048,329.97,2048,309.48,2048z"
      />
      <path
        d="M1411.56,2048c-9.53-13.58-6.76-27.87-2.84-42.14c1.35-4.93,2.91-9.92,5.24-14.44
		c4.28-8.3,8.35-8.49,12.97-0.54c3.14,5.41,5.94,11.23,7.65,17.22c3.69,12.91,6.59,26.04,9.66,39.49
		C1433.4,2048,1422.72,2048,1411.56,2048z"
      />
      <path
        d="M1217.65,1799.15c-0.31,0.56-0.61,1.12-1.27,2.19c-2.49,7.28-4.62,14.04-6.76,20.8
		c-0.15,0.4-0.3,0.81-0.69,1.74c-0.26,1.1-0.29,1.68-0.31,2.26c-0.12,0.41-0.24,0.82-0.63,1.83c-0.96,5.46-1.64,10.32-2.33,15.18
		c-0.14,0.41-0.27,0.82-0.58,1.89c-0.18,3.15-0.19,5.64-0.19,8.12c-0.14,6.93-0.28,13.86-0.41,21.67c0.27,4.3,0.52,7.71,0.78,11.13
		c0.01,0.73,0.02,1.47,0.04,3c0.98,9.2,1.95,17.61,2.93,26.01c0.06,1.04,0.13,2.08,0.14,3.84c0.25,1.86,0.56,3.01,0.87,4.16
		c0.05,1.04,0.11,2.09,0.12,3.82c0.26,1.52,0.55,2.35,0.84,3.19c0.06,1.04,0.13,2.08,0.16,3.82c0.27,1.52,0.57,2.35,0.87,3.18
		c0.03,0.73,0.06,1.46,0.03,2.93c-0.43,2.53-0.79,4.32-1.16,6.11c-0.98,1.06-1.96,2.12-3.37,3.53c-1.09,1.42-1.74,2.49-2.39,3.55
		c0,0-0.31,0.27-0.66,0.56c-0.7,1-1.06,1.71-1.41,2.42c0,0-0.3,0.25-0.65,0.54c-0.66,1.02-0.97,1.75-1.28,2.48
		c0,0-0.31,0.3-0.67,0.62c-8.11,12.53-12.56,26.13-16.22,39.9c-4.24-5.55-8.19-10.74-12.15-15.92c-0.78-0.89-1.56-1.77-2.66-3.13
		c-11.16-9.74-22.39-10.95-32.81-2.3c-5.79,4.81-9.77,11.8-14.57,17.81c-0.42,0.69-0.84,1.37-1.62,2.48
		c-0.94,1.81-1.51,3.2-2.08,4.59c-0.43,0.77-0.86,1.55-1.79,2.53c-0.73-2.13-0.95-4.46-1.17-6.8c0.14-2.01,0.28-4.01,0.6-6.72
		c0.27-1.56,0.36-2.42,0.46-3.27c0.11-0.72,0.23-1.44,0.55-2.74c0.25-1.15,0.29-1.71,0.33-2.28c0.14-0.71,0.28-1.43,0.66-2.75
		c0.3-1.49,0.36-2.36,0.42-3.23c0.11-0.41,0.22-0.83,0.55-1.86c0.58-3.8,0.93-6.98,1.28-10.16c0.15-0.41,0.3-0.81,0.64-1.73
		c0.16-1.38,0.12-2.26,0.09-3.13c0.24-1.11,0.49-2.22,0.91-3.96c0.16-1.82,0.14-3,0.12-4.18c0.29-1.67,0.58-3.33,0.98-5.59
		c0.05-1.15-0.01-1.7-0.07-2.26c0.29-1.75,0.58-3.5,1.01-5.87c0.09-1.79,0.03-2.96-0.02-4.13c0.28-2.73,0.57-5.47,1.01-8.96
		c0.22-6.2,0.27-11.65,0.33-17.09c0.05-5.03,0.1-10.05,0.28-15.84c-0.22-6.53-0.57-12.3-0.92-18.06c-0.16-2.41-0.32-4.83-0.44-7.96
		c-0.16-2.49-0.34-4.27-0.53-6.05c-0.22-2.09-0.43-4.17-0.58-6.83c-0.05-1.11-0.17-1.65-0.29-2.19c-0.24-2.08-0.48-4.16-0.65-6.86
		c-0.09-1.45-0.25-2.29-0.41-3.13c-0.19-1.43-0.38-2.87-0.5-4.92c-0.17-1.98-0.41-3.33-0.66-4.69c-0.09-0.6-0.19-1.2-0.21-2.38
		c-0.21-2.04-0.5-3.51-0.79-4.98c-0.03-0.43-0.05-0.86-0.04-1.92c-0.23-1.79-0.51-2.95-0.79-4.11c-0.02-0.43-0.04-0.86-0.04-1.96
		c-0.33-2.13-0.68-3.58-1.02-5.03c-0.04-0.73-0.09-1.46-0.13-2.81c-0.23-1.15-0.47-1.68-0.71-2.22c-0.05-0.73-0.1-1.46-0.14-2.86
		c-0.31-1.49-0.64-2.31-0.96-3.13c-0.05-0.73-0.09-1.46-0.12-2.85c-0.3-1.49-0.61-2.32-0.93-3.14c0-0.43-0.01-0.86,0-1.92
		c-0.31-1.45-0.63-2.27-0.96-3.09c0-0.43,0-0.86,0.02-2.01c-1.35-6.8-2.71-12.89-4.08-18.97c-0.07-0.43-0.14-0.86-0.24-1.96
		c-1.27-8.36-2.36-16.08-3.8-23.73c-0.64-3.38-1.99-6.63-3.01-9.94c-0.26-0.89-0.52-1.78-0.94-3.36c-2.46-8.46-4.55-16.3-7.16-23.97
		c-1.83-5.38-1.85-9.81,3.69-12.86c0,0,0.38-0.07,0.87-0.22c4.03-2.36,7.57-4.58,11.11-6.8c0,0,0.39-0.07,0.87-0.21
		c7.35-5.04,14.22-9.93,21.08-14.83c0.46-0.31,0.91-0.61,1.85-1.14c1.72-1.43,2.95-2.63,4.18-3.84c0.78-0.6,1.56-1.2,2.84-2.11
		c4.21-4.26,7.93-8.22,11.65-12.17c0,0,0.35-0.17,0.76-0.41c1.7-2.02,2.98-3.8,4.27-5.58c0.38-0.4,0.76-0.8,1.56-1.55
		c1.76-2.38,3.1-4.41,4.44-6.45c0,0,0.32-0.25,0.66-0.53c0.68-1.01,1-1.74,1.33-2.47c0,0,0.31-0.26,0.68-0.57
		c4.44-7.54,9.04-14.53,12.48-22.06c11.6-25.4,17.61-52.58,24.6-79.43c0.12-0.41,0.23-0.83,0.57-1.84c1.13-3.28,2.04-5.96,2.95-8.64
		c-0.39-0.19-0.79-0.38-1.18-0.58c-0.95,1.4-1.9,2.8-2.84,4.2c-8.12,9.66-11.84,21.51-16.34,32.96
		c-6.52,16.58-12.19,33.55-19.69,49.68c-13.42,28.85-35.28,50.2-62.59,66.17c-4.6,2.69-9.13,5.52-13.8,8.1
		c-10.46,5.77-15.12,14.6-14.29,26.44c0.22,3.14,0.4,6.28,0.67,10.6c-29.54-16.62-58.08-33.43-90.75-43.02
		c48.78-51.6,90.83-106.87,116.34-173.07c-0.54-0.43-1.08-0.86-1.63-1.29c-6.08,6.83-13.83,12.78-17.96,20.64
		c-21.35,40.61-47.63,77.85-75.57,114.04c-8.76,11.34-17.59,22.64-26.17,34.11c-1.92,2.56-3.69,3.15-6.7,2.37
		c-24.94-6.42-50.17-7.35-75.84-5.67c-11.4,0.74-23.11-2.08-34.56-3.96c-7.68-1.26-15.16-3.7-23.2-5.73
		c0.56-0.92,0.81-1.71,1.34-2.17c20.78-18.04,36.73-40.09,50.54-63.51c12.58-21.34,23.84-43.52,34.57-65.87
		c3.58-7.45,3.74-16.54,5.46-24.88c-0.72-0.24-1.44-0.48-2.17-0.72c-1.36,2.69-2.84,5.32-4.06,8.07
		c-21.08,47.94-49.18,91.44-82.5,131.75c-7.82,9.46-14.9,15.3-29.09,13.64c-21.96-2.58-44.21,1.03-66.14,4.59
		c-1.89,0.31-3.81,0.48-6.76,0.85c20.3-43.3,39.71-85.83,45.15-132.94c-0.48-0.2-0.95-0.4-1.43-0.6c-0.71,1.5-1.51,2.97-2.1,4.51
		c-10.34,26.71-20.26,53.6-31.07,80.12c-16.15,39.59-41.58,73.15-70,104.6c-32.5,35.97-65,71.94-97.82,107.63
		c-14.68,15.97-30.49,30.82-49.11,42.28c-18.36,11.3-38.86,15.8-59.97,17.15c-23.27,1.48-46.61,1.84-69.92,2.82
		c-3.3,0.14-6.67,0.68-9.82,1.65c-4.86,1.5-7.1,5.75-5.47,10.13c7.02,18.87,14.07,37.73,21.32,56.51c2.02,5.23,4.77,10.18,7.44,15.8
		c-1.33,0.1-2.08,0.28-2.79,0.18c-12.9-1.69-23.37,3.2-30.19,13.65c-5.47,8.38-8.95,18.06-13.22,27.21
		c-0.67,1.43-0.87,3.08-1.4,5.09c-4.11-6.55-7.56-12.83-11.73-18.59c-16.3-22.5-38.03-21.25-52.07,2.82
		c-1.08,1.85-2.13,3.71-3.6,6.29c-5.88-11.07-12.63-20.39-25.14-23.27c-12.05-2.77-22.93,1.42-34.98,6.51
		c1.61-2.34,2.41-3.46,3.17-4.62c11.79-17.75,24.42-35.02,35.07-53.43c6.76-11.68,10.6-25.06,15.72-37.69
		c0.69-1.7,0.98-3.6,1.9-5.15c12.75-21.61,16.93-45.75,20.66-70c0.73-4.76,2.61-7.69,7.56-9.14c23.11-6.78,43.69-18.6,63.61-31.91
		c10.21-6.82,20.94-13.13,32.1-18.19c15.47-7.02,31.72-7.66,47.48-0.41c4.86,2.23,8.94,6.17,13.64,9.52
		c0.31-0.99,0.82-1.87,0.82-2.76c0.13-13.56,4.08-26.24,8.86-38.74c15.55-40.6,30.77-81.33,46.8-121.74
		c5.34-13.47,12.73-26.14,18.68-39.94c-1.47,1.39-3.28,2.56-4.34,4.21c-7.66,11.88-16.09,23.37-22.54,35.88
		c-21.16,41.04-38.25,83.9-54.32,127.14c-2.35,6.31-3.31,13.13-4.88,19.56c-22.25-14.43-44.5-9.2-67.3,2.18
		c4.49-14.77,7.86-29.03,8.41-43.77c-0.52-0.23-1.04-0.47-1.56-0.7c-2.89,5.22-6.5,10.18-8.51,15.72
		c-3.92,10.78-6.79,21.93-10.45,32.81c-0.82,2.44-2.73,4.94-4.83,6.45c-21.05,15.11-42.91,28.78-67.84,36.76
		c-5.55,1.78-8.79,5.65-10.14,11.33c-3.23,13.58-6.65,27.11-9.85,40.7c-0.63,2.68-1.53,4.53-4.55,5.05
		c-15.1,2.61-30.18,5.35-45.27,8.01c-0.77,0.14-1.58,0.02-3.23,0.02c8.28-14.11,16.25-27.68,24.21-41.25
		c-0.5-0.41-0.99-0.81-1.49-1.22c-5.93,6.05-12.57,11.57-17.58,18.3c-5.01,6.72-8.3,14.72-12.58,22.02
		c-0.87,1.49-2.71,3.28-4.22,3.39c-28.18,1.98-56.28,1.66-84.1-4.06c-0.16-0.03-0.29-0.15-1.09-0.58
		c20.1-15.2,42.14-26.74,63.29-40.42c-1.19,0-2.41-0.2-3.56,0.03c-25.74,5.06-47.23,18.18-66.38,35.51
		c-2.61,2.36-4.73,2.71-7.98,1.75c-34.9-10.34-58.02-32.22-67.16-67.97c-0.16-0.64-0.26-1.3-0.36-1.96
		c-0.02-0.15,0.07-0.32,0.26-1.05c21.28-1.57,42.7-1.77,65.34,1.84c-5.52-5.56-9.45-10.39-17.06-10.04
		c-14.61,0.68-29.25,0.74-43.88,1.09c-1.8,0.04-3.6,0.3-5.88,0.5c-1.91-9.81-1.27-19.33,0.51-28.82
		c1.96-10.43,5.84-20.03,11.78-28.92c8.04-12.04,15.34-24.58,23.47-36.55c14.11-20.79,28.61-41.32,42.97-61.95
		c0.17-0.24,0.57-0.31,1.39-0.73c3.59,9.94,7.14,19.8,10.83,30.01c4.95-13.16,1.79-24.17-6.23-34.05c-1.81-2.23-0.97-3.69,0.12-5.71
		c8.58-15.98,17.85-31.66,25.33-48.14c6.93-15.27,11.92-31.42,17.74-47.18c0.51-1.38,0.92-2.78,1.51-4.58
		c5.59,8.61,10.95,16.86,16.31,25.11c0.61-0.26,1.22-0.53,1.83-0.79c-1.04-4.03-1.42-8.39-3.27-12.01
		c-3.09-6.05-7.22-11.57-10.69-17.45c-0.92-1.55-1.74-3.74-1.39-5.36c3.49-15.95,5.77-31.98,7.4-48.29
		c4.01-40.09-2.92-77.93-19.11-114.41c-7.01-15.78-15.99-30.35-27.71-43.13c-2.33-2.54-4.41-5.31-7.22-8.72
		c11.52-0.97,21.94,0.41,32.34,2.33c17.47,3.21,30.94,13.22,43.65,24.88c39.04,35.8,61.32,80.31,66.84,132.61
		c2.08,19.72-0.03,39.89-0.52,59.85c-0.04,1.68-1.97,3.87-3.59,4.7c-0.81,0.42-3.27-1.39-4.39-2.7
		c-6.13-7.18-11.21-15.4-20.12-19.74c-0.57-0.28-1.18-0.48-3.26-1.32c0,9.62,0.49,18.55-0.09,27.41
		c-2.31,35.85-14.58,68.82-29.54,101.03c-9.33,20.09-18.47,40.28-27.04,60.7c-5.82,13.87-9.78,28.38-9.72,43.65
		c0.01,3.48,0.38,7,1.04,10.41c2.84,14.68,12.8,23.27,27.75,24.1c14.39,0.8,27.73-3.17,40.41-9.5
		c25.72-12.85,48.8-29.65,68.81-50.11c27.14-27.74,53.7-56.09,79.61-84.98c30.57-34.08,63.28-65.51,103.66-87.75
		c19.82-10.91,40.52-20.22,60.76-30.39c2.6-1.3,5.26-3,7.11-5.18c36.6-43.12,63.28-92.15,85.18-143.96
		c1.79-4.24,2.33-8.98,3.7-13.42c0.88-2.85-0.46-4.15-2.6-5.58c-16.32-10.9-34.6-15.26-53.88-16
		c-36.65-1.41-71.26,8.07-105.23,20.42c-10.47,3.81-20.59,8.59-30.86,12.93c-1.95,0.82-3.88,1.68-7.05,3.06
		c2.39-5.28,4.25-9.44,6.15-13.58c23.36-50.88,46.49-101.87,70.14-152.61c46.76-100.29,95.34-199.7,147.68-297.21
		c48.91-91.12,103.46-178.82,161.6-264.32c30.36-44.64,59.72-89.96,89.8-134.8c9.55-14.24,20.02-27.87,29.92-41.89
		c2.07-2.94,4.07-3.47,7.53-2.48c12.41,3.56,24.93,4.94,37.12-0.86c4.02-1.92,7.74-4.46,12.23-7.09c0.52,1.72,1.17,3.48,1.6,5.29
		c6.28,26.56,4.81,53.27,1.56,80.03c-5.11,42.03-16.34,82.65-28.12,123.15c-17.96,61.74-38.57,122.75-51.92,185.75
		c-4.45,20.99-7.09,42.37-10.21,63.63c-3.89,26.5,2.91,49.72,19.99,70.78c20.97,25.85,39.84,53.41,60.98,79.1
		c22.32,27.12,45.09,53.91,71.66,77.26c27.56,24.22,57.14,45.3,89.27,62.95c25.88,14.22,48.23,32.51,65.55,56.75
		c12.28,17.19,19.14,35.77,19.58,57.15c0.33,15.57,2.78,31.1,4.32,46.64c0.04,0.44,0.39,0.84,1.26,2.66
		c0.22-4.93,0.59-8.45,0.49-11.97c-0.46-16.65-1-33.29-1.6-49.94c-0.45-12.4-3.93-24.1-10.08-34.72
		c-6-10.34-12.27-20.75-19.94-29.83c-6.21-7.35-7.74-15.15-8.61-24.05c-1.84-18.82,3.6-36.32,8.69-53.89
		c18.18-62.78,50.49-117.63,95.07-165.06c31.12-33.11,68.1-57.52,112.82-68.74c24.36-6.12,49.23-8.84,74.23-9.34
		c31.31-0.63,62.64,0.05,93.96-0.23c7.56-0.07,15.1-1.74,22.65-2.65c1.15-0.14,2.39-0.53,3.44-0.25c9.71,2.58,19.19,5.7,26.68,12.89
		c0.84,0.81,1.77,1.57,2.43,2.51c20.35,28.78,50.66,43.48,81.38,57.61c1.36,0.63,2.71,1.32,4.12,1.8
		c19.34,6.56,32.57,19.68,42.38,37.37c15.92,28.74,37.43,52.52,65.29,70.38c7.56,4.84,14.59,10.52,21.76,15.96
		c6.78,5.15,10.82,12.02,12.63,20.36c1.55,7.16,3.06,14.38,5.41,21.3c1.14,3.34,3.57,6.9,6.44,8.87
		c21.31,14.62,45.39,22.88,70.06,29.76c10.84,3.02,21.51,6.69,32.76,10.22c-4.47,5.27-9.85,7.33-18.53,8.03
		c-37.54,3.03-74.53-1.12-111.22-8.67c-43.54-8.96-85.2-24.1-126.87-39.17c-1.04-0.38-2.11-0.7-3.27-1.08
		c0.36,6.11,15.88,23.82,42.89,49.14c16,15,33.29,28.17,54.91,38.98c-1.7,0.96-3.64,1.65-5.05,2.92
		c-13.73,12.35-25.46,26.31-34.3,42.63c-0.96,1.77-3.24,3.64-5.14,3.92c-5.21,0.76-10.69,1.6-15.8,0.79
		c-17.53-2.79-33.12-9.84-45.59-22.94c-14.43-15.16-29.7-29.48-48.86-38.4c-18.53-8.63-37.55-16.22-56.44-24.05
		c-9.72-4.03-19.24-8.17-27.62-14.82c-2.33-1.85-6.64-2.15-9.88-1.76c-13.23,1.6-26.37,3.86-39.58,5.6
		c-3.39,0.45-7.04,0.42-10.36-0.33c-4.99-1.11-5.39-2.85-3.01-7.38c4.35-8.25,8.69-16.51,12.82-24.87c1.11-2.25,1.48-4.86,2.19-7.31
		c-0.37-0.21-0.75-0.41-1.12-0.62c-0.8,0.65-1.76,1.17-2.36,1.97c-1.7,2.26-3.4,4.55-4.84,6.98c-3.05,5.15-6.86,8.34-13.14,10.08
		c-26.67,7.38-40.74,26.82-47.36,52.48c-5.17,20.02-5.85,40.5-5.17,61.05c0.12,3.62,0.32,7.23-0.04,11.1
		c-23.24-40.26-56.65-72.36-85.44-108.69c-0.62-0.22-0.88-0.13-1.14-0.04c0.3,0.07,0.6,0.15,0.9,0.22
		c3.04,6.66,5.09,14.04,9.29,19.87c17.52,24.28,35.97,47.88,53.5,72.16c8.37,11.59,15.49,24.08,23.03,36.25
		c1.09,1.76,1.87,3.99,1.99,6.05c1.82,30.77,3.93,61.52,5.12,92.31c1.33,34.27-5.26,67.65-13.47,100.7
		c-1.69,6.82-4.69,13.32-7.57,21.32c-25.06-64.39-65.92-117.5-100.75-174.16c-0.61,0.29-1.22,0.59-1.83,0.88
		c1.86,4.21,3.31,8.66,5.66,12.58c33.03,55.02,62.72,111.72,86.93,171.22c1.28,3.16,0.99,5.43-0.74,8.29
		c-13.86,22.84-31.62,42.22-53.36,57.53c-33.77,23.78-68.07,46.81-102.26,70c-13.37,9.07-26.99,17.77-40.62,26.45
		c-2.84,1.81-6.23,2.76-9.36,4.1c-0.32-0.36-0.63-0.71-0.95-1.07c0.9-1.61,1.64-3.35,2.73-4.82c21.86-29.4,43.63-58.87,65.7-88.11
		c15.16-20.09,30.9-39.75,42.77-62.08c10.09-18.99,18.37-38.65,17.42-60.75c-0.7-16.31-6.38-30.66-17.64-42.92
		c-8.37-9.12-15.84-19.06-23.94-29.18c-3.15-3.81-6.07-7.07-9-10.33c-0.03,0.12-0.07,0.23-0.26,0.92c1.92,3.65,3.9,7.76,6.18,11.7
		c6.31,10.87,12.74,21.67,19.13,32.49c0.35,0.72,0.7,1.44,1.25,2.74c14.5,22.99,13.83,46.12,3.57,69.75
		c-1.18,2.72-2.04,5.57-3.05,8.36c-0.87,1.63-1.73,3.25-2.99,5.36c-6.36,10.51-12.33,20.55-18.3,30.59c0,0-0.33,0.25-0.69,0.53
		c-0.72,1-1.07,1.72-1.43,2.43c0,0-0.3,0.23-0.67,0.52c-1.69,2.35-3.01,4.42-4.33,6.49c0,0-0.31,0.21-0.69,0.5
		c-5.07,6.67-9.77,13.06-14.46,19.45c-0.65,0.74-1.29,1.48-2.36,2.58c-1.08,1.41-1.74,2.46-2.4,3.51c-0.38,0.41-0.77,0.82-1.54,1.57
		c-0.78,1.02-1.16,1.71-1.55,2.4c-0.38,0.39-0.76,0.78-1.56,1.49c-1.08,1.39-1.75,2.45-2.42,3.52c0,0-0.32,0.21-0.69,0.47
		c-1.35,1.69-2.33,3.11-3.31,4.53c-0.38,0.39-0.76,0.78-1.55,1.51c-0.79,1.04-1.18,1.75-1.56,2.46c-0.37,0.38-0.73,0.76-1.49,1.48
		c-0.8,1.04-1.21,1.75-1.62,2.45c-0.65,0.72-1.3,1.45-2.38,2.54c-2.41,3.1-4.4,5.83-6.38,8.56c0,0-0.32,0.21-0.7,0.48
		c-1.31,1.69-2.25,3.12-3.19,4.56c0,0-0.34,0.25-0.72,0.52c-1.05,1.33-1.73,2.39-2.4,3.44c0,0-0.32,0.21-0.7,0.48
		c-2.03,2.69-3.67,5.1-5.32,7.51c-0.38,0.4-0.75,0.79-1.53,1.51c-0.76,1.05-1.11,1.77-1.46,2.49c0,0-0.32,0.21-0.68,0.48
		c-0.99,1.36-1.62,2.45-2.24,3.55c0,0-0.33,0.23-0.71,0.52c-4.68,6.68-8.98,13.08-13.29,19.48c0,0-0.31,0.27-0.66,0.55
		c-0.69,1-1.03,1.72-1.37,2.44c0,0-0.31,0.24-0.68,0.56c-7.37,12.35-14.37,24.39-21.37,36.43c0,0-0.27,0.3-0.61,0.63
		c-3.47,6.73-7.01,12.96-9.63,19.55c-3.77,9.5-6.88,19.26-10.27,28.92c-0.84,2.7-1.68,5.4-2.76,8.76c-0.9,4.19-1.56,7.72-2.21,11.24
		c-0.14,0.41-0.28,0.81-0.67,1.78C1219.1,1794.71,1218.37,1796.93,1217.65,1799.15z M1683.78,1095.8
		c11.48,6.41,22.52,13.82,34.51,19.05c52.88,23.04,106.84,43.06,163.54,54.73c18.86,3.88,37.8,7.48,56.83,10.36
		c16.92,2.56,34.01,4.12,51.06,5.76c6.05,0.58,12.21,0.09,18.32,0.09c0-0.46,0-0.92,0-1.38c-3.74-1.53-7.41-3.26-11.24-4.52
		c-3.93-1.29-8.01-2.1-12.03-3.12c-38.78-9.86-76.95-21.34-113.59-37.84c-40.06-18.04-76.38-41.39-108.04-71.84
		c-32.63-31.38-55.74-68.25-63.71-113.45c-3.06-17.36-13.73-29.02-27.1-39.11c-7.59-5.73-15.02-5.08-22.8-0.68
		c-12.33,6.97-22.81,16.13-32.07,26.75c-7.32,8.39-13.05,17.53-15.6,28.63c-4.28,18.66-2.71,36.58,6.81,53.22
		c5.01,8.76,10.91,17.16,17.36,24.93C1642.16,1066.8,1662.25,1081.63,1683.78,1095.8z M1793.63,1021.98
		c-9.84-14-22.05-25.29-37.72-32.44c-3.06-1.4-6.67-2.39-9.97-2.26c-4.51,0.18-6.04,2.99-4.42,7.24c2.72,7.13,5.56,14.23,8.82,21.11
		c0.9,1.91,3.27,3.67,5.35,4.38c11.76,3.99,23.62,7.7,35.5,11.32c1.49,0.45,3.99,0.39,4.82-0.52c0.85-0.93,0.49-3.27,0.13-4.88
		C1795.88,1024.72,1794.78,1023.7,1793.63,1021.98z M1528.79,1080.88c2.1,0,4.21,0,6.31,0c0.04-0.48,0.07-0.96,0.11-1.45
		c-15.89-11.2-29.97-24.12-40.23-41.46c17.19-3.71,34.08-6,50.75-12.98c-20.04-5.03-37.57,1.93-55.34,6.36
		c-1.18,0.3-2.5,3.48-2.29,5.13c0.3,2.34,1.75,4.64,3.07,6.74C1500.72,1058.4,1512.95,1070.93,1528.79,1080.88z M1560.58,973.87
		c5.69-0.29,11.37-0.58,17.06-0.88c0.07-0.6,0.15-1.19,0.22-1.79c-4.56-0.91-9.09-2.04-13.68-2.7c-13.63-1.95-27.29-3.72-40.93-5.53
		c-3.7-0.49-6,1.82-4.82,4.86c6.08,15.71,12.55,31.28,19.22,46.76c0.78,1.8,3.61,2.72,5.49,4.05c0.2-0.37,0.4-0.74,0.6-1.11
		c-6.16-15.01-12.32-30.03-18.62-45.37C1537.38,972.77,1548.48,973.32,1560.58,973.87z M1522.09,1098.51
		c-8.1,5.41-16.2,10.82-25.26,16.87c3.41,2.4,5.79,5.01,8.7,5.94c6.91,2.19,14.03,3.84,21.15,5.25c11.8,2.33,23.66,4.31,35.5,6.44
		c-15.36-5.39-30.98-9.6-46.56-13.98c-3.15-0.88-6.11-2.43-8.78-3.51c15-10.49,29.43-20.57,43.86-30.66
		c-0.28-0.67-0.55-1.34-0.83-2.01c-2.45,0.65-5.22,0.76-7.29,2.04C1535.81,1089.04,1529.28,1093.63,1522.09,1098.51z
		 M1387.3,1352.46c0.16,0.07,0.32,0.15,0.48,0.22C1387.75,1352.49,1387.7,1352.3,1387.3,1352.46z"
      />
      <path
        d="M506.96,1250.89c-13.21,32.8-26.29,65.23-39.3,97.7c-1.08,2.7-1.71,5.42-5.53,5.27
		c-3.85-0.15-6.3-2.01-7.41-5.55c-0.59-1.88-0.98-3.91-1-5.87c-0.71-52.12-16.57-99.86-42.1-144.73
		c-12.83-22.55-27.89-43.47-47.08-61.13c-30.44-28.03-65.81-45.76-107.33-49.44c-10.64-0.94-21.66,0.08-32.29,1.68
		c-18.05,2.72-35.92,6.61-54.38,10.1c0.71-1.27,1.48-2.75,2.36-4.16c57.21-92.07,124.61-176.22,198.37-255.43
		c64.62-69.39,133.64-134.01,208.55-192.25c90.53-70.39,187.4-130.17,293.39-174.59c29.67-12.43,59.85-23.51,90.94-31.95
		c2.22-0.6,4.47-1.1,7.37-1.81c-0.47,1.35-0.57,2.12-0.97,2.69c-25.23,36.23-50.73,72.27-75.66,108.7
		c-35.38,51.7-70.86,103.35-105.35,155.64c-38.55,58.45-73.63,119.02-106.7,180.73c-58.66,109.46-112.74,221.15-163.09,334.66
		C515.42,1230.9,511.31,1240.74,506.96,1250.89z"
      />
      <path
        d="M363.91,826.85c-44.58,47.61-86.74,96.8-126.07,148.42c-41.88,54.98-79.39,112.81-113.41,172.93
		c-8.29,14.65-15.75,29.77-24.43,44.18c-6.68,11.09-14.84,21.29-22.04,32.09c-7.48,11.21-14.88,22.49-21.75,34.08
		c-2.24,3.79-2.71,8.62-4.1,13.36c-4.09-7.15-4.72-15.01-5.24-22.77c-3.17-47.83-0.34-95.6,1.71-143.38
		c0.9-20.98,1.73-41.96,2.2-62.95c0.64-28.22,5.17-55.54,16.01-81.81c3.97-9.62,6.31-19.98,8.77-30.15
		c4.29-17.7,13.35-33,23.52-47.67c21.43-30.93,46.98-58.39,73.34-85.07c80.72-81.7,168.98-154.29,264.85-217.53
		c74.11-48.88,152.57-89.34,235.69-120.75c55.9-21.13,112.85-38.42,171.68-49.21c28.88-5.3,58-8.35,87.33-9.37
		c1.46-0.05,3.57,0.52,4.34,1.57c8.26,11.23,18.04,20.87,30.13,28.92c-1.17,0.56-1.68,0.97-2.25,1.06
		c-29.97,4.81-58.4,14.87-86.55,25.73c-72.29,27.88-140.05,64.55-205.1,106.4c-70.95,45.65-136.96,97.73-199.43,154.36
		C435.33,753.56,399.07,789.4,363.91,826.85z"
      />
      <path
        d="M1272.52,360.56c6.54,2.53,12.75,4.89,19.59,7.5c-0.59,1.84-0.93,4.16-2.01,6.05
		c-9.56,16.76-18.52,33.92-29.05,50.06c-32.07,49.13-63.3,98.77-91.13,150.42c-14.12,26.21-26.46,53.39-39.41,80.22
		c-15.78,32.69-31.4,65.47-47.01,98.24c-2.28,4.79-4.14,9.77-6.42,15.18c-0.8-1.01-1.28-1.61-1.26-1.58
		c-1.3,6.23-2.58,12.85-4.09,19.42c-2.22,9.66-0.33,18.94,2.79,27.97c8.85,25.58,15.81,51.92,28.08,76.19
		c13.36,26.42,27.44,52.47,41.35,78.6c14.17,26.62,28.56,53.12,42.66,79.77c0.86,1.62,0.73,4.5-0.15,6.17
		c-2.22,4.26-2.42,8.15,0.09,12.23c17.63,28.66,29.69,59.96,41.73,91.13c4.62,11.95,4.91,25.58,7.13,38.25
		c-1.4,0.5-1.8,0.78-1.96,0.68c-18.54-12.07-36.79-24.61-55.65-36.16c-36.5-22.34-69.23-49.2-98.38-80.43
		c-22.51-24.12-44.92-48.35-64.56-74.96c-8.8-11.92-17.83-23.68-26.82-35.46c-20.14-26.42-28.35-55.12-23.05-88.93
		c7.82-49.91,20.61-98.43,34.78-146.73c15.01-51.15,30.36-102.21,44.39-153.63c8.74-32.04,13.36-64.95,14.02-98.28
		c0.63-32.07-6.51-62.16-23.2-89.77c-0.5-0.83-0.85-1.74-1.53-3.13c3.5-1.61,6.68-3.35,10.05-4.58
		c35.68-12.96,71.5-25.56,107.06-38.84c18.84-7.03,37.43-14.81,55.79-23.02c6.55-2.93,12.09-8.11,18.36-12.45
		C1234.24,333.18,1250.41,349.48,1272.52,360.56z"
      />
      <path
        d="M1341.81,1678.74c31.76-19.63,62.55-40.02,92.73-64.07c-0.61,2.83-0.83,4.18-1.19,5.48
		c-7.47,27.21-12.74,54.78-13.91,83.05c-1.95,47.02,10.33,89.93,36.91,128.78c9.5,13.88,18.61,28.04,28.32,41.78
		c16.34,23.13,29.88,47.68,39.31,74.43c0.55,1.57,1.27,3.08,1.93,4.6c4.96,11.45,11.89,20.27,26.5,19.02
		c-4.74,7.55-9.15,14.59-13.77,21.94c-0.58-0.94-1.52-2.38-2.37-3.87c-4.64-8.17-9.9-15.83-17.74-21.34
		c-9.67-6.8-20.62-6.85-30.32-0.15c-8.65,5.97-14.61,14.29-18.83,23.56c-5.03,11.03-8.93,22.57-13.31,33.89
		c-0.58,1.5-1.05,3.04-2.51,4.65c-0.48-1.98-0.96-3.97-1.44-5.96c-3.28-13.61-6.39-27.31-13.9-39.41
		c-4.63-7.48-10.63-12.89-20.2-11.64c0.55-22.86-9.59-42.05-19.19-61.64c-17.64-36-46.2-62.83-73.96-90.42
		c-0.71-0.7-1.4-1.44-2.17-2.06c-16.08-12.81-24.27-29.6-25.55-50.01c-1.22-19.42-2.61-38.84-4.06-58.25
		c-0.26-3.49,1.05-5.27,4.1-6.95C1312.05,1695.9,1326.74,1687.36,1341.81,1678.74z"
      />
      <path
        d="M1507.46,772.39c42.9-27.4,70.6-63.42,69.3-116.61c-0.06-2.32-0.48-4.63-0.83-6.94
		c-0.34-2.23-0.8-4.44-1.21-6.66c0.42-0.16,0.84-0.32,1.26-0.48c1.98,4.04,4.57,7.89,5.84,12.14c9.03,30.14,18.05,60.29,26.58,90.57
		c12.9,45.79,25.39,91.7,38.06,137.56c0.43,1.55,0.8,3.12,1.46,5.69c-6.26,0.29-12,0.81-17.74,0.79
		c-31.14-0.08-62.31-0.98-93.43-0.27c-34.52,0.79-68.78,4.37-101.45,16.9c-4.19,1.61-8.32,3.37-11.95,4.85
		c-31.78-75.96-63.54-151.87-95.57-228.42c1.42,1.23,2.81,2.24,3.97,3.47c16.95,17.87,33.17,36.5,50.99,53.45
		c19.49,18.54,42.22,32.48,68.8,38.92C1470.53,781.95,1489.19,781.02,1507.46,772.39z"
      />
      <path
        d="M1357.44,776.59c17.61,41.62,35.05,82.89,52.53,124.15c1.75,4.12,3.73,8.14,5.84,12.72
		c-35.12,18-64.9,42.95-91.19,73.29c-8.8-7.51-17.59-14.57-25.89-22.17c-33.17-30.39-66.19-60.94-99.26-91.44
		c-0.22-0.2-0.29-0.55-0.34-0.63c10.29,0,20.79,1.02,31-0.24c14.69-1.82,25.98-10.84,35.94-21.16
		c22.35-23.16,36.31-51.16,45.88-81.55c7.86-24.97,9.41-50.64,8.09-75.17C1332.12,720.91,1344.7,748.58,1357.44,776.59z"
      />
      <path
        d="M1276.57,1058.51c-6.49,12.95-12.78,25.58-19.58,39.2c-22.54-12.85-45.13-25.73-67.92-38.72
		c43.99-54.78,39.14-112.56,9.01-173.18c40.22,37.3,79.49,73.72,118.01,109.45C1303.01,1016.2,1289.88,1037.2,1276.57,1058.51z"
      />
      <path
        d="M1024.27,426.26c-14.6,6.11-28.29,2.66-41.5-3.03c-16.44-7.08-29.16-19.08-40.26-32.85
		c-0.9-1.12-1.5-2.61-1.85-4.02c-5.36-21.64-15.84-40.84-27.86-59.38c-12.44-19.18-25.23-38.04-41.6-54.19
		c-0.44-0.43-0.66-1.07-1.9-3.15c4.54,0.31,8.11-0.03,11.34,0.86c32.8,9.02,60.25,26.89,83.92,51.02
		c19.1,19.47,34.85,41.51,50.17,63.96c5.87,8.61,12.5,16.58,16.7,26.35c2.25,5.22,1.74,8.56-2.6,11.67
		C1027.49,424.45,1026.02,425.24,1024.27,426.26z"
      />
      <path
        d="M1289.81,270.19c10.75,26.94,11.7,54.21,6.48,83.56c-7.94-3.4-15.14-5.92-21.78-9.46
		c-12.22-6.52-22.31-15.69-30.2-27.1c-1.13-1.63-1.88-4.51-1.25-6.24c10.07-27.36,10.23-55.4,6.27-83.77
		c-0.22-1.59-0.45-3.19-0.85-6.02C1267.84,233.67,1280.78,250.01,1289.81,270.19z"
      />
      <path
        d="M1215.61,1101.54c-5.46-9.67-10.73-19.01-15.75-27.91c17.49,10.62,35.61,21.61,53.65,32.56
		c-5.24,18.41-10.63,37.34-15.4,54.09C1231.13,1142,1223.47,1121.94,1215.61,1101.54z"
      />
      <path
        d="M1783.47,1321.66c-3.49-14.39-6.92-28.38-10.34-42.33c13.48,5.73,27.7,12.09,44.67,10.05
		c-3.62,4.61-6.8,8.53-9.83,12.55c-5.51,7.3-10.88,14.71-16.46,21.96c-1.31,1.7-3.24,2.92-4.88,4.36
		C1785.6,1326.19,1784.56,1324.13,1783.47,1321.66z"
      />
    </>
  );
}
