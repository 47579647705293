export default function Balor(): JSX.Element {
  return (
    <>
      <path
        d="M1109.68,2050.56c-7.77-13.53-4.55-28.57-7.76-42.64c-0.96-4.22,1.9-7.22,4.96-9.75c29.11-24.08,58.53-48.22,95.45-59
		c22.84-6.67,45.52-7.03,64.8,12.9c34.38,35.52,76.23,58.2,124.71,69.19c16.61,3.77,31.8,11.87,41.31,28.56
		C1326.03,2050.56,1218.45,2050.56,1109.68,2050.56z"
      />
      <path
        d="M400.56,2050.56c15.1-14.97,30.96-30.43,44.62-47.85c9.8-12.49,22.42-20.1,36.79-25.93c17.33-7.03,34.92-13.01,53.18-17.05
		c41.78-9.24,71.75,11.12,89.56,46.04c6.25,12.27-2.44,25.84-1.82,38.96c0.06,1.24-0.46,2.5-0.76,4.8
		C548.64,2050.56,475.2,2050.56,400.56,2050.56z"
      />
      <path
        d="M1275,901.29c-35.11,4.85-58.68,25.35-80.8,50.09c-66.66,74.54-134.29,148.21-201.72,222.07
		c-34.26,37.53-68.97,74.65-103.33,112.09c-8.36,9.11-19.51,8.46-29.73,7.95c-36.12-1.8-72.11-5.41-107.7-12.1
		c-12.45-2.34-19.06-13-21.18-32.62c-0.73-6.76-0.73-13.74-2.49-20.24c-7.11-26.24,8.24-42.78,23.96-60.07
		c23.96-26.34,38.87-58.32,52.59-90.71c3.42-8.08,8.68-15.49,9.25-26.56c15.65,11.31,29.38,22.27,45.09,30.2
		c20.45,10.33,40.81,20.65,63,27.1c21.67,6.3,42.61,2.89,63.69-0.78c0.01-4.76-2.5-4.93-4.42-4.89
		c-54.05,0.95-97.79-26.01-142.8-50.91c-16.97-9.39-18.53-20.26-14.53-35.95c1.33-5.22,2.54-10.68,0.91-17.58
		c-9.47,11.83-14.93,24.57-19.43,37.51c-12.57,36.12-29.09,70.38-49.01,102.86c-10.37,16.91-22.61,32.83-35.32,48.09
		c-13.26,15.93-14.27,32.23-7.69,51.06c8.82,25.24,17.11,50.74,23.9,76.58c3.56,13.54,6.64,27.27,12.53,39.84
		c6.18,13.21,15.44,24.46,30.64,28.39c14.15,3.66,23.75,13.43,34.19,22.86c14.18,12.8,27.88,25.77,38.64,41.63
		c7.9,11.64,6.27,22.45-3.41,33.22c-18.02,20.06-38.23,37.35-62.82,48.49c-16.21,7.34-33.34,14.61-50.99,3.25
		c-12.1-7.78-16.38-21.35-23.13-32.98c-17.61-30.35-30.81-63.51-56.86-88.43c-14.3-13.67-32.97-20.66-48.85-31.91
		c-32.87-23.28-55.93-53.96-69.2-91.97c-5.04-14.44-7.94-29.28-10.86-44.34c-4.12-21.25,3.99-35.61,16.3-50.63
		c12.21-14.9,26.84-28.31,32.81-47.72c1.41-4.6,2.23-8.97,2.21-13.69c-0.19-45.28,15.1-84.57,45.82-117.84
		c1.74-1.88,3.21-4.04,5.11-5.72c12.83-11.31,13.98-24.42,11.15-41.09c-2.9-17.1-8-33.84-9.62-51.02
		c-3.01-31.95,9.93-59.18,29.66-82.98c26.16-31.55,56.32-58.86,93.06-77.85c45.22-23.37,92.41-36,143.77-24.75
		c9.39,2.06,19.2,2.23,27.77,3.16c4.22-4.29,0.18-6.49-0.02-10.67c28.95-14.64,55.09-34.59,81.16-54.74
		c10.78-8.33,22.66-16.08,27.77-28.75c6.4-15.84,21.68-22.89,30.87-35.6c19.64-27.19,20.07-68.23-5.15-87.79
		c-10.59-8.22-22.33-7.29-34.05-7.17c-11.68,0.12-22.64-1.37-33.79-7.56c12.12-15.59,32.29-13.43,46.35-23.51
		c-4.32-6.76-13.26-6.19-16.29-14.43c9.54-9.15,21.4-13.28,34.15-9.88c9.15,2.44,14.83-0.46,20-6.5c3.93-4.59,8.61-8.08,13.35-11.63
		c6.03-4.5,10.99-5.66,17.79,0.29c8.83,7.73,20.33,5.71,30.41,2.37c6.49-2.15,11.17-2.32,17.86,0.09c10.85,3.91,21.1,9.22,34.85,2.8
		c11.02-5.14,25.76,1.73,36.49,7.84c28.25,16.07,56.56,32.82,74.23,61.78c7.62,12.5,6.88,27.89,13.35,40.92
		c1.85,3.73,1.8,8.38,3.56,12.18c7.16,15.44,5.62,30.73-4.18,43.57c-11.9,15.59-5.05,28.12,3.75,40.48
		c11.94,16.76,22.7,33.71,24.23,55.02c0.78,10.87,0.91,20.72-9.09,28.56c-7.29,5.71-8.21,14.87-5.91,23.5
		c3.22,12.09,12.42,6.62,19.67,5.35c7.94,23.04-9.11,47.59-32.69,46.22c-31.11-1.8-62.05-4.63-92.94,2.55
		c-11.54,2.68-23.48,3.66-35.26,5.23c-9.68,1.29-17.57-2.12-23.44-9.92c-22.38-29.72-49.94-53.64-82.15-72.02
		c-8.65-4.94-12.58-11.01-11.48-20.56c2.05-17.84,2.99-35.66-0.19-53.47c-0.5-2.77,0.45-6.15-4.48-7.79
		c-3.9,4.87-1.86,11.04-2.77,16.45c-2.2,13.08-1.26,26.47-2.84,39.49c-2.51,20.69,6.53,32.78,24.17,41.04
		c25.49,11.94,45.96,30.48,64.38,51.3c6.18,6.99,13.31,13.01,18.89,20.72c6.22,8.61,16.53,7.79,25.9,6.64
		c32.18-3.96,64.39-6.55,96.86-5.02c7.85,0.37,14,1.6,20.31,7.86c11.06,10.97,27.04,13.63,41.31,18.71
		c52.41,18.68,76.2,65.64,58.32,119.29c-6.17,18.51-11.66,37.19-19.82,54.96c-3.92,8.54-2.29,17.72-1.59,26.45
		c2.21,27.56,5,55.08,8.16,82.55c1,8.68,1.79,17.63-1.74,25.2c-10.16,21.77,1.04,36.63,14.97,50.57
		c17.8,17.82,32.81,37.36,42.76,60.74c2.6,6.11,7.04,11.69,11.57,16.68c6.27,6.91,17.42,3.76,24.85-6.19
		c10.8-14.47,23.46-25.13,43.28-24.21c11.51,0.53,17.03,6.13,19.13,16.57c0.25,1.25,0.58,2.62,0.31,3.81
		c-5.27,23.68,6.34,38.44,25.31,50.23c12.5,7.77,20.94,20.1,28.95,32.07c11.49,17.17,7.78,35.54-0.85,52.29
		c-15.64,30.38-38.52,53.37-71.96,64.3c-5.14,1.68-9.41,2.33-14.59-0.21c-34.83-17.07-66.47-38.9-95.52-64.41
		c-17.18-15.09-36.96-24.37-59.2-27.62c-27.22-3.98-52.09-14.72-77.48-24.2c-22.77-8.5-44.82-17.93-62.54-35.51
		c-5.6-5.56-8.4-10.92-8.22-18.88c0.46-20.9-0.67-41.85,0.41-62.7c0.87-16.68-0.45-33.72-9.23-46.82
		c-12.33-18.39-4.44-32.88,3.15-48.21c9.02-18.22,20.13-35.39,27.25-54.6c2.38-6.43,6.83-11.61,12.08-16.24
		c19.95-17.64,39.26-36.03,59.73-53.03c7.35-6.1,9.25-13.85,12.17-21.55c13.48-35.67,26.79-71.41,39.91-107.22
		c1.42-3.87,1.27-8.31,2.4-16.49C1331.34,880.79,1303.53,890.44,1275,901.29z M1163.82,730.73c-4.12,8.11-3.01,15.87,2.77,22.46
		c6.84,7.81,16.33,11.24,26.03,8.32c10.32-3.11,6.59-12.66,5.19-19.67c-2.03-10.17-0.5-20.35-1.64-30.46
		c-0.95-8.48-0.48-17.32-5.35-26.19C1177.71,698.12,1168.93,712.1,1163.82,730.73z M1214.19,611.25c2.62-4.4-1.16-6.48-3.63-8.19
		c-12.89-8.92-24.93-19.14-39.63-25.32c-6.21-2.61-12.49-3.61-18.66-1.76c-6.62,1.99-12.68,5.61-13.62,13.59
		c-0.92,7.79,2.87,13.1,9.72,16.25c3.83,1.76,7.8,3.38,11.87,4.44C1177.44,614.71,1194.94,612.43,1214.19,611.25z M1278.92,620.04
		c10.61-0.95,19.77-4.5,25.06-14.53c3.43-6.49,2.61-11.98-3.81-16.55c-6.15-4.38-11.65-4.64-16.88,1.05
		c-6.85,7.44-11.91,15.98-15.86,24.76C1270.23,618.55,1273.54,619.25,1278.92,620.04z M1186.26,882.48
		c36.17-6.72,72.34-13.44,108.52-20.16c-0.37-1.8-0.74-3.59-1.1-5.39c-40.58-1.64-77.71,14.57-117.7,21.99
		C1179.38,884.46,1181.93,883.65,1186.26,882.48z M1260.21,700.52c2.73-0.49,3.28-2.33,2.67-4.65c-2.61-9.9-7.26-18.91-13.58-26.81
		c-4.68-5.85-11.54-10.89-18.18-3.87c-5.98,6.33-2.29,13.54,3.3,18.9C1241.46,690.84,1250.09,695.37,1260.21,700.52z
		 M1279.64,665.11c-3.66,10.32-7.63,20.57-9.18,35.22c9.82-9.06,16.34-15.78,21.11-24.16c2.02-3.54,3.02-7.23,0.68-10.74
		C1289.32,661.05,1285.28,661.37,1279.64,665.11z"
      />
      <path
        d="M444.46,577.91c-19.05,46.88-33.68,94.15-46.86,142c-8.35,30.29-16.05,60.72-22.69,91.36
		c-6.73,31.06-12.03,62.4-14.78,94.25c-3.21,37.25-11.11,74.17-12.97,111.44c-2.04,40.92-10.91,80.84-13.33,121.7
		c-2.49,42.19-5.55,84.17-2.17,126.4c1.17,14.57,5.42,29.14,1.25,45.22c-16.77-12.24-27.98-28.09-39.21-43.71
		c-26.6-36.99-43.39-78.96-55.8-122.13c-15.22-52.94-26.48-106.98-38.44-160.81c-9.05-40.75-14.78-82.86-34.17-120.94
		c-7.74-15.2-19.8-23.49-37.86-26.26c10.36-20.54,19.7-40.21,30.06-59.31c16.96-31.27,34.12-62.45,52.05-93.17
		c53.55-91.74,116.5-175.82,201.02-241.78c57.84-45.14,119.66-84.29,184.04-119.41c6.77-3.69,10.81-1.83,21.96,9.2
		c-38.81,34.41-71.9,74.07-103.74,114.73c-10.14,12.95-17.92,27.83-26.15,42.19C470.46,517.16,456.85,546.73,444.46,577.91z
		 M289.07,743.08c2.35-9.45,4.35-18.92-7.17-24.11c-10.34-4.66-21.88-7.01-29.17,3.36c-10.39,14.78-13.69,32.21-8.52,49.9
		c3.18,10.89,12.24,12.99,20.9,5.64C275.62,768.95,284.51,758.66,289.07,743.08z"
      />
      <path
        d="M703.13,447.83c0,49.05,23.08,85.96,55.3,118.61c19.3,19.56,19.39,19.91-3.94,33.96c-6.64,4-9.91,10.05-14.17,15.09
		c1.19,5.12,5.16,5.1,8.28,5.61c5.02,0.83,10.33,1.96,15.22,1.14c36.57-6.14,66.17,6.83,94.82,29.03
		c26.75,20.73,56.15,38.87,93.54,35.61c-16.03,19.81-43.58,30.09-65.77,25.35c-13.51-2.88-27.27-2.5-40.76-0.83
		c-64.68,8-119.56,36.36-166.21,81.38c-16.17,15.61-29.35,33.77-42.65,51.85c-40.81-20.81-78.34-14.59-112.81,11.56
		c-20.63,15.66-32.51,37.89-41.66,62.13c-6.08,16.1-9.69,32.92-16.11,48.94c4.14-54.31,7.48-108.43,17.03-162.19
		c11.16-62.81,26.46-124.39,44.26-185.51c11.15-38.26,23.15-76.21,37.24-113.49c12.86-34.01,24.5-68.53,38.69-101.98
		c10.39-24.48,22.69-48.39,37.68-70.47c5.65-8.31,17.9-8.09,26.57-13.4c5.29-3.24,8.79-13.04,16.56-9.24
		c8.78,4.3,12.83,14.23,15.43,22.69c4.96,16.19,7.39,33.09,6.6,50.37C705.3,404.9,701.97,425.63,703.13,447.83z"
      />
      <path
        d="M1653.99,419.94c-29.24-49.32-63.33-93.85-102.03-135.73c7.11-3.76,10.56,1.84,14.33,5.01
		c20.89,17.58,38.22,38.71,55.73,59.38c27.55,32.51,57.86,62.21,87.88,92.28c39.66,39.71,75.69,82.44,107.66,128.68
		c13.75,19.89,27.87,39.55,38.12,61.42c11.89,25.37,17.6,52.89,25.18,79.75c7.05,24.98,12.69,50.38,15.82,75.97
		c5.23,42.62,11.56,85.22,12.09,128.3c0.03,2.43,0.78,5.17-1.77,7.17c-3.18,0.66-5.52-1.39-8.01-2.88
		c-13.49-8.12-19.26-7.33-29.13,4.67c-44.4,53.94-88.99,107.74-132.98,162.02c-19.98,24.65-38.31,50.68-62.86,71.24
		c-2.83,2.37-4.77,6.09-10.12,6.97c0.75-9.64,6.97-16.88,9.76-25.14c8.58-25.35,16.41-50.91,22.27-76.94
		c9.12-40.5,17.02-81.22,23.38-122.33c5.36-34.61,7.81-69.35,10.48-104.07c4.39-57.01,3.1-114.01-2.12-171.08
		c-2.68-29.28-6.1-58.4-12.3-86.94c-11.11-51.14-29.23-99.99-54.37-146.06C1658.98,427.92,1656.6,424.4,1653.99,419.94z"
      />
      <path
        d="M1029.3,1602.72c-11.79,36.59-17.65,73.56-26.81,109.71c-2.83,11.15-7.1,21.7-12.78,34.71
		c-4.73-12.27-2.73-23.59-7.58-33.28c-2.96-5.91-2.84-15.73-10.11-16.23c-8.64-0.59-10.05,9.57-11.91,15.94
		c-4.51,15.4-7.37,31.28-11.25,46.87c-4.58,18.38-9.22,36.78-18.47,53.35c-1.8-0.39-2.92-0.28-3.35-0.77
		c-14.42-16.37-16.92-15.74-28.46,3.72c-4.98,8.39-5.27,18.59-12.65,29.32c-1.66-15.79-2.86-28.72-4.42-41.61
		c-5.14-42.58-8.8-85.31-21.05-126.83c-13.6-46.11-21.26-93.43-25-141.42c-0.91-11.63,1.19-20.53,10.94-27.72
		c5.76-4.25,10.39-10.07,15.38-15.33c18.28-19.29,20.75-37.49,4.09-58.04c-19.11-23.57-38.99-46.37-69.32-57.89
		c-19.22-7.3-33.46-21.79-38.93-43.92c-3.41-13.79-10.02-26.9-13.45-42.11c40.05,7.32,79.37,16.5,122.12,15.58
		c-6.88,11.87-15.64,17.98-23,25c-8.14,7.77-7.37,13.13,0.64,19.67c17.35,14.18,34.5,28.62,52.35,43.46
		c9.78-5.8,16.45-15.36,24.78-23.02c21.9-20.13,43.5-40.58,65.08-61.05c2.25-2.13,4.21-4.3,7.48-4.19
		c27.88,0.91,55.08-5.28,82.63-7.85c2.43-0.23,4.93,0.32,8.45,0.59c0.83,7.89,2.73,15.5,2.21,22.94
		c-2.07,29.24-2.1,58.55-7.25,87.69c-5.62,31.81-13.09,63.18-21.65,94.13c-6.87,24.83-15.46,49.31-22.93,74.06
		C1032.7,1586.01,1031.21,1593.83,1029.3,1602.72z M860.09,1417.07c12.88,10.02,21.76,24.73,38.86,33.18
		c6.23-7.03,12.58-14.19,18.79-21.21c-0.5-3.73-3.38-5.16-5.52-7.13c-18.67-17.21-36.87-34.97-56.21-51.39
		c-16.45-13.96-30.03-30.95-47.53-43.74c-3.99-2.92-7.55-5.84-12.58-3.21c-15.57,8.12-16.39,16.84-3.12,28.81
		C815.45,1372.81,837.37,1394.04,860.09,1417.07z"
      />
      <path
        d="M1601.33,377.25c30.9,46.85,58.36,94.6,75.52,147.24c17.66,54.19,27.33,109.85,30.42,167.13
		c3.38,62.75,1.86,125.29-1.06,187.83c-1.38,29.54-7.27,58.87-11.81,88.23c-3.71-24.2-3.61-48.68-13.77-71.78
		c-6.9-15.71-16.25-29.21-29.95-38.41c-18.36-12.32-36.75-7.15-53,5.82c-23.25,18.57-39.4,42.99-54.44,68.2
		c-4.68,7.85-9.09,15.86-14.91,26.06c-3.68-17.19,3.06-30.78,3.84-44.83c2.35-42.46,12.81-84,13-126.83
		c0.11-25,3.76-50.05,5.22-75.15c2.41-41.44-0.65-82.62-3.06-123.88c-1.11-19.11-0.66-18.68,18.16-20.38
		c11.51-1.04,23.5-1.44,33.98-8.13c-2.54-7.6-9.05-9.52-14.27-10.33c-11.92-1.84-22.7-7.59-34.41-9.32
		c-12.21-1.8-13.76-8.48-14.23-19.37c-2.18-50.16,0.02-100.55-7.28-150.46c-3.19-21.82-3.43-44.13-11.74-65.08
		c-1.86-4.7-2.82-10.36,3.19-13.46c5.1-2.62,9.82-3.34,15.27,0.9c17.7,13.76,29.83,32.08,42.24,50.16
		C1586.09,352.86,1593.26,364.79,1601.33,377.25z"
      />
      <path
        d="M1642.5,1289.05c-13.78,10.84-24.76,23.12-34.69,37.87c-14.53-11.37-25.8-24.81-41.59-34.45
		c13.96-26.15,31.31-49.56,53.86-68.05c48.19-39.52,103.61-52.23,163.45-34.54c47.09,13.93,87.97,39.37,112.43,83.99
		c17.69,32.26,20.8,67.3,14.96,103.9c-7.53,47.16-38.2,78.01-71.37,107.49c-34.97,31.08-72.22,59.15-113.5,81.4
		c-33.97,18.31-69.2,33.99-105.29,47.7c-38.3,14.55-76.6,29.68-102.31,63.81c-26.13,34.69-49.77,71.47-54.16,115.93
		c-5.73,58.07,22.56,87.59,80.66,98.52c27.07,5.09,54.63,4.81,81.38-0.27c32.39-6.15,60.17-22.28,82.42-47.35
		c14.32-16.13,20.18-34.92,20.3-56.02c0.02-2.82-1.06-6.03,1.7-8.33c14.49,0.67,24.25,15.96,24.75,28.86
		c0.93,23.93-10.15,42.52-24.82,59.64c-24.26,28.31-56.01,45.5-90.93,55.55c-32.76,9.43-66.3,13.6-100.8,4.89
		c-31.33-7.91-59.45-20.09-80.55-45.21c-28.96-34.47-37.96-74.52-30.21-118.21c6.44-36.31,20.04-70.31,42.64-99.45
		c18.21-23.49,41.78-41.98,66.85-58.05c51.44-32.98,108.08-55.91,162.25-83.62c33.05-16.91,66.49-33.63,94.44-59.22
		c32.6-29.86,55.25-65.3,56.39-110.01c1.19-46.48-16.1-75.03-61.08-96.91c-43.05-20.94-82.53-8.89-120.88,13
		C1659.98,1276.94,1651.67,1282.86,1642.5,1289.05z"
      />
      <path
        d="M1379.62,693.56c-7.2-2.04-8.85,3.93-12.28,7.33c-3.26,3.23-5.94,7.29-13.29,8.15c0.26-29.49-15.43-52.47-32.65-75.58
		c16.72-12.75,31.27-25.95,45.41-39.73c9.2-8.96,24.97-6.83,37.93-8.52c37.53-4.91,75.17-8.94,112.71-13.71
		c14.7-1.87,15.06-2.02,15.1,13.2c0.18,68.62-2.16,137.08-6.57,205.64c-3.77,58.6-14.5,116.51-15.22,175.62
		c-0.34-16.03-6.87-29.98-12.36-44.3c-7.39-19.3-27.01-31.91-45.6-28.84c-9.58,1.58-15.29,10.47-26.23,13.89
		c-10.6-54.31-59.19-65.94-100.2-88.9c16.72-9.9,27.67-22.73,29.33-41.6c0.04-0.42,0.35-0.89,0.24-1.24
		c-7.07-23.49,11.25-38.73,18.67-57.51C1377.55,710.02,1382.38,703.03,1379.62,693.56z"
      />
      <path
        d="M1204.5,1475.74c9.1,11.15,14.49,22.86,15.94,36.4c4.4,41.01,22.17,78,34.98,116.5c6.34,19.04,3.17,38.86,2.01,58.29
		c-0.35,5.91,0.43,12.81-3.8,18.72c-9.16-5-12.64-14.73-19.42-21.5c-17.68-17.67-40.38-19.21-58.44-1.92
		c-29.48,28.22-55.25,59.78-81.11,91.24c-10.42,12.68-21.21,25.06-31.68,37.39c-4.86-0.79-5.37-4.74-6.56-7.62
		c-12.79-31.01-21.7-62.48-13.32-96.58c4.45-18.1,2.56-36.37-1.21-54.52c-2.98-14.37-1.87-28.37,2.44-42.75
		c13.15-43.77,25.37-87.76,35.27-132.48c5.22-23.59,6.55-48.09,15.15-70.88c3.23-8.55-0.38-17.9,4.84-28.57
		C1137.76,1407.28,1173.16,1438.63,1204.5,1475.74z"
      />
      <path
        d="M684.97,1868.03c-11.73,21.56-25.21,41.12-34.12,63.12c-5.5,13.57-12.35,26.59-16.19,42.67
		c-43.29-50.84-93.74-46.11-148.38-21.68c4.96-16.9,14.48-29.96,18.98-45.22c8.51-28.86,15.69-58.05,23.49-87.08
		c6.03-22.45,15.06-44.09,22.48-66.17c3.49-10.38,13.74-11.27,21.35-14.26c18.21-7.14,33.78-19.13,51.98-26.15
		c26.86-10.36,53.16-22.23,80.2-32.06c20.23-7.35,28.21-2.21,35.54,17.52c8.14,21.89,8.71,44.97,11.85,67.66
		c1.71,12.4-8.56,19.19-14.72,27.18c-16.83,21.85-33.08,44-47.07,67.79C689.08,1863.52,687.13,1865.29,684.97,1868.03z"
      />
      <path
        d="M707.92,1657.07c-49.5,15.33-95.69,35.56-139.21,62.46c8.51-21.87,18.41-42.15,36.65-56.97
		c13.75-11.17,22.97-25.93,31.75-40.85c10.12-17.19,3.8-35.7,2.72-53.72c-1.45-24.22-5.72-48.09-8.71-72.12
		c-2.64-21.23-0.33-42.43,7.02-62.92c3.5-9.75,7.13-11.19,14.95-3.83c9.88,9.3,19.32,19.15,24.04,32.2
		c9.41,26.05,28.5,45.86,43.55,68.26c16.81,25.03,36.4,29.72,65.09,18.68c13.15-5.06,24.46-13.21,39.06-20.17
		c-6.19,45.17,7.38,88.3,3.52,132.04c-2.02,22.87-16.06,40.67-28.75,58.63c-5.8,8.22-12.94,15.5-20.72,24.66
		c-4.48-17.19-8.26-32.91-12.7-48.45c-4.91-17.17-12.71-32.27-31.02-38.98C726.23,1652.74,717.58,1653.89,707.92,1657.07z
		 M707.34,1551.04c4.17-3.23,8.74-7.04,5.63-12.66c-7.83-14.15-16.42-27.89-26.1-44.15c-4.92,5.85-7.85,9.96-11.4,13.45
		c-25.95,25.5-28.92,18.63-1.56,49.49c6.67,7.53,13.87,6.86,21.49,2.23C698.99,1557.21,702.24,1554.45,707.34,1551.04z"
      />
      <path
        d="M388.68,828.44c5.71-35.42,13.87-69.32,23.03-102.79c17.77-64.93,36.75-129.38,70.21-188.88
		c23.25-41.33,45.44-83.13,74.6-120.67c15.77-20.3,32.45-39.87,53.37-59.7c-86.71,196.95-150.09,396.47-153.72,610.94
		c-10.39-22.77-17.02-47.32-27.29-70.55c-10.28-23.26-20.14-35.24-43.85-43.89C383.23,844.79,387.1,837.28,388.68,828.44z"
      />
      <path
        d="M1148.02,1744.44c15.69-16.59,29.38-33.84,47.18-47.13c6.11-4.57,10.83-4.05,15.76,0.72
		c16.45,15.93,26.26,36.24,36.53,56.2c2.04,3.95,0.1,8.35-0.65,12.54c-4.87,27.17-9.33,54.41-14.54,81.51
		c-3.26,16.99-1.11,34.02-1.84,51.02c-0.25,5.83-0.3,11.18-7.95,12.22c-47.9,6.53-84.19,33.83-117.79,65.94
		c-0.9,0.87-2.04,1.49-3.77,2.73c-3.99-4.49-4.39-9.93-4.28-15.23c0.6-28.53-9.31-55.06-15.33-82.33
		c-2.84-12.9-6.99-25.29-11.75-37.59c-3.51-9.06-0.82-16.31,6.47-23.77C1100.36,1796.39,1123.65,1770.53,1148.02,1744.44z"
      />
      <path
        d="M172.72,718.58c-11.44,21.84-22.34,42.92-33.09,64.07c-6.22,12.24-12.21,24.59-19.33,38.96
		c-4.69-18.14,4.28-31.36,9.03-44.63c11.72-32.76,24.04-65.35,32.44-99.07c7.7-30.93,10.45-62.81,12.93-94.61
		c3.4-43.79,24.96-75.08,62.6-98.25c62.55-38.5,126.52-74.35,191.76-107.86c46.63-23.95,93.15-48.44,142.39-66.98
		c5.47-2.06,9.32-7.5,15.71-7.83c3.9,2.88,3.72,7.45,6.11,12.25c-42.66,16.74-80.81,41.02-118.77,65.62
		c-57.94,37.56-111.37,80.48-158.5,131.24c-41.15,44.32-75.05,93.87-107.28,144.66C196.04,676.16,185.08,697.25,172.72,718.58z"
      />
      <path
        d="M1003.54,610.95c-78.18-3.73-155.12-1.7-232.75-2.73c18.43-7.86,36.86-15.73,55.3-23.57
		c44.84-19.06,92.4-30.23,137.82-47.58c16.55-6.32,32.02-15.5,49.89-18.17c2.52-0.38,5.05-0.81,7.59-1
		c32.75-2.38,46,10.63,46.34,43.42c0.19,18.82-6.14,33.6-20.7,45.32c-4.31,3.48-8.79,4.7-14.11,4.56
		C1023.56,610.95,1014.18,611.01,1003.54,610.95z"
      />
      <path
        d="M1452.53,405.26c9.92-36.26,12.45-72.96,21.98-108.2c1.42-5.26,1.65-14.3,9.31-12.94c8.03,1.42,17.91,3.07,19.55,14.93
		c3.95,28.62,10.35,57.03,11.01,85.95c0.95,41.4,5.74,82.44,9.63,123.56c2.43,25.72,1.86,27.24-22.91,22.25
		c-22.4-4.52-45.35-5.94-67.45-12.14c-6.9-1.94-14.14-3.13-20.85-9.95C1436.35,478.84,1445.44,442.96,1452.53,405.26z"
      />
      <path
        d="M1166.42,1145.92c15.05-13.41,29.47-26.17,43.89-38.94c0.58,0.37,1.17,0.74,1.75,1.1c-6.84,12.64-13.79,25.23-20.49,37.94
		c-12.58,23.86-18.6,49.92-25.06,75.81c-3.88,15.58-7.68,31.18-11.54,46.76c-1.26,5.1-4.07,7.67-9.81,8.06
		c-45.33,3.12-90.07,12.01-137.02,13.19C1060.97,1241.84,1113.38,1194.2,1166.42,1145.92z"
      />
      <path
        d="M1543.91,553.26c-2.09,7.08-7.99,2.71-12.21,3.97c-4.84,1.45-10.02,1.87-15.09,2.47c-43.45,5.18-86.93,10.11-130.34,15.55
		c-12.23,1.53-24.27,4.51-36.39,6.88c-10.74,2.1-16.69-0.82-20.66-12.54c-6.75-19.93-6.77-40.78-12.82-62.51
		c13.05-0.38,24.45,2.54,35.42,5.52c52.46,14.25,105.93,23.9,158.99,35.35C1521.47,550.25,1532.8,549.47,1543.91,553.26z"
      />
      <path
        d="M665.89,267.82c18.09,23.52,3.52,37.26-15.98,45.36c-20.44,8.49-40.67,2.23-51.32-14.96c-3.58-5.78-4.94-12.07-3.5-18.39
		c3.66-16.11,7-32.41,12.34-47.98c4.37-12.74,4.38-26.85,12.75-40.11C643.49,212.58,654.64,239.65,665.89,267.82z"
      />
      <path
        d="M1433.58,1533.46c-7.12,6.68-14.69,10.43-23.57,10.39c-7.74-0.04-15.85-0.83-19.82-8.85c-3.78-7.64-3.49-15.81,1.04-23.17
		c11.54-18.74,23.25-37.38,35.11-55.92c6.86-10.72,12.65-16.08,29.65-6.67c7.25,4.01,13.39,9.27,18.92,14.78
		c8.04,8.02,6.33,18.04-0.15,26.54C1462.87,1506.15,1448.76,1519.71,1433.58,1533.46z"
      />
      <path
        d="M1604.69,174.23c-11.41,10.87-25.83,17.06-34.82,30.82c-6.86,10.51-12.64,21.48-15.87,33.04
		c-3.14,11.23-9.43,17.8-19.16,21.75c-11.83,4.8-24.09,8.19-37.13,8.14c-10.58-0.05-12.17-6.2-7.73-13.22
		c21.61-34.25,47.73-64.33,86.09-80.23C1584.19,171.14,1594.85,165.63,1604.69,174.23z"
      />
      <path
        d="M1157.68,1357.64c4.06-0.89,6.79-0.52,8.62,2.57c11.93,20.17,27.65,38.32,31.87,62.55c1.25,7.19,1.79,14.12,0.82,22.99
		c-29.82-29.88-59.1-58.47-96.04-81.33C1122.22,1362.05,1139.5,1359.92,1157.68,1357.64z"
      />
      <path
        d="M1106.92,1346.67c-3.25-1.59-4.84-3.6-4.86-6.34c-0.11-13.93-0.32-27.84-4.89-42.89c15.53-1.44,29.28-2.78,43.04-3.96
		c5.28-0.45,9.06,2.08,10.99,6.94c2.51,6.3,5.46,12.54,6.98,19.09c3.59,15.56,0.58,19.06-15.29,21.28
		C1131.13,1342.43,1119.49,1344.84,1106.92,1346.67z"
      />
      <path
        d="M275.51,701.96c-19.5,2.04-36.67,18.15-39.93,37.48c-0.26,1.56-0.36,3.38,0.76,4.48c0.81,0.79,2.02,0.97,3.14,1.04
		c7.55,0.47,15.24-2.35,20.69-7.6c6.58-6.34,11.14-16.59,20.25-17.37c-2.27,11.18-4.54,22.35-6.81,33.53
		c-1.1,5.43-2.27,11.01-5.38,15.59c-7.59,11.16-24,12.86-32.73,23.14c4.2,7.07,13.81,8.3,22.03,8.15c2.03-0.04,4.14-0.13,5.92-1.11
		c2.07-1.14,3.34-3.27,4.77-5.14c10.55-13.78,32.2-15.45,42.07-29.73c3.44-4.97,5.03-10.95,6.56-16.79
		C320.2,734.85,323.54,722.07,275.51,701.96z"
      />
      <path
        d="M213.3,779.63c19.56,3.52,41.49,6.56,57.78-4.82c7.26-5.07,12.52-12.52,19.44-18.04c3.79-3.03,8.16-5.56,10.84-9.6
		c3.25-4.9,3.47-11.16,3.2-17.03c-0.34-7.37-1.32-14.86-4.5-21.52c-5.33-11.15-17.03-18.98-29.38-19.65
		c-12.34-0.67-24.82,5.85-31.33,16.36"
      />
    </>
  );
}
