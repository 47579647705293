{
  "$schema": "./monster-schema.json",
  "monsters": [
    { "name": "Aarakocra", "cr": 0.25 },
    { "name": "Aboleth", "cr": 10, "isCaptain": true },
    { "name": "Allip", "cr": 5 },
    { "name": "Deva", "cr": 10 },
    { "name": "Planetar", "cr": 16 },
    { "name": "Solar", "cr": 21, "isCaptain": true },
    { "name": "Animated Armor", "cr": 1 },
    { "name": "Flying Sword", "cr": 0.25 },
    { "name": "Rug of Smothering", "cr": 2 },
    { "name": "Ankheg", "cr": 2 },
    { "name": "Aurochs", "cr": 2 },
    { "name": "Cattle", "cr": 0.25 },
    { "name": "Dolphin", "cr": 0.125 },
    { "name": "Swarm of Rot Grubs", "cr": 0.5 },
    { "name": "Astral Dreadnought", "cr": 21, "isCaptain": true },
    { "name": "Azer", "cr": 2 },
    { "name": "Balhannoth", "cr": 11, "isCaptain": true },
    { "name": "Banderhobb", "cr": 5 },
    {
      "name": "Banshee",
      "cr": 4,
      "pointCostOverride": 50,
      "note": "Banshees have a very powerful ability to automatically reduce enemies to 0 Hit Points, so they are manually set to a much higher point cost than their CR."
    },
    { "name": "Barghest", "cr": 4 },
    { "name": "Basilisk", "cr": 3 },
    { "name": "Behir", "cr": 11 },
    { "name": "Beholder", "cr": 13, "isCaptain": true },
    { "name": "Death Kiss", "cr": 10 },
    { "name": "Death Tyrant", "cr": 14 },
    { "name": "Gauth", "cr": 6 },
    { "name": "Gazer", "cr": 0.5 },
    { "name": "Spectator", "cr": 3 },
    { "name": "Berbalang", "cr": 2 },
    { "name": "Needle Blight", "cr": 0.25 },
    { "name": "Twig Blight", "cr": 0.125 },
    { "name": "Vine Blight", "cr": 0.5 },
    { "name": "Bodak", "cr": 6 },
    { "name": "Boggle", "cr": 0.125 },
    { "name": "Boneclaw", "cr": 12 },
    { "name": "Bugbear", "cr": 1 },
    { "name": "Bugbear Chief", "cr": 3 },
    { "name": "Bulette", "cr": 5 },
    { "name": "Bullywug", "cr": 0.25 },
    { "name": "Cadaver Collector", "cr": 14 },
    { "name": "Cambion", "cr": 5 },
    { "name": "Carrion Crawler", "cr": 2 },
    { "name": "Catoblepas", "cr": 5 },
    { "name": "Cave Fisher", "cr": 3 },
    { "name": "Centaur", "cr": 2 },
    { "name": "Chimera", "cr": 6 },
    { "name": "Chitine", "cr": 0.5 },
    { "name": "Choldrith", "cr": 3 },
    { "name": "Choker", "cr": 1 },
    { "name": "Chuul", "cr": 4 },
    { "name": "Cloaker", "cr": 8 },
    { "name": "Bronze Scout", "cr": 1 },
    { "name": "Iron Cobra", "cr": 4 },
    { "name": "Oaken Bolter", "cr": 5 },
    { "name": "Stone Defender", "cr": 4 },
    { "name": "Cockatrice", "cr": 0.5 },
    { "name": "Corpse Flower", "cr": 8 },
    { "name": "Couatl", "cr": 4 },
    { "name": "Cranium Rat", "cr": 0 },
    { "name": "Swarm of Cranium Rats", "cr": 5 },
    { "name": "Crawling Claw", "cr": 0 },
    { "name": "Cyclops", "cr": 6 },
    { "name": "Darkling", "cr": 0.5 },
    { "name": "Darkling Elder", "cr": 2 },
    { "name": "Darkmantle", "cr": 0.5 },
    { "name": "Death Knight", "cr": 17 },
    { "name": "Deathlock", "cr": 4 },
    { "name": "Deathlock Mastermind", "cr": 8 },
    { "name": "Deathlock Wight", "cr": 3 },
    { "name": "Deep Scion", "cr": 3 },
    { "name": "Demilich", "cr": 18, "isCaptain": true },
    { "name": "Baphomet", "cr": 23, "isCaptain": true },
    { "name": "Demogorgon", "cr": 26, "isCaptain": true },
    { "name": "Fraz-Urb'Luu", "cr": 23, "isCaptain": true },
    { "name": "Graz'Zt", "cr": 24, "isCaptain": true },
    { "name": "Juiblex", "cr": 23, "isCaptain": true },
    { "name": "Orcus", "cr": 26, "isCaptain": true },
    { "name": "Yeenoghu", "cr": 24, "isCaptain": true },
    { "name": "Zuggtmoy", "cr": 23, "isCaptain": true },
    { "name": "Abyssal Wretch", "cr": 0.25 },
    { "name": "Alkilith", "cr": 11 },
    { "name": "Armanite", "cr": 7 },
    { "name": "Babau", "cr": 4 },
    { "name": "Balor", "cr": 19 },
    { "name": "Barlgura", "cr": 5 },
    { "name": "Bulezau", "cr": 3 },
    { "name": "Chasme", "cr": 6 },
    { "name": "Draegloth", "cr": 7 },
    { "name": "Dretch", "cr": 0.25 },
    { "name": "Dybbuk", "cr": 4 },
    { "name": "Glabrezu", "cr": 9 },
    { "name": "Goristro", "cr": 17 },
    { "name": "Hezrou", "cr": 8 },
    { "name": "Manes", "cr": 0.125 },
    { "name": "Marilith", "cr": 16 },
    { "name": "Maurezhi", "cr": 7 },
    { "name": "Maw Demon", "cr": 1 },
    { "name": "Molydeus", "cr": 21, "isCaptain": true },
    { "name": "Nabassu", "cr": 15 },
    { "name": "Nalfeshnee", "cr": 13 },
    { "name": "Quasit", "cr": 1 },
    { "name": "Rutterkin", "cr": 2 },
    { "name": "Shadow Demon", "cr": 4 },
    { "name": "Shoosuva", "cr": 8 },
    { "name": "Sibriex", "cr": 18, "isCaptain": true },
    { "name": "Vrock", "cr": 6 },
    { "name": "Wastrilith", "cr": 13 },
    { "name": "Yochlol", "cr": 10 },
    { "name": "Derro", "cr": 0.25 },
    { "name": "Derro Savant", "cr": 3 },
    { "name": "Black Abishai", "cr": 7 },
    { "name": "Blue Abishai", "cr": 17 },
    { "name": "Green Abishai", "cr": 15 },
    { "name": "Red Abishai", "cr": 19 },
    { "name": "White Abishai", "cr": 6 },
    { "name": "Amnizu", "cr": 18 },
    { "name": "Bael", "cr": 19, "isCaptain": true },
    { "name": "Geryon", "cr": 22, "isCaptain": true },
    { "name": "Hutijin", "cr": 21, "isCaptain": true },
    { "name": "Moloch", "cr": 21, "isCaptain": true },
    { "name": "Titivilus", "cr": 16, "isCaptain": true },
    { "name": "Zariel", "cr": 26, "isCaptain": true },
    { "name": "Barbed Devil", "cr": 5 },
    { "name": "Bearded Devil", "cr": 3 },
    { "name": "Bone Devil", "cr": 9 },
    { "name": "Chain Devil", "cr": 8 },
    { "name": "Erinyes", "cr": 12 },
    { "name": "Hellfire Engine", "cr": 16 },
    { "name": "Horned Devil", "cr": 11 },
    { "name": "Ice Devil", "cr": 14 },
    { "name": "Imp", "cr": 1 },
    { "name": "Lemure", "cr": 0 },
    { "name": "Merregon", "cr": 4 },
    { "name": "Narzugon", "cr": 13 },
    { "name": "Nupperibo", "cr": 0.5 },
    { "name": "Orthon", "cr": 10 },
    { "name": "Pit Fiend", "cr": 20 },
    { "name": "Spined Devil", "cr": 2 },
    { "name": "Devourer", "cr": 13 },
    { "name": "Allosaurus", "cr": 2 },
    { "name": "Ankylosaurus", "cr": 3 },
    { "name": "Brontosaurus", "cr": 5 },
    { "name": "Deinonychus", "cr": 1 },
    { "name": "Dimetrodon", "cr": 0.25 },
    { "name": "Hadrosaurus", "cr": 0.25 },
    { "name": "Plesiosaurus", "cr": 2 },
    { "name": "Pteranodon", "cr": 0.25 },
    { "name": "Quetzalcoatlus", "cr": 2 },
    { "name": "Stegosaurus", "cr": 4 },
    { "name": "Triceratops", "cr": 5 },
    { "name": "Tyrannosaurus Rex", "cr": 8 },
    { "name": "Velociraptor", "cr": 0.25 },
    { "name": "Displacer Beast", "cr": 3 },
    { "name": "Doppelganger", "cr": 3 },
    { "name": "Adult Black Dragon", "cr": 14, "isCaptain": true },
    { "name": "Ancient Black Dragon", "cr": 21, "isCaptain": true },
    { "name": "Black Dragon Wyrmling", "cr": 2 },
    { "name": "Yound Black Dragon", "cr": 7 },
    { "name": "Adult Blue Dragon", "cr": 16, "isCaptain": true },
    { "name": "Ancient Blue Dragon", "cr": 23, "isCaptain": true },
    { "name": "Blue Dragon Wyrmling", "cr": 3 },
    { "name": "Young Blue Dragon", "cr": 9 },
    { "name": "Adult Green Dragon", "cr": 15, "isCaptain": true },
    { "name": "Ancient Green Dragon", "cr": 22, "isCaptain": true },
    { "name": "Green Dragon Wyrmling", "cr": 2 },
    { "name": "Young Green Dragon", "cr": 8 },
    { "name": "Adult Red Dragon", "cr": 17, "isCaptain": true },
    { "name": "Ancient Red Dragon", "cr": 24, "isCaptain": true },
    { "name": "Red Dragon Wyrmling", "cr": 4 },
    { "name": "Yound Red Dragon", "cr": 10 },
    { "name": "Adult White Dragon", "cr": 13, "isCaptain": true },
    { "name": "Ancinet White Dragon", "cr": 20, "isCaptain": true },
    { "name": "White Dragon Wyrmling", "cr": 2 },
    { "name": "Yound White Dragon", "cr": 6 },
    { "name": "Adult Brass Dragon", "cr": 13, "isCaptain": true },
    { "name": "Ancinet Brass Dragon", "cr": 20, "isCaptain": true },
    { "name": "Brass Dragon Wyrmling", "cr": 1 },
    { "name": "Young Brass Dragon", "cr": 6 },
    { "name": "Adult Bronze Dragon", "cr": 15, "isCaptain": true },
    { "name": "Ancient Bronze Dragon", "cr": 22, "isCaptain": true },
    { "name": "Bronze Dragon Wyrmling", "cr": 2 },
    { "name": "Young Bronze Dragon", "cr": 8 },
    { "name": "Adult Copper Dragon", "cr": 14, "isCaptain": true },
    { "name": "Ancinet Copper Dragon", "cr": 21, "isCaptain": true },
    { "name": "Copper Dragon Wyrmling", "cr": 1 },
    { "name": "Young Copper Dragon", "cr": 7 },
    { "name": "Adult Gold Dragon", "cr": 17, "isCaptain": true },
    { "name": "Ancient Gold Dragon", "cr": 24, "isCaptain": true },
    { "name": "Gold Dragon Wyrmling", "cr": 3 },
    { "name": "Young Gold Dragon", "cr": 10 },
    { "name": "Adult Silver Dragon", "cr": 16, "isCaptain": true },
    { "name": "Ancient Silver Dragon", "cr": 23, "isCaptain": true },
    { "name": "Silver Dragon Wyrmling", "cr": 2 },
    { "name": "Young Silver Dragon", "cr": 9 },
    { "name": "Dragon Turtle", "cr": 17 },
    { "name": "Drider", "cr": 6 },
    { "name": "Drow", "cr": 0.25 },
    { "name": "Drow Arachnomancer", "cr": 13 },
    { "name": "Drow Elite Warrior", "cr": 5 },
    { "name": "Drow Favored Consort", "cr": 18 },
    { "name": "Drow House Captain", "cr": 9 },
    { "name": "Drow Inquisitor", "cr": 14 },
    { "name": "Drow Mage", "cr": 7 },
    { "name": "Drow Matron Mother", "cr": 20 },
    { "name": "Drow Priestess of Lolth", "cr": 8 },
    { "name": "Drow Shadowblade", "cr": 11 },
    { "name": "Dryad", "cr": 1 },
    { "name": "Duergar", "cr": 1 },
    { "name": "Duergar Despot", "cr": 12 },
    { "name": "Duergar Hammerer", "cr": 2 },
    { "name": "Duergar Kavalrachni", "cr": 2 },
    { "name": "Duergar Mind Master", "cr": 2 },
    { "name": "Duergar Screamer", "cr": 3 },
    { "name": "Duergar Soulblade", "cr": 1 },
    { "name": "Duergar Stone Guard", "cr": 2 },
    { "name": "Duergar Warlord", "cr": 6 },
    { "name": "Duergar Xarrorn", "cr": 2 },
    { "name": "Eidolon", "cr": 12 },
    { "name": "Sacred Statue", "cr": 0 },
    { "name": "Autumn Eladrin", "cr": 10 },
    { "name": "Spring Eladrin", "cr": 10 },
    { "name": "Summer Eladrin", "cr": 10 },
    { "name": "Winter Eladrin", "cr": 10 },
    { "name": "Elder Tempest", "cr": 23, "isCaptain": true },
    { "name": "Leviathan", "cr": 20, "isCaptain": true },
    { "name": "Phoenix", "cr": 16, "isCaptain": true },
    { "name": "Zaratan", "cr": 22, "isCaptain": true },
    { "name": "Air Elemental Myrmidon", "cr": 7 },
    { "name": "Earth Elemental Myrmidon", "cr": 7 },
    { "name": "Fire Elemental Myrmidon", "cr": 7 },
    { "name": "Water Elemental Myrmidon", "cr": 7 },
    { "name": "Air Elemental", "cr": 5 },
    { "name": "Earth Elemental", "cr": 5 },
    { "name": "Fire Elemental", "cr": 5 },
    { "name": "Water Elemental", "cr": 5 },
    { "name": "Empyrean", "cr": 23, "isCaptain": true },
    { "name": "Ettercap", "cr": 2 },
    { "name": "Ettin", "cr": 4 },
    { "name": "Faerie Dragon", "cr": 1 },
    { "name": "Firenewt Warlock of Imix", "cr": 1 },
    { "name": "Firenewt Warrior", "cr": 0.5 },
    { "name": "Flail Snail", "cr": 3 },
    { "name": "Flameskull", "cr": 4 },
    { "name": "Flumph", "cr": 0.125 },
    { "name": "Fomorian", "cr": 8 },
    { "name": "Froghemoth", "cr": 10 },
    { "name": "Gas Spore", "cr": 0.5 },
    { "name": "Shrieker", "cr": 0 },
    { "name": "Violet", "cr": 0.25 },
    { "name": "Galeb Duhr", "cr": 6 },
    { "name": "Gargoyle", "cr": 2 },
    { "name": "Dao", "cr": 11 },
    { "name": "Djinni", "cr": 11 },
    { "name": "Efreeti", "cr": 11 },
    { "name": "Marid", "cr": 11 },
    { "name": "Ghost", "cr": 4 },
    { "name": "Ghoul", "cr": 1 },
    { "name": "Ghast", "cr": 2 },
    { "name": "Giant Strider", "cr": 1 },
    { "name": "Cloud Giant", "cr": 9 },
    { "name": "Cloud Giant Smiling One", "cr": 11 },
    { "name": "Fire Giant", "cr": 9 },
    { "name": "Fire Giant Dreadnought", "cr": 14 },
    { "name": "Frost Giant", "cr": 8 },
    { "name": "Frost Giant Everlasting One", "cr": 12 },
    { "name": "Hill Giant", "cr": 5 },
    { "name": "Hill Giant Mouth of Grolantor", "cr": 6 },
    { "name": "Stone Giant", "cr": 7 },
    { "name": "Stone Giant Dreamwalker", "cr": 10 },
    { "name": "Storm Giant", "cr": 13 },
    { "name": "Storm Giant Quintessent", "cr": 16, "isCaptain": true },
    { "name": "Gibbering Mouther", "cr": 2 },
    { "name": "Giff", "cr": 3 },
    { "name": "Girallon", "cr": 4 },
    { "name": "Githyanki Gish", "cr": 10 },
    { "name": "Githyanki Kith'Rak", "cr": 12 },
    { "name": "Githyanki Knight", "cr": 8 },
    { "name": "Githyanki Supreme Commander", "cr": 14, "isCaptain": true },
    { "name": "Githyanki Warrior", "cr": 3 },
    { "name": "Githzerai Anarch", "cr": 16, "isCaptain": true },
    { "name": "Githzerai Enlightened", "cr": 10 },
    { "name": "Githzerai Monk", "cr": 2 },
    { "name": "Githzerai Zerth", "cr": 6 },
    { "name": "Gnoll", "cr": 0.5 },
    { "name": "Gnoll Fang of Yeenoghu", "cr": 4 },
    { "name": "Gnoll Flesh Gnawer", "cr": 1 },
    { "name": "Gnoll Hunter", "cr": 0.5 },
    { "name": "Gnoll Pack Lord", "cr": 2 },
    { "name": "Gnoll Witherling", "cr": 0.25 },
    { "name": "Flind", "cr": 9 },
    { "name": "Deep Gnome (Svirfneblin)", "cr": 0.5 },
    { "name": "Goblin", "cr": 0.25 },
    { "name": "Goblin Boss", "cr": 1 },
    { "name": "Clay Golem", "cr": 9 },
    { "name": "Flesh Golem", "cr": 5 },
    { "name": "Iron Golem", "cr": 16 },
    { "name": "Stone Golem", "cr": 10 },
    { "name": "Gorgon", "cr": 5 },
    { "name": "Gray Render", "cr": 12 },
    { "name": "Grell", "cr": 3 },
    { "name": "Grick", "cr": 2 },
    { "name": "Grick Alpha", "cr": 7 },
    { "name": "Griffon", "cr": 2 },
    { "name": "Grimlock", "cr": 0.25 },
    { "name": "Grung", "cr": 0.25 },
    { "name": "Grung Elite Warrior", "cr": 2 },
    { "name": "Grung Wildling", "cr": 1 },
    { "name": "Guard Drake", "cr": 2 },
    { "name": "Annis Hag", "cr": 6 },
    { "name": "Bheur Hag", "cr": 7 },
    { "name": "Green Hag", "cr": 3 },
    { "name": "Night Hag", "cr": 5 },
    { "name": " Sea Hag", "cr": 2 },
    { "name": "Harpy", "cr": 1 },
    { "name": "Hell Hound", "cr": 3 },
    { "name": "Helmed Horror", "cr": 4 },
    { "name": "Hippogriff", "cr": 1 },
    { "name": "Hobgoblin", "cr": 0.5 },
    { "name": "Hobgoblin Captain", "cr": 3 },
    { "name": "Hobgoblin Devastator", "cr": 4 },
    { "name": "Hobgoblin Iron Shadow", "cr": 2 },
    { "name": "Hobgoblin Warlord", "cr": 6 },
    { "name": "Homunculus", "cr": 0 },
    { "name": "Hook Horror", "cr": 3 },
    { "name": "Howler", "cr": 8 },
    { "name": "Hydra", "cr": 8 },
    { "name": "Marut", "cr": 25, "isCaptain": true },
    { "name": "Intellect Devourer", "cr": 2 },
    { "name": "Invisible Stalker", "cr": 6 },
    { "name": "Jackalwere", "cr": 0.5 },
    { "name": "Kenku", "cr": 0.25 },
    { "name": "Ki-Rin", "cr": 12, "isCaptain": true },
    { "name": "Kobold", "cr": 0.125 },
    { "name": "Kobold Dragonshield", "cr": 1 },
    { "name": "Kobold Inventor", "cr": 0.25 },
    { "name": "Kobold Scale Sorcerer", "cr": 1 },
    { "name": "Winged Kobold", "cr": 0.25 },
    { "name": "Korred", "cr": 7 },
    { "name": "Kraken", "cr": 23, "isCaptain": true },
    { "name": "Kruthik Hive Lord", "cr": 5 },
    { "name": "Adult Kruthik", "cr": 2 },
    { "name": "Young Kruthik", "cr": 0.125 },
    { "name": "Kuo-Toa", "cr": 0.25 },
    { "name": "Kuo-Toa Archpriest", "cr": 6 },
    { "name": "Kuo-Toa Whip", "cr": 1 },
    { "name": "Lamia", "cr": 4 },
    { "name": "Leucrotta", "cr": 3 },
    { "name": "Lich", "cr": 21, "isCaptain": true },
    { "name": "Lizard King/Queen", "cr": 4 },
    { "name": "Lizardfolk", "cr": 0.5 },
    { "name": "Lizardfolk Shaman", "cr": 2 },
    { "name": "Werebear", "cr": 5 },
    { "name": "Wereboar", "cr": 4 },
    { "name": "Wererat", "cr": 2 },
    { "name": "Weretiger", "cr": 4 },
    { "name": "Werewolf", "cr": 3 },
    { "name": "Magmin", "cr": 0.5 },
    { "name": "Manticore", "cr": 3 },
    { "name": "Meazel", "cr": 1 },
    { "name": "Medusa", "cr": 6 },
    { "name": "Meenlock", "cr": 2 },
    { "name": "Dust Mephit", "cr": 0.5 },
    { "name": "Ice Mephit", "cr": 0.5 },
    { "name": "Magma Mephit", "cr": 0.5 },
    { "name": "Mud Mephit", "cr": 0.25 },
    { "name": "Smoke Mephit", "cr": 0.25 },
    { "name": "Steam Mephit", "cr": 0.25 },
    { "name": "Merfolk", "cr": 0.125 },
    { "name": "Merrow", "cr": 2 },
    { "name": "Mimic", "cr": 2 },
    { "name": "Mind Flayer", "cr": 7 },
    { "name": "Mind Flayer Lich (Illithilich)", "cr": 22, "isCaptain": true },
    { "name": "Alhoon", "cr": 10 },
    { "name": "Elder Brain", "cr": 14, "isCaptain": true },
    { "name": "Ulitharid", "cr": 9 },
    { "name": "Mindwitness", "cr": 5 },
    { "name": "Minotaur", "cr": 3 },
    { "name": "Ape", "cr": 0.5 },
    { "name": "Awakened Shrub", "cr": 0 },
    { "name": "Awakened Tree", "cr": 2 },
    { "name": "Axe Beak", "cr": 0.25 },
    { "name": "Baboon", "cr": 0 },
    { "name": "Badger", "cr": 0 },
    { "name": "Bat", "cr": 0 },
    { "name": "Black Bear", "cr": 0.5 },
    { "name": "Blink Dog", "cr": 0.25 },
    { "name": "Blood Hawk", "cr": 0.125 },
    { "name": "Boar", "cr": 0.25 },
    { "name": "Brown Bear", "cr": 1 },
    { "name": "Camel", "cr": 0.125 },
    { "name": "Cat", "cr": 0 },
    { "name": "Constrictor Snake", "cr": 0.25 },
    { "name": "Crab", "cr": 0 },
    { "name": "Crocodile", "cr": 0.5 },
    { "name": "Death Dog", "cr": 1 },
    { "name": "Deer", "cr": 0 },
    { "name": "Dire Wolf", "cr": 1 },
    { "name": "Draft Horse", "cr": 0.25 },
    { "name": "Eagle", "cr": 0 },
    { "name": "Elephant", "cr": 4 },
    { "name": "Elk", "cr": 0.25 },
    { "name": "Flying Snake", "cr": 0.125 },
    { "name": "Frog", "cr": 0 },
    { "name": "Giant Ape", "cr": 7 },
    { "name": "Giant Badger", "cr": 0.25 },
    { "name": "Giant Bat", "cr": 0.25 },
    { "name": "Giant Boar", "cr": 2 },
    { "name": "Giant Centipede", "cr": 0.25 },
    { "name": "Giant Constrictor Snake", "cr": 2 },
    { "name": "Giant Crab", "cr": 0.125 },
    { "name": "Giant Crocodile", "cr": 5 },
    { "name": "Giant Eagle", "cr": 1 },
    { "name": "Giant Elk", "cr": 2 },
    { "name": "Giant Fire Beetle", "cr": 0 },
    { "name": "Giant Frog", "cr": 0.25 },
    { "name": "Giant Goat", "cr": 0.5 },
    { "name": "Giant Hyena", "cr": 1 },
    { "name": "Giant Lizard", "cr": 0.25 },
    { "name": "Giant Octopus", "cr": 1 },
    { "name": "Giant Owl", "cr": 0.25 },
    { "name": "Giant Poisonous Snake", "cr": 0.25 },
    { "name": "Giant Rat", "cr": 0.125 },
    { "name": "Giant Scorpion", "cr": 3 },
    { "name": "Giant Sea Horse", "cr": 0.5 },
    { "name": "Giant Shark", "cr": 5 },
    { "name": "Giant Spider", "cr": 1 },
    { "name": "Giant Toad", "cr": 1 },
    { "name": "Giant Vulture", "cr": 1 },
    { "name": "Giant Wasp", "cr": 0.5 },
    { "name": "Giant Weasel", "cr": 0.125 },
    { "name": "Giant Wolf Spider", "cr": 0.25 },
    { "name": "Goat", "cr": 0 },
    { "name": "Hawk", "cr": 0 },
    { "name": "Hunter Shark", "cr": 2 },
    { "name": "Hyena", "cr": 0 },
    { "name": "Jackal", "cr": 0 },
    { "name": "Killer Whale", "cr": 3 },
    { "name": "Lion", "cr": 1 },
    { "name": "Lizard", "cr": 0 },
    { "name": "Mammoth", "cr": 6 },
    { "name": "Mastiff", "cr": 0.125 },
    { "name": "Mule", "cr": 0.125 },
    { "name": "Octopus", "cr": 0 },
    { "name": "Owl", "cr": 0 },
    { "name": "Panther", "cr": 0.25 },
    { "name": "Phase Spider", "cr": 3 },
    { "name": "Poisonous Snake", "cr": 0.125 },
    { "name": "Polar Bear", "cr": 2 },
    { "name": "Pony", "cr": 0.125 },
    { "name": "Quipper", "cr": 0 },
    { "name": "Rat", "cr": 0 },
    { "name": "Raven", "cr": 0 },
    { "name": "Reef Shark", "cr": 0.5 },
    { "name": "Rhinoceros", "cr": 2 },
    { "name": "Riding Horse", "cr": 0.25 },
    { "name": "Saber-Toothed Tiger", "cr": 2 },
    { "name": "Scorpion", "cr": 0 },
    { "name": "Sea Horse", "cr": 0 },
    { "name": "Spider", "cr": 0 },
    { "name": "Swarm of Bats", "cr": 0.25 },
    { "name": "Swarm of Insects", "cr": 0.5 },
    { "name": "Swarm of Poisonous Snakes", "cr": 2 },
    { "name": "Swarm of Quippers", "cr": 1 },
    { "name": "Swarm of Rats", "cr": 0.25 },
    { "name": "Swarm of Ravens", "cr": 0.25 },
    { "name": "Tiger", "cr": 1 },
    { "name": "Vulture", "cr": 0 },
    { "name": "Warhorse", "cr": 0.5 },
    { "name": "Weasel", "cr": 0 },
    { "name": "Winter Wolf", "cr": 3 },
    { "name": "Wolf", "cr": 0.25 },
    { "name": "Worg", "cr": 0.5 },
    { "name": "Duodrone", "cr": 0.25 },
    { "name": "Monodrone", "cr": 0.125 },
    { "name": "Pentadrone", "cr": 2 },
    { "name": "Quadrone", "cr": 1 },
    { "name": "Tridrone", "cr": 0.5 },
    { "name": "Morkoth", "cr": 11 },
    { "name": "Mummy", "cr": 3 },
    { "name": "Mummy Lord", "cr": 15, "isCaptain": true },
    { "name": "Myconid Adult", "cr": 0.5 },
    { "name": "Myconid Sovereign", "cr": 2 },
    { "name": "Myconid Sprout", "cr": 0 },
    { "name": "Bone Naga", "cr": 4 },
    { "name": "Guardian Naga", "cr": 10 },
    { "name": "Spirit Naga", "cr": 8 },
    { "name": "Nagpa", "cr": 17 },
    { "name": "Neogi", "cr": 3 },
    { "name": "Neogi Hatchling", "cr": 0.125 },
    { "name": "Neogi Master", "cr": 4 },
    { "name": "Neothelid", "cr": 13 },
    { "name": "Nightmare", "cr": 3 },
    { "name": "Nightwalker", "cr": 20 },
    { "name": "Nilbog", "cr": 1 },
    { "name": "Nothic", "cr": 2 },
    {
      "name": "Abjurer",
      "cr": 9,
      "banned": true,
      "note": "General note, banning all the NPCs from the MM and Volo Apendixes"
    },
    { "name": "Acolyte", "cr": 0.25, "banned": true },
    { "name": "Apprentice Wizard", "cr": 0.25, "banned": true },
    { "name": "Archdruid", "cr": 12, "banned": true },
    { "name": "Archer", "cr": 3, "banned": true },
    { "name": "Archmage", "cr": 12, "banned": true },
    { "name": "Assassin", "cr": 8, "banned": true },
    { "name": "Bandit", "cr": 0.125, "banned": true },
    { "name": "Bandit Captain", "cr": 2, "banned": true },
    { "name": "Bard", "cr": 2, "banned": true },
    { "name": "Berserker", "cr": 2, "banned": true },
    { "name": "Blackguard", "cr": 8, "banned": true },
    { "name": "Champion", "cr": 9, "banned": true },
    { "name": "Commoner", "cr": 0, "banned": true },
    { "name": "Conjurer", "cr": 6, "banned": true },
    { "name": "Cult Fanatic", "cr": 2, "banned": true },
    { "name": "Cultist", "cr": 0.125, "banned": true },
    { "name": "Diviner", "cr": 8, "banned": true },
    { "name": "Druid", "cr": 2, "banned": true },
    { "name": "Enchanter", "cr": 5, "banned": true },
    { "name": "Evoker", "cr": 9, "banned": true },
    { "name": "Gladiator", "cr": 5, "banned": true },
    { "name": "Guard", "cr": 0.125, "banned": true },
    { "name": "Illusionist", "cr": 3, "banned": true },
    { "name": "Knight", "cr": 3, "banned": true },
    { "name": "Kraken Priest", "cr": 5, "banned": true },
    { "name": "Mage", "cr": 6, "banned": true },
    { "name": "Martial Arts Adept", "cr": 3, "banned": true },
    { "name": "Master Thief", "cr": 5, "banned": true },
    { "name": "Necromancer", "cr": 9, "banned": true },
    { "name": "Noble", "cr": 0.125, "banned": true },
    { "name": "Priest", "cr": 2, "banned": true },
    { "name": "Scout", "cr": 0.5, "banned": true },
    { "name": "Spy", "cr": 1, "banned": true },
    { "name": "Swashbuckler", "cr": 3, "banned": true },
    { "name": "Thug", "cr": 0.5, "banned": true },
    { "name": "Transmuter", "cr": 5, "banned": true },
    { "name": "Tribal Warrior", "cr": 0.125, "banned": true },
    { "name": "Veteran", "cr": 3, "banned": true },
    { "name": "War Priest", "cr": 9, "banned": true },
    { "name": "Warlock of the Archfey", "cr": 4, "banned": true },
    { "name": "Warlock of the Fiend", "cr": 7, "banned": true },
    { "name": "Warlock of the Great Old One", "cr": 6, "banned": true },
    { "name": "Warlord", "cr": 12, "banned": true },
    { "name": "Oblex Spawn", "cr": 0.25 },
    { "name": "Adult Oblex", "cr": 5 },
    { "name": "Elder Oblex", "cr": 10 },
    { "name": "Ogre", "cr": 2 },
    { "name": "Ogre Battering Ram", "cr": 4 },
    { "name": "Ogre Bolt Launcher", "cr": 2 },
    { "name": "Ogre Chain Brute", "cr": 3 },
    { "name": "Ogre Howdah", "cr": 2 },
    { "name": "Half Ogre", "cr": 1 },
    { "name": "Oni", "cr": 7 },
    { "name": "Black Pudding", "cr": 4 },
    { "name": "Gelatinous Cube", "cr": 2 },
    { "name": "Gray Ooze", "cr": 0.5 },
    { "name": "Ochre Jelly", "cr": 2 },
    { "name": "Orc", "cr": 0.5 },
    { "name": "Orc Blade of Ilneval", "cr": 4 },
    { "name": "Orc Claw of Luthic", "cr": 2 },
    { "name": "Orc Eye of Gruumsh", "cr": 2 },
    { "name": "Orc Hand of Yurtrus", "cr": 2 },
    { "name": "Orc Nurtured One of Yurtrus", "cr": 0.5 },
    { "name": "Orc Red Fang of Shargaas", "cr": 3 },
    { "name": "Orc War Chief", "cr": 4 },
    { "name": "Orog", "cr": 2 },
    { "name": "Tanarukk", "cr": 5 },
    { "name": "Otyugh", "cr": 5 },
    { "name": "Owlbear", "cr": 3 },
    { "name": "Pegasus", "cr": 2 },
    { "name": "Peryton", "cr": 2 },
    { "name": "Piercer", "cr": 0.5 },
    { "name": "Pixie", "cr": 0.25 },
    { "name": "Pseudodragon", "cr": 0.25 },
    { "name": "Purple Worm", "cr": 15 },
    { "name": "Quaggoth", "cr": 2 },
    { "name": "Quickling", "cr": 1 },
    { "name": "Rakshasa", "cr": 13 },
    { "name": "Redcap", "cr": 3 },
    { "name": "Remorhaz", "cr": 11 },
    { "name": "Young Remorhaz", "cr": 5 },
    { "name": "Retriever", "cr": 14 },
    { "name": "Revenant", "cr": 5 },
    { "name": "Roc", "cr": 11 },
    { "name": "Roper", "cr": 5 },
    { "name": "Rust Monster", "cr": 0.5 },
    { "name": "Sahuagin", "cr": 0.5 },
    { "name": "Sahuagin Baron", "cr": 5 },
    { "name": "Sahuagin Priestess", "cr": 2 },
    { "name": "Salamander", "cr": 5 },
    { "name": "Fire Snake", "cr": 1 },
    { "name": "Frost Salamander", "cr": 9 },
    { "name": "Satyr", "cr": 0.5 },
    { "name": "Scarecrow", "cr": 1 },
    { "name": "Sea Spawn", "cr": 1 },
    { "name": "Shadar-Kai Gloom Weaver", "cr": 9 },
    { "name": "Shadar-Kai Shadow Dancer", "cr": 7 },
    { "name": "Shadar-Kai Soul Monger", "cr": 11 },
    { "name": "Shadow", "cr": 0.5 },
    { "name": "Shadow Dragon Template", "cr": 13 },
    { "name": "Shadow Mastiff", "cr": 2 },
    { "name": "Shadow Mastiff Alpha", "cr": 2 },
    { "name": "Shambling Mound", "cr": 5 },
    { "name": "Shield Guardian", "cr": 7 },
    { "name": "Skeleton", "cr": 0.25 },
    { "name": "Minotaur Skeleton", "cr": 2 },
    { "name": "Warhorse Skeleton", "cr": 0.5 },
    { "name": "Skulk", "cr": 0.5 },
    { "name": "Skull Lord", "cr": 15, "isCaptain": true },
    { "name": "Slaad Tadpole", "cr": 0.125 },
    { "name": "Blue Slaad", "cr": 7 },
    { "name": "Death Slaad", "cr": 10 },
    { "name": "Gray Slaad", "cr": 9 },
    { "name": "Green Slaad", "cr": 8 },
    { "name": "Red Slaad", "cr": 5 },
    { "name": "Slithering Tracker", "cr": 3 },
    { "name": "The Angry", "cr": 13 },
    { "name": "The Hungry", "cr": 11 },
    { "name": "The Lonely", "cr": 9 },
    { "name": "The Lost", "cr": 7 },
    { "name": "The Wretched", "cr": 0.25 },
    { "name": "Spawn of Kyuss", "cr": 5 },
    { "name": "Specter", "cr": 1 },
    { "name": "Androsphinx", "cr": 17, "isCaptain": true },
    { "name": "Gynosphinx", "cr": 11, "isCaptain": true },
    { "name": "Sprite", "cr": 0.25 },
    { "name": "Star Spawn Grue", "cr": 0.25 },
    { "name": "Star Spawn Hulk", "cr": 10 },
    { "name": "Star Spawn Larva Mage", "cr": 16, "isCaptain": true },
    { "name": "Star Spawn Mangler", "cr": 5 },
    { "name": "Star Spawn Seer", "cr": 13 },
    { "name": "Female Steeder", "cr": 1 },
    { "name": "Male Steeder", "cr": 0.25 },
    { "name": "Steel Predator", "cr": 16 },
    { "name": "Stirge", "cr": 0.125 },
    { "name": "Stone Cursed", "cr": 1 },
    { "name": "Succubus/Incubus", "cr": 4 },
    { "name": "Sword Wraith Commander", "cr": 8 },
    { "name": "Sword Wraith Warrior", "cr": 3 },
    { "name": "Tarrasque", "cr": 30, "isCaptain": true },
    { "name": "Thorny", "cr": 1 },
    { "name": "Thri-Kreen", "cr": 1 },
    { "name": "Tlincalli", "cr": 5 },
    { "name": "Tortle", "cr": 0.25 },
    { "name": "Tortle Druid", "cr": 2 },
    { "name": "Trapper", "cr": 3 },
    { "name": "Treant", "cr": 9 },
    { "name": "Troglodyte", "cr": 0.25 },
    { "name": "Troll", "cr": 5 },
    { "name": "Dire Troll", "cr": 13 },
    { "name": "Rot Troll", "cr": 9 },
    { "name": "Spirit Troll", "cr": 11 },
    { "name": "Venom Troll", "cr": 7 },
    { "name": "Umber Hulk", "cr": 5 },
    {
      "name": "Unicorn",
      "cr": 5,
      "isCaptain": true,
      "pointCostOverride": 50,
      "note": "Unicorns would be a very cheap option for a captain. Raising unicorn cost to be equivalent to CR 10 to keep it in line cost-wise with most other captains. This probably means the unicorn isn't worth it."
    },
    { "name": "Vampire", "cr": 13, "isCaptain": true },
    { "name": "Vampire Spawn", "cr": 5 },
    { "name": "Vampiric Mist", "cr": 3 },
    { "name": "Vargouille", "cr": 1 },
    { "name": "Vegepygmy", "cr": 0.25 },
    { "name": "Vegepygmy Chief", "cr": 2 },
    { "name": "Water Weird", "cr": 3 },
    { "name": "Wight", "cr": 3 },
    { "name": "Will-O'-Wisp", "cr": 2 },
    { "name": "Wood Woad", "cr": 5 },
    { "name": "Wraith", "cr": 5 },
    { "name": "Wyvern", "cr": 6 },
    { "name": "Xorn", "cr": 5 },
    { "name": "Xvart", "cr": 0.125 },
    { "name": "Xvart Speaker", "cr": 0.125 },
    { "name": "Xvart Warlock of Raxivort", "cr": 1 },
    { "name": "Yeth Hound", "cr": 4 },
    { "name": "Yeti", "cr": 3 },
    { "name": "Abominable Yeti", "cr": 9 },
    { "name": "Yuan-Ti Abomination", "cr": 7 },
    { "name": "Yuan-Ti Anathema", "cr": 12 },
    { "name": "Yuan-Ti Broodguard", "cr": 2 },
    { "name": "Yuan-Ti Malison", "cr": 3 },
    { "name": "Yuan-Ti Mind Whisperer", "cr": 4 },
    { "name": "Yuan-Ti Nightmare Speaker", "cr": 4 },
    { "name": "Yuan-Ti Pit Master", "cr": 5 },
    { "name": "Yuan-Ti Pureblood", "cr": 1 },
    { "name": "Arcanaloth", "cr": 12 },
    { "name": "Canoloth", "cr": 8 },
    { "name": "Dhergoloth", "cr": 7 },
    { "name": "Hydroloth", "cr": 9 },
    { "name": "Merrenoloth", "cr": 3 },
    { "name": "Mezzoloth", "cr": 5 },
    { "name": "Nycaloth", "cr": 9 },
    { "name": "Oinoloth", "cr": 12 },
    { "name": "Ultroloth", "cr": 13 },
    { "name": "Yagnoloth", "cr": 11 },
    { "name": "Zombie", "cr": 0.25 },
    { "name": "Beholder Zombie", "cr": 5 },
    { "name": "Ogre Zombie", "cr": 2 }
  ]
}
