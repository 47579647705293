export default function Vampire(): JSX.Element {
  return (
    <>
      <path
        d="M1214.64,2050.56c2.06-18.17-4.39-35.48-6.06-53.21c-4.88-51.96-19.41-101.57-38.12-150.09
		c-6.82-17.69-9.52-36.7-17.35-54.06c5.43-4.59,11.23-2.8,16.48-2.69c30.25,0.6,60.4,0.99,89.23-10.52c8.52-3.4,9.05,2.81,9.8,8.64
		c4.61,35.51,10.97,70.63,25.69,103.59c8.3,18.58,16.61,37.37,29.44,53.34c18.12,22.54,44.4,27.34,71.01,28.83
		c19.15,1.08,38.22,1.74,57.18,5.09c33.94,5.99,53.4,27.21,63.54,58.75c1.17,3.63,2.77,7.12,4.67,11.51
		C1419.04,2050.56,1317.44,2050.56,1214.64,2050.56z"
      />
      <path
        d="M712.88,2050.56c4.26-17.82,9.37-35.76,15.3-53.43c4.59-13.66,14.79-20.5,29.27-19.94c28.46,1.1,56.92,3.43,84.88,8.4
		c20.77,3.69,24.66,10.82,24.47,32.25c-0.09,10.2-0.92,20.39-1.47,31.65C814.88,2050.56,764.48,2050.56,712.88,2050.56z"
      />
      <path
        d="M863.54,1089.16c1.9,3.49,3.91,6.99,5.56,10.6c0.03-0.55,0.07-1.09,0.1-1.64c0.31-4.82,0.91-9.71,0.86-14.54
		c-0.02-2.26-0.06-4.53-0.2-6.78c-0.14-2.38-0.57-4.71-0.78-7.07c-0.13-1.5-0.42-2.91-0.73-4.34c-2.3,5.22-6.84,10.27-11.7,12.18
		C859.13,1081.31,861.39,1085.2,863.54,1089.16z"
      />
      <path
        d="M955.41,1001.94c6.23-34.38,7.08-68.75,1.59-104.04c-7.91-50.8-25.44-96.68-55.98-137.55
		c-17.29-23.14-31.11-25.43-57.52-12.19c-33.35,16.72-54.94,45.92-78.09,73.51c-7.12,8.49-6.7,14.51,5.07,16.7
		c5.18,0.97,7.03,4.85,8.81,8.41c7.47,14.98,19.67,26.71,27.87,41.15c3.05,5.38,6.56,7.36,13.53,5.81
		c11.62-2.58,24.63-4.22,35.37,0.3c28.99,12.22,52.7,32.43,74.27,55.09c3.17,3.33,3.72,7.81,1.76,11.91
		c-1.81,3.8-5.79,2.96-9.13,2.68c-14.31-1.23-28.75-1.58-44.86-8.2c10.98,18.65,22.13,33.83,27.83,52.36
		c5.77,18.74,8.22,36.44,0.45,55.96c-0.12,1.19-0.24,2.36-0.39,3.54c-0.17,1.95-0.4,3.9-0.9,5.75c-0.34,1.26-0.66,2.57-1.04,3.87
		c0.2,0.29,0.4,0.63,0.6,1.02c-1.6,6.07-5.64,10.86-12.57,15.95c-9.33-27.22-13.27-55.2-29.46-78.65c2.2,6.44,3.79,12.94,4.93,19.51
		c0.08,0.21,0.17,0.4,0.24,0.62c0.57,1.83,0.99,3.74,1.12,5.65c0.25,0.68,0.4,1.37,0.47,2.1c0.26,0.65,0.36,1.31,0.54,2.11
		c0.28,1.26,0.74,2.52,1.07,3.77c0.15,0.58,0.18,1.19,0.36,1.76c0.13,0.44,0.44,0.82,0.59,1.25c0.14,0.39,0.13,0.81,0.26,1.19
		c0.06,0.18,0.47,0.62,0.64,1.03c0.3,0.71,0.4,1.51,0.54,2.27c0.44,2.27,0.48,4.58,1.02,6.81c0.55,2.31,0.75,4.6,1.08,6.93
		c0.69,4.9,0.91,9.98,0.68,14.93c-0.23,5.03-0.86,10.07-1.3,15.09c-0.2,2.28-0.23,4.56-0.31,6.85c-0.07,2.03-0.02,4-1.36,5.66
		c-0.53,0.65-1.76,0.97-2.54,0.67c-2.1-0.81-2.49-2.65-3.29-4.52c-0.75-1.76-1.73-3.41-2.58-5.12c-1.79-3.57-3.6-7.14-5.46-10.67
		c-2.66-5.04-5.43-10.09-8.6-14.85c-4.46-0.55-4.69-4.25-4.88-7.93c-0.03,0.28-0.05,0.56-0.1,0.83c-0.15,0.84-0.32,1.68-0.5,2.53
		c0.08,0.63,0.15,1.25,0.24,1.89c0.5,3.74,0.45,7.47,0.54,11.23c0.1,4.08,0.35,8.15,0.28,12.24c-0.03,1.89,0.01,3.8-0.21,5.69
		c-0.2,1.77-0.55,3.52-0.72,5.29c-0.04,0.43-0.16,0.8-0.34,1.11c-0.11,5.25-0.12,10.5-0.28,15.75c-0.08,2.58-3.72,3.81-5.04,1.36
		c-0.86-1.6-2.37-2.69-3.38-4.19c-0.28-0.42-0.52-0.86-0.74-1.31c-1.8,1.05-4.18-0.5-5.09-2.6c-2.51-5.76-5.46-11.59-7.1-18.69
		c-0.52-1.06-1.03-2.13-1.52-3.21c-2.25-4.97-4.36-10-5.85-15.26c-0.29-0.51-0.57-1.03-0.81-1.57c-0.18-0.41-0.25-0.85-0.22-1.27
		c-0.72-1.55-1.27-3.39-1.64-5.56c-0.05-0.08-0.1-0.16-0.15-0.24c-0.66-1.03-1.26-2.11-1.8-3.21c-1.26-2.56-2.31-5.35-3.27-8.03
		c-3.29-9.18-6.73-18.24-10.92-27.06c-1.32-2.78-2.7-5.53-4.1-8.27c3.76,14.58,7.97,28.77,11.68,43.09c0.75,2.91,1.38,5.37,1.86,7.5
		c2.46,2.22,3.56,6.03,3.98,9.11c0.15,1.13,0.22,2.25,0.25,3.39c0.02,1.09,0.23,2.07,0.44,3.14c0.46,2.35,0.59,4.66,0.42,7.05
		c-0.08,1.15-0.14,2.29-0.2,3.43c0.6,0.45,0.99,1.16,1,1.95c0.01,1.11-0.23,2.22-0.25,3.33c-0.03,1.26,0.01,2.52,0.02,3.77
		c0.02,2.39,0.04,4.78,0.06,7.17c0.02,2.18,0.03,4.35-0.26,6.51c-0.15,1.17-0.35,2.33-0.72,3.46c-0.11,0.35-0.38,0.78-0.42,0.96
		c-0.03,0.13-0.03,0.28-0.03,0.42c0.07,0.1,0.14,0.2,0.2,0.31c0.86,1.62-0.52,3.6-2.18,3.41c-1.05,0.4-2.14,0.12-3.02-0.68
		c-1.47-1.35-2.63-2.79-3.84-4.39c-0.35-0.47-0.73-1.01-1.13-1.6c-0.82-0.92-1.55-1.93-2.28-2.92c-0.79-1.08-1.73-2.04-2.6-3.06
		c-1.99-2.33-4.13-4.55-5.91-7.05c-0.84-1.18-1.67-2.46-2.32-3.75c-0.21-0.41-0.38-0.83-0.57-1.25c0.21,0.46-0.05-0.09-0.1-0.19
		c-0.3-0.5-0.64-1.01-0.75-1.6c-0.12-0.64,0.02-1.28,0.32-1.81c-3.81-6.59-7.66-13.15-11.96-19.4
		c-10.1-14.68-14.07-33.29-27.7-45.44c-12.41-11.06-23.02-23.33-31.98-39.8c-5.38,14.47-14.76,7.76-22.44,6.45
		c-10.05-1.71-14.86,0.25-18.22,11.27c-5.75,18.83-7.23,38.45-13.12,57.19c-8.13,25.9-14.28,52.38-22.08,78.43
		c-11.81,39.45-25.12,78.36-39.43,116.87c-12.86,34.58-26.62,68.88-41.81,102.59c-23.53,52.25-46.19,104.89-69.12,157.41
		c-19.53,44.73-39.87,89.13-58.14,134.37c-19.35,47.91-38.08,96.14-52.91,145.73c-7.42,24.83-13.82,49.95-18,75.56
		c-0.65,3.99-1.89,8.5,2.55,12.49c6.53-0.23,12.38-3.59,18.47-6.46c21.99-10.34,45.06-18.11,68.45-24.12
		c46.5-11.95,93.49-21.46,141.22-27.85c53.61-7.17,106.82-17.84,161.25-15.85c11.69,0.43,15.77-4.65,17.49-15.14
		c3.53-21.49,5.45-43.05,5.98-64.82c0.21-8.55-1.7-14.87-9.23-19.38c-12.14-7.26-14.26-17.67-10.41-30.86
		c6.62-22.7,18.76-42.82,29.48-63.47c13.4-25.8,23.41-54.12,47.48-73.13c2.88-2.27,3.13-5.78,3.39-9.3
		c3.48-47.56,13.7-94.1,22.23-140.84c8-43.82,16.76-87.59,27.51-130.8c10.24-41.16,19.61-82.59,32.64-123.09
		c3.99-12.41,9.2-24.81,12.32-37.73c-6.62-1.16-6.87,6.85-13.22,4.76c-1.27-11.12,1.84-21.46,7.12-31.21
		C939.95,1063.72,949.72,1033.32,955.41,1001.94z"
      />
      <path
        d="M898.73,1042.34c-10.64,8.85-10.64,8.85-2.3,34.17c0.43,0.19,0.89,0.2,1.35,0.12c0.07-0.29,0.13-0.59,0.22-0.9
		c0.45-1.62,1.01-3.23,1.57-4.8c0.51-1.44,0.77-3.2,0.88-4.73c0.14-1.82,0.31-3.7,0.36-5.54c0.13-4.16,0.34-8.33,0.7-12.48
		c0.09-1.02,0.16-2.05,0.23-3.08C901.08,1044.06,900.13,1043.13,898.73,1042.34z"
      />
      <path
        d="M823.2,1086.55c0.85,1.91,1.57,3.88,2.38,5.8c0.79,1.86,1.68,3.67,2.45,5.53c0.72,1.74,1.9,3.33,2.86,4.96
		c1.02,1.72,1.64,3.7,2.42,5.54c0.68,1.59,1.5,3.12,2.5,4.52c1.03,1.45,2.11,2.9,3.05,4.4c0.11,0.18,0.22,0.38,0.33,0.58
		c-0.01-1.59-0.03-3.19-0.03-4.78c-0.02-4.17,0.04-8.34,0.27-12.51c0.06-1.03,0.01-2.05,0.06-3.08c0.05-1,0.3-1.98,0.39-2.97
		c0.16-1.94-0.46-3.81-0.46-5.72c-0.01-1.94-0.01-3.88-0.03-5.82c-1.41,1.87-4.16,3.19-8.89,3.8
		C827.67,1087.04,825.26,1086.95,823.2,1086.55z"
      />
      <path
        d="M803.44,1122.31c0.19,0.23,0.37,0.46,0.56,0.69c0.56-7.52,1.12-15.03,1.68-22.55c0.11,1.02,0.36,2.05,0.68,3.09
		c0.01-0.32,0.02-0.64,0.04-0.96c-0.08-0.49-0.22-0.9-0.31-1.49c-0.25-1.55,0.09-3.21-0.05-4.78c-0.15-1.68-0.28-3.37-0.72-5
		c-0.01-0.03-0.02-0.05-0.02-0.08c-2.33,2.45-6.59,4.58-13.52,8.05c2.22,7.38,5.68,14.2,9.71,20.76
		C802.14,1120.8,802.8,1121.55,803.44,1122.31z"
      />
      <path
        d="M900.45,1021.59c-0.27-2.9-1.15-5.85-2.29-8.56c-11.57-27.67-26.53-53.2-47.64-74.81c-1.65-1.69-1.96-3.69-0.01-5.55
		c2.86-0.93,5.18,0.34,6.84,2.32c7.19,8.56,16.64,9.35,26.75,8.24c9.17-1,13.88-10.22,7.13-16.16c-15-13.22-30.84-25.35-53.13-23.45
		c-15.87,1.35-32.13,4.8-47.14-5.27c-5.04-3.38-11.46-1.3-17.33,0.62c-23.17,7.62-39.86,23.28-50.37,44.37
		c-4.99,10.02-11.13,21.11-7.43,33.99c4.86,16.93,13.95,30.28,27.79,41.43c14.6,11.76,27.89,25.35,32.46,44.61
		c4.1,17.26,4.69,17.82,22.76,11.07c-4.91-28.94-24.77-55.37-17.93-86.94c4.32,7.45,13.84,12.1,13.8,21.47
		c1.17,1.88,2.23,3.83,3.26,5.75c2.17,4.05,4.19,8.18,6.24,12.28c2.18,4.37,3.92,8.92,5.72,13.45c0.87,2.18,1.74,4.36,2.65,6.53
		c0.84,2,1.83,3.93,2.6,5.98c0.68,1.83,1.41,3.58,2.24,5.34c0.5,1.06,0.64,2.32,1.06,3.43c0.31,0.82,0.69,1.61,1.06,2.39
		c0.94,2-1.1,3.79-2.69,3.28c0.34,1.04,0.59,2.1,0.91,3.14c0.02,0.07,0.04,0.14,0.07,0.21c6.03,4.22,12.48,2.4,19.3-2.94
		c0.04,0.08,0.07,0.16,0.11,0.24c-0.05-0.19-0.09-0.38-0.14-0.56c-0.12-0.14-0.22-0.29-0.27-0.47c-0.12-0.29-0.17-0.57-0.2-0.88
		c-0.05-0.52-0.05-1.04-0.14-1.56c-0.2-1.19-0.51-2.38-0.87-3.54c-0.63-2.02-1.02-4.08-1.36-6.17c-0.64-3.96-2.04-7.77-3.04-11.65
		c-0.65-2.53-1.39-5.03-2.16-7.52c-0.51-1.28-1.01-2.57-1.46-3.87c-0.65-1.84-1.29-3.67-1.91-5.51c-0.07-0.08-0.14-0.15-0.21-0.24
		c-0.18-0.24-0.31-0.48-0.45-0.75c-0.01-0.03-0.02-0.03-0.02-0.04c-0.02-0.03-0.04-0.06-0.07-0.11c-0.14-0.22-0.26-0.41-0.37-0.64
		c-0.72-1.55-1.18-3.21-1.91-4.75c-0.51-1.09-0.99-2.2-1.48-3.3c-0.61-0.05-1.22-0.12-1.82-0.21c-9.29-12.83-12.14-26.16-14.96-39.5
		c1.26-0.62,2.53-1.24,3.79-1.86c6.8,11.31,14.53,22.2,20.04,34.11c0.57,1.22,0.84,2.26,0.9,3.15c0.27,0.52,0.53,1.04,0.79,1.56
		c2.13,4.26,3.9,8.68,5.91,12.99c1.99,4.25,3.58,8.66,5.37,13c0.33,0.81,0.71,1.61,1.09,2.41c0.04-0.01,0.07-0.02,0.11-0.03
		c4.15,1.99,4.38,6.1,5.22,9.68c1.14,4.83,1.9,9.63,8.84,7.59c5.87-1.73,6.99-5.88,5.31-11.02c-4.34-13.32-5.79-27.35-11.97-40.34
		c-6.94-14.59-11.65-30.23-17.45-45.75c5.02-0.29,6.15,3.01,7.8,5.25c14.09,19.14,29.4,37.47,38.2,60.03
		c1.75,4.49,4.18,10.9,10.82,7.87C894.7,1032.88,901.18,1029.65,900.45,1021.59z"
      />
      <path
        d="M1486.3,729.18c-23.9-25.94-51.82-46.11-84.31-60.77c-13.95,14.93-27.77,29.28-41.06,44.11
		c-7.5,8.37-13.81,10.83-23.35,2.01c-19.75-18.24-39.04-36.64-52.57-60.29c-3.12-5.45-6.85-11.69-11.98-14.65
		c-24.99-14.4-52.5-20.56-80.56-23.3c-2.68,4.74-0.16,7.56,1.21,10.55c6.4,13.98,14.57,26.95,23.58,39.34
		c5.94,8.17,6.57,16.42,2.16,25.21c-9.17,18.26-18.06,36.67-27.83,54.6c-0.86,1.57-1.56,3.25-2.23,4.95
		c0.11,0.36,0.11,0.75-0.05,1.17c-0.83,2.2-1.89,4.3-2.99,6.38c-0.44,0.92-0.91,1.81-1.43,2.67c-0.95,1.77-1.88,3.55-2.69,5.38
		c-1.44,3.31-2.75,6.85-4.57,10.04c-1.43,5.24-5.43,9.92-9.27,16.01c-1.11-8.16,0.49-13.78,3.77-18.92c0.02-0.3,0.08-0.61,0.21-0.9
		c0.65-1.47,1.3-2.94,1.95-4.4c0.5-1.71,1.07-3.4,1.82-4.97c0.17-0.36,0.34-0.73,0.51-1.1c-0.01-0.83,0.08-1.53,0.41-2.09
		c0.54-0.91,1.07-1.82,1.58-2.74c0.07-0.18,0.17-0.35,0.28-0.51c8.11-14.54,13.36-30.38,20.72-45.25
		c10.14-20.51,18.45-40.76-2.11-60.39c-3.55-3.39-5.63-8.53-7.77-13.16c-7.7-16.67-16.73-32.86-20.75-51.22
		c8.58-1.84,7.59,8.18,13.64,8.65c33.63-36.38,74.6-60.8,121.96-74.97c17.14-5.13,17.86-7.73,8.9-23.56
		c-1.04-1.84-2.53-3.41-3.72-5.17c-17.73-26.01-41.41-46.06-65.58-65.63c-14.86-12.03-24.69-9.85-27.14,9.18
		c-4.29,33.21-25.3,55.87-44.89,79.69c-11.76,14.29-27.52,16.67-44.98,9.56c-23.38-9.53-42.35-26.53-64.7-37.57
		c-21.95-10.84-34.43-29.36-44.97-49.97c-1.63-3.19-4.01-7.05,1.81-9.86c10.35,10.58,14.91,25.54,26.42,35.27
		c26.12,22.07,56.68,37.16,85.73,54.59c6.84,4.11,16.11,6.98,22.97,0.9c17.05-15.14,31.76-32.38,40.65-53.84
		c-1.51,1.73-3.03,3.47-5.23,6c-0.78,2.09-4.65,2.27-5.08-0.29c-0.04-0.15-0.05-0.21-0.04-0.19c0-0.01,0-0.01,0-0.02
		c-0.06-0.15-0.13-0.3-0.19-0.45c-0.16-0.43-0.23-0.85-0.28-1.31c-0.02-0.23-0.03-0.47-0.04-0.7c-0.06-0.25-0.11-0.51-0.16-0.76
		c-0.45-0.88-0.71-1.92-0.71-2.9c0-0.69,0.03-1.39,0.01-2.08c-0.02-0.61-0.21-1.06-0.39-1.65c-0.39-1.27-0.43-2.63-0.65-3.93
		c-0.23-1.32-0.48-2.63-0.65-3.95c-0.32-2.5-0.19-4.98,1.08-7.22c1.22-2.14,3.21-3.53,5.58-4.13c0.08-0.02,0.15-0.02,0.22-0.03
		c0.56-0.19,1.17-0.31,1.85-0.32c4.9-0.07,6.11,3.77,6.2,7.86c0.1,4.21-0.08,8.43,0.38,13.07c0.34-0.29,0.67-0.6,0.99-0.91
		c0.01-0.03,0.02-0.06,0.04-0.09c0.6-1.24,1.3-2.43,2-3.63c1.19-3.01,2.31-6.05,2.98-9.26c0.08-0.41,0.27-0.7,0.49-0.93
		c0.75-12.7,7.12-23.25,12.8-34.09c8.59-16.41,11.39-33.34,5.66-52.83c-6.75,6.43-13.98,6-21.15,4.74
		c-5.53-0.97-8.25-4.93-2.83-8.37c6.02-3.82,11.01-10.75,18.5-10.77c10.1-0.03,11.92-5.78,12.7-13.79
		c-11.98-7.99-21.65,9.29-34.34,2.8c5.9-9.32,15.56-10.81,23.72-14.19c12.88-5.33,14.86-12.66,7.57-28.64
		c-6.07-13.31-12.11-2.97-18.03-0.32c-4.24,1.9-8.2,4.42-12.23,6.76c-7.29,4.23-13.95,4.68-21.05-1.13
		c-7.86-6.43-16.01-12.81-24.91-17.6c-43.13-23.24-91-7.47-99.21,45.63c-1.5,9.71-4.64,18.83-11.26,30.13
		c-4.46-26.1,1.8-46.35,13.26-65.22c8.37-13.79,19.34-24.39,36.13-28.17c27.13-6.1,51.55-0.91,73.65,15.55
		c22.36,16.66,22,16.3,44.17,3.85c21.01-11.8,19.26-29,9.81-44.02c-24.1-38.3-60.71-53.63-104.14-56.58
		c-34.85-2.37-64,11.86-89.96,32.57c-21.06,16.8-39.64,36.38-44.9,64.78c-1.76,9.5,0.63,15.27,9.17,21.21
		c15.14,10.55,29.38,21.73,30.83,43.85c1,15.15,2.14,31.73,12.15,45.93c3.51-10.27,2.03-20.96,9.82-32.09
		c3.48,19.38-0.86,34.47-6.61,49.2c-6.57-0.31-7.91-4.42-9.37-7.68c-6.82-15.19-13.6-30.5-12.23-47.77
		c1.03-12.95-5.21-22.46-12.91-32.12c-16.39-20.57-42.2-21.41-63.94-34.13c-6.85,44.48,12.42,78.94,36.26,114.85
		c-14.82-3.24-15.67-14.66-22.28-20.42c-5.06-4.41-7.81-13.22-17.38-9.63c-15.5,5.81-31.13,11.07-45.69,19.37
		c-44.31,25.28-78.23,60.77-104.68,103.95c-2.96,4.83-5.79,9.35-2.37,16.65c98.72-15.49,169.85,28.52,223.17,107.47
		c11.02-4.58,9.23-18.7,19.28-22.97c3.42,4.47,1.83,8.2,0.52,11.71c-8.12,21.91-17.31,43.33-31.24,62.3
		c-6.25,8.51-6.78,15,2.51,22.43c53.03,42.48,105.43,85.75,158.67,127.96c7.34,5.82,14.03,15.64,24.86,14.31
		c-0.05-0.09-0.1-0.18-0.16-0.27c-0.24-0.24-0.49-0.46-0.71-0.71c-0.19-0.18-0.33-0.39-0.41-0.63c-0.03-0.07-0.05-0.12-0.07-0.16
		c-0.04-0.08-0.14-0.27-0.14-0.27c-0.13-0.23-0.27-0.45-0.4-0.69c-0.03-0.06-0.06-0.13-0.09-0.19c-0.29-0.46-0.58-0.91-0.87-1.38
		c-0.02-0.02-0.03-0.05-0.04-0.07c-0.09-0.16-0.19-0.31-0.28-0.47c-0.65-1.06-1.29-2.1-1.89-3.19c-0.05-0.09-0.1-0.18-0.15-0.26
		c-0.75-0.87-1.34-1.86-1.78-2.76c-11.48-23.36-28.58-43.87-29.39-72.31c-1.04-36.61-12.29-71.97-17.65-108.11
		c-0.37-2.51-0.64-6.09-5.69-7.38c6.54,39.28,12.99,77.95,19.43,116.62c-1.03,0.46-2.06,0.92-3.09,1.38
		c-14.44-17.13-25.48-36.77-35.66-56.55c-10.6-20.58-7.97-43.79-7.87-67.03c-7.93,10.14-3.28,22.74-8.84,34.13
		c-8.88-10.17-16.1-20.69-18.14-32.23c-2.13-12.05,7.07-21.4,16.37-28.36c18.56-13.88,40.22-13.35,61.36-9.6
		c11.42,2.02,15.91,13.97,19.55,23.79c6.86,18.51,2.87,37.49,1.29,56.25c-1.19,0.24-2.38,0.48-3.58,0.72
		c-3.65-13.75-7.3-27.49-10.94-41.18c-4.13,0.67-3.56,3.41-3.05,5.02c6.6,20.94,6.47,42.84,9.5,64.29
		c2.49,17.66-2.84,34.8-2.13,52.26c0.26,6.36,0.62,12.72,0.93,19.08c8.91,17.56,17.1,33.4,25.16,49.19c1.14,1.69,2.3,3.49,3.03,5.4
		c0.18,0.47,0.37,0.94,0.55,1.41c0.1,0.25,0.18,0.5,0.27,0.76c0.04,0.11,0.08,0.22,0.12,0.33c0.02,0.05,0.03,0.08,0.03,0.1
		c0,0.01,0.01,0.01,0.01,0.02c0.12,0.26,0.26,0.5,0.4,0.74c0.12,0.2,0.24,0.4,0.31,0.63c0.05,0.16,0.01,0.09-0.02,0
		c0.1,0.29,0.21,0.53,0.28,0.82c0,0.01,0.01,0.01,0.01,0.02c0.14,0.19,0.22,0.4,0.27,0.61c0.45,0.76,0.79,1.29,0.73,1.78
		c-5.21,41.76-9.54,83.67-16.08,125.23c-4.65,29.56-5.48,59.38-9.22,88.96c-0.85,6.75,1.71,9.69,8.15,11.97
		c13.19,4.67,26.53,9.55,38.08,17.63c9.59,6.71,18.34,14.63,29.47,23.65c-11.06-69.44-17.06-136.72-15.75-204.54
		c1.32-68.33,23.48-131.57,45.07-196.73c5.25,5.57,3.31,9.49,2.57,12.93c-3.15,14.56-7.01,28.97-10,43.55
		c-2.06,10.06-5.05,20.5-4.24,30.44c0.51,0.32,1.01,0.65,1.48,1.01c0.42,0.32,0.83,0.66,1.24,0.99c1.66,0.86,3.36,1.66,4.99,2.58
		c1.23,0.69,2.26,1.55,3.37,2.41c0.37,0.29,0.66,0.6,0.94,0.93c2.25,0.72,4.35,1.68,5.81,3.39c0.54,0.64,1.33,1.07,1.98,1.61
		c29.3,24.5,58.48,49.15,87.95,73.45c15.33,12.64,17.59,12.85,30.11-2.08c13.86-16.53,26.24-34.3,39.4-51.42
		c19.42-25.26,38.97-50.43,58.48-75.62C1490.11,738.85,1491.24,734.53,1486.3,729.18z M1092.64,330.07
		c-1.31-4.1,0.86-5.42,4.74-4.56c18.36,3.48,32.23,11.77,45.41,21.77c-8.85,10.11-26.41,9.2-39.48-1.73
		C1098.36,341.42,1094.61,336.25,1092.64,330.07z M1144.11,444.97c-4.01,6.4-9.09,11.88-17.9,18.04
		c0.67-14.13-3.88-26.22,7.15-35.91C1148.39,429.55,1150.75,434.35,1144.11,444.97z M1180.33,412.13c9.16,2.8,15.48,1,13.67-10.69
		c-6.26-17.12-15.29-31.82-9.51-49.25c0.53-1.59,1.24-3.15,2.09-4.6c0.38-0.65,1.29-1,1.96-1.48c2.67,0.48,3.52,2.54,3.18,4.66
		c-2.88,17.75,4,33.28,10.94,48.78c7.39,16.49,4.44,20.72-13.98,21.68c-12.04,0.63-22.67-0.76-30.44-13.12
		C1166.8,406.69,1173.4,410,1180.33,412.13z M1164.11,335.17c-16.01,1.8-24.65-11.43-36.41-16.85
		c-12.25-5.64-26.24-6.48-37.48-14.44C1118.43,296.91,1156.79,313.27,1164.11,335.17z M1049.38,586.9
		c-15.32-7.28-23.7-19.38-31.53-32.06c-11.18-22-18.01-43.09-14.78-69.42c23.47,15.96,42.27,35.97,69.34,44.37
		C1062.61,548.59,1052.82,565.46,1049.38,586.9z M1159.77,554.99c-2.08,9.08-4.04,18.74-8.7,26.55c-6.18,10.35-17,1.32-25.7,3
		c-9.87,1.9-20.34-0.43-30.06,4.22c-8.72,4.17-17.77,7.62-26.63,11.37c-12.8-14.94-6.93-44.66,12.45-63.65
		c5.37-3.29,9.38-6.09,13.6-4.35c16.07,6.65,32.72,4.01,49.23,3.84C1157.48,535.83,1162.8,541.79,1159.77,554.99z"
      />
      <path
        d="M1532.92,1799.43c19.98,59.2,37.53,117.89,40.69,180.75c-11.05-0.19-16.84-7.22-23.68-11.47
		c-37.58-23.35-76.71-43.56-117.56-60.52c-26.99-11.21-53.81-22.85-80.83-33.99c-10.2-4.2-20.51-9.55-31.19-10.86
		c-21.21-2.6-29.05-16.29-31.09-34.32c-1.69-14.93-4.83-29.48-8.67-43.81c-2.82-10.53,0.52-15.88,9.73-19.94
		c18.41-8.13,19.64-10.48,12.46-28.85c-7.35-18.83-7.92-39.07-13.6-58.3c-9.23-31.24-13.9-64.02-33.99-91.15
		c-2.54-3.43-2.38-7.1-2.12-10.97c7.01-103.47,8.15-207.1,7.1-310.73c-0.38-37.88-3.8-75.62-9.07-113.29
		c-12.77-91.41-24.09-183.03-18.69-275.72c1.05-17.95,5.33-35.41,9.16-52.86c1.67-7.58-2.22-16.46,5.24-23.52
		c13.43,8.39,25.14,18.65,36.1,29.78c1.68,1.71,3.31,4.43,3.31,6.68c-0.01,18.08,6.17,35.23,7.79,52.96
		c3.22,35.23,10.58,69.78,16.19,104.6c7.03,43.61,15.02,87.07,22.89,130.54c2.94,16.29,6.37,32.5,10.12,48.62
		c11.93,51.23,23.7,102.5,36.37,153.54c6.75,27.18,15.18,53.94,22.95,80.85c10.11,35.03,19.75,70.2,30.67,104.98
		c9.78,31.17,20.93,61.91,31.63,92.78c10.54,30.43,21.16,60.83,31.96,91.17c7.7,21.6,15.92,43.02,23.61,64.62
		C1524.82,1773.37,1528.63,1785.95,1532.92,1799.43z"
      />
      <path
        d="M1148.55,1031.19c-0.14,25.3-9.54,34.6-34.24,35.29c-36.67,1.01-73.25-2.83-110-0.88c-26.24,1.39-48.56,10.48-71.51,27.89
		c14.71-27.2,23.17-53.52,29.71-80.66c10.98-45.55,10.91-90.87,0.19-136.35c-6.84-29.04-15.61-57.36-29.95-83.72
		c-14.01-25.75-32.12-48.29-56.77-64.1c-9.16-5.87-20.59,1.3-29.9,5.7c-39.09,18.48-66.32,49.68-89.03,85.34
		c-9.36,14.7-11.71,16.05-25.01,4.22c-16.68-14.84-35.56-24.59-57.01-29.71c-15.69-3.74-25.28-1.56-37.43,9.57
		c32.24-1.66,61.58,4.73,86.98,29.69c-62,12.97-91.64,56.83-111,112.66c-21.39-20.92-34.65-44.16-39.12-72.25
		c-0.4-2.5,0.28-5.22-0.3-7.65c-7.71-32.67,9.77-52.51,32.95-72.1c47.56-40.2,95.66-80.21,135.94-127.98
		c13.19-15.64,30.52-19.64,47.05-25.7c23.84-8.74,48.9-14.53,74.2-17.74c35.62-4.52,71.54-3.85,107.22-1.31
		c24.54,1.74,26.58,7.75,13.98,28.32c-17.68,28.85-17.54,28.7,7.35,52.03c31.33,29.37,64.7,56.06,100.12,80.42
		c23.42,16.1,44.97,34.88,67.79,51.89c10.1,7.53,10.66,15.82,8.92,27.75C1161.52,917.72,1147.71,972.96,1148.55,1031.19z
		 M1140.66,936.38c4.53-6.25,6.25-12.32-2.15-16.68c-3.69-1.91-7.55-0.48-10.52,2.15c-2.91,2.58-2.63,6.15-1.12,9.32
		C1129.27,936.22,1133.02,939.13,1140.66,936.38z M1147.08,853.38c-3.56-4.76-7.89-6.31-12.91-2.45c-3.43,2.64-3.75,6.52-2.21,10.27
		c1.55,3.78,4.57,6.47,8.82,5.36C1146.47,865.08,1148.8,860.91,1147.08,853.38z M1131.93,1008.77c5.28-2.81,9.83-6.33,4.69-12.53
		c-2.41-2.91-5.32-7.24-10.41-5.16c-3.34,1.37-5.24,4.61-5.17,8.01C1121.16,1004.63,1123.74,1008.69,1131.93,1008.77z"
      />
      <path
        d="M1102.29,1380.61c0.86,18.26,7.05,34.52,11.63,50.57c11.44,40.09,14.39,81.09,19.07,122.01c0.34,2.96,0.76,5.92,1.26,8.86
		c2.86,16.72,6.06,31.65-2.83,49.66c-21.88,44.34-25.34,93.84-31.74,142.29c-2.8,21.2,0.4,26.46,21.87,28.8
		c16.28,1.77,23.78,9.56,24.89,26.55c0.62,9.59-6.31,7.96-11.34,6.05c-26.46-10.05-54.28-10.29-81.87-11.5
		c-38.83-1.72-77.47,0.61-115.71,8.07c-3.16,0.62-6.48,2.74-11-0.83c5.32-13.58,10.68-27.61,16.36-41.51
		c1.4-3.44,5.13-3.92,8.48-4.93c17.48-5.24,20.26-8.81,19.84-27.23c-1.2-52.84-5.89-105.42-13.52-157.7
		c-1.32-9.07,1.16-16.7,4.31-24.62c14.87-37.4,29.73-74.79,43.77-112.53c12.86-34.57,27.51-68.49,41.54-102.63
		c9.5-23.11,19.27-46.11,30.37-69.1C1085.59,1307.44,1093.55,1343.57,1102.29,1380.61z"
      />
      <path
        d="M792.45,1652.03c10.67-24.08,21.76-46.89,34.39-68.91c5.34-9.31,12.3-14.39,24.18-9.64c26.69,10.68,54.71,11.07,82.79,8.07
		c8.45-0.9,15.14-0.71,14.89,9.71c-1.13,47.32,8.51,94.03,7.76,141.32c-0.28,17.82-1.76,18.9-19.66,20.17
		c-51.57,3.68-100.46-5.98-146.97-28.28c-2.67-1.28-5.3-2.65-7.83-4.18c-17.61-10.66-17.54-10.71-8.77-28.63
		C779.53,1678.76,784.14,1665.03,792.45,1652.03z"
      />
      <path
        d="M899.29,1839.18c-17.49,39.47-30.07,79.48-36.92,121.1c-1.92,11.67-8.1,13.96-17.86,12c-26.38-5.29-52.98-8.82-79.88-9.59
		c-9.2-0.26-10.76-4.6-8.65-12.49c8.11-30.38,12.91-61.52,19.48-92.23c7.71-36.07,12.32-73.07,9.63-110.37
		c-0.12-1.6,0.4-3.24,0.94-7.17c46.14,19.53,93.38,30.04,145.14,29.45C920.89,1793.96,907.72,1815.02,899.29,1839.18z"
      />
      <path
        d="M1129.79,1659.01c2.45-15.86,8.21-29.6,12.89-43.69c2.66-7.99,6.94-11.97,15.78-11.48c24.47,1.37,48.31-4.01,71.87-9.17
		c12.88-2.82,16.98,2.73,20.65,12.35c9.39,24.63,15.82,50.13,22.2,75.63c4.22,16.87,6.94,34.04,12.33,50.72
		c4.63,14.34-2.26,21.82-22.15,29.47c-36.86,14.17-74.78,14.32-113.17,9.24c-0.84-0.11-1.65-0.43-2.49-0.58
		c-29.61-5.21-30.22-5.26-27.61-35.6C1122.29,1710.53,1122.56,1684.85,1129.79,1659.01z"
      />
      <path
        d="M1636.62,509.69c-10.86,5.51-21.17,9.29-32.59,9.25c-3.08-0.01-6.24,0.69-6.93,4.33c-0.87,4.58,2.79,6.11,6.06,6.74
		c8.73,1.67,17.56,2.82,28.48,4.5c-7.36,3.13-13.24,5.52-19.02,8.15c-2.67,1.22-5.61,2.54-5.63,6.23c-0.02,4.58,3.91,5.81,6.93,6.24
		c8.31,1.18,16.74,1.51,25.57,2.21c-4.46,5.17-10.6,5.27-16.19,6.62c-3.93,0.95-9.17-0.55-10.78,4.88
		c-1.92,6.46,6.18,4.53,7.86,9.18c-6.19,2.22-14.27,2.72-18.11,7.01c-6.96,7.78,7.35,8.05,7.24,14.8
		c-8.73,1.77-15.44,0.33-22.27-7.06c-18.42-19.91-41.76-33.33-65.92-45.05c-6.31-3.06-9.2-7.05-9.78-13.47
		c-0.89-9.76-1.48-19.54-2.62-29.27c-2.9-24.84,4.84-44.88,24.92-60.49c11.32-8.8,21.53-19.05,32.12-28.79
		c5.73-5.27,11.52-7.17,18.13-1.65c6.51,5.43,7.91,11.75,3.61,19.22c-2.92,5.07-5.72,10.2-8.35,14.91c3.05,4.42,5.69,2.31,8.36,1.2
		c18.42-7.65,36.2-16.78,55.44-22.55c22-6.6,27.17-4.98,39.38,15.08c11.84,19.46,10.48,45.75-3.4,63.51
		c-5.34-1.16-7.5-5.19-8.13-10.01c-1.39-10.53-7.11-19.17-12.44-27.76c-10.7-17.24-26.56-16.99-39.93,0.83
		c9.92,1.77,20.23-10.29,28.46-0.05c5.47,6.8,11.56,15.38,11.98,23.44C1659.71,504.46,1647.02,506.13,1636.62,509.69z"
      />
      <path
        d="M1522.16,574.65c19.61,12.86,38.62,25.07,53.28,42.66c13.35,16.02,13.73,20.58,2.76,38.6
		c-10.71,17.6-26.49,30.95-39.44,46.68c-5.15,6.25-10.33,12.52-16.06,18.22c-6.76,6.72-13.65,7.57-20.87-0.29
		c-27.15-29.54-59.17-52.05-96.86-66.34c-7.84-2.97-11.9-14.46-6.41-21.47c18.06-23.06,38.1-44.39,58.11-65.81
		c21.61-23.13,38.51-6.36,57,2.89C1516.3,571.12,1518.74,572.83,1522.16,574.65z"
      />
      <path
        d="M629.21,981.41c-8.41-3.32-9.64-8.71-7.49-15.93c11.68-39.32,27.51-75.87,62.51-100.9c23.13-16.54,48.45-21.92,76.19-17.84
		c7.73,1.14,13.07,5.18,16.47,12.45c3.73,7.97,8.26,15.57,13.03,24.42c-17.54,1.93-32.52,6.95-46.14,17.5
		c-27.06,20.95-37.53,49.47-40.63,81.79c-1.36,14.17-1.96,14.57-16.17,13.04C667.3,993.82,649.61,984.27,629.21,981.41z"
      />
      <path
        d="M786.87,627.51c-4.96,1.43-9.04,2.56-14.59,4.09c25.9-37.19,53.93-69.22,101.69-75.15c6.25-0.78,12.32-3.2,18.42-5.03
		c14.17-4.26,27.47-4.04,39.72,5.84c20.34,16.41,40.24,33.16,55.89,56.55C918.57,599.62,852.5,603.98,786.87,627.51z"
      />
      <path
        d="M1209.22,583.11c23.36-1.23,51.44,9.12,53.78,34.74c-24.41-7.03-48.81-12.23-75.1-12.29
		C1193.43,595.56,1200.49,589.13,1209.22,583.11z"
      />
      <path
        d="M1689.84,506.23c7.1-15.73,10.45-31.29,9.44-48.06c14.8,8.76,21.91,29.49,15.65,46.12c-2.5,6.64-6.33,12.95-10.47,18.76
		c-4.61,6.47-10.32,9.48-18.12,3.33c-8.02-6.33-2.03-10.97,0.96-16.15C1687.94,509.13,1688.76,508.14,1689.84,506.23z"
      />
      <path
        d="M1727.26,512.64c2.05,7.37,2.7,14.02,1.85,20.89c-3.02,24.59-10.3,31.08-36.29,31.24c-3.02-8.79-4.15-17.13,5.81-22.72
		c10.91-6.12,17.71-15.11,21.11-27.13C1720.46,512.36,1720.81,503.38,1727.26,512.64z"
      />
      <path
        d="M1697.04,593.99c-10.14,8.37-21.74,7.04-32.62,9.44c-2.3-16.73,1.06-21.54,15.43-23.73c7.89-1.2,15.77-2.45,23.66-3.63
		c0.33-0.05,0.73,0.37,1.9,1.01C1705.25,583.44,1700.07,587.79,1697.04,593.99z"
      />
      <path
        d="M1676.29,532.4c-17.55-8.67-27.61,9.68-41.71,14.23c2.46,0,4.93,0,8.18-0.08c11.91-3.94,24.63-3.62,34.15-12.17
		C1676.64,533.74,1676.41,533.07,1676.29,532.4z"
      />
      <path
        d="M1612.41,390.78c3.85-0.78,6.75-4.13,10.89-0.31c-7.23,7.28-11.43,17.04-20.71,24.17c-3.62-4.68-6.86-8.87-9.98-12.9
		C1597.39,393.83,1605.04,393.45,1612.41,390.78z"
      />
      <path
        d="M1642.8,586.19c4.55-0.99,8.24-2.75,12.27,0.55c0.73,5.58-1.04,12-6.09,13.44c-8.78,2.5-15.39-4.47-23.51-8.3
		C1630.93,586.81,1636.87,588.1,1642.8,586.19z"
      />
      <path
        d="M1652.66,571.79c-4.02-0.69-7.54-0.12-11.37-4.01c14.76,0.91,24.88-10.02,38.9-10.87c2.2,7.79-0.48,12-7.66,13.1
		C1666.27,570.98,1659.89,571.25,1652.66,571.79z"
      />
      <path
        d="M915.29,946.55c3.58,5.14,0.54,6.41-3.18,7.08c-4.08,0.74-7.55-0.46-9.41-4.33c-1.3-2.69-1.81-5.88,0.8-8.05
		c2.75-2.29,5.42-0.8,7.71,1.34C912.41,943.73,913.63,944.86,915.29,946.55z"
      />
      <path
        d="M1668.48,515.22c-0.02-0.02-0.04-0.04-0.06-0.06c-0.18-0.04-0.35-0.11-0.52-0.22c-0.09-0.06-0.16-0.12-0.23-0.17
		c-0.11-0.05-0.23-0.1-0.32-0.19c-0.14-0.09-0.26-0.2-0.34-0.34c-0.12-0.12-0.2-0.26-0.25-0.42c-0.12-0.23-0.18-0.48-0.17-0.74
		c0-0.01,0-0.01,0-0.02c-7.12-3.72-10.45-1.98-19.47,8.09c2.41,0,4.48,0,7.37-0.04c5.32-1.57,11.22-0.58,14.1-5.8
		C1668.55,515.28,1668.52,515.25,1668.48,515.22z"
      />
      <path
        d="M1198.01,460.18c0.6-1.24,1.3-2.43,2-3.63c0.59-1.48,1.15-2.97,1.66-4.47l-3.56-11.03c0.54,5.89-0.79,11.83-3.05,17.3
		c-1.44,3.5-3.25,6.82-5.22,10.05c0,0.02,0.01,0.05,0.01,0.07c0,0.01,0,0.01,0.01,0.02c0,0,0,0,0,0c0.04,0.22,0.07,0.45,0.1,0.7
		c0.13,1.44-0.98,2.33-2.21,2.54c-0.44,0.69-0.89,1.38-1.34,2.06c1.33-0.77,3.07,1.04,4.44,0.35c0.66-0.33,0.94-1.11,1.19-1.81
		c0.68-1.87,1.61-3.64,2.54-5.4c0.94-1.77,1.87-3.54,2.81-5.31C1197.58,461.13,1197.78,460.65,1198.01,460.18z"
      />
      <path
        d="M846.43,1070.19c3.01,6.34,5.62,12.87,7.82,19.53c0.75,2.26,1.45,4.65,1,6.99c-0.38,2-1.57,3.74-2.58,5.51
		c-6.18,10.86-6.25,24.61-13.49,34.78c3.27-1.43,6.1-3.84,8.02-6.85c-1.25,1.84-3.55,2.9-5.76,2.65"
      />
      <path
        d="M841.58,1143.74c3.39-5.35,5.71-11.37,6.79-17.61c0.13-0.76,0.24-1.56-0.07-2.27c-0.59-1.36-2.57-1.67-3.81-0.84
		c-1.23,0.83-1.76,2.4-1.78,3.89c-0.01,1.48,0.4,2.93,0.67,4.39C844.12,1135.27,843.73,1139.45,841.58,1143.74z"
      />
      <path d="M794.79,1031.24c4.8,15.13,9.02,30.46,12.63,45.92c3.53,15.1,6.49,30.81,3.42,46.01c4.11-3.42,10.55-3.65,14.9-0.53" />
      <path
        d="M778.05,1098.21c8.82,10.9,17.63,21.8,26.45,32.7c3.22,3.98,6.48,8.03,8.45,12.76c0.64,1.55,1.15,3.21,0.98,4.88
		c-0.18,1.79-1.12,3.4-2.19,4.84c-4.18,5.62-10.81,9.35-17.78,10"
      />
      <path
        d="M804.82,1067.02c5.87,24.33,7.09,49.77,3.59,74.56c-0.24,1.72-0.47,3.6,0.47,5.06c0.43,0.67,1.07,1.18,1.73,1.63
		c6.13,4.22,14.74,4.45,21.09,0.57"
      />
      <path
        d="M800.07,1045.54c2.87,15.14,11.89,28.26,18.42,42.22c5.82,12.45,9.91,26.06,18.92,36.44c1.94,2.24,4.11,4.33,5.53,6.93
		c2.23,4.08,2.34,9.25,0.27,13.42c-2.06,4.17-6.24,7.22-10.84,7.91"
      />
      <path
        d="M843.34,1055.22c2.7,19.83,2.73,40.01,0.09,59.85c-1.04,7.84-2.43,16.19,0.93,23.35c4.18,8.92,16.01,13.3,25,9.27
		c1.12-0.5,2.26-1.19,2.79-2.3c0.46-0.97,0.38-2.1,0.28-3.17c-0.76-8.24-1.83-16.44-3.45-24.57"
      />
      <path
        d="M863.98,1018.11c8.31,21.81,10.27,45.48,11.16,68.8c0.18,4.67,0.31,9.38-0.36,14.01c-0.81,5.49-2.77,10.81-5.73,15.51
		c-0.49,0.78-1.03,1.62-0.98,2.55c0.05,0.87,0.63,1.61,1.24,2.22c4.39,4.43,11.22,5.09,17.44,5.48c2.36,0.15,4.77,0.29,7.06-0.3
		c2.29-0.59,4.48-2.06,5.33-4.27"
      />
      <path
        d="M906.73,1002.05c4.91,24.57,2.51,50.55-6.83,73.8c-1.24,3.09-2.63,6.18-4.71,8.78c-1.62,2.02-3.63,3.71-5.12,5.83
		c-1.49,2.12-2.41,4.89-1.48,7.31c0.89,2.31,3.36,3.76,5.82,4c2.47,0.24,4.93-0.56,7.15-1.67c7.45-3.72,12.76-10.85,15.89-18.56
		c3.13-7.71,4.33-16.06,5.38-24.32c2.32-18.15,4.07-36.76-0.09-54.58c-0.77-3.28-1.77-6.6-3.84-9.26"
      />
      <path
        d="M779.84,986.27c4.98,3.12,7.83,8.64,10.45,13.89c9.86,19.79,19.81,39.84,24.44,61.45c0.71,3.32,1.72,7.21,4.88,8.45
		c2.79,1.1,5.99-0.54,7.95-2.82c3.9-4.54,4.36-11.19,3.08-17.03c-1.27-5.85-1.1-12.7-3.02-18.37c-1.51-4.47-3.65-7.52-4.94-12.05
		c-1.44-5.07-2.62-10.14-4.42-15.09c-2.41-6.61-7.21-13.22-9.62-19.83c-1.77-4.85-3.56-9.75-6.46-14.02s-7.07-7.9-12.09-9.08"
      />
      <path
        d="M814.4,1057.85c-1.42,8.2,5.62,16.81,13.94,17.04c1.5,0.04,3.02-0.16,4.39-0.77c3.76-1.69,5.54-6.21,5.4-10.34
		c-0.14-4.12-1.78-8.02-3.11-11.92c-1.34-3.94-2.39-8-4.04-11.83c-1.72-3.98-4.06-7.66-6.39-11.32c-2.58-4.06-5.17-8.12-7.75-12.17"
      />
      <path
        d="M924.48,954.78c-0.34-0.39-0.63-0.82-0.96-1.22c-0.44-0.53-0.9-0.98-1.34-1.52c-0.41-0.5-0.78-1.03-1.17-1.55
		c-0.2-0.26-0.41-0.52-0.62-0.79c0.02,0.03-0.83-0.96-0.42-0.58c-0.57-0.54-1.06-0.94-1.54-1.57c-0.24-0.31-0.49-0.62-0.7-0.95
		c-0.18-0.28-0.41-0.63-0.01-0.23c-0.5-0.49-0.96-0.91-1.42-1.46c-0.23-0.27-0.48-0.52-0.7-0.8c-0.06-0.07-0.15-0.19-0.23-0.3
		l-13.36-10.56c-0.27,6.28-4.37,11.75-8.98,16.02c-0.25,0.24-0.54,0.55-0.43,0.88c0.08,0.26,0.37,0.39,0.63,0.48
		c10.01,3.69,20.31,7.43,30.98,7.19c0.9-0.02,1.84-0.13,2.72-0.38C926.12,956.56,925.27,955.69,924.48,954.78z"
      />
      <path
        d="M1191.8,436.74c5.51,4.82,6.36,13.39,3.92,20.3c-2.43,6.9-7.52,12.5-12.59,17.77c1.07,1.31,3.13,1.43,4.65,0.69
		c1.52-0.74,2.6-2.14,3.56-3.54c3.64-5.35,6.12-11.39,8.57-17.38C1202.4,448.5,1204.93,441.83,1191.8,436.74z"
      />
      <path
        d="M1187.21,436.23c-4.6,5.09-4.83,12.74-3.85,19.53s2.88,13.66,1.61,20.4c-0.11,0.61-0.31,1.29-0.86,1.56
		c-0.49,0.25-1.08,0.08-1.62,0.03c-2.17-0.2-4.11,1.51-6.28,1.55c-2.25,0.04-4.1-1.65-5.69-3.24c-2.36-2.36-4.81-4.86-5.78-8.05
		c-0.66-2.18-0.58-4.52-0.31-6.79C1165.32,453.49,1168.5,445.78,1187.21,436.23z"
      />
      <path
        d="M1666.28,514.15c-1.33-2.6-2.9-5.42-5.65-6.39c-3.01-1.06-6.25,0.5-9.05,2.02c-12.18,6.6-24.76,13.49-38.57,14.55
		c2.41,3.35,7.16,3.75,11.28,3.73c14.37-0.07,29.01-1.64,42.16-7.45c1.11-0.49,2.28-1.08,2.81-2.17S1669.16,515.69,1666.28,514.15z"
      />
      <path
        d="M1677.25,535.12c-0.29-0.6-0.56-1.25-0.76-1.91c-1.83-3.01-5.57-4.69-9.16-4.65c-3.98,0.04-7.74,1.83-11.05,4.05
		c-3.3,2.22-6.29,4.89-9.65,7.02c-7.13,4.54-15.57,6.46-23.82,8.31c1,1.36,2.72,1.95,4.36,2.34c14.65,3.56,29.9-1.79,43.72-7.81
		c1.9-0.83,3.83-1.69,5.34-3.11c0.96-0.9,1.39-1.85,1.33-2.98C1677.4,535.98,1677.29,535.55,1677.25,535.12z"
      />
      <path
        d="M1678.61,555.48c-10.85,1.56-20.93,6.35-31.42,9.52c-2.37,0.72-4.81,1.36-7.29,1.35c-3.84-0.01-8.45-1.32-10.97,1.57
		c1.5,1.97,3.9,3.01,6.25,3.75c12.6,3.97,26.22,1.44,39.18-1.11c1.97-0.39,3.98-0.79,5.7-1.83c2.89-1.74,4.58-5.27,4.12-8.61
		C1683.84,557.63,1681.68,555.04,1678.61,555.48z"
      />
      <path
        d="M1656.15,587.17c-0.09-0.12-0.19-0.25-0.28-0.37c-0.06-0.14-0.12-0.29-0.18-0.43c-0.02-0.16-0.04-0.32-0.06-0.48
		c0-0.12,0-0.24-0.01-0.37c-0.04-0.14-0.06-0.28-0.05-0.42c0-0.05,0-0.1,0-0.14c-0.01-0.04-0.03-0.07-0.05-0.1
		c-0.04-0.1-0.06-0.2-0.08-0.3c-0.03-0.23-0.06-0.44-0.07-0.66c-1.94-1.67-4.5-2.63-7.05-2.44c-1.81,0.13-3.52,0.79-5.24,1.39
		c-10.09,3.51-20.93,4.84-31.57,3.88c-0.91,2.05,1.07,4.19,2.92,5.46c9.38,6.42,21.07,9.38,32.38,8.2c2.07-0.22,4.21-0.6,5.89-1.83
		c3.11-2.27,3.63-6.46,3.66-11.18C1656.29,587.29,1656.21,587.24,1656.15,587.17z"
      />
    </>
  );
}
