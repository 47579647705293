export default function Unicorn(): JSX.Element {
  return (
    <>
      <path
        d="M605.36,2050.56c-6.1-24.62-21.99-39.96-43.61-50.67c-21.07-10.45-30.28-28.59-32.72-51.16
			c-3.17-29.34-2.56-58.78-2.48-88.19c0.12-44.02-4.29-87.69-13.17-130.64c-4.57-22.11,0.38-41.44,9.47-60.25
			c26.58-55.03,40.81-112.64,36.81-173.87c-2.77-42.49-15.79-82.18-36.59-119.46c-10.72-19.21-21.24-38.71-29.63-58.99
			c-8.79-21.24-11.07-44.22-13.22-67.14c-3.68-39.38,10.02-74.12,25.59-108.95c15.55-34.78,36.39-65.92,60.02-95.49
			c24.81-31.05,53.31-57.35,92.62-68.26c19.16-5.32,38.57-9.71,58.79-10.99c55.02-3.46,109.95,1.05,164.93,0.56
			c24.19-0.22,48.22,2.74,72.65,0.2c46.12-4.79,87.32-20.22,123-49.9c22.81-18.98,43.8-40.32,69.48-55.54
			c9.29-5.51,13-14.2,17.7-22.45c33.87-59.54,72.02-115.79,118.83-166.27c48.03-51.79,104.13-91.98,166.98-123.14
			c58.03-28.77,119.49-38.48,183.48-28.2c14.43,2.32,29.57,4.37,42.73,12.21c2.77,1.65,5.63,1.57,8.54,0.11
			c41.69-20.96,84.75-39.45,122.45-67.64c3.53-2.64,7.42-5.43,11.49-2.09c3.48,2.86,2.17,6.99,0.4,10.61
			c-9.72,19.8-19.83,39.33-36.02,54.87c-13.47,12.93-27.33,25.49-33.8,43.91c-2.17,6.17-2.99,11.68-1.45,18.46
			c11.73,51.98,13.18,104.31,7.14,157.35c-3.91,34.35-11.31,67.79-18.49,101.43c-6.91,32.35-12.3,65.02-18.89,97.44
			c-4.44,21.85-16.02,40.34-31.11,56.19c-8.52,8.95-19.07,0.92-27.42-2.01c-13.49-4.74-26.75-10.94-39.46-17.45
			c-16.33-8.36-22.45-25.98-20.21-42.52c4.04-29.83-3.28-57.25-10.87-85.12c-1.16-4.27-2.21-7.62-6.15-10.43
			c-39.49-28.04-45.39-67.27-36.32-111.21c3.06-14.84,5.37-29.94,14.96-44.71c-9.67,3.06-11.37,10.21-13.48,15.42
			c-12.53,30.86-20.44,62.46-9.09,95.67c4.26,12.45-4.16,22.26-7.61,32.53c-14.45,42.98-22.2,87.23-27.09,132
			c-1.09,9.97,3.36,20.78,6.3,30.9c7.1,24.45,15.19,48.56,18.91,73.95c5.31,36.2-1.65,70.03-16.76,102.83
			c-12.79,27.76-36.15,46.51-56.29,68.07c-30.83,33-57.53,68.68-77.29,109.78c-19.38,40.33-29.16,83.9-45.82,125
			c-10.96,27.05-17.17,55.23-23.45,83.25c-8,35.7-11.69,72.26-5.63,109.03c4.9,29.73,1.42,58.14-10.92,86.04
			c-8.3,18.77-5.52,40.19-9.98,60.14c-7.97,35.72-6.79,72.26-4.65,108.3c1.63,27.59,15.74,51.39,33.41,72.97
			c16.91,20.65,39.11,34.88,59.39,52.72c-40.62,0.8-81.64,0.8-124.11-0.13c-5.65-11.51-9.14-22.12-15.58-32.02
			c-13.57-20.83-24.85-43.15-18.27-69.49c12.97-51.87,11.85-104.73,11.29-157.52c-0.12-11.37-0.56-23.65-4.79-33.88
			c-11.53-27.88-9.72-55.88-4.02-84.06c8.81-43.55,9.54-87.28,2.27-130.82c-6.83-40.92-24.38-79.52-27.59-121.41
			c-0.71-9.22-7.05-14.13-16.72-14.17c-14.49-0.06-29-0.09-43.48-0.54c-97.09-2.97-194.26-4.19-291.14-12.31
			c-10.63-0.89-11.39-6.02-11.11-14.04c1.64-45.7,0.51-90.99-17.94-134.08c-6.43-15.01-13.25-29.6-24.36-41.87
			c12.54,30.1,23.2,60.27,27.89,92.91c5.3,36.89,4.49,73.35-3.34,109.15c-7.63,34.89-22.68,66.63-47.01,94.38
			c-39.13,44.63-77.87,89.77-111.67,138.75c-15.46,22.4-29.02,45.72-35.11,72.95c-2.92,13.03-2.71,26.29-5.42,39.1
			c-8.31,39.3-7.54,78.77-3.53,118.32c1.45,14.31,3.07,28.9,7.2,42.58c8.18,27.07,26.29,48.18,45.5,68.18
			c22.81,23.75,45.9,47.23,68.86,70.83C688.59,2050.56,647.57,2050.56,605.36,2050.56z"
      />
      <path
        d="M382.64,2050.56c-1.93-13.21-4.4-26.08-7.43-38.97c-3.46-14.72-11.04-27.08-21.94-36.42
			c-22.43-19.22-24.11-43.53-16.71-68.5c11.45-38.63,23.83-77.07,32.85-116.29c13.31-57.87,27.56-115.75,29.06-175.63
			c0.18-7.13,2.27-13.04,6.88-18.65c21.55-26.26,44.18-51.7,61.54-81.17c15.82-26.84,28-55,34.4-85.67
			c2.91-13.96,8.39-27.37,7.63-43.91c13.03,6.9,13.88,18.69,17.29,27.76c14.77,39.19,24.48,79.52,22.52,121.96
			c-2.02,43.7-12.15,83.65-40.62,119.17c-32.79,40.91-51.29,89.18-62.82,140.46c-8.16,36.27-12.06,73-16.11,109.71
			c-3.8,34.39,9.9,63.79,30.76,90.62c14.28,18.36,31.81,33.99,44.36,54.64C464.16,2050.56,424,2050.56,382.64,2050.56z"
      />
      <path
        d="M372.95,1255.67c-15.39,29.31-19.6,60.64-23.82,91.74c-4.39,32.36-0.4,64.27,6.18,96.32c4.06,19.79,2.86,40.73,3.38,61.16
			c1.03,40.91,3.02,82.1-4.94,122.41c-8.52,43.17-25.25,83.11-60.43,112.12c-48.37,39.89-102.6,55.3-162.64,31.15
			c-46.8-18.82-84.2-70.81-82.56-121.57c0.57-17.86,3.59-35.99,24.94-43.85c8.98,10.5,7.4,25.02,15.82,35.86
			c11.81,15.2,26.96,23.77,45.07,18.74c34.43-9.56,58.79-31.16,73.33-63.89c19.97-44.97,15.57-89.77,1.41-135.38
			c-11.37-36.6-29.69-70.24-42.27-106.24c-22.26-63.68-33.64-127.83-15.5-195.07c14.25-52.82,44.95-93.57,85.74-128.11
			c34.82-29.48,74.23-48.76,119.71-55.36c14.72-2.14,28.82,0.34,43.13,2.64c38.26,6.16,70.74,25.04,102.44,45.86
			c15.16,9.95,32.81,12.87,50.5,15.82c-1.09,14.16-11.59,23.15-16.25,34.92c-3.87,9.77-9.96,18.71-15.47,27.78
			c-4.89,8.06-10.57,15.29-20.03,18.84c-32.44,12.17-56.08,36.15-79.08,60.57c-17.17,18.23-31.86,38.5-43.2,60.99
			C376.5,1246.9,375.05,1250.9,372.95,1255.67z"
      />
      <path
        d="M1388.84,1374.04c21.92-49.97,52.84-92.7,87.39-133.33c11.4-13.41,25.18-16.74,40.47-17.6
			c24.44-1.37,47.53-9.1,71.01-14.49c37.18-8.52,73.05-22.85,111.09-27.6c37.27-4.65,75.6,13.18,74.23,60.34
			c-0.53,18.39-4.58,35.55-10.03,52.07c-10.14,30.73-18.16,61.97-25.09,93.4c-9.41,42.66-17.28,85.72-23.98,128.9
			c-3.14,20.26-16.17,34.22-25.44,50.66c-10.42,18.47-21.54,36.56-31.51,55.27c-17.67,33.13-43.61,59.27-70.59,84.45
			c-9.13,8.52-17.81,17.97-30.94,25.02c0.24-15.2,3.65-28.49,6.23-41.22c5.76-28.37,8.09-57.24,14.35-85.47
			c1.69-7.62,4.31-14.22,9.95-20.12c17.38-18.2,29.37-39.56,33.65-64.58c3.21-18.79,12.39-34.32,25.4-47.49
			c43.33-43.85,51.56-98.5,49.23-156.59c-0.22-5.39-1.92-9.34-8.12-9c-11.2,0.62-21.36-5-27.33-12.04
			c-13.14-15.49-26.58-10.12-39.91-3.71c-44.69,21.51-88.88,44.06-133.53,65.67c-32.36,15.66-64.14,32.68-98.11,44.83
			c-1.89,0.68-3.95,0.9-6.71,1.51C1378.71,1391.77,1387.67,1384.45,1388.84,1374.04z"
      />
      <path
        d="M1880.42,659.44c-36.69,17.33-72.52,34.17-110.42,51.99c-1.7-29.04,0.51-56.63-6.53-84.38
			c78.16-7.14,154.88-14.15,231.61-21.16C1959.85,629.15,1919.78,642.4,1880.42,659.44z"
      />
    </>
  );
}
