export default function Beholder(): JSX.Element {
  return (
    <>
      <path
        d="M1661.36,1262.04c-16.72,20.43-38.99,31.54-61.01,42.82c-27.93,14.31-57.64,24.28-87.73,32.62
			c-37.16,10.3-74.58,19.53-113.49,21.53c-31.5,1.61-62.89,4.52-94.5,2.42c-28.11-1.86-55-6.72-81.6-17.47
			c-23.42-9.46-47.64-17.61-69.71-29.55c-26.5-14.34-52.42-30.2-75.57-50.04c-19.95-17.1-40.74-33.19-62.88-47.46
			c-2.94-1.89-5.65-4.59-8.96-1.35c-3.73,3.65-1.31,6.74,1.3,9.94c18.73,22.97,37.74,45.72,55.95,69.1
			c32.46,41.68,60.72,86.14,86.92,132.03c26.91,47.14,54.07,94.17,82.7,140.27c18.08,29.11,40.97,54.94,64.56,79.79
			c17.98,18.94,40.76,29.41,66.8,33.89c20.49,3.52,40.54,8.81,61.57,9.88c42.06,2.14,79.47-11.28,114.63-32.28
			c14.43-8.62,29.4-16.91,40.89-29.78c1.44-1.61,3.49-2.44,6.81-0.73c1.71,9.29,2.65,19.29-0.75,29.08
			c-15.23,43.86-38.62,83.02-68.92,118.11c-30.84,35.71-69,57.31-116.47,62.85c-29.42,3.43-58.16,3.13-87.55-1.59
			c-37.71-6.05-71.71-22.04-107.04-34.64c-32.61-11.62-65.64-21.88-98.97-31.35c-35.38-10.05-70.71-20.42-105.44-32.82
			c-29.93-10.69-59.15-23.06-86.39-38.98c-26.49-15.48-51.36-34-73.33-55.72c-29.82-29.48-59.64-59.68-80.17-96.22
			c-26.12-46.51-57.9-89.04-86.7-133.63c-1.13-1.75-2.55-3.39-4.11-4.78c-1.15-1.03-2.74-1.57-4.6-2.58
			c-4.36,5.48-1.41,10.37,0.26,14.7c13.6,35.19,19.57,72.51,30.55,108.44c4.28,14.01,3.31,28.75,0.72,43.1
			c-6.57,36.32-23.08,68.56-40.41,100.71c-10.91,20.23-24.21,39.34-30.49,62.1c-4.24,15.35,1.43,27.16,11.01,36.97
			c26.2,26.84,34.03,57.89,26.78,94.32c-5.97,29.97-22.76,51.78-48.54,65.76c-22.44,12.16-47.23,11.2-70.13,0.46
			c-49.68-23.31-71.51-64.5-73.24-118.15c-1.13-35.03,10.46-65.93,26.17-96.56c16.46-32.11,37.56-61.56,52.76-94.23
			c16.08-34.56,23.3-70.19,14.72-108.81c-5.92-26.67-16.17-51.3-26.39-76.26c-8.26-20.18-11.58-41.91-12.97-63.39
			c-2.62-40.48-1.74-81.35,10.4-120.27c10.29-32.99,21.43-66.1,39.8-95.96c1.77-2.88,3.06-6.05,4.29-8.52
			c-11.5-19.7-19.34-41.27-34.65-57.37c-31.68-33.31-67.11-59.94-116.36-61.81c-35.68-1.36-65.92,8.12-90.47,34.55
			c-13.08,14.08-15.99,30.48-10.97,48.24c7.99,28.26,27.41,45.26,54.69,54.98c25.25,9,46.11,24.81,56.49,49.92
			c9.5,22.98,3.89,46.18-7.7,67.42c-10.41,19.09-26.48,32.95-44.8,44.37c-28.49,17.76-74.57,9.76-96.36-16.25
			c-17.84-21.3-28.02-46.45-27.51-73.19c0.71-37.44-11.78-68.99-32.95-98.14c-20.73-28.54-28.78-60.3-23.88-94.76
			c2.65-18.65,12.67-35.04,23.53-50.31c24.96-35.1,60.03-57.92,98.95-73.22c30.16-11.86,62.17-19.83,95.4-20.02
			c16.59-0.09,33.15-2.47,49.75-2.7c27.29-0.37,54.4,2.53,80.21,11.67c18.09,6.41,31.48,20.7,46.77,31.77c2.1,1.52,3,5.74,7.71,3.56
			c-6.02-19.53-18.34-35.48-30.54-51.14c-21.84-28.04-28.36-60.91-31.5-94.81c-1.41-15.23,0.18-30.71-0.32-46.05
			c-1.29-39.43,8.52-76.4,24.35-112.01c33.1-74.45,80.47-138.18,143.57-190.02c39.31-32.3,82.56-58.02,129.27-78
			c39.32-16.82,80.46-25.17,122.93-19.48c26.89,3.6,51.82,13.61,64.23,41.6c10.24,23.1,10.13,46.13-4.03,66.72
			c-14.22,20.66-37.73,29.34-60.62,24c-26.95-6.29-52.4-3.89-77.79,2.11c-24.29,5.74-47.04,16.45-67.89,30.76
			c-35.54,24.39-65.88,54.09-90.59,89.29c-20.53,29.25-35.37,61.45-42.96,96.43c-2.81,12.95,2.91,26.14,5.36,38.88
			c3.98,20.71,13.83,39.74,25.9,57.18c8.23,11.88,14.26,11.18,21.2-1.18c7.64-13.61,15.46-27.12,23.13-40.71
			c8.06-14.28,13.04-28.96,12.22-46.05c-1.26-26.25,1.88-52.63-8.71-78.09c-4.3-10.34-1.89-22.76-5.91-33.74
			c-1.99-5.44,2.68-8.99,5.67-11.93c23.78-23.37,49.23-44.72,78.04-61.72c4.57-2.69,9.92-4.05,16.72-6.75
			c-3.03,31.14,2.08,60.15,4.93,89.29c2.32,23.66,7.23,46.7,23.07,65.94c5.94,7.22,9.95,10.29,15.31-0.47
			c14.84-29.76,36.61-53.66,63.64-72.81c22.13-15.68,44.34-31.2,64.69-49.27c32.69-29.03,57.66-63.4,73.73-104.13
			c2.79-7.07,1.72-14.43,0.58-21.5c-5.41-33.49-14.22-66.34-20.21-99.66c-4.02-22.33-5.12-45.38-3.59-68.44
			c1.58-23.77,4.25-46.9,11.27-70.03c11.83-39,46.48-83.91,91.36-92.41c7.88-1.49,15.24-6.19,23.11-7.11
			c29.63-3.44,56.56,3.36,73.31,29.67c17.63,27.68,15.28,55.65-9.89,83.62c-13.93,15.48-31.75,24.03-51.66,29.47
			c-29.29,7.99-47.2,40.45-41.01,71.69c3.07,15.46,9.5,29.84,15.36,44.05c18.6,45.07,22.57,91.67,15.71,138.95
			c-4.49,30.96-18.41,58.51-40.63,81.76c-24.13,25.25-51.05,47.86-72.23,75.94c-2.67,3.54-5.38,6.58-3.94,12.51
			c12.34,0.95,20.28-8.56,29.13-14.16c28.34-17.95,55.13-38.06,75.98-64.95c30.6-39.46,49.61-83.24,52.3-133.78
			c1.2-22.53,13.8-40.45,28.49-56.68c24.12-26.66,55.41-42.11,87.97-55.48c21.14-8.68,42.6-16.7,64.74-22.21
			c27.52-6.84,54.85-13.6,83.91-3.82c24.12,8.11,41.39,35.41,43.06,56.36c2.92,36.75-20.38,73.06-54.52,86.51
			c-9.93,3.91-21.58,4.7-32.45,4.74c-20.93,0.08-41.83,0.93-62.11,5.7c-18.84,4.43-32.39,17.58-42.65,33.4
			c-18.13,27.97-24.48,60.78-36.24,91.38c-12.21,31.75-27.78,61.49-53.69,84.67c-5.61,5.02-11.97,9.62-14.63,17.42
			c4.2,6,9.36,4.95,14.68,2.54c31.91-14.43,62.31-31.39,91.73-50.56c26.91-17.54,52.26-36.93,76.93-57.27
			c35.46-29.23,69.31-60.5,105.67-88.54c37.44-28.88,77.12-55.95,124.21-65.87c33.83-7.12,68.51-6.28,98.56,16.25
			c11.1,8.33,16.8,36.29,10.06,50.48c-12.42,26.15-34.67,37.15-62.01,39.73c-21.17,1.99-42.33-1.48-63.63-0.25
			c-18.86,1.09-33.85,9.86-45.59,22.12c-25.31,26.46-47.97,55.44-72.86,82.32c-23.05,24.89-49.1,46.47-79.17,62.78
			c-2.84,1.54-5.49,2.28-8.48,1.66c-27.16-5.63-54.26-3.11-81.34,0.54c-14.01,1.89-26.77,8.09-39.53,13.35
			c-16.82,6.93-35.17,10.1-50.66,20.3c-1.75,1.15-3.92,1.73-4.01,4.53c2.04,3.86,5.17,3.42,8.61,1.95
			c25.04-10.7,51.21-17.1,78.05-21.37c25.11-4,50.06-3.64,75.01,0.61c37.16,6.32,71.83,18.95,103.38,40.01
			c33.47,22.34,60.59,50.72,78.28,86.67c13.34,27.12,28.62,53.66,36.57,83.12c6.83,25.29,14.4,50.5,12.38,77.44
			c-1.24,16.55,0.52,33.31-0.48,49.89c-0.69,11.52,2.59,20.95,9.9,29.14c14.98,16.77,23.43,36.72,30.23,57.83
			c5.44,16.89,0.09,32.54-5.59,47.18C1696.02,1221.12,1681.23,1242.96,1661.36,1262.04z M1527.96,1219.84
			c14.45-6.93,28.77-13.92,42.26-22.84c27.77-18.34,49.49-42.53,64.78-71.28c29.23-54.98,33.15-113.96,18.03-173.55
			c-10.77-42.47-32.66-79.61-64.56-110.29c-2-1.92-4.14-3.06-7.28,0.11c4.2,11,6.87,22.78,9.05,34.82
			c9.11,50.28,11.74,100.13-0.62,150.51c-10.5,42.79-51.85,75.98-96.36,72.55c-43.09-3.32-77.28-24.35-105.05-56.92
			c-17.44-20.45-31.91-42.71-42.31-67.41c-9.68-23-18.26-46.35-21.98-71.35c-3.14-21.11-5.74-42.13-5.64-63.53
			c0.05-10.38-4.76-12.24-14.29-7.13c-16.01,8.58-26.85,21.97-34.77,37.91c-17.53,35.28-27.98,72.54-30.33,111.94
			c-1.8,30.3-0.77,60.48,7.86,89.79c6.67,22.66,16.27,43.9,29.27,64.04c24.81,38.41,57.42,67.9,98.84,85.77
			C1424.94,1244.57,1476.06,1240.54,1527.96,1219.84z M1518.6,915.57c17.76-21.04,20.95-62.09,5.1-84.95
			c-6.02-8.69-10.75-18.29-24.65-21.44c-24.88-5.63-49.63-5.34-74.62-6.31c-13.19-0.51-18.18,7.25-19.15,17.28
			c-2.69,27.88-3.62,55.6,9.77,81.96C1435.57,942.49,1487.41,956.3,1518.6,915.57z M638.45,1888.82
			c19.69-24.43,18.94-51.28,6.85-77.92c-7.91-17.44-20.41-31.73-43.02-34.35c1.05,5.89,2.06,10.39,2.64,14.93
			c3.02,23.75,1.05,46.59-13.3,66.84c-12.66,17.87-33.69,15.9-43.48-3.51c-7.06-14-11.39-28.32-10.05-44.19
			c0.28-3.31,2.24-7.76-3.71-10.5c-13.23,19.46-23.12,40.34-22.68,64.22c0.48,26.23,19.61,50.02,40.72,53.73
			C585.73,1923.93,614.6,1916.17,638.45,1888.82z M394.84,1187.39c-3.34-0.17-6.04,0.2-5.17,4.77c0.87,4.56,1.36,9.2,2.22,13.76
			c3.96,21.2-0.02,40.56-14.25,57.04c-11.27,13.06-30.23,16.03-42.88-1.31c-9.46-12.96-9.77-27.5-8.14-42.49
			c0.47-4.33,2.53-8.81-0.98-13.89c-21.87,14.12-34.29,32.84-31.85,58.44c2.34,24.54,13.8,45.56,37.73,53.96
			c28.65,10.05,53.8-1.73,75.41-20.76C436.97,1270.44,439.57,1203.09,394.84,1187.39z M1501.63,438.6c2-0.63,4.43-0.73,5.94-1.95
			c25.73-20.85,41.77-45.48,37.25-81.06c-3.84-30.27-37.61-52.01-65.68-41.85c-3.95,1.43-8.14,2.33-12.3,2.97
			c-15.65,2.42-26.81,12.25-33.11,25.23c-10.75,22.12-12.59,44.94-2.15,68.97C1443.12,437.45,1476.35,451.9,1501.63,438.6z
			 M1002.33,405.79c-2.11,3.7-4.16,7.43-6.33,11.09c-11.94,20.21-34.99,21.27-49.34,2.96c-7.4-9.44-5.54-20.64-7.58-31.38
			c-13.3,8.44-21.9,36.3-16.43,51.34c8.06,22.17,31.07,36.3,54.24,33.3c34.61-4.48,45.63-16.35,49.25-53.08
			c1.82-18.43-9.58-38.26-24.93-42.92C1003.32,385.99,1002.87,394.87,1002.33,405.79z M1176.74,172
			c-0.7,12.21,6.64,20.91,14.04,28.81c15.93,17,39.23,18.92,60.4,6.09c18.76-11.37,29.61-38.95,22.77-58.95
			c-3.35-9.8-6.08-20.35-17.37-25.35c-0.16,32.49-10.42,47.97-31.18,48.42c-6.89,0.15-12.42-1.82-17.35-6.42
			c-15.05-14.05-9.65-30.3-2.61-47.36C1184.56,124.66,1176.44,140.57,1176.74,172z M1724.71,456.63c2.44-4.31,4.89-8.62,7.44-13.12
			c-18.73-3.46-34.3,5.64-41.39,23.63c-6.41,16.28,2.18,38.38,19.18,49.37c14.01,9.05,42.09,3.49,55.82-11.05
			c12.55-13.29,15.29-28.85,6.48-41.22c-3.26,6.72-5.79,13.39-9.52,19.3c-7.86,12.46-24.29,15.97-35.74,8.21
			C1716.37,484.55,1714.97,471.06,1724.71,456.63z"
      />
      <path
        d="M477.09,395.9c21.99,18.24,42.44,36.99,55.91,61.4c17.6,31.89,26.26,65.7,18.6,102.75c-9.71,47.01-34.72,85.63-64.19,122
			c-17.23,21.26-35.24,41.78-46.03,67.56c-8.62,20.59-2.58,38.33,8.9,54.96c12.97,18.79,32.31,30.56,50.36,43.87
			c16.04,11.83,28.82,27.66,48.18,41.15c-12.97-0.88-22.46-1.26-31.88-2.22c-37.17-3.81-73.95-0.99-110.73,4.9
			c-11.24,1.8-22.94,0.66-34.08,4.17c-3,0.95-5.62-0.68-8.35-2.15c-49.48-26.58-77.56-68.09-80.99-123.78
			c-2.11-34.23,15.3-62.59,38.1-87.24c18.78-20.31,41.71-35.72,63.28-52.71c32.39-25.5,54.35-67.73,38.2-111.47
			c-10.58-28.64-30.5-47.18-53.67-63.46c-26.18-18.39-51.48-37.42-64.36-67.81c-10.72-25.29-11.87-51.88-6.44-78.82
			c9.61-47.67,32.37-87.97,73.25-114.45c41.7-27.02,93.88-30.73,134.95,2.22c25.14,20.17,25.09,59.2,16.39,83.22
			c-9.24,25.49-29.05,41.78-52.03,54.25c-20.06,10.89-23.65,25.25-10.2,43.06C465.14,383.81,470.59,389.67,477.09,395.9z
			 M440.5,262.75c-31.49-8.76-32.32-34.49-30.42-62.06c-23.1,15.47-35.03,51.73-27.01,77.22c10.27,32.62,27.08,44.14,54.39,43.65
			c28.46-0.51,61.37-14.65,70.41-51.27c4.99-20.21,3.4-50.78-15.39-61.43C487.09,237.32,475.95,260.57,440.5,262.75z"
      />
      <path
        d="M355.77,1397.69c23.99-23.39,49.56-43.45,75.08-63.66c32.15-25.45,65.19-49.96,92.97-80.58c2.69-2.96,3.45-7.69,8.28-9.75
			c4.27,3.95,1.14,8.07,0.17,11.52c-12.61,44.94-17.25,90.75-16.07,137.31c0.6,23.57-0.34,21.89-23.35,30.82
			c-34.49,13.39-63.34,35.07-84.09,66.58c-7.72,11.74-12.22,24.8-18.03,37.37c-10.16,21.98-2.86,42.74,1.32,64.01
			c10.22,52.05-35.76,94.43-84.59,86.79c-25.99-4.07-43.74-21.99-52.94-46.25c-17-44.85-7.38-87.18,14.5-128.07
			C290.88,1462.97,321.65,1429.59,355.77,1397.69z M270.7,1596.8c0,5.11-0.43,10.27,0.08,15.33c3.05,30.64,36.94,58.83,68.84,46.18
			c22.16-8.78,38.2-33.54,38.67-56.21c0.42-20.28-11.14-43.35-31.55-46.58c1.38,14.5,1.15,28.77-1.7,43
			c-2.25,11.26-17.85,29.03-26.82,29.28c-11.91,0.33-22.39-9.3-27.42-23.82c-4.36-12.62-3.69-25.26-1.16-38.44
			C278.75,1573.02,271,1581.43,270.7,1596.8z"
      />
      <path
        d="M1699.21,809.59c-25.08-24.42-52.84-43.02-84.18-56.79c-19.24-8.45-38.77-14.84-59.65-15.83
			c-10.76-0.51-20.6-1.4-30.35-7.09c-10.76-6.28-22.01-12.02-35.56-15.76c12.51-12.59,28.29-17.52,40.05-28.54
			c8.84-8.28,18.35-16.03,27.01-24.63c15.31-15.19,30.99-17.7,51.47-9.93c34.07,12.93,60.74,37.44,90.72,56.75
			c22.84,14.7,46.7,26.3,73.32,33.38c26.96,7.18,54.98,4.21,81.7,11.87c47.79,13.69,66.52,50.93,48.04,97.17
			c-14.31,35.82-41.8,56.42-78.42,64.84c-19.51,4.48-35.53-5.09-49.36-18.04c-19.21-17.98-33.09-40.49-50.48-60.02
			C1715.59,828.08,1707.74,819.13,1699.21,809.59z M1778.98,831.16c-3.89,13.09-2.73,25.85,1.25,38.85
			c6.38,20.82,20.19,29.52,41.49,26.35c24.34-3.63,63.67-36.41,67.47-66.69c2.43-19.3-7.43-47.69-21.3-53.97
			c-26.97-12.2-44.08-9.44-64.42,8.22C1790.4,795.28,1782.49,811.35,1778.98,831.16z"
      />
      <path
        d="M724.78,423.63c-6.45,1.9-5.93-3.32-6.4-5.85c-7.28-38.74-7.78-77.71-4.2-116.9c2.75-30.02,6.71-59.99,16.51-88.4
			c14.92-43.29,36.87-82.81,81.42-101.86c21.68-9.26,45.73-10.99,65.32,8.01c9.41,9.13,14.04,20.68,15.38,33.81
			c3.93,38.69-15.44,66.56-41.85,91.22c-6.2,5.79-12.81,11.14-19,16.95c-13.54,12.7-22.8,27.25-21.26,47.01
			c1.45,18.57-2.73,37.56,3.79,55.74c1.9,5.3-1.32,7.36-4.75,8.78C779.01,384.83,752.09,403.75,724.78,423.63z M784.18,196.17
			c22.95,29.71,48.49,33.42,75.94,11.67c18.99-15.05,30.01-54.93,12.72-69.32c-15.05,39.11-27.73,51.44-51.05,41.62
			c-25.11-10.58-19-33.53-12-54.94c-6.48,4.44-11.9,9.17-16.87,14.33C777.44,155.57,779.15,174.74,784.18,196.17z"
      />
      <path
        d="M1597.6,1459.5c-4.17,8.08-7.29,15.81-16.32,22.13c-2.37-49.19-7.06-96.08-29.36-140.47
			c29.81-12.81,60.19-23.86,88.8-44.16C1641.34,1356.48,1623.99,1408.67,1597.6,1459.5z"
      />
      <path
        d="M1371.4,1543.79c1.8-2.85,3.11-4.99,6.17-10.04c20.57,44.3,37.94,87.08,47.04,133.16c-10.71,3.29-19.02,0.23-27.15-1.21
			c-25.67-4.56-51.71-7.43-74.84-21.83c-9.59-5.97-9.21-9.52-2.71-17.06c16.28-18.88,27.49-41.13,39.95-62.52
			C1363.67,1557.76,1367.23,1551.1,1371.4,1543.79z"
      />
      <path
        d="M1507.38,1354.66c4.05,6.93,0.58,13.65-1.5,19.31c-12.26,33.26-23.54,67.02-42.88,97.19c-3.01,4.7-2.26,11.75-9.15,14.55
			c-11.97-39.33-25.32-77.73-54.87-111.77C1436.93,1371.32,1471.82,1363.4,1507.38,1354.66z"
      />
      <path
        d="M1255.89,1386.31c-4.99-5.73-9.29-10.86-15.52-18.29c35.98,6.75,69.54,12.62,105.72,9.69
			c-5.05,39.96-8.35,77.96-21.81,117.62C1301.34,1456.8,1282.25,1419.75,1255.89,1386.31z"
      />
      <path
        d="M1546.46,1558.59c7.57,20.9,6.27,42,8.7,62.58c0.46,3.88-3.56,6.03-6.01,7.66c-23.55,15.67-47.55,30.54-78.68,35.56
			c29.46-47.56,44.91-98.35,58.3-152.92C1534.67,1528.52,1542.57,1542.17,1546.46,1558.59z"
      />
      <path
        d="M1486.22,400.62c-21.31-10.81-17.41-29.92-15.39-46.55c1.65-13.6,9.79-25.28,24.53-29.87
			c17.29-5.39,33.93,12.17,31.95,33.46c-1.28,13.76-5.53,26.69-14.12,37.18C1506.84,402.61,1497.61,406.82,1486.22,400.62z"
      />
      <path
        d="M1832.4,799.98c13.25-2.71,23.57,1.73,29.28,12.04c6.23,11.26,4.81,30.1-4.09,37.92c-4.11,3.62-7.79,7.87-12.31,10.86
			c-13.33,8.82-29.86,2.02-31.75-13.86c-1.39-11.63-0.06-23.49,3.73-35.01C1819.7,804.52,1826.76,804.17,1832.4,799.98z"
      />
    </>
  );
}
