export default function Wyvern(): JSX.Element {
  return (
    <>
      <path
        d="M1618.06,391.75c-1.2,26.1,10.28,43.46,33.91,54.2c6.17,2.8,12.31,5.61,18.53,8.2c0.11-0.56,0.22-0.93,0.26-0.92
		 c10.54,4.34,22.27,6.4,33.33,9.09c5.88,1.43,11.72,2.42,17.76,2.87c0.93,0.07,2,0.19,3.13,0.32c1.79,0.05,3.58,0.09,5.37,0.11
		 c0.42,0.01,0.87,0.02,1.33,0.04c-0.95-0.86-2.46-1.73-3.47-2.55c-9.77-7.88-20.08-15.18-29.23-23.72
		 c-28.61-26.7-54.79-55.55-75.42-89.07c-0.85-1.38-2.43-3.5-3.42-3.37c-2.91,0.38-2.04,3.35-2.06,5.19
		 C1617.99,365.34,1618.67,378.57,1618.06,391.75z"
      />
      <path
        d="M1741.07,612.72c17.48,1.07,33.44-4.26,49.11-11.17c4.24-1.87,8.89-3.9,13.37-3.93c27.93-0.2,55.75-3.17,83.23,7.52
		 c17.97,6.99,37.09,11.53,55.4,18.11c1.81,0.65,3.82,1.69,5.76,2.01c-0.11-3.44-0.22-6.87-0.23-10.32
		 c-0.01-2.08,0.06-4.13,0.16-6.19c-0.41-3.25-0.81-6.49-1.16-9.72c-4.78-9.37-9.67-18.69-14.38-28.05
		 c-7.01-13.94-17.38-19.81-31.43-22.19c-29.59-5.02-54.13-20.06-77-38.59c-18.36-14.87-36.83-29.38-61.43-33.26
		 c-9.82-1.55-19.65-1.45-29.43-2.26c-4.3,1.45-10.89-1.03-15.23-1.71c-4.4-0.69-8.75-1.31-13.08-2.16
		 c-13.8,0.55-26.81-4.65-38.62-9.85c-48.53-21.39-85.65-54.87-103.82-106.41c-1.14-3.24-2.17-8.59-6.71-7.77
		 c-3,0.54-3.65,5.35-3.83,8.78c-1.42,26.03-0.51,52.1,6.34,77.2c2.7,9.9-0.18,15.13-7.2,18.59c-7.28,3.58-13.97,8-21.01,11.9
		 c-39.88,22.09-78.64,45.95-107.47,82.19c-25.24,31.72-43.26,66.65-42.43,108.92c0.46,23.46,10.9,43.38,22.06,62.61
		 c6.97,12.01-0.03,10.19-6.73,9.45c-32.67-3.6-65.56-2.84-98.26-5.12c-12.33-0.86-25.39-0.22-36.82-8.05
		 c-27.96-19.16-37.56-47.39-37.39-78.9c0.2-36.94,2.32-74.18,10.87-110.16c9.32-39.23,24.46-76.2,46.26-110.06
		 c3.09-6.5,6.12-13.03,8.94-19.65c3.54-8.31,6.99-16.88,9.63-25.65c0.62-2.54,1.21-5.1,1.78-7.65c-0.11-0.04-0.23-0.08-0.35-0.11
		 c-1.44-0.33-3.76,1.53-5.17,2.92c-7.52,7.43-14.81,15.09-22.3,22.55c-31.76,31.63-59.85,66.71-92.3,97.7
		 c-24.64,23.53-48.45,47.87-73.5,71.02c-32.33,29.88-64.32,60.14-97.67,88.86c-41.65,35.87-84.2,70.63-127.74,104.26
		 c-31.35,24.21-63.84,46.83-95.07,71.13c-11.93,9.28-25.79,15.86-36.5,26.74c0.03-0.02,0.19-0.08,0.87-0.25
		 c3.04-0.54,5.42-0.31,7.2-1.23c28.06-14.47,58-23.3,88.45-31.21c46.96-12.21,93.01-8.15,138.11,6.97
		 c29.62,9.93,57.69,24.03,82.34,43.71c26.6,21.25,47.3,46.33,53.9,81.3c6.73,35.65-11.33,66.12-45.6,75.83
		 c-23.97,6.79-47.68,14.53-71.35,22.31c-27.7,9.1-54.98,19.32-82.13,30.06c-48.72,19.27-98.05,37.19-142.2,65.87
		 c-29.62,19.24-58.69,39.38-83.33,65.38c-18.91,19.96-37.07,40.48-52.14,63.33c-12.24,18.56-22.92,38.17-32.19,58.59
		 c-13.88,30.58-22.54,62.88-33.11,94.53c-3.33,9.97-7.05,19.95-9.51,30.11c-8.58,35.32-18.79,70.19-30.83,104.4
		 c-11.34,32.24-24.68,63.91-44.05,92.42c-26.26,38.66-54.7,74.24-103.08,88c-18.6,5.29-36.8,6.9-55.3,5.55
		 c-25.65-1.87-55.22-29.72-63.67-53.86c-10.37-29.6-8.17-59.89-6.39-89.72c1.12-18.8,4.26-38.8,17.01-55.04
		 c11.81-15.05,25.98-27.8,39.35-41.31c17.92-18.11,31.38-38.69,38.93-63.18c3.51-11.39,1.7-20.32-7.05-28.87
		 c-15.24-14.88-33.21-24.95-52.93-32.07c-15.32-5.54-25.19-2.68-33.94,8.31c-15.2,19.06-21.49,41.6-26.45,64.91
		 c-4.21,19.78,1.1,40.2-4.35,59.68c-9.68,34.57-20.71,68.69-27.77,104.04c-5.75,28.76-4.63,57.27,2.88,84.63
		 c9.87,35.96,33.71,63.88,62.76,86.55c32.84,25.64,71.44,31.56,111.94,28.98c12.32-0.79,21.5-6.78,28.77-16.45
		 c3.81-5.07,6.81-11.2,13.67-12.92c-0.99,5.04-3.26,9.68-6,14.08c-1.57,1.57-2.95,3.22-3.67,5.29c-0.06,0.18-0.15,0.35-0.25,0.52
		 c-0.18,0.25-0.36,0.51-0.54,0.76c-0.93,1.07-2.37,1.97-3.43,3c2.06,1.68,3.85,0.19,5.84-0.33c36.76-9.67,68.26-29.34,97.15-53.04
		 c9.14-7.5,21.23-14.74,21.18-29.65c-0.06-16.32,6.51-31.6,7.63-47.74c0.21-3.03,0.98-7.43,4.19-6.76c4.73,0.99,1.84,5.55,1.44,8.45
		 c-1.88,13.45-4.13,26.84-6.07,40.28c-0.33,2.32-1.84,5.55,0.55,6.81c1,0.52,4.21-2.3,5.9-4.1c4.86-5.18,9.46-10.59,14.15-15.93
		 c19.32-21.98,36.28-45.8,51.94-70.37c10.25-16.09,22.43-31.31,23.35-52.54c1.16-26.77,4.07-53.48,4.25-80.33
		 c0.02-3.39,0.38-6.8,0.9-10.15c0.25-1.59,1.34-3.12,3.14-2.84c0.23,3.34,0.78,6.71,1.3,9.95c0.65,4.05,1.21,8.12,1.71,12.19
		 c0.11,19.53-0.01,39.05-2.5,58.49c-0.25,1.98-0.11,5.29,0.99,5.89c2.45,1.34,3.49-1.71,4.44-3.34
		 c18.84-32.54,37.06-65.45,56.63-97.53c6.62-10.86,8.7-21.51,7.81-33.58c-1.3-17.74,3.84-34.57,7.97-51.5
		 c1.51-6.18,3.56-12.23,5.58-18.27c0.44-1.32,1.5-2.58,2.97-2.65c0,0.03-0.01,0.06-0.01,0.09c-1.16,9.94-2.32,19.92-2.95,29.9
		 c-1.89,13.09-2.06,26.43-4.17,39.49c-0.27,1.64,0.07,3.38,0.12,5.07c1.21-1.02,2.5-1.94,3.6-3.07c1.49-1.52,2.95-3.08,4.2-4.79
		 c22.54-30.95,47.09-59.84,78.82-82.12c9.34-6.56,21.08-11.67,23.96-24.87c5.95-27.32,22.94-48.41,39.61-69.64
		 c1.06-1.34,2.33-3.25,3.95-3.67c-12.33,21.01-24.47,42.42-31.93,65.5c-1.04,1.61-2.2,3.37-1.58,5.17
		 c-0.41,1.41-0.81,2.81-1.17,4.23l1.96-2.92c2.27,2.65,5.52,0.49,7.71-0.42c9.73-4.05,19.02-9.17,28.84-12.98
		 c24.01-9.32,47.99-18.88,73.78-22.48c9.95-1.39,19.21-3.91,23.43-14.77c8.95-23.02,22.82-43.31,37.68-62.66
		 c7.01-9.13,14.07-19.31,25.5-24.14c-3.4,5.22-6.53,10.61-9.54,16.06c-23.45,26.4-38.46,57.06-49.23,89.76
		 c-11.23,34.1-27.18,64.37-54.53,88.22c-11.25,9.81-11.78,25.49-0.73,33.29c5.96,4.2,12.89,7.61,20.21,10.16
		 c33.63,11.68,64.77,0.12,95.18-11.08c23.52-8.66,47.37-15.86,71.22-23.31c7.84-2.45,12.76-1.37,15.74,7.1
		 c2.75,7.82,7.87,9.82,15.82,5.06c10.71-6.42,22.52-5.05,30.89,3.16c8.66,8.5,14.06,5.92,20.46-1.25c1.13-1.27,2.03-3.03,3.45-3.73
		 c12.34-6.06,23.52-19,39.11-6.78c2.24,1.75,3.93,0.72,4.11-2.18c1.15-18.18,3.87-36.48-9.67-52.13
		 c-23.97-27.7-55.08-28.81-86.44-20.4c-2.21,0.59-4.44,1.18-6.67,1.76c-19.22,0.92-38.31,3.64-56.87,8.7
		 c-4.5,1.23-8.97,2.61-13.4,4.12c9.09-7.35,18.07-14.83,27.11-22.26c1.46-1.2,3.09-3.21,4.82-2.8c1.2-1.03,2.39-2.09,3.52-3.22
		 c1.21-1.21,2.48-2.4,3.79-3.58c0.57-0.66,1.32-1.29,1.89-1.93c0.1,0,0.19,0,0.29-0.01c3.54-3.07,7.28-6.01,10.87-8.73
		 c5.23-3.96,9.31-8.54,14.14-12.85c4.76-4.26,9.69-8.08,13.85-13c7.4-8.75,17.08-15.28,25.33-23.26
		 c9.42-9.11,17.35-19.77,26.58-29.06c2.45-2.46,4.9-4.43,6.88-7.35c1.92-2.83,3.54-5.84,5.62-8.56c1.41-1.85,2.85-3.67,4.3-5.49
		 c0.24-0.62,0.58-1.21,0.93-1.71c13.98-19.71,21.55-41.94,26.04-65.41c3.66-11.04,5.64-20.33,5.42-29.95
		 c-0.27-1.25-0.48-2.52-0.51-3.83c-0.03-1.25,0.68-1.94,1.56-2.12c0.17-0.14,0.36-0.27,0.59-0.39c0.03,0.12,0.07,0.23,0.1,0.35
		 c0.91,0.07,1.83,0.61,2.23,1.57c2.22,5.34,4.33,10.14,4.47,16.03c0.11,4.69-0.65,9.16-1.09,13.79c-0.5,5.24-0.54,10.19-1.99,15.33
		 c-1.26,4.48-3.02,8.84-4.48,13.28c-6,18.29-16.25,35.34-27.23,51.04c-6.69,9.56-14.36,18.32-21.58,27.47
		 c-6.98,8.84-14.64,17.17-23.2,24.51c-7.66,6.56-14.59,14.07-22.19,20.75c-4.52,3.97-8.6,8.21-12.96,12.34
		 c-3.39,3.21-7.3,5.83-10.69,9.09c-1.85,1.78-4.02,3.03-5.97,4.72c-2.04,1.78-3.7,3.91-5.77,5.68c-3.62,3.09-7.24,6.14-10.81,9.3
		 c-5.59,4.96-12.2,8.75-18.86,12.31c4.14-0.91,8.29-1.83,12.43-2.75c0.41-0.09,0.79-0.31,1.18-0.48
		 c29.65-13.27,60.7-18.12,93.04-15.76c6.48,0.47,12.46,2.06,18.53,4.17c25.84,9,32.92,32.22,36.03,54.38
		 c7.75,55.12,13.75,110.91,0.1,165.96c-6.32,25.49,3.29,44.28,21.29,58.08c34.46,26.41,66.4,55.25,96.73,86.19
		 c8.26,8.43,18.27,16.25,31.89,15.88c21.38-0.58,39.37,6.08,50.37,25.84c3.1,5.58,5.56,4.83,8.49,0.04
		 c8.28-13.51,20.77-21.61,35.33-26.79c4.59-1.63,5.84-3.17,3.82-8.24c-9.02-22.66-11.11-46.01-5.64-69.95
		 c0.78-3.43,4.5-7.37,0.44-10.58c-4.28-3.38-9.28-1.95-13.01,1.12c-8.61,7.06-19.35,8.94-29.39,11.52
		 c-16.75,4.3-32.38,0.66-46.67-10.13c-28.27-21.35-45.36-51-60.57-81.62c-6.56-13.22-11.73-27.94-6.6-44.2
		 c6.65-21.05,15.57-41.08,24.66-61.06c16.46-36.17,39.12-69.07,55.42-105.41c11.72-26.13,9.01-47.01-9.63-68.77
		 c-9.1-10.63-20.72-17.44-31.41-25.52c-12.69-9.6-26.32-11.83-42.47-3.86c-14.23,7.02-28.68,14.84-44.75,17.88
		 c-2.29,0.43-0.2,0.28-2.26,0.01c-0.79,0.28-1.58,0.55-2.41,0.74c-1.75,0.39-3.36,0.96-5.04,1.64c-0.5,0.2-0.94,0.19-1.3,0.06
		 c-0.24,0.16-0.48,0.23-0.72,0.23c2.52-3.17,4.58-6.87,6.45-10.43c4.39-8.37,8.73-16.85,11.44-25.91c1.37-4.58,2.32-9.28,3.26-13.98
		 c0.94-4.66,1.87-9.31,2.81-13.97c0.52-2.61,1.05-5.24,1.36-7.88c0.46,1.48-0.25,3.72-0.15,5.26c0.08,1.23,0.07,2.46,0.04,3.7
		 c0.47,3.05,0.37,6.24,0.04,9.25c-0.69,6.36-1.71,13.07-3.58,19.2c-0.02,0.08-0.04,0.16-0.07,0.24c-0.28,1.31-0.58,2.61-0.9,3.92
		 c-0.26,1.07-0.55,2.13-0.87,3.17c-0.34,1.37-0.7,2.72-1.15,4.02c-0.44,1.29-1.17,2.44-1.89,3.6c-0.24,0.48-0.46,0.97-0.71,1.45
		 c-0.77,1.47-1.69,3.52-2.83,5.31c-0.47,0.93-0.91,1.85-1.29,2.79c2.06,0.82,3.94,0.31,5.82-0.88
		 c27.44-17.36,57.42-28.55,89.05-35.02c4.73-0.97,8.12-2.66,10.71-5.07c0.28-1.02,0.4-2.05,0.85-3.09c1.02-2.33,2.09-4.63,3.18-6.93
		 c0.09-0.27,0.18-0.54,0.27-0.81c8.78-19.36,9.51-42.08,7.21-63.7c0.39,2.49,0.64,5.2,1.08,7.28c4.13,19.53,1.61,38.88-0.56,58.25
		 c-0.78,6.96,0.9,8.9,8.18,7.84c11.77-1.73,23.73-2.98,35.61-3.01c31.27-0.07,58.81-10.87,84.59-27.5c2.91-1.88,6.4-3.12,6.15-7.24
		 c-0.02-0.34-0.03-0.69-0.05-1.03c1.76-18.07,3.34-36.27,1.41-54.31c-2-18.66-7.81-36.91-16.96-53.3c-1.12-2.01-2.31-3.99-3.57-5.94
		 c0.05-0.01,0.09-0.03,0.14-0.05c0.63-0.16,1.86,2.3,2.93,3.44c7.15,7.57,11,17.17,14.46,26.53c7.8,21.07,15.57,42.34,13.12,65.58
		 c-0.38,3.64-3.69,8.68-0.24,10.61c3.27,1.83,6.35-3.19,9.08-5.65c29.67-26.72,47.59-60.96,61.16-97.68
		 c2.12-5.72,6.84-11.08,5.51-17.97c-3.77-19.49-8.13-38.3-19.51-55.64c-1.91-2.91-3.87-5.78-5.87-8.61
		 c-14.18-28.49-32.25-54.9-50.59-81c30.8,36.84,62.55,71.75,79.98,116.65c0.69,1.79,2.48,3.15,3.76,4.71
		 c1.32-1.59,2.91-3.02,3.89-4.8c1.43-2.59,2.66-5.34,3.56-8.16c7.58-23.79,7.85-48.28,8.52-73c0.81-29.91-9.62-55.04-25.51-79.3
		 c-8.23-12.58-18.86-25.7-19.9-40.36c-1.09-15.41-6.87-27.38-14.98-38.58c-1.18-2.85-2.37-5.68-3.57-8.51l-6.8-16.06
		 c0.33-0.28,0.71-0.53,1.16-0.75c4.06-1.93,4.91,2.9,7.04,5.41c17.74,20.86,10.22,24.65,26.33-0.73
		 c12.37-19.47,30.5-32.87,54.04-32.64c25.42,0.24,50.47,6.28,74.67,14.28c21.23,7.02,42.84,12.89,63.44,22
		 c9.78,4.33,21.58,8.54,32.2,3.37c19.3-9.39,39.2-17.96,56.01-31.68c2.38-3.67,4.99-7.21,7.85-10.41c0.82-0.92,1.66-1.9,2.56-2.85
		 c-2.18-3.13-6.72-1.31-9.93-1.12c-39.16,2.36-77.16-2.45-113.43-17.99c-25.34-10.86-44.9-28.3-59.07-52.01
		 C1730.6,613.06,1734.83,612.34,1741.07,612.72z M654.85,1531.07c0.53,8.11,0.78,16.24,0.71,24.36L654.85,1531.07z M1723.21,529.56
		 c-4.13-0.98-8.11-2.54-12.29-3.35c-1.85-0.36-3.69-0.79-5.71-1.5c-2.05-0.72-4.09-1.38-6.18-1.98c-1.6-0.46-2.55-1.99-2.35-3.61
		 c0.14-1.16,1.08-2.36,2.26-2.74c0.38-0.26,0.82-0.44,1.29-0.54c0.01,0,0.04-0.02,0.07-0.03c-0.05,0,0.01-0.03,0.2-0.08
		 c-0.09,0.04-0.15,0.06-0.2,0.08c0,0,0,0,0,0c0.11-0.05,0.33-0.14,0.66-0.28c0.28-0.11,0.55-0.23,0.84-0.31
		 c0.99-0.27,2.03-0.26,3.05-0.28c0.5-0.01,1.02,0,1.53,0c1.47-0.49,2.84-1.2,4.33-1.6c3.77-1.02,7.62-1.7,11.4-2.69
		 c3.91-1.02,7.69-2.3,11.68-3.03c2.04-0.38,4.17-0.89,6.24-0.95c1.62-0.04,3.24,0.01,4.86,0.02c1.3,0,2.44,0.92,2.81,2.12
		 c1.69,0.83,3.15,2.03,4.68,3.12c1.88,1.35,3.98,2.37,5.86,3.72c1,0.72,2.46,1.35,3.22,2.3c0.48,0.6,0.86,1.13,1.47,1.59
		 c0.93,0.71,1.73,1.1,2.53,1.98c0.65,0.72,0.82,1.33,1.47,1.69c1.09,0.6,1.62,2,1.3,3.18c-0.35,1.27-1.47,1.96-2.72,2.07
		 c-0.88,0.08-1.75,0.57-2.65,0.72c-1.36,0.22-2.69,0.53-4.06,0.71c-2.72,0.35-5.44,0.28-8.17,0.44c-2.39,0.14-4.76,0.5-7.15,0.68
		 c-2.24,0.17-4.46,0.09-6.69-0.07C1732.26,530.63,1727.66,530.61,1723.21,529.56z"
      />
      <path d="M1116.66,1313.23c-1.23,0.94-2.43,1.92-3.56,2.95C1114.31,1315.24,1115.5,1314.26,1116.66,1313.23z" />
      <path
        d="M1054.13,1346.12c9.46-3.08,17.25-0.42,25.68-5.72c27.27-17.13,39.46-29.38,63.21-51.13
		 c25.6-23.43,63.36-57.57,83.94-85.51c17.25-23.42,29.38-42.94,34.84-71.51c3.75-19.64,4.9-30.34-5.18-51.17"
      />
      <path
        d="M386.18,802.75c32.11-55,75.9-100.8,116.55-149.15c24.39-29.01,46.43-60.25,62.71-94.64
		 c11.39-24.06,20.62-49.44,19.14-76.96c-1.54-28.64-15.6-50.29-38.96-66.06c-52.79-35.62-111.97-43.75-173.88-39.71
		 c-7.23,0.47-14.45,1.08-22.78,1.7c-1.1,0.08-1.01,0.17-1.01,0.17c13.01-9.55,29.19-10.95,43.79-16.19
		 c24.22-8.7,49.39-14.84,74.33-21.41c38.91-10.24,78.4-17.37,118.15-23.89c41.11-6.75,82.46-11.52,123.59-17.9
		 c50.31-7.79,101.05-12.81,151.47-19.99c26.55-3.78,53-7.46,79.85-8.68c40.12-1.83,79.9-0.89,119.49,6.75
		 c47.94,9.24,94.08,24.61,140.67,38.76c24.12,7.32,47.73,17.62,73.34,19.8c2.24,0.19,4.66,1.29,4.7,3.51
		 c0.06,3.01-2.93,2.24-4.9,2.54c-30.68,4.67-60.03,13.64-88.69,25.62c-29.57,12.37-58.67,25.7-87.18,40.24
		 c-24.86,12.68-49.05,26.67-73.47,40.19c-34.2,18.94-69.08,36.43-104.79,52.42c-35.04,15.69-70.99,29.15-106.98,42.04
		 c-59.81,21.42-117.98,46.47-175.05,73.99c-33.4,16.1-64.93,36.11-95.42,57.32c-47.64,33.14-92.52,69.77-134.47,109.93
		 c-6.42,6.15-12.51,12.65-19.54,19.25C386.06,802.64,386.18,802.75,386.18,802.75z"
      />
      <path
        d="M1482.93,135.24c-0.54-4.63-1.07-9.31-2.17-13.82c-2.18-8.88-5.07-16.54-16.65-17.53c-16.09-1.37-31.98-5.36-48.07-6.32
		 c-60.37-3.6-119.97-11.51-178.56-27.01c-22.08-5.84-44.76-9.47-67.26-13.58c-7.49-1.37-15.13-1.06-22.72,1.43
		 c-26.08,8.55-51.95,18.11-78.56,24.59c-27.48,6.7-55.05,13.27-83.77,14.72c-27.76,1.4-54.7,6.13-78.23,24.91
		 c-25.36,20.24-52.91,37.91-80.52,55.09c-31.13,19.37-63.64,36.39-98.26,48.98c-1.07,0.39-2.65,0.69-3.54,1.48
		 c0.74,0.06,1.48,0.12,2.22,0.21c4.63,0.56,9.07,1.17,13.74,1.24c2.96,0.05,5.86,0.03,8.77,0.36c2.67-0.43,5.34-1,8-1.83
		 c18.56-5.79,37.55-10.2,57.37-15.45c10.28-3.29,19.92-5.25,29.13-8.06c36.05-11,72.44-20.37,109.09-29.16
		 c33.96-8.14,66.95-4.72,99.42,4.22c32.24,8.88,61.81,24.91,91.41,40.36c20.21,10.54,40.05,21.97,60.89,31.09
		 c29.81,13.05,60.77,23.05,92.41,31.05c22.76,5.76,45.72,9.14,69.06,10.72c7.17,0.49,11-1.78,14.4-8.9
		 c21.07-44.11,45.72-86.16,88.93-112.17C1485.95,161.93,1484.61,149.64,1482.93,135.24z"
      />
      <path
        d="M708.64,853.86c23.44-29.18,48.48-57.35,66.4-90.39c10.49-19.34,17.29-40.28,12.1-63.37
		 c-3.5-15.58-14.07-23.99-27.74-28.05c-25.24-7.5-50.86-6.21-76.98-2.71c-45.56,6.11-87.74,22.42-129.31,40.24
		 c-39.14,16.78-76.64,37.11-112.46,62.02c-0.81,0.36-0.78,0.39-0.78,0.39c11.46-13.6,25.95-23.78,39.5-35.05
		 c31.09-25.87,63.9-49.48,98.12-70.87c16.99-10.62,34.76-20.31,52.55-29.82c21.62-11.56,43.55-22.16,65.95-32
		 c25.42-11.16,51.3-21.19,76.95-31.75c29.7-12.23,60.11-22.45,90-34.1c23.95-9.34,47.8-18.87,71.63-28.5
		 c23.56-9.51,45.71-21.64,68.23-33.03c25.13-12.7,49.27-27.36,73.77-41.29c32.23-18.33,65.39-34.88,98.99-50.49
		 c29.93-13.91,60.26-26.68,93.17-32.24c8.27-1.4,16.14-5.18,25.14-7.98c0.94-0.11,0.84-0.13,0.84-0.13
		 c-59.62,53.61-112.13,114.38-170.83,168.95c-45.41,42.22-90.22,85.11-137.22,125.55c-46.08,39.65-93.27,77.96-141.59,114.93
		 c-44.31,33.89-88.39,68.02-135.57,99.4C708.65,853.84,708.64,853.86,708.64,853.86z"
      />
      <path
        d="M1293.81,324.87c-5.83-15.24-14.01-28.57-31.38-32.07c-17.55-3.53-34.22-9.8-50.45-16.68
		 c-41.57-17.61-81.45-38.97-123.86-54.88c-35.45-13.3-70.62-26.7-110.67-24.75c-40.99-1.65-78.36,11.35-115.92,20.66
		 c-33.29,8.25-66.84,15.75-100.09,24.27c-17.1,4.38-34.54,9.42-52.13,11.41c-41.78,4.73-82.82,13.8-124.43,19.6
		 c-33.61,4.68-67.38,8.51-100.92,13.99c-23.39,3.82-47.19,5.84-69.81,12.96c-30.81,9.7-59.32,24.61-85.11,44.13
		 c-5.57,4.22-11.15,8.44-16.59,12.83c-3.34,4.64-6.81,9-10.46,13.43c-5.33,6.48-10.56,13.04-15.74,19.64c-1,1.37-2.01,2.74-3.02,4.1
		 c-0.25,0.58-0.51,1.16-0.77,1.74c0.19-0.12,0.37-0.24,0.56-0.34c27.29-15.51,56.47-26.57,85.88-37.28
		 c43.44-15.81,87.69-28.86,132.69-39.09c29.76-6.77,60.04-11.29,90.07-16.84c30.99-5.72,62.27-9.35,93.38-13.3
		 c50.15-6.37,100.08-14.27,150.17-20.94c35-4.66,69.94-10.24,105.11-12.67c49.47-3.42,98.31,0.91,146.77,13.59
		 c39.29,10.28,78.01,22.02,116.62,34.4c28.58,9.16,57.46,17.37,86.17,26.13C1294.48,330.33,1295.47,329.2,1293.81,324.87z"
      />
      <path
        d="M1372,707.11c-1.55-3.71-3.32-7.31-4.81-11.04c-1.13-2.8-2.15-5.64-3.13-8.48c-1.46-2.39-2.74-4.91-3.6-7.72
		 c-7.44-24.25-14.19-48.44-18.8-73.59c-7.27-39.64-8.95-79.4-8.74-119.27c0.11-21.19,1.6-42.35,6.3-62.92
		 c-0.3-0.76-0.28-1.67-0.06-2.73c0.7-3.39,2.47-6.64,3.3-10.08c0.13-0.53,0.27-1.07,0.42-1.6c-0.01-0.2-0.01-0.41,0.03-0.63
		 c1.01-5.98,1.52-12.59,3.76-18.23c0.53-1.34,1.09-2.67,1.59-4.02c0.45-2.63,0.96-5.25,1.53-7.85c0.19-2.34,0.35-4.68,0.4-7.01
		 c-21.62,13.49-37.02,32.5-52.2,51.37c-17.63,21.92-28.74,47.46-37.8,74.12c-14.27,41.96-19.72,84.96-18.1,129
		 c0.67,18.29,3.67,36.45,10.96,53.27c4.99,11.52,11.76,22.8,24.65,27.44c29.64,10.66,60.14,10.16,90.83,6.07
		 c2.27-0.3,3.95-0.82,5.09-1.62C1373.05,710.09,1372.59,708.51,1372,707.11z"
      />
      <path
        d="M935.14,1054.77c-13.09,3.44-24.36,11.81-37.67,7.72c-11.76-3.61-23.88-5.69-35.64-8.91
		 c-38.5-10.55-77.7-14.05-117.33-14.01c-13.66,0.01-27.2-0.68-40.74-2.23c-1.58-0.18-4.4-0.45-4.48-0.99
		 c-0.41-2.88,2.5-3.02,4.24-3.67c24.25-9.06,49.51-14.73,74.88-18.9c40.83-6.71,82.24-6.99,123.45-5.14
		 c37.33,1.67,74.88,2.15,111.74,9.72c1.66,0.34,3.46,0.17,5.05,0.68c2.22,0.71,4.29,1.85,6.43,2.81c-1.81,1.05-3.51,2.71-5.44,3.05
		 c-12.24,2.12-23.13,8.05-34.74,11.86C968.43,1042.18,951.88,1047.25,935.14,1054.77z"
      />
      <path
        d="M680.03,1155.02c-24.56,5.71-47.33,13.62-69.82,22.33c-7.4,2.86-14.3,7.01-21.47,10.48c-1.35,0.65-3.05,2.68-4.27,0.62
		 c-0.45-0.76,1.11-3.04,2.13-4.32c8.54-10.7,19.6-18.82,30.26-26.99c21.01-16.1,43.3-30.47,67.54-41.45
		 c28.1-12.73,56.83-23.2,86.8-31.27c30.16-8.11,60.7-11.98,91.34-16.32c9.42-1.34,18.53-0.85,27.41,2.66
		 c-7.4,3.62-14.59,7.74-22.22,10.76c-37.81,14.95-74.52,32.01-107.56,56c-10.8,7.84-23.57,7.77-35.44,8.81
		 C709.69,1147.65,695.54,1151.69,680.03,1155.02z"
      />
      <path
        d="M1454.29,356.41c-2.53-3.59-5.31-7.08-8.14-10.45c-7.24-8.63-16.02-15.72-25.29-22.02c-0.22-0.15-0.38-0.32-0.52-0.5
		 c-17.36-11.63-36.94-19.43-60.25-20.98c-17.71-1.22-34.3-2.28-50.88-3.32c-3.16-0.2-7.13-2.15-9.37,0.4
		 c4.85,6.66,8.39,14.15,11.13,21.93c3.51,9.95,5.7,20.43,6.59,30.94c0.54,6.38,0.88,12.59,0.23,18.73c0.28,0.3,0.58,0.55,0.94,0.72
		 c3.36-1.82,6.89-3.42,10.01-5.43c5.39-3.48,10.2-7.52,16.47-9.22c7-1.89,13.75-4.01,21-4.88c6-0.72,12.1-0.92,18.13-1.5
		 c5.4-0.52,10.95-0.28,16.46,0.07c10.91-0.05,21.79,0.53,32.57,2.48c6.72,1.05,14.35,1.98,21.52,3.93
		 C1454.67,357.01,1454.49,356.7,1454.29,356.41z"
      />
      <path
        d="M360.15,1392.76c-4.41-9.65-5.97-19.2-10.81-27.88c-16.95-30.42-39.42-56-67.26-76.39c-18.28-13.39-40.01-19.7-62.74-21.6
		 c-2.9-0.24-6.91-0.88-8.28,2.05c13.58,7.01,25.01,17.23,36.7,27.45c8.01,7,15.18,16.75,20.71,25.8c2.81,4.59,5.39,9.36,6.6,14.63
		 c0.27,1.17,0.59,2.33,0.92,3.48c2.18,4.81,4.22,9.7,6.16,14.66c2.69,6.87,6.17,10.6,13.94,9.86c10-0.95,18.74,2.75,27.94,6.63
		 c13.44,5.67,24.22,14.99,36.39,22.33C360.43,1393.8,360.37,1393.71,360.15,1392.76z"
      />
      <path
        d="M1440.83,1718.56c-14.28-8.07-21.92-10.93-28.91-7.71c-2.17,2.87-4.38,5.73-6.44,8.67c-1.84,2.62-2.83,5.57-4.58,8.21
		 c-3.57,5.41-7.5,10.39-11.3,15.66c-8.56,11.85-18.02,22.92-28.24,33.37c-6.57,6.73-12.81,13.59-19.44,20.1
		 c28.43-3.04,56.4-7.67,82.35-20.87C1453,1761.36,1459.16,1741.76,1440.83,1718.56z"
      />
      <path
        d="M1516.58,1713.2c-0.05-4.58-3.07-8.24-7.36-5.47c-13.88,8.95-28.36,17.43-36.73,32.24c-0.98,6.54-2.91,12.92-5.43,19.03
		 c-2.46,5.97-4.18,12.17-6.5,18.18c-4.88,12.62-10.7,24.83-16.07,37.25c-5.07,11.72-11.07,22.91-16.37,34.5
		 c-0.01,0.02-0.02,0.04-0.03,0.06c1.18-0.52,2.24-1.36,3.32-2.04c37.55-23.84,69.01-52.54,82.29-97.26
		 C1517.36,1737.33,1516.72,1725.41,1516.58,1713.2z"
      />
      <path
        d="M679.91,1201.55c-8.27,15.55-22.19,26.6-32.46,40.67c-11.53,15.8-22.13,32.03-31.44,49.14
		 c-5.72,10.52-14.84,16.59-24.68,22.79c-24.71,15.54-47.03,34.4-69.63,52.8c-1.15,0.94-1.19,3.3-3.55,2.63
		 c0.47-1.49,0.76-3.06,1.44-4.45c12.34-25.35,30.67-46.36,48.72-67.64c30.68-36.15,65.91-67.07,105.46-93.14
		 c1.4-0.92,3.11-1.36,5.46-2.37C680.02,1201.64,679.91,1201.55,679.91,1201.55z"
      />
      <path
        d="M1527.58,1613.19c-5.38-5.57-9.48-6.25-11.24,2.16c-5.06,24.29-4.01,47.69,8.92,69.68c2.73,2.02,4.86,4.55,7.23,7.12
		 c2.83,3.07,5.92,5.65,8.27,9.17c0.35,0.52,0.69,1.03,1.05,1.53c0.95,1.22,1.85,2.48,2.68,3.8c1.46,2.08,2.89,4.18,4.14,6.47
		 c2.38,4.34,4.26,8.92,6.52,13.32c2.08,4.03,3.23,8.54,4.96,12.74c0.96,2.32,1.62,4.47,2.08,6.7c0.29-0.9,0.52-2.04,0.67-2.87
		 c1.46-7.95,3.46-15.87,4.09-23.9C1570.21,1677.79,1556.18,1642.79,1527.58,1613.19z"
      />
      <path
        d="M1174.58,1467.93c-11.6,16.56-25.22,29.77-40.95,40.92c-2.27,1.61-4.67,3.01-6.67,1.24c-1.71-1.52,0.41-3.73,1.17-5.64
		 c9.42-23.55,19.57-47.08,16.49-73.28c-1.8-15.35,8.93-16.17,18.91-17.8c10.21-1.66,18.49,1.64,22.61,11.92
		 C1192.79,1441.9,1182.2,1454.14,1174.58,1467.93z"
      />
      <path
        d="M1229.47,1474.28c-5.42,9.07-10.25,17.38-15.17,25.64c-1.27,2.14-2.72,4.24-5.52,4.63c-0.2-1.99-0.59-3.98-0.57-5.97
		 c0.19-16.68-2.44-32.83-7.92-48.65c-7.04-20.32,15.41-47.75,36.75-44.65c10.03,1.46,11.77,9.07,10,18.05
		 C1243.58,1440.82,1238.14,1457.61,1229.47,1474.28z"
      />
      <path
        d="M1590.02,134.49c-0.23-0.13-0.47-0.26-0.71-0.39c-10.51-5.39-21.6-9.75-32.6-13.61c-2.13-0.75-4.26-1.47-6.39-2.18
		 c-7.28-1.68-14.64-3.02-21.99-4.31c-11.3-1.98-22.7-4.63-34.19-5.19c-0.8,1.3-0.44,3.54,1.3,6.64c4.52,8.06,5.59,16.84,4.99,25.91
		 c-0.22,3.31,0.19,5.69,1.28,7.19c7.29-2.75,15.29-3.93,22.82-5.55c9.77-2.1,19.99-3.79,29.98-4.17
		 c11.02-0.43,21.59-0.07,32.57,1.18c3.36,0.38,6.68,0.73,9.93,1.26c0.42-0.43,0.74-1.03,0.9-1.86c0.02-0.09,0.01-0.17,0.02-0.25
		 C1595.33,137.57,1592.7,135.99,1590.02,134.49z"
      />
      <path
        d="M529.87,1483.3c3.89-18.45,15.55-33.33,23.22-50.05c4.71-10.26,11.28-19.68,17.09-29.43c0.6-1,2.11-2.56,2.58-2.36
		 c2.73,1.12,0.21,2.57-0.1,3.84c-2.53,10.23-5.77,20.34-7.61,30.69c-3.75,21.18-21.37,31.78-34.67,46.71
		 C529.75,1483.24,529.87,1483.3,529.87,1483.3z"
      />
      <path
        d="M1540.35,440.01c-15.17-3.87-29.2-10.72-43.58-16.65c-0.9-0.37-1.86-0.88-2.79-1.12c1.45,2.73,2.83,5.51,4.2,8.29
		 c0.78,1.33,1.57,2.66,2.39,3.96c1.29,2.06,2.55,4.28,3.83,6.52c0.41,0.51,0.82,1.03,1.23,1.55c2.86,2.41,5.42,5.46,7.73,8.5
		 c1.41,1.38,2.72,2.9,3.93,4.44c9.14-1.68,15.95-7.53,23.68-11.43C1545.45,441.81,1543.46,440.8,1540.35,440.01z"
      />
      <path
        d="M290.25,1655.8c-0.59,0.8-1.19,1.58-1.78,2.37c0.51,0.58,0.71,1.42,0.23,2.3c-3.91,7.14-4.6,14.81-7.06,22.47
		 c-0.01,0.04-0.03,0.07-0.05,0.11c2.06,12.51,8.84,24.11,19.84,32.82c-0.04-0.07-0.1-0.27-0.18-0.85
		 C297.46,1694.64,290.61,1675.81,290.25,1655.8z"
      />
      <path
        d="M349.04,1721.83c-3.2-2.59-7.42-5.9-10.7-9.67c-3.11-2.83-5.96-5.89-8.89-8.88c-0.22-0.22-0.45-0.45-0.69-0.69
		 c-0.28,0.9-0.51,1.81-0.49,2.7c0.58,20.6,11.32,30.01,31.86,27.94c0.97-0.1,2.41,0.27,3.38,0.01
		 C358.14,1730.48,353.56,1725.47,349.04,1721.83z"
      />
      <path
        d="M1465.4,482.59c0.96,1.15,1.94,2.26,2.93,3.33c0.6,0.37,1.24,0.61,1.9,0.72c0.59-0.24,1.17-0.49,1.75-0.75
		 c1.51-0.78,3.05-1.54,4.46-2.43c3.32-2.08,6.54-4.33,9.66-6.7c1.53-1.16,2.88-2.46,4.25-3.75c0.06-1.3-1.18-1.89-3.35-2.05
		 c-2.99-0.22-5.98-0.39-8.96-0.61c-0.56-0.03-1.11-0.07-1.67-0.13c-1.49-0.12-2.98-0.26-4.47-0.42c-0.79-0.09-1.64-0.27-2.52-0.5
		 c-2.49-0.27-5-0.6-7.5-0.93c-1.06-0.1-2.12-0.23-3.18-0.38c-0.29,0.22-0.55,0.48-0.73,0.83c-0.02,0.04-0.04,0.08-0.06,0.12
		 c0.73,1.73,1.41,3.49,2.21,5.15c0.2,0.39,0.4,0.77,0.6,1.15C1462.53,477.35,1463.92,480.04,1465.4,482.59z"
      />
      <path
        d="M439.83,771.94c-2.05,1.49-4.09,3-6.87,4.74c-0.74,0.23-0.71,0.28-0.71,0.28c2.05-1.48,4.11-2.97,6.9-4.71
		 C439.87,772,439.84,771.97,439.83,771.94z"
      />
      <path
        d="M749.65,846.18c-0.78,0.37-1.54,0.76-2.31,1.16c0.26-0.13,0.51-0.31,0.79-0.39c0.34-0.09,0.7-0.11,1.32-0.44
		 C749.72,846.23,749.66,846.2,749.65,846.18z"
      />
      <path
        d="M347.84,377.95c-0.62,0.5-1.17,1.08-1.81,1.55c-0.13,0.09-0.61-0.31-0.92-0.48c0.67-0.32,1.34-0.64,2.44-0.94
		 C347.97,378.09,347.88,378,347.84,377.95z"
      />
      <path
        d="M680.07,1201.69c0.35-0.22,0.66-0.5,0.97-0.78c-0.14,0.18-0.29,0.37-0.78,0.6
		 C679.91,1201.55,680.02,1201.64,680.07,1201.69z"
      />
      <path
        d="M1353.42,370.2c0.42-0.14,0.8-0.33,1.18-0.51c-0.29,0.02-0.58,0.04-1.09,0.24C1353.29,370.11,1353.39,370.16,1353.42,370.2
		 z"
      />
      <path
        d="M301.34,1716.06c0.35,0.34,0.76,0.64,1.17,0.93c-0.22-0.24-0.43-0.47-0.84-0.9C301.47,1715.9,301.4,1716.02,301.34,1716.06
		 z"
      />
      <path d="M432.21,776.88c-0.01-0.04,0.04,0.32,0.04,0.2C432.24,776.97,432.21,776.91,432.21,776.88z" />
      <path d="M1294.83,344.75c0.03-0.03,0.56-0.03,0.56-0.03s-0.49-0.22-0.59-0.1C1294.7,344.76,1294.8,344.78,1294.83,344.75z" />
      <path d="M526.62,1486.02c0,0,0.05,0.44,0.08,0.26C526.73,1486.1,526.62,1486.02,526.62,1486.02z" />
      <path
        d="M529.71,1483.18c-0.69,0.61-1.34,1.28-2.54,2.4c-0.55,0.45-0.43,0.52-0.4,0.59c0.66-0.64,1.29-1.35,2.52-2.46
		 C529.87,1483.3,529.75,1483.24,529.71,1483.18z"
      />
      <path d="M386,802.59c-0.06-0.05-0.08,0.14,0.05,0.15C386.18,802.75,386.06,802.64,386,802.59z" />
      <path d="M708.64,853.84c-0.01,0-0.76,0.33-0.76,0.33s0.94,0.22,0.85-0.04C708.64,853.86,708.65,853.84,708.64,853.84z" />
      <path d="M360.32,1393.71c-0.05,0,0.52,0.33,0.52,0.33s-0.11-0.51-0.26-0.38S360.37,1393.71,360.32,1393.71z" />
      <path
        d="M1494.27,423.08c1.51,2.96,3.03,5.93,4.54,8.89c-1.91-3.39-3.41-7.01-4.45-10.76c-0.29,0.72-0.06,1.54,0.18,2.28
		 c0.98,2.95,2.25,5.8,3.77,8.51c4.93,8.74,12.41,15.71,19.77,22.52"
      />
      <path
        d="M1494.28,422.38c1.8,5.76,3.65,11.6,7,16.62c1.51,2.26,3.31,4.31,5.1,6.35c1.78,2.03,3.56,4.07,5.34,6.1
		 c0.87,1,1.77,2.01,2.91,2.7c1.14,0.68,2.58,0.99,3.81,0.49c-0.7,0.83-2.09,0.48-2.9-0.24c-0.8-0.72-1.34-1.74-2.24-2.34
		 c1.26,0.7,2.52,1.4,3.77,2.1c0.82,0.46,2.03,0.84,2.55,0.06"
      />
      <path
        d="M1514.42,454.09c0.12-0.01,0.25-0.01,0.38-0.02c0.18-0.06,0.37-0.11,0.57-0.14c0.82-0.13,0.79-0.14,1.7-0.19
		 c0.33-0.02,0.62-0.05,0.89-0.08c0.23-0.05,0.47-0.1,0.71-0.16l-12.3-9.1c2.21,2.89,4.51,5.71,6.88,8.47
		 C1513.61,453.29,1514,453.71,1514.42,454.09z"
      />
      <path
        d="M1514.39,453.63c0.67,0.34,1.33,0.68,2,1.02c-0.59-0.01-1.17-0.17-1.67-0.46c0.43,0.43,1,0.73,1.61,0.85
		 c0.27,0.05,0.54,0.06,0.81,0.07c1.47,0.01,2.97-0.34,4.22-1.12"
      />
      <path
        d="M1516.87,454.95c-0.32-0.73-0.65-1.46-0.97-2.19c-0.09-0.21-0.33-0.45-0.5-0.3c-0.07,0.06-0.08,0.18-0.15,0.25
		 c-0.09,0.1-0.25,0.09-0.39,0.12c-0.26,0.07-0.42,0.36-0.42,0.63s0.14,0.52,0.3,0.74c0.46,0.62,1.16,1.05,1.92,1.18
		 c0.41,0.07,0.88,0.03,1.14-0.29C1518.02,454.82,1518.06,454.37,1516.87,454.95z"
      />
    </>
  );
}
