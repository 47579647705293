export default function ElderBrain(): JSX.Element {
  return (
    <>
      <path
        d="M321.2,2050.56c0.01-52.41,1.3-104.81,2.38-157.22c0.5-24.2,0.31-24.15,23.84-24.05c56.71,0.23,113.42-0.06,170.12,0.69
			c368.78,4.84,737.58,1.43,1106.37,2.27c13.21,0.03,26.54,0.21,39.58-1.47c11.81-1.52,15.06,2.64,14.98,13.73
			c-0.32,49.46-0.12,98.92-0.07,148.38c0,5.11,0.25,10.22,0.67,16.51C1227.04,2050.56,774.72,2050.56,321.2,2050.56z"
      />
      <path
        d="M902.07,1255.31c-4.14,9.48-8.8,17.75-11.63,26.6c-7.59,23.7-28.72,31.16-47.95,40.36
			c-37.86,18.09-78.43,27.33-119.5,34.81c-13.6,2.48-26.14-2.75-39.22-3.49c-32.26-1.82-61.35-11.89-88.03-30.26
			c-13.48-9.28-20.72-22.25-26.1-36.63c-2.97-7.92,3.96-7.58,9-8.22c22.14-2.8,37.59-15.06,50.27-35.79
			c-15.56,5.95-29.02,10.48-41.96,16.17c-22.89,10.07-41.53,0.74-58.98-12.53c-42.78-32.56-66.46-77.82-81.83-127.81
			c-14.02-45.61-10.28-91.63,1.66-137.07c5.39-20.51,24.65-48.53,41.19-63.25c14.23,26.06,40.35,36.66,64.58,49.7
			c23.04,12.4,36.38,30.95,32.73,55.6c-6.57,44.33,9.77,55.63,43.2,80.28c0.75,0.55,2.38-0.09,8.34-0.5
			c-33.08-23.38-47.63-52.15-39.62-90.97c14.56,3.66,28.93,8.8,43.61,2.52c19.29-8.25,37.06-19.67,60.72-26.07
			c-9.07-5.62-14.88-4.44-20.3-2.88c-11.45,3.29-23.61,5.52-33.88,11.11c-17.88,9.73-32.17,3.08-45.79-7.22
			c-15.55-11.76-29.77-25.28-45.47-36.81c-10.86-7.98-23.62-13.33-34.9-20.8c-23.67-15.68-30.35-34.2-25.1-62.28
			c4.86-25.98,9.78-51.91,23.53-74.92c17.32-28.98,37.83-54.64,73.44-62.09c4.31-0.9,6.7-2.94,8.05-7.02
			c14.47-43.65,46.13-72.34,84.31-94.57c28.84-16.79,59.87-28.2,93.85-28.62c13.05-0.16,25.86,0.08,37.7,8.57
			c6.04,4.33,15.54,2.67,23.45,4.67c50.84,12.89,83.26,49.33,113.6,88.22c18.82,24.12,35.56,49.67,50.49,76.51
			c12.66,22.77,17.82,46.99,16.91,72.3c-0.62,17.3-1.25,34.78-5.88,51.91c-6.37,23.6-4.59,47.68-1.06,71.75
			c17.11-52.13,31.96-104.51,23.65-163c17.98,10.22,27.97,24.12,39.75,35.66c19.82,19.43,43.3,32.63,67.9,44.24
			c26.36,12.44,55.92,18.11,79.05,37.44c0.66,0.55,2.32-0.11,4.95-0.33c-3.55-12.92-11.4-21.51-21.79-28.19
			c-19.76-12.71-42.37-19.48-63.09-30.27c-28.66-14.92-52.88-35.42-75.89-57.67c-9.46-9.14-19.46-17.63-31.65-23.11
			c-14.43-6.48-16.62-21.96-24.02-33.5c-32.46-50.62-66.16-99.99-117.39-134.23c-12.9-8.62-27.75-12.35-43.59-18.38
			c14.23-13.25,30.3-21.17,45.78-28.79c34.95-17.21,72.04-28.65,111.58-27.49c47.55,1.4,92.15,14.25,133.09,38.69
			c13.32,7.95,22.45,20.74,32.58,32.06c26.86,30,57.32,54.25,96.24,66.87c28.75,9.32,42.64,31.44,50.03,59.39
			c5.52,20.9,10.19,42.52,27.16,58.2c1.79,1.65,3.13,4.26,8.77,3.07c-21.44-24.48-16.86-56.82-30.78-86.14
			c17.73,8.41,25.3,24.5,38.91,33.76c-5.33-17.6-17.03-30.71-28.44-44.01c-18.25-21.28-42.7-33.65-67.58-44.35
			c-32.29-13.89-59.76-33.63-82.9-59.74c-3.02-3.41-7.87-5.27-9.11-10.04c0.82-4.21,3.93-4.19,6.96-4.4
			c48.51-3.36,97.22-6.94,145.47,0.44c43.22,6.61,82.4,21.19,115.39,53.84c42.19,41.76,59.22,92.56,68.05,148.53
			c4.71,29.83,4.7,59.41-0.32,89.05c-4.11,24.27-17.62,43.99-31.98,63.06c-13.78,18.3-28.88,35.53-41.3,54.93
			c-11.65,18.2-20.22,37.76-24.9,58.39c-6.56,28.91-23.44,48.31-48.48,62.08c-2.59,1.42-5.17,3-7.39,4.93
			c-2.45,2.13-4.48,4.74-7.11,7.59c29.39,2.12,61.04-20.21,71.61-47.94c8.91-23.37,15.38-47.8,28.13-69.59
			c5.26-8.98,10.89-17.83,20.38-26.38c-0.44,23.67,3.38,44.56,13.56,63.46c8.03,14.9,17.38,29.27,27.59,42.77
			c12.03,15.92,11.61,33.08,8.66,51.1c-0.93,5.7-1.68,11.44-2.65,18.13c16.55-10.36,24.83-36.28,19-54.21
			c-7.64-23.47-24.02-41.82-35.97-62.76c-15.59-27.31-23.35-71.49,1.53-101.89c26.75-32.69,32.24-72.95,30.65-114.52
			c-1.2-31.35-6.05-61.72-17.35-91.02c-3.88-10.05,2.06-12.86,10.2-11.58c32.29,5.09,62.58,14.86,86.48,38.89
			c15.34,15.42,28.99,32.15,39.29,51.32c9.14,17.01,25.72,25.46,40.03,36.59c36.17,28.14,60.1,63.91,69.2,109.36
			c2.86,14.27,4.3,28.64,6.39,42.97c3.83,26.36-1.97,49.74-19.32,70.17c-4.3,5.07-8.1,10.57-13.16,17.24
			c-15.98-22.7-32.04-43.98-57.86-60.92c5.02,17.86,14.97,28.44,22.59,40.12c14.45,22.14,27.03,44.98,34.57,70.49
			c17.96,60.77-3.21,110.49-50.66,147.27c-36.86,28.57-78.95,45.49-123.84,54.36c-40.11,7.93-80.87,10.54-122.04,4.12
			c-26.6-4.15-52-13.32-78.39-16.94c-23.57-3.23-46.55-8.52-69.66-13.51c-13.17-2.84-26.05-6.98-41.32-11.15
			c15.57-22.47,25.68-45.8,50.66-58.67c20.94-10.79,40.07-12.92,61.96-3.92c23.13,9.51,44.19,1.68,62.97-13.01
			c9.21-7.21,19.11-13.93,24.95-24.6c-3.75-3.18-5.52-0.56-7.18,1.04c-24.84,24.07-52.65,33.85-85.88,18.15
			c-27.02-12.77-49.42-1.56-70.94,13.83c-2.02,1.44-3.27,4.01-6.38,3.77c-10.52-33.72-10.23-69.19-16.7-103.8
			c-8.89,16.46-12.53,33.44-9.4,52.08c3.45,20.51,10.09,41.6,5.18,61.97c-8.93,36.98-31.72,63.28-69.3,74.36
			c-49.43,14.58-100,22.26-151.45,17.92c-39.78-3.36-79.87-7.32-116.92-24.45c-6.42-2.97-13.24-2.96-19.82-4.61
			c-7.88-1.97-9.52-5.74-5.85-12.93c22.63-44.3,43.47-89.45,59.1-136.71c7.07-21.38,10.77-43.69,10.47-66.6
			c-0.48-36.69,10.91-47.76,47.12-54.86c29.8-5.85,57.67-17.65,80-39.57c0.43-0.43,0.16-1.57,0.3-3.68
			c-13.83,3.29-26.85,7.5-39.31,13.49c-17,8.18-35.73,10.55-53.62,15.66c-16.37,4.67-30.48,12.65-42.9,23.83
			c-3.6,3.24-6.44,5.29-11.04,2.38c-6.73-4.27-13.7-2.81-20.99-1.23c-23.07,4.98-37.45-1.53-48.3-22.83
			c-7.11-13.96-12.55-28.75-18.98-43.07c-6.09-13.55-14.16-25.61-26.1-34.12c-2.88,1.58-3.98,3.31-3.13,4.82
			c14.8,26.32,22.05,56.03,37.35,82.19c14.72,25.17,34.03,33.91,61.95,26.15c11.42-3.18,18.74,3.67,20.33,16.95
			c2.84,23.63-7.65,44.08-14.45,65.39C924.04,1189.19,913.1,1221.75,902.07,1255.31z"
      />
      <path
        d="M380.13,1634.84c-17.86-37.3-22.38-75.74-17.11-115.37c5.55-41.73,12.63-83.27,17.72-125.05
			c4.5-36.85,2.89-73.47-16.65-106.56c-14.04-23.78-33.43-41.56-61.69-47.57c-29.27-6.22-52.46,6.18-73.01,24.54
			c-28.89,25.81-43.93,59.77-53.59,96.51c-0.76,2.88-1.51,5.77-2.56,8.55c-1.03,2.73-2.45,5.32-4.81,10.35
			c-3.56-8.21-6.46-14.41-8.98-20.77c-15.24-38.37-15.33-76.57-1.33-115.66c14.6-40.8,41.89-70.12,79.25-89.5
			c27.65-14.34,58.2-15.08,88.6-8.38c76.88,16.94,124.86,67.57,154.4,136.97c11.74,27.57,15.89,58.03,20.7,88
			c7.02,43.75,7.8,88.13,16.26,131.77c4.84,25,8.13,50.11,16.88,74.24c12.97,35.78,35.91,63.59,67.5,83.88
			c30.9,19.85,64.46,35.04,102.1,29.3c48.94-7.47,95.05-20.7,122.48-69.02c25.29-44.54,41.42-90.62,43.15-142.39
			c1.08-32.47-2.49-64.72-0.93-97.11c0.41-8.54-3.06-16.6-6.24-24.61c-6.24-15.72-1.45-22.94,14.96-22.19
			c42.56,1.93,48.06,12.54,60.45,49.18c18.04,53.33,23.88,108.82,23.66,164.74c-0.2,50.73-6.25,100.83-21.03,149.83
			c-12.19,40.4-27.11,79.46-47.26,116.52c-7.4,13.61-17.97,24.73-28.77,35.51c-6.06,6.05-13.47,9.31-22.93,9.16
			c-50.33-0.82-100.76-2.96-150.97-0.66c-36.23,1.66-66.44-9.96-96.05-28.03c-55.21-33.71-108.33-70.29-156.9-113.14
			C411.79,1695.25,394.66,1666.48,380.13,1634.84z"
      />
      <path
        d="M1130.01,1534.49c-12.34,38.4-33.28,71.67-49.73,107.03c-16.73,35.97-27.18,72.84-13.22,112.13
			c8.49,23.89,23.06,43.56,46.82,54.65c60.03,28,115.39,14.92,166.8-21.35c43.74-30.86,67.89-73.51,72.44-127.61
			c1.94-23.08,0.5-44.24-10.03-65.4c-12.81-25.73-33.37-40.81-60.18-46.54c-16.84-3.6-30.71,7.6-44.08,16.69
			c-17.75,12.06-29.64,28.82-38.27,51.96c-3.94-21.23,2.09-37.99,8.13-53.51c13.34-34.31,36.42-61.27,70.61-76.72
			c63.1-28.51,133.44-10.27,168.64,52.94c19.74,35.45,24.93,74.44,17.34,115.09c-7.09,37.97-13.24,76.15-23.07,113.5
			c-8.9,33.82-25.99,63.31-51.27,87.88c-4.76,4.63-10,5.8-16.21,5.79c-145.01-0.37-290.02-0.65-435.03-0.99
			c-13.37-0.03-23.5-14.71-27.72-33.66c-4.1-18.4,7.23-30.93,13.45-45.23c30.6-70.37,52.32-142.44,51.51-220.36
			c-0.42-41-0.67-81.91-9.01-122.07c-5.25-25.3-13.66-49.95-20.68-74.89c-0.77-2.75-3.27-5.14-0.83-10.01
			c15.6,5.53,32.53,6.27,48.9,10.18c42.1,10.05,84.71,7.49,127.21,4.69c10.41-0.68,14.84,1.63,16.29,12
			c4.26,30.48,5.29,60.79,3.19,91.66C1140.58,1493.52,1134.4,1513.16,1130.01,1534.49z"
      />
      <path
        d="M475.54,877.14c-11.28-29.37-24.5-56.8-54-70.6c-18.1-8.46-38-12.48-58.01-13.85c-43.07-2.95-84.41-12.11-120.49-36.67
			c-24.38-16.6-44.21-38.26-59.22-64.07c-19.01-32.68-31.93-68.08-37.86-104.81c-5.94-36.77-6.61-74.35-3.22-112.02
			c3.63-40.36,14.39-78.44,30.2-115.17c15.57-36.18,37.91-68.01,67.8-94.11c20.98-18.32,44.18-33.49,70.33-42.83
			c41.73-14.91,83.96-19.75,124.39,4.2c23.94,14.18,35.53,38.67,45.31,62.97c12.71,31.57,14.9,64.35,1.83,96.5
			c-6.9-8.7-8.09-20.07-12.59-30.14c-16.06-35.94-45.83-49.67-82.45-52.48c-43.1-3.32-77.62,14.35-106.18,45.32
			c-19.86,21.53-35.09,46.22-43.59,74.12c-9.72,31.89-15.14,64.2-6.35,97.98c8.65,33.25,22.04,63.01,48.25,86.06
			c25.66,22.57,57.01,32.8,89.23,41c25.52,6.49,51.87,8.52,77.61,14.4c25.72,5.88,51.23,12.24,75.69,21.83
			c17.95,7.03,36.01,14.81,50.97,29.91c-65.26,37.3-91.41,95.74-92.67,167.31C476.39,882.38,476.83,879.47,475.54,877.14z"
      />
      <path
        d="M245.34,1425.46c17.05-18.61,36.44-33.27,54.62-49.29c19.39-17.09,38.71-34.26,58.05-51.39c5.17,1.81,4.19,6.14,4.31,9.73
			c1.19,35.7,5.69,71.53-0.1,107.06c-3.96,24.27-12.48,47.48-22.85,69.89c-10.3,22.24-21.4,43.85-25.79,68.72
			c-8.39,47.51,5.38,85.29,43.67,114.39c9.13,6.94,19.77,10.13,30.73,10.93c12.68,0.93,21.97,6,30.94,15.06
			c38.05,38.48,80.23,71.88,126.86,99.54c4.69,2.78,8.83,6.47,14.67,10.81c-18.7,14.54-36.52,27.44-60.63,27.26
			c-18.76-0.14-37.52-0.12-56.27,0.05c-45.74,0.41-88.25-12.34-127.8-33.99c-37.65-20.6-69.54-48.48-95.57-83.1
			c-24.21-32.2-38.86-67.6-39.67-108.02c-0.75-37.38-2.97-74.79,9.56-111.31c8.23-24,20.04-45.85,33.67-66.89
			C230.17,1444.96,237.72,1435.73,245.34,1425.46z"
      />
      <path
        d="M1481.08,1612.6c0.78-11.79,10.03-19.03,24.4-21.68c47.71-8.78,86.63,6.6,119.34,41.43
			c15.92,16.96,32.69,33.19,49.9,48.85c38.16,34.75,72.1,18.92,96.82-15.45c36.16-50.29,16.4-104.92-22.67-141.47
			c-30.02-28.08-64.41-46.15-104.58-54.3c-16.49-3.35-31.05,2.74-46.31,6.09c-3.46,0.76-7.01,3.69-12.25,0.07
			c13.31-14.77,29.99-23.85,47.72-30.11c21.21-7.48,42.86-12.71,66.15-11.66c64.49,2.9,113.88,32.25,152.25,82.69
			c22.34,29.36,34.29,62.75,35.61,99.19c1.67,46.26-12.93,87.46-47.44,119.22c-46.66,42.94-101.26,53.47-162.53,38.03
			c-31.21-7.87-58.91-24-89.29-33.34c-40.6-12.47-80.31-12.94-117.8,10.52c-1.37,0.86-3.02,1.28-4.3,1.81
			c-4.18-2.58-3.97-6.47-2.87-9.43c7.2-19.33,7.37-40.11,12.8-59.84C1482.29,1660.52,1483.56,1637.16,1481.08,1612.6z"
      />
      <path
        d="M1194.42,1641.17c7.34,6.49,10.27,2.41,12.17-3.93c9.9-32.94,30.14-56.39,62.23-69.8c10.72-4.48,18.38-2.63,23.8,7.04
			c8.08,14.42,18.6,26.26,32.77,34.93c4.6,2.82,6.81,7.66,7.19,12.97c2.9,39.97-2.47,77.46-26.15,111.86
			c-13.64,19.82-30.4,23.83-51.62,20.56c-34.06-5.24-57.4-26.31-76.17-53.07c-33.57-47.85-42.87-101.24-32.36-158.07
			c10.21-55.21,15.46-110.46,5.63-166.21c-2.09-11.85,2-15.45,13.45-17.27c23.57-3.74,45.82-12.59,67.39-22.63
			c18.21-8.48,34.87-2.55,51.64,3.66c5.25,1.94,3.93,6.62,2.85,10.98c-3.86,15.59-10.56,30.21-12.7,46.52
			c-2.25,17.11-4.43,33.87,0.37,50.62c3.17,11.08,0.59,18.78-9.74,24.47c-30.93,17.04-52.8,42.77-65.64,75.11
			C1187.85,1578.32,1179.21,1608.87,1194.42,1641.17z"
      />
      <path
        d="M1257.12,570c-13.34,0.8-25.42,1.6-37.51,2.39c28.39-16.39,55.8-33,82.38-51.07c26.77-18.19,50.66-39.08,64.38-69.03
			c12.83-28.03,12.65-57.3,2.28-85.85c-8.95-24.65-23.1-45.91-45.42-61.5c-25.33-17.69-65.59-18.75-91.75-1.6
			c-22.7,14.87-40.46,34.27-51.63,58.53c-5.86,12.73-6.77,27.73-10.06,42.36c-5.11-8.51-5.76-18.04-5.63-26.86
			c0.63-42.82,10.14-82.27,43.57-112.88c29.65-27.15,64.43-37.89,103.89-36.65c58.87,1.85,98.3,31.55,122.77,83.9
			c21.03,44.98,29.98,92.62,25.73,141.43c-4.23,48.57-21.84,93.14-54.23,130.93c-4.35,5.08-7.98,6.49-14.15,4.31
			C1348.57,573.17,1303.75,569.48,1257.12,570z"
      />
      <path
        d="M814.33,1352.61c3.43-0.87,5.93-1.46,8.26-2.46c18.82-8.12,24.69-6.5,29.84,13.94c7.6,30.17,10.07,60.9,6.86,92.32
			c-4.88,47.88-16.6,93.51-38.43,136.43c-19.35,38.04-48.97,61.77-92.23,68.25c-22.69,3.4-44.89,10.74-68.1,4.51
			c-17.42-4.68-35.21-8.69-50.82-24.13c21.4-3.09,39.02-9.77,53.83-22.38c22.5-19.17,44.18-39.62,52.78-68.79
			c12.57-42.6,21.21-85.64,7.14-130.26c-4.43-14.03-10.42-27.67-14.19-44.72C744.98,1370.61,779.65,1363.74,814.33,1352.61z"
      />
      <path
        d="M1119.58,1791.14c-26.51-15.16-39.02-37.39-41.25-66.87c-2.38-31.5,7.39-59.45,21.3-86.67
			c6.51-12.74,12.89-25.55,20.72-41.1c16.66,87.24,48.98,158.24,145.85,176.63c-27.82,19.9-57.97,31.6-92.16,31.65
			C1155.15,1804.82,1136.78,1802.09,1119.58,1791.14z"
      />
      <path
        d="M494.28,1236.28c11.13,10.52,21.33,20.69,34.53,27.22c7.55,3.73,12.31,10.86,15.93,17.92
			c6.79,13.26,14.76,25.63,23.94,37.27c4.08,5.17,3.57,9.55,0.21,15.05c-16.35,26.8-34.92,52.06-55.03,80.81
			c-1.33-15.3-2.73-27.55-3.41-39.83c-2.14-38.37-13.91-74.04-30.03-108.5c-3.23-6.91-6.9-13.62-10.53-20.33
			c-9.15-16.91-9.18-16.89,8.61-27.02C483.48,1224.39,488.56,1230.02,494.28,1236.28z"
      />
    </>
  );
}
