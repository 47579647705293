export default function Succubus(): JSX.Element {
  return (
    <>
      <path
        d="M1269.75,862.13c-14.56,3.03-21.32,14.86-27.97,26.05c-7.96,13.4-14.04,27.83-18.68,42.79c-1.77,5.69-6.69,8.58-10.6,12.08
		c-24.82-5.75-49-13.21-72.26-22.7c-26.11-10.65-52.97-20.18-76.6-36.08c-5.22-3.51-8.93-4.12-13.47-0.14
		c-29.6,25.92-65.04,45.33-90.37,75.74c-26.49,31.78-44.16,68.35-49.18,110.57c-2.64,22.22-9.06,43.98-13.47,66.01
		c-5.4,26.96-11.73,53.68-22.62,78.99c-8.67,20.16-20.15,38.93-31.45,57.73c-24.14,40.15-52.15,77.58-80.64,114.69
		c-8.52,11.09-12.94,23.73-16.09,37.9c-7.75,34.93-12.61,70.41-15.7,105.66c-4.12,46.97-9.38,94.24-5.66,141.66
		c0.79,10.03-1.13,20.36,3.83,30.27c-0.08-21.33,7.83-41.04,11.23-61.6c2.43-14.73,7.14-28.76,12.04-42.61
		c8.98-25.39,19.04-50.53,32.37-73.94c16.04-28.19,32.3-56.28,50.79-83.05c12.69-18.37,25.87-36.33,38.92-54.42
		c13.71-19,27.51-37.88,39.8-57.97c17.21-28.15,24.92-58.97,31.22-90.56c3.75-18.84,3.69-38.02,5.37-57.04
		c3.47-39.55,4.2-79.68,18.04-117.74c5.8-15.95,10.13-17.5,25.04-9.04c20.91,11.86,41.1,24.49,58.99,40.84
		c20.9,19.1,36.42,41.99,50.15,66.36c16.57,29.41,27.03,61.52,40.77,92.19c0.5,1.12,1.37,2.08,3.44,5.17
		c3.74-14.27,9.16-25.57,12.61-37.33c10.24-34.94,26.55-67.04,43.14-99.17c9.09-17.6,16.57-36.04,26.76-53.09
		c16.62-27.83,37.11-52.52,61.67-73.7c12.24-10.56,24.71-21.03,43.01-22.15c-4-6.55-9.26-7.44-14.78-7.36
		c-18.76,0.28-37.6-0.26-56.23,1.43c-13.81,1.25-26.99-0.87-40.4-2.03c-0.63-6.63,2-8.74,8.07-8.65c24.72,0.38,49.39,0.06,74.1-2.02
		c12.84-1.08,25.59,1,37,10.12c11.45,9.16,25.09,1.95,37.64,0.01c11.7-1.81,22.59-6.7,34.51-8.59c34.29-5.44,60.76,32.97,54.4,62.91
		c-6.69,31.47-18.71,61.23-30.41,91.08c-11.67,29.78-23.21,59.58-33.53,89.9c-6.92,20.33-14.19,40.56-20.48,61.11
		c-10.99,35.91-24.01,71.43-31.65,108.06c-7.59,36.36-3.36,73.72,2.22,110.48c1.4,9.23,4.33,18.62,3.61,27.7
		c-2.03,25.67,10.41,45.7,23.77,65.29c5.09,7.46,8.33,15.56,3.58,22.18c-4.78,6.66-12.83,5.56-21.6,2.3
		c-20.59-7.66-37.97-18.77-50.86-36.6c-5.17-7.16-6.7-15.45-7.19-24.1c-2.04-35.67-9.93-69.3-32.86-98.12
		c-8.58-10.78-14.17-23-13.32-37.64c0.64-10.98,8.23-18.57,12.77-27.25c13.78-26.35,24.35-53.9,30.33-82.62
		c7.61-36.58,12.82-73.88,10.47-111.42c-1.43-22.78,0.6-44.59,9.35-66.06c-16.72,3.36-25.66,14.83-28.01,29.49
		c-6.47,40.29-8.28,81.06-15.52,121.37c-10.48,58.3-33.41,111.18-61.31,162.52c-2.07,3.81-2.92,7.39-2.76,11.99
		c0.43,12.32-0.75,24.69-0.75,37.04c0,49.93-8.03,99.4-8.71,149.27c-0.56,41.39,4.81,81.86,16.35,121.99
		c10.9,37.9,27.94,72.54,45.39,107.5c11.5,23.03,24.29,45.84,28.79,72.17c2.38,13.9,8.5,27.41,14.52,40.36
		c5.63,12.11-4.09,17.96-8.44,25.88c-3.87,7.06-10.98,5.4-17.15,3.48c-10.62-3.31-19.35-8.54-26.9-17.82
		c-18.93-23.27-30.73-51.51-52.21-72.93c-11.27-11.23-21.3-23.71-32.6-34.9c-6.27-6.21-9.16-13.99-13.47-21.19
		c-11.54-19.25-4.54-38.8-2.05-58.3c4.07-31.91,3.15-63.55-2.94-95.22c-6.63-34.51-20.97-66.34-32.43-101.64
		c-28.73,55.12-53.63,109.94-94.49,154.86c-0.7,0.77-3.21-0.1-5.15-0.22c-1.58-7.4-1.94-14.64,0.14-21.6
		c12.73-42.5,15.18-86.09,14.35-130.07c-0.14-7.41-0.02-14.82-0.02-25.79c-15.24,23.57-27.55,44.45-41.66,64.03
		c-25.14,34.9-52.88,67.65-86.89,94.48c-5.41,4.27-10.21,5.19-16.73-0.52c-34.68-30.36-62.25-66.48-87.25-104.82
		c-4.37-6.7-8.89-13.31-13.02-20.15c-3.89-6.44-0.95-11.83,4.39-15.27c28.16-18.16,52.49-40.78,75.56-64.78
		c32.42-33.72,60.36-71.1,84.46-111.03c18.66-30.9,33.89-63.78,46-97.93c2.67-7.54,2.79-15.86,7.03-22.95
		c2.39-3.99-1.05-6.1-2.97-8.79c-16.47-23.13-30.04-47.78-39.34-74.7c-0.64-1.85-1.74-3.98-5.36-3.74
		c-5.01,14.23-3.19,29.64-5.62,44.42c-8.54,51.97-34.46,95.38-66.04,136.42c-32.26,41.91-62.3,85.35-88.02,131.82
		c-12.5,22.59-24.77,45.28-33.88,69.23c-14.58,38.35-26.32,77.63-28.68,119.15c-1.23,21.7-3.51,43.34-2.66,65.12
		c0.14,3.52-0.42,6.81-5.3,8.43c-8.67-9.48-10.44-21.91-11.27-33.66c-3.27-46.73-8.31-93.47-5.33-140.41
		c3.12-49.2,5.67-98.37,14.08-147.22c4.11-23.88,8.16-47.31,16.95-69.86c4.89-12.54,16.05-20.25,23.8-30.61
		c28.68-38.29,56.28-77.16,79.32-119.21c9.16-16.71,16.98-33.91,24.26-51.46c2.01-4.84,3.97-9.38,1.4-15.33
		c-26.93,8.45-49.12,25.07-70.43,42.22c-51.24,41.25-95.74,89.54-140.05,137.93c-29.31,32.01-59.16,63.43-92.7,91.21
		c-25.66,21.25-53.3,39.28-84.95,49.48c-53.45,17.22-104.82,15.02-148.75-24.75c-29.85-27.03-38.14-62.05-31.58-100.88
		c8.21-48.61,35.81-85.37,72.02-116.97c6.83-5.96,13.65-12.03,21.68-18.15c-15.3-9.33-31-7.8-46.54-7.6
		c5.15-9.15,8.64-13.1,24.86-18.29c66.61-21.31,134.23-36.31,208.32-28.52c-42.76,48.28-84.36,95.26-126.1,142.39
		c-4.55-4.65-4.43-10.47-4.32-16.29c0.24-13.08-7.97-23.07-13.22-33.96c-3.35-6.95-10.12-3.17-13.97,0.57
		c-28.24,27.44-49.21,59.43-52.05,99.59c-3.22,45.51,26.85,67.85,68.55,57.5c45.91-11.4,79.97-40.43,109.41-75.74
		c24.49-29.36,45.13-61.47,66.13-93.35c28.2-42.82,60.43-82.51,100.17-115.17c31.86-26.18,67-47.72,103.81-66.45
		c19.91-10.13,38.6-23.56,59.49-30.58c23.7-7.96,28.55-28.26,36.9-45.98c15.17-32.22,37.39-57.97,65.35-79.17
		c12.76-9.68,26.31-18.29,39.38-27.56c17.4-12.34,25.42-30.72,31.39-50.19c1.52-4.95-0.39-9.2-6.49-10.79
		c-11.19,19.55-14.05,44.2-35.88,57.85c-12.08-16.4-22.98-34.3-37.68-49.33c-4.66-4.76-3.45-9.79,1.62-15.29
		c15.79-17.16,30.71-35.11,45.97-52.76c2.22-2.57,4.95-5.1,0.42-9.17c-12.34,9.34-23.28,20.14-33.5,31.89
		c-21.94,25.24-45.4,49.06-70.18,71.5c-11.92,10.79-27.02,16.88-41.14,24.18c-23.15,11.96-46.88,22.78-70.21,34.41
		c-12,5.98-24.18,11.61-36.11,17.75c-20.05,10.32-30.89,28.6-43.34,45.18c-11.28,15.03-26.3,23.29-42.26,30.64
		c-21.84,10.05-39.54,24.23-51.95,45.12c-3.78,6.37-4.79,18.09-17.82,12.55c-7.98-3.39-13.56-14.95-10.9-25.52
		c3.85-15.31,11.44-29.1,19.03-44.39c-17.6-8.86-36.02-8.82-52.79-2.11c-20.99,8.4-42.09,15.1-64.27,19.12
		c-10.87,1.97-20.25-1.96-29.61-5.47c-7.15-2.68-7.54-11.82-0.68-15.14c26.79-12.99,48.66-32.56,71.57-50.95
		c12.89-10.35,29.31-9.96,45.32-8.17c42.39,4.74,79.82-8.7,115.54-30.37c34.58-20.99,61.96-50.3,91.76-76.76
		c14.36-12.75,31.59-19.69,48.56-26.2c33.41-12.83,60.26-31.96,80.72-62.01c15.22-22.37,27.82-45.81,39.61-69.9
		c0.74-1.52,1.92-3.16,1.85-4.7c-1.3-28.54,22.27-30.5,40.55-38.18c17.5-7.35,35.05-14.58,52.32-22.45c3.62-1.65,6.7-3.42,12.32-2.1
		c-2.19,15.91-0.15,32.58-5.78,48.18c-8.68,24.06-17.31,48.04-28.18,71.42c-14.11,30.36-12.27,67.48,16.84,96.09
		c22.87,22.47,50.58,21.91,79.2,15.01c21.53-5.2,41.65-13.71,59.45-27.08c3.84-2.88,6.65-2.25,10.28,0.18
		c7.26,4.87,15.23,8.75,22.17,14.02c24.32,18.45,68.2,9.9,86.75-9.26c16.78-17.34,27.27-43.09,17.57-71.99
		c-12.65-37.7-40.16-61.82-69.6-85.24c-14.4-11.46-25.06-26.14-31.16-44.61c7.52,0,14.58-0.7,21.44,0.18
		c8.31,1.06,14.58-3.8,16.81-9.25c10.05-24.61,26.57-45.83,36.01-70.7c2.75-7.26,2.68-14.06-0.12-21.09
		c-2.22-5.59-5.31-12.03-2.28-17.29c10.2-17.73,7.99-36.41,4.29-54.64c-2.8-13.79,0.48-26.41,4.1-38.69
		c10.03-34.1,6.54-67.84-3.08-100.67c-5.28-18.02-15.69-34.78-33.4-45.16c-6.72-3.94-9.02-9.01-6.92-18.83
		c6.86-31.99,9.34-64.74,10.65-97.47c0.66-16.62,0.3-33.29,1.03-49.9c0.71-16.16,4.63-18.16,19.63-13.03
		c8.75,2.99,17.33,6.58,27.02,6.96c10.14,0.4,13.01,11.04,18.08,17.27c19.49,23.97,37.53,49.28,52.14,76.5
		c19.9,37.08,36.69,75.56,49.4,115.96c8.65,27.52,16.13,55.29,22.63,83.26c9.69,41.67,18.4,83.61,22.79,126.33
		c2,19.45,5.75,38.72,8.81,58.06c1.06,6.69,2.62,13.33-0.28,20.93c-17.25-1.5-34.09-6.09-51.66-5.68
		c-25.94,0.61-51.9-0.9-76.94,8.16c-23.14,8.38-41.8,23.27-52.83,44.46c-7.93,15.25-19.18,28.93-23.79,46.97
		c11.35,14.71,26.54,27.38,35.85,45.13c13.99,26.68,15.09,52.35,1.62,79.72c-12.95,26.32-34.72,40.3-60.91,49.56
		c-2.7,0.96-6.14,0.38-7.65,4.68C1269.59,862.38,1269.75,862.13,1269.75,862.13z M1047.25,1329.7
		c-10.48-11.07-20.97-22.13-32.12-33.9c-5.15,25.85-12.91,48.81-22.68,70.64c-18.63,41.65-42.17,80.47-70.17,116.85
		c-32.77,42.58-69.46,80.84-113.13,112.05c-7.67,5.48-6.82,8.93-2.59,15.03c17.09,24.7,33.59,49.84,53.3,72.62
		c12.2,14.09,24.79,27.78,39.56,39.98c19.85-14.17,35.67-31.57,50.95-49.43c24.87-29.07,46.83-60.19,66.33-93.18
		c15.19-25.7,29.8-51.8,41.61-79.03c13.9-32.04,24.98-65.35,35.97-98.55c3.1-9.36,8.88-20.23-2.5-30.14
		C1076.7,1359.52,1062.89,1344.94,1047.25,1329.7z M1282.07,1160.31c-0.61-7.75,5.26-15.08,2.05-23.48
		c-29.85,10.72-57.5,25.86-88.77,30.29c-4.83,0.68-5.38,5.15-6.71,8.5c-10.13,25.42-20.59,50.79-27.2,77.37
		c-2.46,9.9-8.29,20.28-4.93,30.39c4.2,12.65,11.33,24.3,16.75,36.57c7.4,16.76,16.27,32.79,22.7,50.1
		c5.06,13.6,9.2,27.67,14.72,42.28c4.78-7.06,9.36-12.23,12.16-18.22c19.31-41.27,36.76-83.38,44.72-128.44
		C1273.59,1231.51,1276.93,1196.88,1282.07,1160.31z M1090.45,1450.09c-11.33,34.05-25.33,66.97-41.34,99.07
		c-3.99,8-12.96,14.4-9.26,25.19c11.1,32.32,8.63,65.26,5.18,98.39c-1.49,14.32-8.09,28.11-5.61,43.9c2.11-1.32,3.47-1.71,4.06-2.62
		c22.14-33.68,40.73-69.35,59.54-104.94c11.39-21.55,15.35-42.13,5.18-65.74c-8.59-19.94-6.88-41.39,0.08-62.08
		c4.98-14.82,10.75-29.43,14.82-44.49c6.13-22.71,2.05-34.38-14.41-46.22c-3.43,4.99-4.53,10.65-5.95,16.41
		C1099.33,1420.82,1095.09,1434.47,1090.45,1450.09z"
      />
      <path
        d="M288.39,1048.08c-15.09-33.06-30.36-64.96-43.24-97.99c-10.41-26.69-23.34-52.39-34.38-78.84
		c-13.48-32.29-25.95-65.01-39.26-97.38c-9.61-23.37-19.58-46.61-29.95-69.67c-16.24-36.12-34.48-71.34-48.54-107.93
		c21.48-33.15,50.09-59.24,79.77-83.63c38.55-31.69,79.73-59.95,123.1-84.97c31.4-18.12,63.05-35.72,96.09-50.58
		c34.12-15.34,68.51-30.05,104.04-42.12c21.15-7.18,42.12-14.91,63.71-20.61c29.46-7.78,59.11-14.82,88.72-22.01
		c2.68-0.65,5.66-0.09,11.67-0.09c-13.79,8.85-25.1,15.74-36.03,23.19c-47.07,32.1-87.77,70.93-123.91,114.99
		c-26.73,32.59-50.38,67.16-69.73,104.38c-15.48,29.77-29.17,60.45-40.27,92.43c-8.21,23.67-14.85,47.65-21.44,71.67
		c-9.78,35.66-14.83,72.35-18.08,109.01c-6.58,74.16-14.38,148.24-15.98,222.81c-0.67,30.97-4.34,61.88-6.65,92.82
		c-1.19,0.44-2.37,0.88-3.56,1.32C312.63,1099.55,300.79,1074.21,288.39,1048.08z"
      />
      <path
        d="M1409.51,149.5c-19.55-20.59-40.38-38.45-61.85-55.88c17.6,0.55,34.23,4.27,50.53,7.62c37.2,7.64,73.54,18.98,109.45,31.88
		c39.47,14.17,74.9,35.89,112.08,54.39c28.84,14.34,56.19,30.87,82.43,49.05c40.12,27.79,79.34,56.87,115.82,89.57
		c9.61,8.62,19.43,16.88,27.38,26.96c12.76,16.19,14.24,36.21,14.91,55.42c2.49,71.66-1.56,143.13-6.11,214.68
		c-2.37,37.39-7.75,74.46-9.21,111.92c-0.77,19.86-6.55,39.49-7.83,59.38c-1.61,25.1-8.35,49.4-9.76,74.45
		c-0.4,7.19-1.85,14.33-7.7,20.37c-9.82-12.12-12.39-27.26-18.3-40.76c-11.48-26.2-21.76-52.93-32.34-79.52
		c-10.91-27.4-21.44-54.95-32.4-82.34c-9.98-24.95-19.87-49.94-30.57-74.58c-11.38-26.21-23.04-52.33-35.67-77.96
		c-19.9-40.39-36.65-82.31-58.92-121.55c-20.09-35.4-41.69-69.74-65.04-103.16C1506.14,251.8,1460.08,199.38,1409.51,149.5z"
      />
      <path
        d="M706.74,299.92c7.92-7.37,18-5.22,27.26-5.66c16.46-0.79,30.82-7.49,45.49-15.57c8.08,18.91,10.73,37.25,7.65,56.56
		c-4.1,25.72-14.83,49.17-24.91,72.76c-12.82,30-22.28,61.02-29.9,92.63c-3.24,13.46,1.61,17.39,15.65,19.61
		c40.17,6.35,81.29,6.09,120.72,17.61c5.42,1.58,6.89,4.33,6.34,9.05c-2.17,18.9,8.25,34.03,14.53,50.51
		c6.54,17.15,16.01,34.06,8.88,53.74c-9.12,25.15-16.46,27.69-36.17,11.86c-4.8,12.62,3.38,29.17,16.51,38.68
		c30.66,22.21,53.51,3.14,71.39-20.59c4.16-5.52,6.03-13.42,17.68-17.51c-7.7,16.95-12.71,31.59-20.57,44.48
		c-12.26,20.11-26.33,39.04-40.96,57.67c-9.94,12.65-20,12.26-31.69,8.41c-37.92-12.51-76.72-19.5-116.52-16.47
		c-53.01,4.04-100.74,20.69-136.13,63.46c-2.38,2.87-1.2,7.94-7.55,9.28c-0.71-7.04-2-13.93-2.01-20.82
		c-0.12-64.74-0.84-129.36,6.48-194.02c5.88-52,14.5-103.04,27.85-153.61c7.89-29.88,17.64-58.91,27.67-88.06
		c8.73-25.35,20.88-48.73,32.53-73.38C706.9,299.69,706.74,299.92,706.74,299.92z"
      />
      <path
        d="M707.02,299.61c-8.86-0.95-9.59,7.93-12.12,12.36c-20.77,36.23-36.3,74.63-48.64,114.68
		c-8.37,27.19-15.25,54.61-21.77,82.19c-7.61,32.27-11.19,65.16-15.34,98c-7.89,62.33-9.13,124.86-7.49,187.5
		c0.59,22.43,3.14,44.81,4.97,69.52c-7.77-17.78-13.57-33.94-21.62-48.89c-17.64-32.74-41.88-60.42-77.11-73.62
		c-32.52-12.19-67.02-16.52-100.72-1.61c-15.64,6.92-26.14,19.52-33.47,34.55c-2.59,5.3-5.4,10.19-9.34,15.66
		c-1.33-10.89,0.08-20.43,2.41-29.92c8.54-34.89,14.13-70.53,25.53-104.68c6.93-20.78,14.63-41.37,23.34-61.46
		c9.21-21.22,19.24-42.18,30.37-62.45c14.71-26.77,31.68-52.16,50.22-76.54c18.72-24.61,39.01-47.86,61.4-69.02
		c30.65-28.97,62.11-57.15,99.85-77.15c13.73-7.28,26.28-17.13,43.01-16.57c4.16,0.14,8.05-0.04,5.99,6.89
		C706.74,299.92,706.9,299.69,707.02,299.61z"
      />
      <path
        d="M1012.88,600.58c-12.56,4.67-20.83-1.98-26.29-11.69c-6.92-12.32-3.51-30.86,5.39-39.09
		c13.19-12.18,29.78-15.58,46.6-17.09c18.2-1.63,36.44-2.82,54.65-4.55c10.43-0.99,18.57-8.73,29.44-7.73
		c1.35,7.43,1.71,14.57-1.27,21.43c-7.61,17.53-8.89,37.75-24.01,52.12c-9.63,9.15-22.25,12.53-33.49,16.29
		c-25.95,8.67-52.6,16.68-76.74,28.58c-13.63,6.72-29.31,14.94-40.14,27.93c-8.77,10.51-14.77,23.43-27.75,30.3
		c-16.09,8.53-35.64,3.55-43.32-11.66c28.04-9.31,41.51-35.7,33.66-65.1c-4.29-16.06-11.72-30.79-18.71-45.67
		c-11.41-24.3-5.62-72.12,20.39-87.29c10.01-5.84,21.4-10.31,32.72-12.55c8.19-1.62,15.36-3.44,22.81-11.76
		c-0.83,12.9,4.19,21.68,11.65,27.26c5.6,4.19,13.03,7.96,21.36,6.87c18.13-2.39,30.24-15.5,32.15-29.5
		c1.41-10.39-2.17-17.44-13.85-18.41c-4.46,5.73-3.11,11.5,0.39,16.59c5.39,7.83,1.77,13.03-4.63,16.93
		c-8.18,4.99-17,8.49-26.48,3.08c-9.07-5.17-11.34-13.23-12.1-23.47c-1.35-18.12,3.93-34.23,11.21-50.08
		c7.18-15.64,40.03-31.58,57.44-28.85c20.99,3.3,41.95,6.84,63.43,5.71c15.19-0.8,26.3,8.93,33.63,27.52
		c1.55,3.92,2.9,7.99,5.01,11.6c3.38,5.78,2.82,10.4-0.48,16.48c-9.01,16.62-18.19,33.49-17.07,53.43
		c0.34,6.09-3.25,8.06-7.98,11.16c-19.65,12.88-42.07,12.12-63.82,14.15c-11.84,1.1-23.72,1.85-35.58,3.03
		c-16.2,1.61-28.56,8.79-38.52,20.99c-16.23,19.86-10.02,48.2,13.06,58.67c6.19,2.81,13.14,4.85,17.97-4.4
		c12.91-3.69,14.06-13.46,13.19-23.07c-0.59-6.54-5.08-11.04-15.75-8.46c12.32,4.38,11.59,12.19,8.98,19.86
		C1018.72,593.99,1015.33,597.13,1012.88,600.58z"
      />
      <path
        d="M1288.42,566.82c-6.78,12.7-12.32,25.04-19.53,36.33c-6.58,10.3-23.8,12-37.25,4.85c-20.4-10.84-41.77-19.91-60.13-34.41
		c-11.12-8.79-21.5-17.16-22.08-33.4c-0.34-9.57-5-19.07-8.79-27.65c-7.52-17.04-1.03-32.05,3.89-46.83
		c3.37-10.11,5.53-21.43,13.33-29.92c4.45-4.84,1.88-10.31-1.24-14.01c-6.36-7.55-4.09-12.79,1.31-19.86
		c13.15-17.2,27.6-32.65,47.38-43.81c10.77,14.29,6.16,33.3,16.22,48.45c10.88-12.57,18.35-25.78,21.59-41.75
		c6.23-0.59,11.54-4.26,17.41-6.54c13.97-5.43,27.4,0.85,34.96,14.02c7.53,13.12,10.57,26.67,9.67,41.51
		c-0.7,11.47,0.1,22.88,2.92,34.99c-15.01-3.36-25.1,4.25-37.28,13.3c8.54,0.92,13.01-3.54,18.33-4.89
		c6.11-1.55,13.35-3.94,18.13,1.23c4.85,5.24,4.12,12.39,1.55,19.25c-5.46,14.59-10.19,28.42-4.87,45.28
		C1309.01,539.01,1296.07,552.28,1288.42,566.82z M1201.41,480.65c-4.38,2.96-10.81,2.62-14.03,8.92
		c10.84,11.2,24.51,14.37,39.07,13.1c9.7-0.85,10.77-8.95,8.44-16.41C1231.42,475.14,1220.63,467.22,1201.41,480.65z
		 M1258.41,554.66c-10.78-0.94-21.75-2.61-30.18,6.99c10.94,12.46,21.67,15.95,33.69,12.16c5.47-1.72,10.04-4.73,10.09-10.06
		C1272.06,557.29,1266.93,554.53,1258.41,554.66z M1296.07,498.1c2.6-4.89,3.86-9.88-2.04-13.08c-5.47-2.96-10.97-5.75-17.74-1.93
		c-6.79,3.84-8.48,9.56-6.66,15.95c2.02,7.12,8.79,7.27,14.62,6.18C1288.15,504.5,1291.69,501.78,1296.07,498.1z M1216.51,445.82
		c8.97,4.1,13.69,14.83,27.56,17.46c-13.59-15.61-24.33-30.02-44.2-29.75c-4.21,0.06-11.71,1.73-11.71,11.45
		C1197.86,439.42,1206.56,438.43,1216.51,445.82z M1249.64,537.54c5.92,1.58,12,1.38,17.73-0.11c10.28-2.68,11.83-10.12,3.95-17.51
		c-4.5,14.77-19.17,1.81-26.89,10.42C1245.44,532.13,1246.62,534.27,1249.64,537.54z"
      />
      <path
        d="M1567.74,357.39c22.82,38.94,46.15,76.6,63.3,117.66c-19.18-14.77-29.92-14.79-50.19,0.55
		c-19.03,14.4-35.4,31.48-45.49,53.27c-4.91,10.6-7.67,22.42-9.81,33.99c-1.2,6.5-2.71,12.21-8.91,19.96
		c-1.04-16.18-0.74-29.92-3.13-43.39c-5.41-30.48-8.38-61.29-13.93-91.83c-5.9-32.47-13.6-64.51-20.35-96.73
		c-5.08-24.29-14.11-47.79-21.92-71.47c-12.74-38.61-30.2-75.09-51.19-109.88c-5.39-8.93-11.15-17.63-16.98-26.82
		c3.4-1.52,5.55,0.82,7.23,2.62c21.74,23.32,43.96,46.25,64.86,70.31c25.74,29.63,49.61,60.78,72.47,92.77
		C1545.1,324.33,1554.74,341.47,1567.74,357.39z"
      />
      <path
        d="M1269.47,862.47c17.34-0.14,33.93,5.59,51.08,7.73c20.54,2.57,40.61,8.68,61.04,12.38c27.37,4.96,53.52-2.35,75.16-17.37
		c28.79-19.97,51.71-12.03,76.94,5.43c23.16,16.03,47.7,30.35,76.72,33.93c3.65,0.45,7.49,1.73,10.27,5.81
		c-1.68,7.15-5.85,10.73-13.95,10.82c-24.2,0.27-48.26,1.92-71.65-7.8c-13.3-5.52-28-2.88-42.52-1.49
		c14.85,15.37,39.74,15.52,53.53,36.39c-30.63,3.05-56.8-0.02-82.27-11.46c-18.46-8.29-37.51-15.03-58.86-10.51
		c-11.98,2.53-24.55,2.5-36.88,3.06c-41.21,1.87-81.9,7.28-122.03,16.9c-4.09,0.98-8.38,1.11-14.8,1.92
		c7.36-31.57,21.47-58.6,38.72-85.11C1269.75,862.13,1269.59,862.38,1269.47,862.47z"
      />
      <path
        d="M1134.76,629.23c-11.58-10.33-25.17-16.23-38.03-25.35c24-17.98,30.97-43.91,35.93-71.18c8.01,6.18,6.92,16.31,11.77,23.69
		c10.55,16.08,25.75,26.29,42.05,35.11c13.3,7.2,25.28,16.72,40.08,21.44c6.73,2.15,9.5,22.99,4.23,28.37
		c-9.76,9.96-20.06,19.38-30.48,29.35C1177.34,658.54,1156.74,643.63,1134.76,629.23z"
      />
      <path
        d="M630.23,159.49c12.76-0.66,21.85,8.16,31.24,14.34c28.33,18.62,56.92,37.14,81.6,60.8c10.52,10.08,20.75,20.32,29.82,33.74
		c-18.79,9.47-38.07,13.89-58.56,13.61c-1.27-0.02-2.58-0.58-3.8-0.38c-14.89,2.38-21.24-4.1-24.95-18.77
		c-7.95-31.47-24.73-58.68-45.68-83.46C635.45,174.08,633.35,166.79,630.23,159.49z"
      />
      <path
        d="M1257.68,347.47c-5.56,1.4-8.05,6.66-15.12,6.33c0.98-20.05-3.52-37.61-29.53-39.86c10.79-18.57,12.64-39.07,26.06-54.86
		c5.85-6.88,12.16-6.78,17.78-3.95c38.38,19.29,64.35,68.52,53.95,115.18C1292.14,341.76,1278.56,342.94,1257.68,347.47z"
      />
      <path
        d="M1119.35,381.76c-8.07-1.35-15.53,0.02-22.15-3.64c-2.9-17.96,19.32-59.33,43.77-65.46c11.05-2.77,49.22,18.31,59.25,34.62
		c-23.43,11.49-41.18,29.01-53.55,52.34C1137.56,393.53,1128.96,387.78,1119.35,381.76z"
      />
      <path
        d="M1089.29,352.55c-4.85,8.17-4.47,16.35-4.26,25.52c-19.84,0.08-38.51-3.36-57.22-6.18c-5.38-0.81-6.52-4.63-5.96-9.43
		c1.5-12.79,6.16-24.4,13.2-35.06c4.43-6.71,10.79-8.67,18.9-10.33c13.61-2.78,26.96-7.2,40.87-8.81
		c7.14-0.83,14.37-0.96,25.24-1.63C1103.89,320.07,1095.43,335.01,1089.29,352.55z"
      />
      <path
        d="M1123.62,1002.69c-18.64-16.7-29.84-38.22-45.3-56.39c-2.65-3.11-4.92-6.53-8.28-9.51c36.07,15.36,71.14,32.5,111.07,43.89
		C1162.19,992.92,1146.92,1006.69,1123.62,1002.69z"
      />
      <path
        d="M1101.34,670.1c-9.22-17.76-5.15-36.88-8.62-55.36c10.72,14.88,21.45,29.74,32.15,44.63c9.6,13.36,18.46,27.34,29.02,39.89
		c6.2,7.37,7.35,11.55-1.49,16.82c-4.32,2.57-7.74,6.63-12.4,10.74C1124.31,710.12,1110.13,692.62,1101.34,670.1z"
      />
      <path
        d="M1362.76,41.92c19.74-5.93,38.54-11.45,57.35-16.97c0.55,1.08,1.1,2.16,1.65,3.25c-22.78,12.26-39.11,31.51-56.73,49.44
		c-3.33,3.39-9.05,6.4-14.92,6.22c-16.59-0.52-32.36-4.89-50.58-9.39C1320.89,61,1340.04,49.16,1362.76,41.92z"
      />
      <path
        d="M1167.5,304.13c-4.14-13.73-2.99-26.23,1.62-40.46c19.91,4.58,37.99-5.02,57.82-9.87c-11.13,18.07-16.57,37.27-18.39,59.21
		C1194.34,309.55,1180.26,312.66,1167.5,304.13z"
      />
      <path
        d="M1017.15,329.62c-5.93,9.95-2.6,20.68-5.14,30.62c-28.08-15.86-42.26-65.46-26.81-93.9c8.43,8.78,8.22,21.82,16.52,30.41
		c4.36,4.51,7.85,10.23,13.28,13.4C1024.3,315.58,1023.19,321.65,1017.15,329.62z"
      />
      <path
        d="M1138.05,661.78c-7.29-10.61-13.98-20.5-20.67-30.39c0.47-0.56,0.95-1.12,1.42-1.69c23.66,16.21,47.33,32.42,71.8,49.18
		c-6.77,8.57-15.1,15.36-25.02,23.4C1156.32,688.63,1147.49,675.57,1138.05,661.78z"
      />
      <path
        d="M1157.27,263.11c-5.3,11.86-2.96,23.33-2.34,35.16c-7.51,1.78-11.04-3.79-14.96-7.64c-16.49-16.19-17.13-36.84-15.46-57.94
		c0.37-4.74,5.39-5.89,7.4-3.53C1140.96,239.79,1149.15,251.13,1157.27,263.11z"
      />
      <path
        d="M1220.73,696.92c-0.07-0.05-0.14-0.11-0.22-0.16c-3.23-2.09-6.38-4.28-9.39-6.67c-2.99-1.29-5.84-2.68-7.9-4.74
		c-1.16-1.15-2.18-1.69-3.13-1.78c-0.13,0.22-0.31,0.43-0.55,0.6c-2.6,1.88-4.8,4.22-7.02,6.52c-1.04,1.4-2.15,2.69-3.42,3.57
		c-1.34,1.42-2.67,2.85-3.95,4.32c-2.32,2.66-4.53,5.4-6.9,8.01c-1.36,1.51-2.75,3.1-4.25,4.54c0.01,0.06,0.02,0.12,0.04,0.17
		c0.43,0.63,0.85,1.27,1.26,1.92c1.49,2.36,2.85,4.8,4.36,7.14c0.7,1.08,1.47,2.15,2.07,3.29c0.84,0.43,1.72,0.75,2.65,0.96
		c2.92-0.74,5.86-1.38,8.81-2c1.97-0.41,3.92-0.86,5.89-1.28c1.93-0.41,3.89-0.98,5.85-1.22c2.04-0.26,4.09-0.46,6.13-0.7
		c1.91-0.22,3.79-0.58,5.7-0.81c1.49-0.19,2.97-0.12,4.45-0.11c3.12-1.5,3.83-4.79,4.63-8.73c0.83-4.11,0.53-7.22-0.51-9.65
		C1223.8,699.06,1222.25,698,1220.73,696.92z"
      />
      <path
        d="M1265.66,699.44c-0.83,4.3-1.46,7.58-2.26,11.75c-7.56-3.33-12.57-9.9-20.36-12.11c-3.03-0.86-6.87-4.64-6.2-10
		c1.73-13.82,3.07-27.7,4.91-44.85C1256.94,661.5,1269.31,676.32,1265.66,699.44z"
      />
      <path
        d="M1183.9,741.45c8.82,14.45,18.61,27.11,27.64,40.83c-21.77-9.98-40.1-24.58-58.56-43.09
		C1164.57,738.74,1174.06,732.52,1183.9,741.45z"
      />
      <path
        d="M1217.76,767.88c-0.87,2.85-1.58,4.82-2.55,7.53c-9.32-13.78-18.25-26.96-28.04-41.42c12.6-2.12,23.37-5.32,35.43-3.22
		C1224.73,743.6,1219.47,755.09,1217.76,767.88z"
      />
      <path
        d="M1228.83,766c3.23-13,2.82-25.36,3.09-38.06c9.09-2.92,17.79-5.71,28.03-9c-1.63,25.01-18.24,39.18-31.37,55.82
		C1225.99,771.42,1228.35,769.29,1228.83,766z"
      />
      <path
        d="M1230.81,339.95c0.01,14.47-0.07,28.07-0.6,41.65c-0.1,2.66-1.93,5.26-3.96,7.96c-1.32-10.97-10.62-18.52-11.86-28.67
		C1213.02,349.74,1222.19,344.19,1230.81,339.95z"
      />
      <path
        d="M1218.62,683.41c-5.25-0.72-6.44-5.12-11.22-7.26c8.32-9.17,15.89-17.52,23.47-25.87c-2.48,12.6,3.27,25.4-1.61,36.88
		C1224.18,688.89,1222.8,684.09,1218.62,683.41z"
      />
      <path
        d="M1247.44,712.14c-1.15,0.04-2.11-0.27-2.98-0.73c-0.64,0.24-1.41,0.19-2.08-0.34c-1.53-1.22-3.52-2.13-5.21-3.15
		c-0.37-0.22-0.73-0.46-1.1-0.69c-0.19,0.05-0.38,0.09-0.58,0.17c-1.44,3.59-2.11,7.18,0.29,10.93c5.16,1.16,10.3-0.17,14.59-2.39
		C1253.36,714.4,1250.13,712.96,1247.44,712.14z"
      />
      <path
        d="M1223.95,963.24c3.51-5.55,5.74-11.79,8.42-17.79c0.26-0.59,0.56-1.21,1.12-1.54c0.46-0.27,1.03-0.29,1.56-0.3
		c1.56-0.03,3.12-0.07,4.68-0.1c0.46-0.01,1.04,0.07,1.16,0.51c0.07,0.25-0.05,0.5-0.16,0.73c-1.63,3.35-2.7,6.97-3.14,10.67
		c-0.14,1.19-0.19,2.49,0.47,3.48c0.55,0.83,1.56,1.4,1.76,2.37"
      />
      <path d="M1164.87,714.51c-4.71,4.22-9.41,8.44-14.12,12.66C1156.96,725.8,1163.27,724.93,1164.87,714.51z" />
      <path
        d="M1151.76,726.64c4.35-0.23,8.69-0.64,13-1.24c0.68-0.09,1.41-0.21,1.92-0.67c1.04-0.92,0.68-2.6,0.24-3.92
		c-0.66-1.97-1.33-3.95-1.99-5.92"
      />
    </>
  );
}
