export default function Flameskull(): JSX.Element {
  return (
    <>
      <path
        d="M1180.19,1840.94c-38.55,11.2-76.3,21.45-115.63,23.6c-14.85,0.81-29.49,3.26-44.46,3.55
			c-46.51,0.9-92.35-2.09-137.98-12.58c-75.16-17.29-141.22-51.96-200.98-99.74c-57.19-45.72-105.55-99.37-146.06-160.43
			c-34.08-51.36-66.03-103.98-87.76-161.69c-12.8-33.99-21.61-69.6-28.64-105.49c-4.65-23.73-10.5-47.24-16.41-70.69
			c-7.28-28.88-15.31-58.67-14.84-87.62c0.74-45.5,0.68-91.31,8.68-136.87c7.33-41.78,14.75-83.55,23.43-125.05
			c5.03-24.01,12.83-47.48,20.11-70.97c13.72-44.26,31.17-87.12,51.97-128.48c22.15-44.04,47.86-86.07,76.96-125.87
			c36.26-49.59,76.72-95.45,125.11-133.65c17.1-13.5,37.44-21.63,57.73-29.34c3.09-1.17,6.74-1.1,10.63,1.39
			c-2.21,15.47-12.6,27.94-17.21,42.76c-15.35,49.44-28.46,98.49-15.83,151.27c8.59,35.92,26.7,65.73,50.33,92.72
			c11.42,13.04,28.7,12.98,44.19,12.12c37.7-2.1,63.99-23.78,85.94-52.76c13.25-17.49,14.03-38.85,20.36-58.37
			c8.27-25.53,9.91-52.32,9.18-79.42c-0.89-32.76-5.03-65.29-19.6-94.09c-16.81-33.24-32.33-67.17-49.15-100.41
			c-27.33-54.03-75.17-80.7-125.27-106.16c-19.53-9.93-39.03-19.89-60.77-30.97c17.29-8.3,32.31-6,47.72-2.98
			c22.47,4.42,45.24,7.34,67.65,12.04c16.15,3.39,32.07,8.12,47.81,13.16c33.51,10.73,67.16,21.17,100.14,33.38
			c33.79,12.51,67.06,26.51,100.17,40.77c30.72,13.24,61.76,26.13,91.11,42.03c32.32,17.5,63.88,36.54,93.77,58.3
			c51.76,37.68,97.71,81.24,134.85,133.34c22.58,31.67,41.8,65.59,57.46,101.39c6.9,15.78,14.86,31.1,23.23,48.49
			c15.02-18.74,27.95-37.06,44.18-52.6c28.9-27.67,61.11-49.57,99.75-61.37c9.29-2.84,17.98-7.42,27.26-10.63
			c18.05-6.27,36.22-3.33,54.37-3.09c4.45,0.06,9.11,2.24,13.23,4.31c7.02,3.54,7.39,7.95,0.15,11.88
			c-45.28,24.62-76.61,61.58-97.99,108.12c-11.8,25.7-14.9,51.68-15.49,80c-0.96,46.2,9.03,90.24,16.12,135.07
			c7.7,48.69,19.22,96.77,25.28,145.59c5.02,40.41,13.27,80.48,12.13,121.85c-0.94,33.99,0.45,68.12-0.32,102.23
			c-0.55,24.42-3.2,48.4-7.41,72.35c-3.9,22.18-5.86,44.83-11.56,66.52c-5.14,19.57-13.49,38.43-21.69,57.04
			c-10.77,24.43-23.05,48.2-34.12,72.5c-4.52,9.91-14.03,15.68-18.89,25.1c-13.44,26.04-28.46,51.12-45.2,75.18
			c-25.94,37.28-56.83,69.91-92.08,98.49c-33.32,27.02-68.52,51.28-106.59,71.05c-23.99,12.46-48.79,23.23-74.27,32.49
			C1203.79,1833.76,1192.22,1836.34,1180.19,1840.94z M951.85,789.47c-12.41,2.81-25.45,4.06-37.07,8.79
			c-19.99,8.13-40.61,14.46-60.39,23.44c-32.47,14.75-63.35,32.65-86.72,58.56c-34.09,37.8-62.13,80.06-70.58,132.74
			c-5.18,32.29-1.01,62.58,10.55,92.37c7.5,19.31,18.22,37.12,25.71,56.59c8.17,21.24,9.75,42.27,5.05,63.76
			c-5.12,23.39-8.68,46.67-8.1,70.75c0.88,36.79,20.03,62.42,49.33,80.92c7.63,4.82,17.51,11.21,28.67,7.58
			c23.33-7.58,46.68-15,70.96-19.26c28.78-5.05,57.32-8.28,85.72,2.03c4.98,1.81,8.44,4.27,11.82,8.69
			c13.19,17.27,11.75,38.61,17.15,57.98c5.5,19.74,12.85,26.37,33.86,26.43c45.08,0.13,90.12,0.98,134.98-5.92
			c30.2-4.65,60.91-4.9,91.44-6.22c17.14-0.74,22.3-6.15,22.36-23.63c0.08-23.46,0-46.92-0.04-70.38
			c-0.03-15.58,5.1-28.84,15.76-40.55c11.66-12.81,25.31-23.75,35.21-38.24c17.73-25.93,31.74-52.72,31.38-85.67
			c-0.22-19.93-6.79-37.16-14.43-54.79c-5.35-12.36-10.91-25.09-11.45-38.84c-1.07-26.93-7.06-53.09-13.04-79.08
			c-10.05-43.69-31.39-81.75-59.95-116.46c-22.92-27.85-49.69-50.83-79.72-69.94c-33.85-21.54-70.02-37.21-110.88-41.46
			C1030.83,785.66,992.51,779.74,951.85,789.47z M1128.29,1544.39c-38.67-2.84-76.72-9.67-114.19-19.29
			c-10.31-2.65-21.65-6.12-28.41-15.88c-10.88-15.69-15.51-34.21-18.36-52.33c-4.41-28.07-24.35-47.11-53.47-46.55
			c-3.04,4.52-0.97,8.97,0.37,13.38c7.32,24.07,10.68,48.91,12.86,73.79c3.27,37.18,5.84,74.41,5.97,111.83
			c0.1,26.59,8.75,36.64,32.43,42.53c40.35,10.04,80.87,19.83,122.05,25.24c25.16,3.31,50.82,5.77,76.44,3.87
			c11.87-0.88,21.24-4.55,27.74-16.55c18.98-35.04,36.76-70.67,54.6-106.28c5.8-11.58,1.87-17.54-10.97-15.95
			C1200.51,1546.53,1165.58,1543.75,1128.29,1544.39z"
      />
      <path
        d="M979.45,1036.51c34.29,5.37,50.52,28.38,60.57,57.41c6.35,18.34,12.2,36.89,17.48,55.57
			c5.28,18.68,3.86,38.34,10.21,56.97c-17.04,4.06-26.86-6.56-37.92-11.38c-45.06-19.67-89.31-41.21-134.09-61.56
			c-7.55-3.43-9.27-8.44-7.8-15.18c7.55-34.63,25.83-61.85,57.87-77.9C955.39,1035.62,967.16,1035.33,979.45,1036.51z"
      />
      <path
        d="M1259.51,1167.37c-21.33,18.93-43.6,34.52-71.43,43.63c-4.02-11.22-1.12-21.46,0.94-31.44
			c8.91-43.1,19.63-85.71,34.38-127.26c4.79-13.51,17.94-19.36,31.79-11.26c17.88,10.46,33.61,23.45,33.49,47.17
			c-0.01,1.27-0.06,2.68,0.43,3.78C1304.78,1126.71,1281.64,1146.4,1259.51,1167.37z"
      />
      <path
        d="M1102.88,1272.42c8.36-16.34,16.32-31.83,24.62-47.12c4.47-8.23,10.03-9.84,16.69-1.79
			c19.17,23.16,38.6,46.05,54.52,71.73c6.66,10.75,4.75,19.48-3.38,27.44c-6.08,5.95-20.9,4.46-28.36-2.07
			c-1.92-1.68-4.05-3.32-5.37-5.43c-5.66-8.99-10.75-10-18.48-1.34c-8.55,9.59-20.34,13.85-33.38,12.91
			c-17.05-1.22-24.07-13.2-17.32-28.76C1095.95,1289.84,1099.22,1281.57,1102.88,1272.42z"
      />
    </>
  );
}
