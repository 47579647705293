export default function Hydra(): JSX.Element {
  return (
    <>
      <path
        d="M1739.68,1977.52c-11.75,22.57-22.56,45.54-30.62,70.1c-50.64,0.38-101.33,0.38-152.49,0.38
		 c-2.79-18.31-4.46-36.74-7.59-54.91c-4.09-23.78-9.79-47.28-18.89-69.72c-3.17-7.8-7.03-15.55-11.91-22.37
		 c-10.85-15.17-25.12-16-36.93-1.62c-7.43,9.04-12.85,19.72-19.11,29.71c-1.12,1.79-1.96,3.75-3.11,5.97
		 c-7.51-11.45-14.74-22.37-21.81-33.4c-0.82-1.28-1.01-3.11-1.08-4.7c-0.75-16.04-0.83-32.14-2.24-48.12
		 c-2.76-31.11-5.75-62.24-13.81-92.58c-2.46-9.27-4.96-18.53-11.82-25.87c42.13-6.32,80.52-22.53,117.6-41.9
		 c49.06-25.62,79.89-66.06,92.1-119.95c6.72-29.67,11.7-59.73,17.17-89.67c5.06-27.67,10.67-55.19,19.79-83.33
		 c1.59,6.31,3,11.45,4.18,16.63c6.01,26.48,12.4,52.89,17.78,79.5c3.63,17.95,5.66,36.3,4.09,54.63
		 c-1.63,18.97-8.19,36.6-17.02,53.47c-11.38,21.74-25.29,41.81-39.7,61.57c-16.66,22.85-33.21,45.8-50.41,68.24
		 c-8.93,11.65-11.14,23.77-6.21,37.25c6.13,16.72,12.06,33.54,19.03,49.91c13.2,31.03,27.18,61.74,40.65,92.66
		 c8.48,19.47,16.53,39.12,24.92,58.63c5.8,13.48,16.52,18.7,30.86,19.1c19.22,0.53,37.26-4.36,55.75-10.03
		 C1739.38,1977.25,1739.68,1977.52,1739.68,1977.52z"
      />
      <path
        d="M731.51,2048c-0.14-10.27,0.77-20.55,0.42-30.79c-1.25-36.58-6.15-72.58-20.26-106.68c-8.83-21.35-20.7-41.11-35.58-58.79
		 c-13.17-15.65-27.34-30.47-40.58-46.07c-2.49-2.93-3.84-7.85-3.7-11.78c0.38-11.16,4.71-15.83,17.03-17.96
		 c31.87-5.52,60.28-18.35,85.3-38.81c15.54-12.71,30.14-26.48,41.98-42.64c14.45-19.71,28.45-39.84,41.19-60.67
		 c6.26-10.23,12.74-13.82,24.81-12.92c34.75,2.6,69.37,5.42,103.02,15.18c1.89,0.55,3.77,1.15,6.39,1.95
		 c-5.67,6.37-10.66,12.66-16.35,18.23c-14.58,14.29-28.97,28.84-44.37,42.21c-18.33,15.91-37.57,30.77-56.53,45.94
		 c-13.81,11.06-27.88,21.79-41.67,32.88c-3.38,2.71-4.47,6.36-2.89,10.99c5.48,16.03,14.11,30.47,23.72,44.09
		 c17.05,24.18,34.88,47.82,53.07,71.15c6.23,8,14.5,14.41,21.68,21.4c-29.65,36.03-48.41,78.87-65.45,122.78
		 C792.69,2048,762.34,2048,731.51,2048z"
      />
      <path
        d="M1322.56,2048c-2.95-24.86,0.89-48.93,8.61-72.51c7.33-22.39,14.37-44.89,22.17-67.11
		 c2.32-6.62,6.37-12.78,10.38-18.64c4.13-6.03,7.92-6.32,12.22-0.33c5.38,7.5,10.47,15.49,13.99,23.98
		 c13.87,33.37,21.52,68.41,26.17,104.15c1.29,9.9,2.41,19.83,3.8,30.1C1387.74,2048,1355.38,2048,1322.56,2048z"
      />
      <path
        d="M1433.56,2048c0.25-5.04,0.36-10.26,1.77-15.1c10.45-35.86,24.11-70.47,42.12-103.23
		 c4.21-7.66,9.49-14.86,15.09-21.6c4.68-5.62,8.79-5.52,12.26,0.81c5.8,10.57,11.29,21.52,15.12,32.92
		 c11.48,34.2,18.02,69.54,22.13,105.77C1506.08,2048,1470.06,2048,1433.56,2048z"
      />
      <path
        d="M1724.59,2048c2.85-9.17,5.93-18.44,9.54-27.5c10.42-26.16,23.33-51.04,38.4-74.83
		 c3.72-5.87,9.02-8.71,15.18-10.39c1.78-0.49,4.23,0.36,6.04,1.26c18.96,9.44,31.87,24.64,41.14,43.34
		 c10.67,21.52,18.62,44.01,24.29,67.71C1814.44,2048,1769.75,2048,1724.59,2048z"
      />
      <path
        d="M490.49,2048c-2.87-8.35-0.18-16.16,2.8-23.64c6.02-15.09,12.21-30.15,19.22-44.8
		 c3.11-6.48,7.94-12.33,12.81-17.73c10.38-11.51,20.74-13.96,35.12-8.1c24.6,10.02,42.55,27.5,54.68,50.96
		 c6.92,13.38,11.11,27.74,13.99,42.89C583.01,2048,536.99,2048,490.49,2048z"
      />
      <path
        d="M1219.55,2048c-1.14-8.18-1.89-16.36-2.45-24.55c-0.65-9.55-1.14-19.11-1.64-28.67
		 c-0.05-0.94,0.22-1.96,0.55-2.86c7.05-19.5,13.74-39.14,21.4-58.39c2.64-6.65,7.22-12.72,11.76-18.38
		 c4.69-5.84,9.66-6.27,15.03-1.02c5.78,5.64,11.34,11.85,15.52,18.7c16.83,27.61,25.53,58,28.21,90.05
		 c0.67,8.02-0.96,16.23-1.69,24.73C1277.4,2048,1248.71,2048,1219.55,2048z"
      />
      <path
        d="M831.52,2048c17.17-31.76,34.61-63.64,52.62-95.2c3.47-6.08,8.76-11.29,13.87-16.22
		 c5.92-5.72,15.66-5.18,20.63,1.47c5.33,7.13,10.4,14.74,14.01,22.84c12.43,27.94,22.86,56.65,30.46,86.71
		 C919.37,2048,875.68,2048,831.52,2048z"
      />
      <path
        d="M344.48,2048c2.42-10.1,4.39-20.58,8.37-30.23c8.83-21.4,23.79-38.47,40.94-53.69c1.97-1.75,4.3-3.16,6.6-4.47
		 c10.93-6.24,21.82-5.88,30.85,2.79c7,6.72,13.36,14.48,18.46,22.73c11.83,19.13,18.13,40.59,23.58,62.53
		 C430.33,2048,387.64,2048,344.48,2048z"
      />
      <path
        d="M645.51,2048c-2.72-9.38-5.5-18.67-7.02-28.16c-1.04-6.5-1.04-13.36-0.24-19.91
		 c1.68-13.83,3.56-27.7,6.54-41.3c2.34-10.7,14-15.28,23.96-10.42c11.25,5.49,18.41,15.05,24.57,25.46
		 c12.9,21.81,21.13,45.49,27.59,69.86c0.33,1.26,0.55,2.54,0.49,4.14C696.02,2048,671,2048,645.51,2048z"
      />
      <path
        d="M979.53,2048c-0.94-3.02-1-6.16-1.93-9.02c-6.15-18.94-12.39-37.86-18.85-56.7c-0.97-2.84-0.92-4.83,0.85-7.25
		 c7.16-9.78,13.67-20.07,21.31-29.46c9.07-11.14,14.62-10.74,22.75,1.29c12.59,18.64,20.4,39.49,26.27,61.03
		 c3.53,12.96,6.09,26.19,9.11,39.7C1019.38,2048,999.69,2048,979.53,2048z"
      />
      <path
        d="M1053.54,2048c-1.78-8.1-2.79-16.26-4.46-24.29c-5.73-27.58-14.13-51.08-26.75-75.86
		 c11.63-9.42,26.96-7.15,37.59,2.43c15.29,13.78,24.69,31.58,33.02,49.88c5.82,12.78,10.29,26.19,15.31,39.34
		 c0.98,2.57,1.67,5.24,2.16,8.19C1091.38,2048,1072.69,2048,1053.54,2048z"
      />
      <path
        d="M673.8,1383.74c-14.41,7.33-25.73,18.31-35.47,30.91c-22.49,29.12-38.17,61.96-51.25,96.1
		 c-14.06,36.72-24.92,74.38-32.34,113.02c-0.21,1.1-0.67,2.15-1.08,3.46c-1.41-0.66-2.72-1-3.7-1.77
		 c-13.77-10.76-30.45-12.28-46.71-13.17c-92.82-5.08-175.99,20.68-248.3,79.69c-16.49,13.46-30.83,29.03-41.3,47.74
		 c-2.98,5.32-5.36,11.1-7.1,16.93c-0.93,3.12-0.58,7.02,0.36,10.22c1.45,4.98,6.51,7.23,11.71,5.77c5.89-1.66,9.94-5.4,12.99-10.85
		 c9.13-16.25,18.1-32.64,28.16-48.31c10.1-15.74,23.97-28.05,39.59-38.35c29.97-19.76,62.41-34.03,96.96-43.61
		 c1.17-0.33,2.41-0.43,2.51-0.44c-8.11,10.62-16.82,21.47-24.89,32.78c-8.28,11.61-12.56,25.1-16.98,38.55
		 c-7.99,24.31-16.09,48.6-24.97,72.59c-9.48,25.62-22.59,49.33-39.9,70.67c-15.51,19.12-33.03,36.01-54.12,48.71
		 c-17.22,10.37-35.72,17.6-56.07,19.8c-23.72,2.57-46.45-0.22-67.72-11.18c-30.86-15.91-49.75-41.26-56.02-75.34
		 c-2.98-16.21-1.81-32.64,0.59-49.03c4.58-31.32,13.88-61.28,26.58-90.04c11.38-25.78,27.41-49.04,43.2-72.34
		 c28.42-41.91,63.92-77.04,102.58-109.38c19.15-16.03,36.99-33.62,55.8-50.06c11.37-9.93,23.36-19.18,35.29-28.44
		 c14.99-11.64,29.87-23.46,45.4-34.34c19.75-13.82,39.64-27.55,60.31-39.92c32.95-19.72,68.15-34.8,104.79-46.35
		 c5.22-1.65,10.92-2.96,16.3-2.69c28.27,1.43,56.53,3.16,84.75,5.29c53.94,4.07,105.9-3.22,155.47-25.63
		 c50.22-22.7,97.96-49.61,142.02-82.85c2.13-1.6,4.11-3.39,6.15-5.12c1.85-1.56,3.67-3.15,5.48-4.71
		 c1.28,12.18,2.58,24.03,3.75,35.9c2.21,22.27,10.45,42.54,20.85,62.02c32.53,60.91,80.3,104.66,145.52,128.62
		 c34.53,12.68,70.23,17.42,106.62,12.55c27.5-3.68,54.67-9.79,82.05-14.47c13.88-2.37,27.9-3.96,42.13-5.94
		 c-3.77-0.57-7.62-1.43-11.52-1.68c-22.81-1.5-45.14,2.39-67.44,6.36c-24.94,4.44-49.93,8.06-75.41,7.52
		 c-39.38-0.85-75.75-12.01-109.62-31.55c-53.85-31.06-91.6-76.2-113.4-134.15c-5.36-14.25-7.02-30.09-9.04-45.37
		 c-4.17-31.58,0.02-62.82,5.96-93.81c3.28-17.14,12.75-31.32,23.47-44.64c11.49-14.27,22.72-28.79,34.98-42.38
		 c28.9-32.01,65.7-48.99,108-55.08c39.54-5.69,79.1-5.34,118.55,0.15c10.7,1.49,21.12,5.42,31.46,8.86c3.1,1.03,5.71,3.97,8.15,6.43
		 c19.97,20.19,36.11,43.01,47.17,69.29c10.51,24.97,20.27,50.3,31.81,74.78c12.05,25.56,25.97,50.24,38.76,75.46
		 c4.95,9.77,9.16,19.93,13.46,30.01c2.58,6.05,2.68,12.36,0.06,18.48c-9.61,22.45-19.3,44.86-28.98,67.28
		 c-0.11,0.26-0.48,0.4-0.07,0.08c0-14.03,1.56-28.78-0.32-43.07c-4.25-32.27-14.23-62.87-32.03-90.52
		 c-14.58-22.66-36.17-36.86-59.31-49.18c-4.84-2.58-9.67-5.19-14.51-7.78c-17.17-9.18-31.97-21.25-43.05-37.29
		 c-9.88-14.31-18.91-29.2-28.65-44.29c-0.37-0.6-0.38-0.78-0.48-0.9c-0.05-0.06-0.24,0-0.37,0.01c0.03,0.41,0.06,0.83,0.09,1.24
		 c0.33-0.23,0.65-0.47,0.98-0.7c-0.23,0.92-0.73,1.87-0.65,2.77c1.55,18.58,10.89,33.87,20.76,48.91
		 c12.27,18.68,28.89,32.47,48.32,43.17c10.34,5.7,20.55,11.64,30.72,17.64c21.46,12.66,37.25,30.61,47.75,53.04
		 c11.91,25.45,21.22,51.72,22.07,80.25c0.1,3.33,0.05,6.66-0.04,9.99c-0.07,2.65-0.3,5.29-0.46,8.02c-4.74-0.46-9.27-0.5-13.59-1.48
		 c-2.1-0.48-4.72-2.32-5.54-4.23c-23.85-55.1-69.61-84.76-122.66-106c-6.17-2.47-12.48-4.58-18.69-6.94
		 c-7.54-2.86-13.27-7.96-16.11-15.44c-5.35-14.13-9.76-28.62-15.27-42.69c-5.8-14.81-12.41-29.31-18.74-43.91
		 c-0.61-1.41-1.75-2.59-3.08-3.67c1.37,19.21,9.78,36.45,14.89,54.55c5.11,18.11,8.75,36.96,20.83,52.37
		 c-19.52,8.72-31.03,29.4-29.52,50.9c1.92,27.34,22.19,51.56,54.43,59.38c8.25,2,16.51,3.99,25.04,5.4
		 c-0.88-0.5-1.74-1.01-2.63-1.49c-14.06-7.73-28.64-14.67-42.08-23.36c-28.16-18.21-33.1-53.65-11.63-77.89
		 c1.1-1.24,2.28-2.43,3.53-3.53c6.2-5.48,6.61-5.33,14.12-1.87c6.94,3.19,14.05,6.03,21.18,8.78
		 c32.28,12.44,60.64,31.03,85.87,54.54c12.46,11.62,22.51,25.02,28.6,41c3.97,10.42,10.55,16.2,22.06,15.71
		 c2.45-0.1,4.93,0.65,7.76,1.06c0,2.26,0.07,4.52-0.02,6.78c-0.11,3.16-0.83,6.39-0.35,9.45c0.34,2.15,1.86,5.22,3.52,5.75
		 c1.64,0.52,4.36-1.34,6.18-2.72c1.38-1.04,2.31-2.86,3.07-4.5c12.6-27.3,25.3-54.55,37.6-81.98c3.76-8.38,3.64-17.54-0.18-25.81
		 c-9.32-20.2-19.28-40.1-28.97-60.13c-0.27-0.55-0.46-1.13-0.05-2.48c1.22,0.76,2.65,1.31,3.61,2.31
		 c14.06,14.68,27.84,29.63,42.11,44.11c25.34,25.71,43.4,55.87,55.85,89.56c0.73,1.98,1.54,3.94,2.71,6.92
		 c0.21-1.72,0.49-2.46,0.34-3.09c-4.59-19.39-7.86-39.19-17.61-57c-8.16-14.89-18.54-28.12-30.68-39.85
		 c-21.08-20.38-42.64-40.25-63.76-60.59c-3.87-3.72-7.54-8.21-9.7-13.06c-10.67-24-20.46-48.39-31.22-72.35
		 c-7.15-15.91-15.29-31.38-23.2-46.94c-6.28-12.33-15.48-22.41-25.3-32c-2.91-2.84-4.58-5.96-5.34-10.52
		 c-8.28-49.53-13.93-99.59-28.29-147.94c-2.05-6.91-5.26-13.48-8.53-21.71c-3.37,4.8-6.11,8.38-8.5,12.17
		 c-14.71,23.27-32.86,43.16-56.39,57.8c-2.96,1.84-5.99,3.55-9.02,5.34c-18.72-40.59-4.92-100.6,28.52-123.3
		 c10.19,13.96,25.7,20.06,40.82,26.51c20.75,8.86,38.42,21.86,53.75,38.18c1.72,1.83,2.8,4.77,3.11,7.32
		 c1.99,16.36,7.7,31.6,13.68,46.77c14.77,37.44,38.05,69.25,64.92,98.76c16.93,18.6,33.69,37.37,50.18,56.36
		 c27.86,32.1,47.42,68.79,59.61,109.49c3.38,11.29,7.55,22.35,11.76,34.68c-0.69-17.54-5.99-32.76-10.25-48.25
		 c-13.57-49.32-42.59-89.31-75.67-126.99c-19.45-22.15-39.34-43.99-57.58-67.11c-22.8-28.89-37.17-62.37-48.39-97.2
		 c0.41-0.34,0.83-0.69,1.24-1.03c5.09,5.68,10.79,10.95,15.1,17.17c3.84,5.55,8.6,7.25,14.51,6.27c12.79-2.12,25.51-4.69,38.26-7.07
		 c0.63-0.12,1.28-0.18,2.79-0.38c2.58,17,5.15,33.84,7.61,50.07c7.43-11.54,16.93-22.46,21.39-36.73c7.19-23.01,13.33-46,11.3-70.43
		 c-1.08-13.05-5.3-25.01-12.45-35.87c-5.04-7.64-10.31-15.17-15.96-22.36c-20.92-26.62-36.28-56.54-51.63-86.47
		 c-0.65-1.27-0.47-3.57,0.26-4.85c14.21-24.71,32.96-45.52,55.19-63.24c1.1-0.87,3.38-1.1,4.77-0.64
		 c20.75,6.88,38.93,17.89,53.92,33.96c6.59,7.07,13.26,14.07,20.12,20.87c6.89,6.82,14.86,11.73,25.56,12.16
		 c0,7.75-0.11,15.37,0.03,22.98c0.13,6.66,0.25,13.34,1.01,19.95c0.23,2.01,2.21,4.67,4.02,5.35c1.29,0.49,4.57-1.38,5.37-2.98
		 c15.96-31.94,32.73-63.56,41.64-98.48c5.44-21.31,3.71-42.28-3.46-62.87c-3.87-11.12-8.2-22.09-12.88-34.58
		 c12.36,1.52,24.38,2.25,36.09,4.57c60.88,12.07,110.91,42.89,151.79,89.12c1.73,1.96,3.2,4.28,4.28,6.66
		 c16.52,36.23,23.6,74.12,22.04,114.03c-0.9,23.07,0.98,46.25,1.51,69.39c0.57,24.8,1.38,49.6,1.39,74.4
		 c0.01,12.18-4.43,23.27-13.25,31.99c-19.15,18.94-38.55,37.61-61.32,52.87c-0.51-0.13-0.86-0.41-0.86-0.41
		 c12.5-17.1,23.51-35.03,30.53-55.17c14.46-41.44,6.4-79.06-20.21-112.91c-9.83-12.5-21.6-23.47-32.43-35.18
		 c-6.99-7.56-14.41-14.8-20.71-22.91c-10.21-13.13-14.74-28.63-15.39-45.06c-0.38-9.75,0.63-19.55,0.78-29.48
		 c-0.03-0.08,0.17-0.02,0.38,0.04c-0.23-0.25-0.46-0.5-0.68-0.75c-1.46,1.99-2.65,4.28-4.44,5.91c-5.41,4.94-6.47,11-6.31,18.03
		 c0.62,27.37,10.09,50.88,29.1,70.69c13.37,13.93,27.15,27.59,39.3,42.55c18.72,23.07,29.33,49.42,26.64,79.9
		 c-1.51,17.15-7.1,33.1-14.43,48.52c-2.27,4.77-4.78,9.43-7.18,14.14c-4.98-1.47-9.57-2.84-14.16-4.19c-2.38-0.7-3.58-1.85-2.96-4.7
		 c6.37-29.21-0.82-56.13-14.44-81.75c-9.14-17.19-21.75-31.86-35.26-45.78c-7.31-7.53-14.72-14.98-21.81-22.72
		 c-13.2-14.43-17.69-32.49-20.53-51.11c-1.89-12.34-3.46-24.72-5.23-37.07c-0.15-1.07-0.67-2.08-1.11-3.38
		 c-7.55,21.5-2.1,42.38,0.91,63.73c-16.99-0.43-32.45,5.1-46.85,13.34c-42.46,24.27-61.62,61.47-59.72,109.93
		 c1.04,26.61,7.18,52.32,18.21,76.27c13.96,30.32,29.83,59.78,45.37,89.35c16.79,31.96,34.05,63.64,45.69,98.03
		 c11.84,34.97,18.4,70.82,19.13,107.7c0.43,21.55-5.09,42.13-10.97,62.63c-2.23,7.79-3.91,15.74-5.85,23.62
		 c0.46,0.16,0.93,0.31,1.39,0.47c1.48-3.27,3.04-6.51,4.44-9.82c9.71-23.05,17.81-46.56,18.61-71.89
		 c1.47-46.57-7.16-91.39-23.56-134.87c-12.96-34.37-31.15-66.18-47.86-98.73c-12-23.37-23.26-47.1-34.88-70.67
		 c-2.77-5.62-1.79-10.44,2.12-15.33c27.6-34.55,58.47-65.7,94.8-91.11c8.56-5.99,17.9-10.85,26.85-16.28
		 c3.37-2.04,5.5-1.86,7.98,1.95c5.9,9.04,13.08,17.27,18.71,26.46c11.08,18.11,16.26,38.21,17.09,59.32
		 c0.22,5.57-1.09,11.24-2.09,16.79c-1.3,7.22,0.37,11.19,7.18,13.87c4.3,1.69,8.91,2.61,14,4.05c-1.23,2.31-2.62,4.84-3.94,7.4
		 c-1.59,3.09-3.35,6.12-4.58,9.35c-0.48,1.26-0.23,3.38,0.59,4.38c0.67,0.82,2.87,1.1,4.03,0.67c3.39-1.26,6.92-2.56,9.83-4.64
		 c16.06-11.44,32-23.05,47.86-34.77c4.67-3.45,8.92-7.48,13.37-11.23c13.79-11.62,20.1-26.87,20.24-44.47
		 c0.19-23.64-0.44-47.28-0.72-70.99c0.06,0.02,0.94,0.21,1.69,0.62c19.35,10.52,34.38,26.01,48.54,42.46
		 c17.58,20.41,30.47,43.89,44.22,66.86c9.1,15.21,19.71,29.56,30.24,43.86c3.62,4.92,4.51,9.78,4.07,15.36
		 c-0.6,7.72-1.39,15.42-1.9,23.15c-1.21,18.59-1.94,37.23-3.53,55.79c-3.05,35.59-3.28,71.22-2.17,106.88
		 c0.08,2.66,0.1,5.35,0.45,7.98c2.05,15.4-2.6,29.25-9.98,42.36c-16.04,28.47-35.19,54.58-59.38,76.73
		 c-2.68,2.46-6.05,4.52-9.47,5.69c-2.07,0.71-5.1-0.19-7.18-1.31c-0.79-0.43-0.46-3.55-0.14-5.36
		 c5.37-30.79,12.46-61.39,15.85-92.39c4.81-43.97-3.5-85.64-33.18-120.26c-11.9-13.87-11.79-28.04-8.36-44.24
		 c4.54-21.45,8.06-43.12,11.67-64.76c0.79-4.74,0.12-9.73,0.12-14.61c-0.48-0.01-0.96-0.02-1.44-0.04
		 c-6.63,29.32-13.16,58.67-19.95,87.96c-3.13,13.51-1,25.56,7.62,36.68c10.01,12.91,19.37,26.3,24.93,41.87
		 c9.82,27.5,12.51,55.81,8.34,84.63c-2.15,14.8-5.47,29.42-8.3,44.12c-0.21,1.1-0.7,2.15-1.03,3.16
		 c-7.37-2.75-14.59-5.17-21.53-8.22c-1.59-0.7-2.83-3.42-3.22-5.4c-4.13-21.23-7.13-42.73-12.31-63.7
		 c-4.85-19.62-11.65-38.78-18.14-57.96c-4.83-14.27-10.31-28.34-15.94-42.32c-1.8-4.47-4.71-8.6-7.66-12.45
		 c-3.53-4.61-4.38-9.45-2.62-14.76c6.26-18.92,12.49-37.85,19.02-56.67c2.92-8.41,6.53-16.59,9.83-24.87
		 c-0.44-0.23-0.89-0.46-1.33-0.68c-4.32,7.18-9.05,14.16-12.87,21.6c-7.61,14.78-14.66,29.86-22.09,44.74
		 c-1.9,3.8-4.34,7.32-6.69,11.22c-4.43-5.8-10.58-5.9-15.85-7.15c-12.29-2.91-22.7,2.15-31.92,9.79
		 c-19.22,15.92-30.79,36.72-36.19,60.69c-4.61,20.49-0.77,40.15,10.71,57.84c9.24,14.23,19.49,27.8,28.74,42.02
		 c7.99,12.28,12.36,25.93,14.23,40.59c3.21,25.19-4.13,48.5-11.47,71.91c-6.06,19.32-12.23,38.61-18,58.02
		 c-7.4,24.88-15.51,49.62-21.39,74.87c-6.86,29.46-11.18,59.5-17.47,89.11c-4.62,21.76-9.38,43.63-16.33,64.71
		 c-11.78,35.79-35.95,62.53-67.77,81.78c-36.34,21.98-75.22,38.53-116.87,47.67c-12.3,2.7-25.09,3.15-37.65,4.74
		 c-4.58,0.58-9.12,1.51-15.19,2.53c2.2-1.84,3.28-2.71,4.33-3.62c14.35-12.44,28.68-24.89,43.04-37.32
		 c19.53-16.9,39.17-33.68,58.55-50.74c4.97-4.38,9.69-9.25,13.63-14.56c9.73-13.09,13-27.83,9.61-43.93
		 c-2.58-12.25-13.29-34.42-21.39-43.13c2.91,8.57,5.41,16.04,7.98,23.5c3.24,9.43,6.92,18.76,5.8,29.05
		 c-1.38,12.64-8.02,22.51-17.08,30.74c-9.25,8.39-19.27,15.93-28.54,24.29c-25.75,23.19-51.41,46.48-76.85,70.02
		 c-12.88,11.91-26.05,23.13-43.23,28.41c-1.35,0.42-2.52,1.41-4.62,2.62c21.73,35.78,43.38,71.31,64.92,106.91
		 c21.9,36.21,49.35,68.17,75.98,100.46c-8.1,22.44-16.3,45.19-23.94,66.35c-2.47-12.86-4.94-27.68-8.2-42.33
		 c-4.98-22.42-11.03-44.6-21-65.4c-3.56-7.44-7.94-14.74-13.22-21.04c-11.6-13.83-26.37-13.47-35.93,1.73
		 c-7.27,11.56-13.22,24.32-17.54,37.28c-6.77,20.3-11.68,41.23-17.37,61.89c-0.16,0.6-0.33,1.19-0.86,3.08
		 c-0.89-2.92-1.48-4.68-1.96-6.47c-5.19-19.57-12.41-38.37-23.5-55.32c-4.94-7.55-11.15-14.65-18.04-20.46
		 c-11.07-9.32-23.59-8.6-32.74,2.54c-7.17,8.73-12.51,19.11-17.75,29.24c-3.93,7.59-6.4,15.94-9.72,24.48
		 c-1.78-17.28-5.72-33.59-11.79-49.31c-8.23-21.34-19.17-41.33-32.69-59.71c-14.46-19.65-29.8-38.65-44.73-57.96
		 c-15.73-20.34-31.93-40.34-46.95-61.18c-10.29-14.28-16.71-30.57-17.09-48.59c-0.05-2.32,1.13-5.22,2.73-6.92
		 c22.97-24.41,49.41-44.59,77.23-63.09c5.68-3.78,11.35-7.6,17.15-11.19c13.96-8.63,24-20.56,29.74-35.86
		 c7.3-19.44,14.13-39.06,21.31-58.56c5.77-15.67,12.79-30.7,23.99-44.01c0.34-0.64,0.36-0.9,0.37-1.15
		 c-0.37,0.15-0.74,0.29-1.12,0.44c-6.51,2.61-11.52,6.86-15.53,12.71c-11.21,16.33-18.47,34.56-26.09,52.64
		 c-4.77,11.33-9.8,22.56-14.79,33.81c-5.43,12.26-14.09,21.58-25.52,28.6c-23.01,14.14-45.41,29.19-65.71,47.08
		 c-9.86,8.69-19.1,18.08-28.42,27.36c-2.2,2.19-3.15,2.28-6,0.56c-10-6.02-20.2-11.82-30.76-16.8
		 c-15.12-7.13-30.32-14.3-46.03-19.97c-45.45-16.43-92.46-21.85-140.58-17.81c-1.65,0.14-3.36,0.39-4.97,0.14
		 c-7.28-1.13-8.49-0.25-12.82,6.07c-16.07,23.48-31.87,47.16-48.74,70.06c-11.68,15.85-26.27,29.17-41.61,41.6
		 c-26.94,21.83-57.89,33.87-92.13,37.16c-26.34,2.53-51.59,9.55-76.94,16.4c-0.64,0.17-1.26,0.44-1.87,0.69
		 c-14.69,6.09-13.28,7.86-9.63,19.69c10.99,35.58,25.55,69.73,42.76,102.71c9.04,17.34,18.75,34.36,31.39,49.43
		 c3.22,3.83,7.49,6.77,11.77,10.56c-0.91,7.44-1.94,15.92-3.05,25.01c-1.17-1.65-2.09-2.93-2.98-4.23
		 c-13.7-20.2-31.26-35.87-53.97-45.2c-22.59-9.28-40.62-4.72-56.34,13.94c-12.99,15.42-20.72,33.59-27,52.48
		 c-0.98,2.94-1.87,5.91-3.05,9.68c-3.22-8.18-5.93-15.56-9.02-22.78c-5.88-13.7-13.31-26.49-23.71-37.32
		 c-19.99-20.85-43.03-22.05-65.15-3.49c-1.66,1.39-3.24,2.87-5.35,4.18c-0.62-0.3-0.75-0.46-0.88-0.63
		 c3.5-10.26,7.01-20.51,10.49-30.78c8.24-24.36,15.03-49.1,18.6-74.61c2.29-16.36,4.78-32.75,5.87-49.21
		 c1.41-21.32,1.45-42.73,2.2-64.1c0.55-15.45,4.92-20.13,17.17-24c25.34-8,50.97-15.09,76.59-22.16
		 c13.77-3.79,27.76-6.8,41.67-10.04c3.19-0.75,4.25-2.59,4.92-5.7c2.82-13.16,6.33-26.17,9-39.36
		 c13.29-65.82,33.85-129.07,66.35-188.08c9.26-16.81,20.33-32.34,33.93-46.12c4.4-4.46,6.73-10.98,10.29-17.14
		 C674.78,1383.91,674.29,1383.82,673.8,1383.74z M1722.54,689.03c-1.36-19.87-8.02-38.28-15.58-56.44
		 c-1.35-3.24-3.26-6.07-7.39-5.21c-3.81,0.79-4.51,3.85-4.76,7.25c-0.38,5.28-1.27,10.54-1.46,15.82
		 c-0.19,5.29-0.3,10.68,0.47,15.89c2.13,14.43,13.14,23.93,20.58,35.39c0.72,1.11,3.86,1.58,5.36,1c1.48-0.56,3-2.73,3.26-4.38
		 C1723.45,695.63,1722.87,692.74,1722.54,689.03z M1291.51,1055.94c-2.36,8.6-1.43,17.17,0.58,25.63c0.55,2.32,1.73,4.97,3.48,6.44
		 c10.34,8.7,20.94,17.1,31.45,25.61c0.69-0.33,1.38-0.66,2.07-0.98c0-2.33,0.74-5-0.12-6.95c-5.09-11.5-10.04-23.11-16.09-34.11
		 c-3.29-5.99-8.44-11.03-13.17-16.11c-1.81-1.94-4.8-2.79-8.1-4.61c0.2,1.98,0.25,2.42,0.29,2.86
		 C1291.94,1054.21,1291.97,1054.71,1291.51,1055.94z M1854.76,968.19c-0.5,0.86-1.02,1.71-1.5,2.59
		 c-10.63,19.1-14.27,38.51-2.83,58.72c1.73,3.05,2.78,6.48,4.15,9.75c13.94-24.57,12.33-49.09,2.06-73.75
		 C1856.22,966.05,1855.66,966.81,1854.76,968.19z"
      />
      <path
        d="M1202.92,648.1c8.78,22.75,20.85,43.63,35.29,63.25c14.29,19.43,33.77,29.76,57.3,31.16
		 c36.65,2.17,59.01,24.07,75.21,53.6c8.69,15.86,14.14,33.49,21.14,50.55c-14.19,2.41-26.88,4.6-39.59,6.71
		 c-4.52,0.75-7.56-1.62-10.12-5.04c-5.29-7.06-10.4-14.28-16.06-21.04c-17.37-20.72-37.92-37.32-63.16-47.67
		 c-3.54-1.45-6.89-3.37-10.47-4.68c-18.02-6.6-30.57-18.99-38.86-36.07c-6.75-13.92-13.66-27.76-20.52-41.63
		 c-1.01-2.04-2.18-4.01-4.27-5.77c1.31,7.84,2,15.86,4.1,23.48c2.14,7.78,5.36,15.32,8.65,22.72c3.29,7.41,7.26,14.52,10.97,21.84
		 c-10.57,2.98-17.79,10.47-24.06,18.98c-12.84,17.44-19,37.42-22.59,58.55c-2,11.75-2.29,23.23,1.27,34.55
		 c2.23,7.09,5.46,13.86,8.37,21.07c-10.28,5.04-21.41,8.15-32.94,9.83c-21.14,3.07-41.53,1.1-60.43-9.76
		 c-2.3-1.32-4.39-3.03-6.69-4.35c-4.17-2.41-7.66-1.42-9.27,3.04c-5.17,14.26-14.12,25.97-24.44,36.73
		 c-13.34,13.9-28.63,25.39-45.26,35.78c-3.68-7.31-7.25-14.53-10.93-21.7c-17.54-34.17-31.93-69.54-42.58-106.5
		 c-24.64-85.52,10.08-199.01,111.49-249.81c31.15-15.61,63.8-24.47,98.38-27.06c32.98-2.47,65.55-0.52,97.6,8.07
		 c9.67,2.59,17.94,7.26,25.45,14.08c15.05,13.65,30.77,26.57,46,40.02c3.03,2.68,5.64,6.15,7.54,9.74
		 c9.16,17.36,18.17,34.8,26.92,52.36c13.58,27.26,30.31,52.47,49.44,76.07c14.35,17.71,22.63,37.41,19.95,60.38
		 c-1.51,12.97-4.35,25.87-7.64,38.52c-2.47,9.5-6.62,18.56-10.42,28.9c-2.3-13.2-3.63-25.16-6.59-36.7
		 c-3.99-15.56-8.5-31.08-14.13-46.1c-7.73-20.62-21.06-37.61-37.32-52.41c-14.77-13.43-32.72-18.82-51.93-20.74
		 c-22.69-2.27-40.9-12.04-54.36-30.52c-5.98-8.21-12.03-16.37-18.07-24.53c-7.02-9.48-14.08-18.93-21.52-28.89
		 c-0.76-0.58-1.12-0.67-1.48-0.75C1202.49,646.92,1202.7,647.51,1202.92,648.1z M1267.82,649.84c8.84,2.87,17.61,5.97,26.55,8.44
		 c2.1,0.58,5.57-0.22,6.93-1.72c0.96-1.06,0.07-4.82-1.13-6.63c-10.48-15.76-23.91-28.53-40.06-38.42
		 c-3.16-1.94-7.12-3.43-9.42-0.04c-1.45,2.15-1.02,6.22-0.22,9.1c1.44,5.2,3.9,10.11,5.72,15.22
		 C1258.3,641.65,1261.6,646.45,1267.82,649.84z"
      />
      <path
        d="M359.35,1152.38c-6.65,7.97-13.31,15.49-19.5,23.37c-12.54,15.96-25.34,31.77-37.08,48.31
		 c-10.55,14.87-20.69,30.16-29.48,46.11c-14.68,26.63-25.77,54.73-32.51,84.61c-4.73,20.95-6.06,42.01-4.72,63.13
		 c1.16,18.24,5.23,36.19,11.78,53.4c1.43,3.76,0.71,6.02-2.51,8.65c-28.62,23.43-57.66,46.44-82.76,73.8
		 c-9.64,10.51-18.79,21.47-28.51,32.62c-1.39-12.41-2.94-24.53-4.05-36.7c-1.72-18.85-3.55-37.71-4.48-56.61
		 c-0.44-8.91,1.01-17.93,1.77-26.88c2.87-33.86,6.31-67.72,15.09-100.62c5.81-21.79,13.08-43.26,20.88-64.44
		 c9.83-26.69,28.06-48.23,46.03-69.66c18.84-22.47,38.54-44.21,57.43-66.64c12.36-14.68,24.36-29.72,35.76-45.15
		 c9.44-12.79,14.5-27.47,15.6-43.54c1.61-23.53-8.16-42.97-21.57-60.99c-13.89-18.65-30.44-35.26-42.35-55.44
		 c-9.25-15.67-16.34-32.17-18.09-50.42c-0.81-8.43-0.27-17.01,0.06-25.5c0.08-2.08,1.45-4.3,2.73-6.09
		 c1.97-2.75,5.47-2.61,7.18,0.38c5.16,9.04,9.97,18.28,15.14,27.31c15.44,26.99,35.4,50.33,58.25,71.26
		 c12.82,11.74,25.71,23.38,35.64,37.83c1.37,1.99,2.58,4.08,4.02,6.39c8.12-19.92,10.3-40.94,8.67-61.47
		 c-2.5-31.46-10.26-61.77-26.03-89.3c-8.15-14.24-17.77-27.81-28.05-40.62c-19.21-23.92-41.33-45.49-57.56-71.82
		 c-12.57-20.4-21.35-42.25-26.25-65.76c-2.86-13.73-3.56-27.53-2.74-41.37c0.63-10.59,2.06-21.2,4.07-31.62
		 c0.9-4.69,4.09-8.95,6.23-13.4c0.41,0.19,0.82,0.37,1.23,0.56c4.86,19.46,9.28,39.04,14.68,58.34
		 c7.34,26.27,19.61,50.31,36.11,71.97c8.28,10.86,17.46,21.09,26.84,31.04c11.54,12.25,23.89,23.72,35.55,35.87
		 c21.87,22.79,41.08,47.53,54.56,76.36c7.55,16.14,12.71,33.01,15.59,50.55c1.35,8.23,2.67,16.55,2.9,24.86
		 c0.28,9.92,1.88,20.07-2.65,29.69c0.42,0.26,0.85,0.52,1.27,0.77c3.86-4.19,8.04-8.13,11.52-12.62
		 c21.07-27.11,34.95-58.01,47-89.85c12.44-32.86,19.7-66.92,24.27-101.69c1.86-14.16,4.93-28.16,7.6-43.11
		 c4.93,4.51,6.64,9.46,7.08,14.72c1.1,13.27,2.36,26.56,2.64,39.87c0.74,35.19,0.17,70.32-6.24,105.12
		 c-5.95,32.32-18.08,62.32-34.06,90.79c-10.98,19.56-22.06,39.13-34.42,57.82c-9.91,15-21.36,29.06-32.94,42.85
		 c-12.33,14.68-25.62,28.54-38.48,42.77C371.48,1138.8,365.52,1145.45,359.35,1152.38z"
      />
      <path
        d="M1549.27,624.15c-8.28,19.98-16.44,39.59-24.59,59.2c-0.42-0.09-0.84-0.18-1.26-0.26
		 c-0.05-1.17-0.14-2.34-0.16-3.5c-0.35-26.49-0.52-52.98-1.11-79.46c-0.22-9.6-3.1-18.68-8.28-26.89
		 c-14.16-22.43-32.38-40.46-56.34-52.4c-18.77-9.36-37.59-18.6-54.19-31.73c-17.68-13.99-29.43-31.47-33.17-53.98
		 c-0.37-2.23-1.29-4.36-2.12-7.06c-5.86,15.17-5.8,20.84,0.35,35.13c7.94,18.42,21.48,32.21,38.05,42.62
		 c14.75,9.26,30.32,17.33,45.96,25.06c18.79,9.29,34.35,22.09,46.76,38.9c7.57,10.26,12.87,21.24,13.19,34.34
		 c0.32,13.12,1.17,26.24,1.78,39.35c-0.42,0.4-0.84,0.8-1.26,1.19c-5-3.34-10.39-6.23-14.89-10.14c-4.88-4.24-9.15-9.24-13.3-14.23
		 c-18.49-22.21-41.25-37.95-68.95-46.56c-6.81-2.12-13.41-4.95-20-7.71c-29.5-12.35-47.14-34.84-55.47-65.23
		 c-3.03-11.05-6.02-22.12-9.08-33.17c-0.45-1.64-1.12-3.22-2.34-4.63c-3.75,29.47,4.5,56.08,19.07,81.02
		 c-22.4,6.29-43.22,14.37-48.8,41.3c-11.59-10.26-25.08-19.58-33.09-33.57c-5.17-9.03-7.81-19.67-10.69-29.84
		 c-14.3-50.57-32.9-99.34-59.79-144.69c-2.73-4.6-5.74-4.83-9.25-0.64c-11.86,14.19-23.4,28.65-35.66,42.49
		 c-6.59,7.44-14.05,14.23-21.78,20.5c-4.25,3.45-9.88,5.19-14.87,7.74c-1.25,0.64-2.44,1.4-4.59,2.66c0.15-2.02-0.31-4.12,0.5-5.42
		 c15.35-24.59,27.45-51.29,46.85-73.26c30.52-34.56,68.97-54.97,114.22-62.28c37.94-6.13,74.09,2.48,109.53,15.35
		 c29.13,10.57,54.62,26.85,76.43,48.76c5.35,5.38,10.43,11.36,14.25,17.88c21.93,37.49,43.78,75.04,64.96,112.96
		 c10.82,19.36,18.92,40.01,22.21,62.17c2.03,13.74-0.21,27.23-3.68,40.52C1553.18,612.41,1551.17,618.06,1549.27,624.15z
			M1431.61,394.75c-5.86-1.69-5.27,3.15-5.59,6.08c-3.16,28.26,17.13,46.6,41.29,57.51c-2.49-9.41-4.45-18.12-7.17-26.59
		 c-1.76-5.47-3.68-11.37-7.22-15.69C1446.72,408.5,1439.22,402.01,1431.61,394.75z"
      />
      <path
        d="M856.05,1083.96c22.82,6.61,43.73,16.35,62.74,29.96c8.37,6,16.14,12.86,24.67,18.62
		 c4.45,3.01,4.51,6.75,4.1,10.93c-1.08,11.09-2.35,22.17-3.68,33.24c-0.13,1.11-0.93,2.38-1.8,3.14
		 c-22.36,19.71-47.94,34.74-73.44,49.79c-32.97,19.46-66.76,37.32-102.41,51.5c-40.99,16.31-83.66,21.13-127.34,19.16
		 c-13.21-0.6-26.4-1.72-39.67-3.78c4.44-0.5,8.98-0.56,13.29-1.58c9.99-2.37,20.25-4.26,29.7-8.07
		 c15.09-6.09,20.85-16.76,17.87-32.67c-1.46-7.83-4.97-15.37-8.27-22.72c-2.53-5.63-2.45-6.36,3.74-6.56
		 c7.94-0.25,15.92-1.33,23.79-0.79c11.56,0.8,23.05,2.71,34.57,4.17c1.77,0.22,3.51,0.6,5.16,0.89c9.23-15.89,5.4-32.22,2.39-48.31
		 c-1.82-9.73-4.94-19.21-7.57-29.15c29.67,3.63,56.83,14.11,81.98,31.92c3.79-2.68,6.14-7.51,7.35-12.93
		 c7.26-32.78,2.01-63.79-13.43-93.32c-0.66-1.27-1.42-2.49-2.7-4.71C811.07,1073.7,833.53,1077.68,856.05,1083.96z"
      />
      <path
        d="M1169.36,408.21c6.32-8.57,12.43-16.86,18.65-25.29c25.65,55.77,51.07,110.99,63.61,170.78
		 c-7.9-1.65-16.57-3.96-25.39-5.23c-85.25-12.29-163.06,3.72-229.4,61.61c-24.07,21-42.12,46.62-54.6,76.09
		 c-0.8,1.89-1.61,3.78-3.24,5.46c0-0.9-0.05-1.81,0.01-2.71c1.34-20.22-0.27-40.29-3.34-60.26c-2.13-13.85,0.98-26.36,10.27-36.75
		 c6.41-7.16,13.7-13.58,20.89-19.99c5.71-5.09,11.86-9.7,17.85-14.48c16.84-13.45,27.78-30.6,33.56-51.39
		 c2.66-9.56,6.72-18.73,10.25-28.05c1.23-3.25,3.7-4.68,7.26-5.08c15.7-1.78,31.42-3.51,47.06-5.82
		 c26.89-3.97,49.22-16.81,67.63-36.51C1157.02,423.55,1162.93,415.88,1169.36,408.21z"
      />
      <path
        d="M1437.56,234.43c6.05,35.64,8.05,71.14,7.8,107.1c-40.09-27.96-84.87-43.41-133.52-47.4
		 c-68.56-5.62-146.75,33.19-181.88,95.8c-5,8.91-9.69,18-15.23,26.96c0.77-5.99,1.74-11.96,2.26-17.98
		 c0.83-9.79,1.26-19.61,2.04-29.4c0.86-10.72,5.14-19.94,13-27.32c3.63-3.42,7.38-6.77,11.41-9.7
		 c23.22-16.87,40.8-38.57,54.74-63.42c2.6-4.64,5.48-9.19,7.54-14.08c7.95-18.88,20.31-35.17,30.67-52.62
		 c7.1-11.96,15.42-11.58,23.88-0.47c8.5,11.17,17.95,21.48,31.04,27.65c15.41,7.26,31.02,7.08,46.7,1.32
		 c18.14-6.67,30.85-19.98,42.09-35.06c12.67-17.01,20.69-36.3,27.94-56c0.33-0.91,0.65-1.82,1.01-2.71c0.16-0.4,0.38-0.76,0.94-1.85
		 C1424.34,167.17,1431.98,200.28,1437.56,234.43z"
      />
      <path
        d="M1767.42,383.27c2.73-4.36,5.27-8.4,7.81-12.45c0.5,0.1,1,0.19,1.5,0.29c0.26,8.51,0.94,17.04,0.7,25.54
		 c-0.87,30.61-6.97,60.52-14.03,90.12c-6.66,27.94-14.99,55.47-22.53,83.2c-1.04,3.81-1.77,7.71-2.77,12.13
		 c-1.46-1.56-2.57-2.72-3.65-3.91c-41.39-45.56-92-74.63-152.95-84.64c-11.4-1.87-23.2-1.17-34.81-1.84
		 c-1.5-0.09-3.65-0.84-4.3-1.98c-12.58-21.75-24.99-43.61-37.42-65.45c-0.15-0.27-0.14-0.63-0.2-0.92
		 c37.33-7.75,60.18-32.87,76.52-64.31c8.67,20.35,16.91,40.64,25.95,60.57c6.74,14.87,18.81,23.82,35.01,26.12
		 c15.62,2.22,30.9-0.2,45.81-5.3c22.44-7.69,42.35-19.71,60.72-34.59C1756.48,399.63,1763.36,392.91,1767.42,383.27z"
      />
      <path
        d="M1909.44,738.18c5.76-4.07,11.73-7.38,16.54-11.91c8.51-8.01,16.73-16.43,24.25-25.37
		 c3.99-4.73,6.07-11.03,9.36-16.41c2.01-3.27,3.76-8.21,8.49-7.24c4.88,1,4.88,6.27,5.43,10.27c0.54,3.93,0.64,7.99,0.35,11.95
		 c-1.12,15.24-1.68,30.59-3.95,45.66c-2.34,15.55-5.97,30.95-9.86,46.21c-7.83,30.73-20.62,59.69-32.89,88.82
		 c-7.25,17.2-13.78,34.65-17.43,54.05c-6.97-9.64-14.01-18.44-20.03-27.89c-12.14-19.03-23.39-38.63-35.63-57.59
		 c-16.03-24.85-36.47-45.78-59.72-63.96c-4.8-3.76-10.37-6.52-15.37-10.06c-1.38-0.97-2.74-3.05-2.78-4.66
		 c-0.52-20.3-0.82-40.62-1.09-60.93c-0.02-1.21,0.4-2.72,1.17-3.6c7.66-8.7,15.43-17.3,23.21-25.89c0.79-0.87,1.91-1.45,3.9-2.9
		 c0,2.81-0.09,4.36,0.01,5.91c0.8,11.94,0.71,24.03,2.68,35.77c2.72,16.13,10.6,21.64,27.17,22.01c2.55,0.06,5.07,1.79,7.59,2.79
		 c10.09,3.99,20.55,6.77,31.39,6.59C1885.54,749.6,1897.92,745.68,1909.44,738.18z"
      />
      <path
        d="M1249.98,844.8c3.83-7.32,7.47-14.34,11.67-22.41c7.8,49.54,15.49,98.31,23.12,146.8
		 c-1.04,0.56-1.35,0.87-1.54,0.8c-28.7-9.46-58.46-11.18-88.31-11.76c-35.61-0.7-70.86,1.95-105.12,12.71
		 c-40.4,12.69-71.19,38.38-95.69,71.99c-10.32,14.15-19.04,29.47-27.99,43.48c-1.36-10.36-0.8-22.01,1.84-33.54
		 c3.56-15.54,7.04-31.12,11.1-46.53c1.33-5.05,4.13-9.79,6.7-14.43c4.08-7.37,9.67-13.12,17.12-17.61
		 c21.09-12.7,40.41-27.77,55.61-47.36c6.18-7.96,11.11-16.88,16.58-25.33c33.15,20.14,67.94,17.92,102.68,3.15
		 C1207.52,892.12,1231.99,872.49,1249.98,844.8z"
      />
      <path
        d="M1597.52,795.36c2.11-1.66,3.83-3.28,5.81-4.49c9.31-5.71,12.66-6.72,18.94,2.71c1.73,2.6,3.99,4.86,6.3,7.63
		 c-34.02,16.4-61.6,40.95-88.79,66.04C1554.01,839.2,1574.24,816.26,1597.52,795.36z"
      />
      <path
        d="M1364.43,616.85c-0.09-1.12-0.59-2.4-0.2-3.32c5.67-13.55,11.46-27.05,17.28-40.71
		 c7.02,3.12,14.08,6.25,21.61,9.59c-15.31,8.67-27.66,20.4-38.7,34C1364.39,616.76,1364.43,616.85,1364.43,616.85z"
      />
      <path
        d="M378.46,1956.65c-0.28,0.6-0.74,0.93-1.19,1.25c0.01-0.59,0.02-1.19,0.33-1.9
		 C378.02,1956.05,378.15,1956.21,378.46,1956.65z"
      />
      <path />
      <path />
      <path
        d="M1691.81,947.34c-0.24-0.4-0.5-0.82-0.53-1.57c0.23-0.33,0.58-0.05,0.74,0.1c0.19,0.52,0.05,0.8-0.24,1.25
		 C1691.79,947.37,1691.78,947.32,1691.81,947.34z"
      />
      <path
        d="M1364.42,616.77c-0.34-0.18-0.73-0.36-1.11-0.55c0.34-0.04,0.67-0.08,1.06,0.25
		 C1364.43,616.85,1364.39,616.76,1364.42,616.77z"
      />
      <path
        d="M1739.23,1977.11c0.14-0.37,0.43-0.6,0.72-0.82c0.04,0.26,0.07,0.52-0.08,1
		 C1739.68,1977.52,1739.38,1977.25,1739.23,1977.11z"
      />
    </>
  );
}
